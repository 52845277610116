import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';


import Moment from 'react-moment';
import moment from 'moment';

// import Linkify from 'react-linkify';

/* import {
  Row,
  Col,
} from 'reactstrap'; */


import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import LinkifyWithTargetBlank from '../UI/LinkifyWithTargetBlank';

import FileModal from '../UI/FileModal';
import DateFormat from '../UI/DateFormat';
import * as constants from '../../../constants/constants';

import computerImg from '../../../images/icons/computer.png';
import phoneImg from '../../../images/icons/tablet.svg';
import androidImg from '../../../images/icons/android.svg';
import iosImg from '../../../images/icons/apple.svg';
import draugiemImg from '../../../images/icons/draugiem.svg';
import facebookImg from '../../../images/icons/facebook.svg';
import inboxImg from '../../../images/icons/inbox.svg';
import mobileWeb from '../../../images/icons/mobile-browser-development.svg';
import MessageAppInfo from './components/UserMessages/messageAppInfo';
import { breakLongWord } from '../../../utils/stringUtils';

// const AdminSupportMessage = ({ message }) => {


class AdminSupportMessage extends React.Component {
  static propTypes = {
    message: PropTypes.shape(),
    chatMessagesUserLastLanguage: PropTypes.string,
  };

  static defaultProps = {
    message: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      modalOpen: false,
      modalFile: '',
      modalFileName: '',
    };
  }

  onHover = (key) => {
    //  console.log('onHover', { key, old: this.state.popoverOpen });
    if (!this.state.popoverOpen) {
      this.setState({
        popoverOpen: key,
      });
    }
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  openFileLink = (modalFile, modalFileName) => {
    this.setState({
      modalFile,
      modalFileName,
      modalOpen: true,
    });
    // window.open(modalFile, "_blank");
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  }

  renderDeviceIcon = (device) => {
    const deviceInfo = device.toLowerCase();
    switch (deviceInfo) {
      case 'tablet':
        return <Media src={phoneImg} className="admin-chat-env" />
      case 'web':
        return <Media src={computerImg} className="admin-chat-env" />
      case 'dweb':
        return <Media src={computerImg} className="admin-chat-env" />
      case 'mweb':
        return <Media src={mobileWeb} className="admin-chat-env" />
      case 'mobile':
        return <Media src={androidImg} className="admin-chat-env" />
      case 'android':
        return <Media src={androidImg} className="admin-chat-env" />
      case 'ios':
        return <Media src={iosImg} className="admin-chat-env" />
      case 'draugiem':
        return <Media src={draugiemImg} className="admin-chat-env" />
      case 'facebook':
        return <Media src={facebookImg} className="admin-chat-env" />
      case 'inbox':
        return <Media src={inboxImg} className="admin-chat-env" />
      default:
        return null;
    }
  }

  render() {
    const { message, chatMessagesUserLastLanguage, t } = this.props;
    const { popoverOpen, modalOpen, modalFile, modalFileName } = this.state;

    if (message.isFiltered && message.filteredMessage && message.message) {
      let splitMessage = [];
      let splitFilteredMessage = [];

      splitMessage = message.message.split(/\n/);

      splitFilteredMessage = message.filteredMessage.split(/\n/);

      return (
        <Fragment>
          {!message.userUid ? (
            <Fragment>
              {message.date && (
                <div className="admin-chat-date-admin">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={moment(message.date)} />
                  {message.osVersion && <MessageAppInfo message={message} />}
                </div>
              )}
              <LinkifyWithTargetBlank>
                <div className="admin-chat-message-admin message">
                  {message.translationKey ? (
                    <p style={{ marginBottom: 0, lineHeight: '20px', wordBreak: 'break-word' }}>
                      {t(`support.${message.translationKey}`, { ...message.translationKeyData, lng: chatMessagesUserLastLanguage })}
                    </p>
                  ) : (
                    <>
                      {splitMessage.map(row => (
                        <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                          {row}
                        </p>
                      ))}
                    </>
                  )}
                  <a onClick={() => this.openFileLink(message.fileUpload || '', message.fileName || '')}>{message.fileName ? message.fileName : ''}</a>
                </div>
              </LinkifyWithTargetBlank>
            </Fragment>
          ) : (
            <Row style={{
              marginTop: 5, marginBottom: 5, paddingLeft: 10, paddingTop: 5, paddingBottom: 5,
            }}
            >
              <Col
                md="12"
                className="message"
                style={{ paddingLeft: 0 }}
              >
                {message.env ? (
                  this.renderDeviceIcon(message.device || message.env)
                ) : (null)}
                {message.date && (
                  <div className="admin-chat-date-user d-flex flex-column">
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={moment(message.date)} />
                    {message.env && (
                      <MessageAppInfo message={message} />
                    )}
                  </div>
                )}
                <LinkifyWithTargetBlank>
                  <div className="admin-chat-message-user">
                    {/*  {splitMessage.map(row => (
                      <p key={row} style={{ marginBottom: 0, lineHeight: '20px' }}>
                        {row}
                      </p>
                    ))}  */}
                    {splitFilteredMessage.map((row, index) => {
                      let words = [];
                      if (splitMessage[index]) {
                        words = splitMessage[index].split(' ');
                      }
                      const filteredWords = row.split(' ');

                      return (
                        <div key={index}>
                          {filteredWords.map((word, index2) => (
                            <>
                              {(words && words[index2] !== word) ? (
                                <>
                                  <div
                                    style={{ display: 'inline-block', marginRight: 2 }}
                                    id={`chat-popover-${message.messageId}-${index2}`}
                                    onMouseEnter={() => this.onHover(`${message.messageId}-${index2}`)}
                                    onMouseLeave={this.onHoverLeave}
                                  >
                                    {`${word} `}
                                  </div>
                                  <Popover container={'div > div'} popperClassName="last-round-popover" placement="top" isOpen={popoverOpen === `${message.messageId}-${index2}`} target={`chat-popover-${message.messageId}-${index2}`}>
                                    <PopoverBody className="last-round-popover-body">
                                      {/*  {`${words[index2]}`} */}
                                      {`${message.message}`}
                                    </PopoverBody>
                                  </Popover>
                                </>
                              ) : (
                                <>
                                  {`${word} `}
                                </>
                              )}
                            </>
                          ))}
                          <a onClick={() => this.openFileLink(message.fileUpload || '', message.fileName || '')}>{message.fileName ? message.fileName : ''}</a>
                        </div>
                      );
                    })}

                  </div>
                </LinkifyWithTargetBlank>
              </Col>
            </Row>
          )}
          <FileModal fileUpload={modalFile} fileName={modalFileName} toggleModal={this.toggleModal} modalOpen={modalOpen} adminPanelOpen />
        </Fragment>
      );
    }
    {
      const splitMessage = message.message.split(/\n/);
      return (
        <Fragment>
          {!message.userUid ? (
            <Fragment>
              {message.date && (
                <div className="admin-chat-date-admin">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={moment(message.date)} />
                  {message.osVersion && <MessageAppInfo message={message} />}
                </div>
              )}
              <LinkifyWithTargetBlank>
                <div className="admin-chat-message-admin message">
                    {message.translationKey ? (
                      <p style={{ marginBottom: 0, lineHeight: '20px', wordBreak: 'break-word' }}>
                        {t(`support.${message.translationKey}`, { ...message.translationKeyData, lng: chatMessagesUserLastLanguage })}
                      </p>
                    ) : (
                      <>
                        {splitMessage.map(row => (
                          <p style={{ marginBottom: 0, lineHeight: '20px' }}>
                            {breakLongWord(row, 34, {color: 'rgb(255, 132, 0)', textDecoration: 'underline'})}
                          </p>
                        ))}
                      </>
                    )}
                  <a onClick={() => this.openFileLink(message.fileUpload || '', message.fileName || '')} >{message.fileName || ''}</a>
                </div>
              </LinkifyWithTargetBlank>
            </Fragment>
          ) : (
            <Row style={{
              marginTop: 5, marginBottom: 5, paddingLeft: 10, paddingTop: 5, paddingBottom: 5,
            }}
            >
              <Col
                md="12"
                className="message"
                style={{ paddingLeft: 0 }}
              >
                {message.env ? (
                  this.renderDeviceIcon(message.device || message.env)
                ) : (null)}
                {message.date && (
                  <div className="admin-chat-date-user">
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={moment(message.date)} />
                    {message.env && (
                      <MessageAppInfo message={message} />
                    )}
                  </div>
                )}
                <LinkifyWithTargetBlank>
                  <div className="admin-chat-message-user">
                    {splitMessage.map(row => (
                      <p key={row} style={{ marginBottom: 0, lineHeight: '20px' }}>
                        {breakLongWord(row, 34, {color: 'rgb(137, 198, 218)', textDecoration: 'underline'})}
                      </p>
                    ))}
                    <a onClick={() => this.openFileLink(message.fileUpload || '', message.fileName || '')} >{message.fileName || ''}</a>
                  </div>
                </LinkifyWithTargetBlank>
              </Col>
            </Row>
          )}
          <FileModal fileUpload={modalFile} fileName={modalFileName} toggleModal={this.toggleModal} modalOpen={modalOpen} adminPanelOpen />
        </Fragment>
      );
    }
  }
}

/* AdminSupportMessage.propTypes = {
  message: PropTypes.shape(),
};

AdminSupportMessage.defaultProps = {
  message: {},
}; */

export default withTranslation("common")(AdminSupportMessage);
