import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Media,
} from 'reactstrap';
import moment from 'moment';

import momentLocalizer from 'react-widgets-moment';

import * as constants from '../../../constants/constants';

// import cookiesImg from '../../../images/redesign/cookies.svg';
// import spadesWhiteImg from '../../../images/redesign/common/spadesWhite.svg';
// import kreistsWhiteImg from '../../../images/redesign/common/kreistsWhite.svg';
// import diamondsImg from '../../../images/redesign/common/karavs.svg';

import closeImg from '../../../images/icons/close.png';

const CookiesOld = ({ cookiesModalOpen, closeCookiesModal, handleCookiesSet, goToTermsPage }) => {
  const { t } = useTranslation('common');

  if (!cookiesModalOpen) return null;

  return (
    <div className="old-modal root-font-xLarge" style={{position: 'fixed', bottom: 0, left: 0, zIndex: 1, maxWidth: 500}}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t('home.cookies')}</h5>
          <Media src={closeImg} className="notification-header-close" alt="X" onClick={/*closeCookiesModal*/() => handleCookiesSet(false)} />
        </div>

        <div className="modal-body">
          <div className='cookies-new-section-cookies-body-rating-comments-section'>
            <p>{t('home.cookiesContent')}</p>
            <label
              style={{color: '#fed65f', cursor: 'pointer'}}
              onClick={goToTermsPage}
            >
              {t('home.cookiesLink')}
            </label>
          </div>
        </div>

        <div className="modal-footer notification-footer">
          <Button
            color="link"
            className="btn notification-footer-button"
            onClick={() => handleCookiesSet(true)}
          >
            {t('home.acceptCookies')}
          </Button>
          <Button
            color="link"
            className="btn notification-footer-button"
            onClick={() => handleCookiesSet(false)}
          >
            {t('home.declineCookies')}
          </Button>
        </div>
      </div>
    </div>
  );
};

CookiesOld.propTypes = {
  cookiesModalOpen: PropTypes.bool,
  handleCookiesSet: PropTypes.func.isRequired,
  closeCookiesModal: PropTypes.func.isRequired,
  toggleHomeContainer: PropTypes.func.isRequired,
  run: PropTypes.bool,
};

CookiesOld.defaultProps = {
  cookiesModalOpen: false,
  run: false,
};

export default CookiesOld;
