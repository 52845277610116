import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';

import { withTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';

import { connect } from 'react-redux';
import { map, filter } from 'lodash';
import ScrollArea from 'react-scrollbar';

import coinImg from '../../../../../images/redesign/common/coin.svg';

import ScoreTableRow from './ScoreTableRow2p';
import EndResultPules from '../Modals/EndResultModal/EndResultPules';
import EndResultFines from '../Modals/EndResultModal/EndResultFines';
import * as constants from '../../../../../constants/constants';

import { getPoints, cancelPointsListeners, getPules } from '../../../../../actions/points';

class ScoreTable2p extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    player1ShortName: PropTypes.string,
    player2ShortName: PropTypes.string,
    player1Uid: PropTypes.string,
    player2Uid: PropTypes.string,
    points: PropTypes.shape(),
    totalPnts: PropTypes.shape({
      player1: PropTypes.number,
      player2: PropTypes.number,
    }),
    myPos: PropTypes.string,
    scoreTableOpen: PropTypes.bool,
    party: PropTypes.number,
    bet: PropTypes.string,
    fetchPartyLog: PropTypes.func,
    isEndResultTable: PropTypes.bool,
    closeReason: PropTypes.shape(),
    gameType: PropTypes.string,
  };

  static defaultProps = {
    player1ShortName: null,
    player2ShortName: null,
    player1Uid: null,
    player2Uid: null,
    points: {},
    totalPnts: {},
    myPos: null,
    scoreTableOpen: true,
    party: null,
    bet: null,
    fetchPartyLog: null,
    isEndResultTable: false,
    closeReason: null,
    gameType: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
    };

    this.pointsScrollbar = React.createRef();
  }

  componentDidMount = () => {
    const {
      fetchPoints,
      fetchPules,
      roomId,
      cancelListeners,
    } = this.props;
    cancelListeners().then(() => {
      fetchPules(roomId);
      fetchPoints(roomId).then(() => {
        this.scrollToBottom();
      });
    });

  }

  shouldComponentUpdate(nextProps) {
    if (!isEqual(nextProps, this.props)) {
      this.scrollToBottom();
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    const { cancelListeners, roomId } = this.props;

    if (roomId) {
      cancelListeners(roomId);
    }
  }

  scrollToBottom = () => {
    if (this.pointsScrollbar) {
      this.timeoutID = setTimeout(() => {
        if (this.pointsScrollbar) {
          this.pointsScrollbar.scrollArea.scrollBottom();
        }
      }, 1000);
    }
  };

  setLastRef = (el) => {
    this.lastRef = el;
  };

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.pointsScrollbar.scrollYTo(newVal);
  }

  fetchPartyLog = (party) => {
    const { fetchPartyLog, isEndResultTable } = this.props;

    if (fetchPartyLog && isEndResultTable) {
      fetchPartyLog(party);
    }
  }

  renderPoints = (key, index) => {
    const {
      points,
      player1ShortName,
      player2ShortName,
      myPos,
      party,
      isEndResultTable,
    } = this.props;

    return (
      <tr
        key={key}
        className="score-table-row"
        ref={(el) => {
          if (party === index + 2) {
            this.lastRef = el;
          }
        }}
      >
        <ScoreTableRow
          player1={points[key].player1}
          player2={points[key].player2}
          pule={points[key].pule}
          player1ShortName={player1ShortName || ''}
          player2ShortName={player2ShortName || ''}
          myPos={myPos}
          index={points[key].id || null}
          roundIndex={index}
          isEndResultTable={isEndResultTable}
          fetchPartyLog={this.fetchPartyLog}
        />
      </tr>
    );
  };

  renderMissedRound = () => {
    const {
      player1Uid,
      player2Uid,
      player1ShortName,
      player2ShortName,
      myPos,
      party,
      isEndResultTable,
      closeReason,
    } = this.props;

    if (closeReason && closeReason.playerUid) {
      return (
        <tr
          key="missed-row"
          className="score-table-row"
          ref={(el) => { this.lastRef = el; }}
        >
          <ScoreTableRow
            player1={closeReason.playerUid === player1Uid ? -16 : 4}
            player2={closeReason.playerUid === player2Uid ? -16 : 4}
            pule={null}
            player1ShortName={player1ShortName || ''}
            player2ShortName={player2ShortName || ''}
            myPos={myPos}
            index="-"
            roundIndex={party - 1}
            isEndResultTable={isEndResultTable}
            fetchPartyLog={this.fetchPartyLog}
          />
        </tr>
      );
    }
    return null;
  };

  render() {
    const {
      t,
      player1ShortName,
      player2ShortName,
      totalPnts,
      points,
      myPos,
      scoreTableOpen,
      bet,
      isEndResultTable,
      gameType,
      closeReason,
    } = this.props;

    console.log('ScoreTable', isEndResultTable, { totalPnts, points });

    const { scrollPos } = this.state;

    let betRatio;

    if (bet) {
      betRatio = parseInt(bet.replace('1:', ''), 10);
    }
    const pPules = [];
    if (points) {
      map(points, (item, key) => {
        if (item.pule) {
          const puleType = item.pule;
          if (puleType !== constants.COMMON_PULE.p || puleType !== constants.COMMON_PULE._p) {
            pPules.push(puleType);
          }
        }
      });
    }
    // pPules.push("player1");
    // pPules.push("player2");

    return (
      <div className={`score-table ${!scoreTableOpen ? 'score-table-hidden' : ''}`}>
        <div className="score-table-top">
          <table className="score-table-names">
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th className="score-table-id-header width-10">#</th>
                {myPos === 'player1' && (
                  <>
                    <th className="score-table-header score-table-pules-header score-table-names-name">
                      <div className="score-table-header-player">
                        {player2ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player2'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-pules-header score-table-names-name">
                      <div className="score-table-header-player">
                        {player1ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player1'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                  </>
                )}
                {myPos === 'player2' && (
                  <>
                    <th className="score-table-header score-table-pules-header score-table-names-name">
                      <div className="score-table-header-player">
                        {player2ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player2'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                    <th className="score-table-header score-table-pules-header score-table-names-name">
                      <div className="score-table-header-player">
                        {player1ShortName || ''}
                      </div>
                      <div className="plues-section">
                        {
                          pPules && map(filter(pPules, (pule) => { return pule === 'player1'; }), item => (
                            <div className='under-pules' />
                          ))
                        }
                      </div>
                    </th>
                  </>
                )}
                <th className="score-table-pules-header width-15">
                  <div className="score-table-pules-header-player">
                    {t('pules')}
                  </div>
                  <div className="plues-section">
                    {
                      pPules && map(filter(pPules, (pule) => { return pule === 'P'; }), item => (
                        <div className='under-pules' />
                      ))
                    }
                  </div>
                </th>
                {isEndResultTable ? (
                  <th className="score-table-info-header" />
                ) : (null)}
              </tr>
            </thead>
            <tbody />
          </table>
          <div className="score-table-table-wrapper">
            <ScrollArea
              speed={0.55}
              className="score-table-table"
              contentClassName="score-table-table-content"
              verticalContainerStyle={{
                background: 'transparent',
                opacity: 1,
                width: 11,
                marginRight: 2,
              }}
              verticalScrollbarStyle={{
                background: '#fff',
                borderRadius: 1,
                width: 10,
              }}
              minScrollSize={25}
              horizontal={false}
              onScroll={this.updateScrollPos}
              ref={(el) => {
                this.pointsScrollbar = el;
              }}
            >
              <table>
                <thead />
                <tbody>
                  {points && Object.keys(points).map(this.renderPoints)}
                  {(isEndResultTable && closeReason && (closeReason.reason === 'leftRoom') && !closeReason.isAutomated) ? (
                    <>
                      {this.renderMissedRound()}
                    </>
                  ) : (null)}
                </tbody>
              </table>
            </ScrollArea>
          </div>
          <table className="score-table-points">
            <thead />
            {console.log('totalPnts', { totalPnts })}
            <tbody style={{ width: '100%' }}>
              <tr>
                <th className="score-table-points-col-id">
                  <div>{t('points')}</div>
                </th>
                <th className="score-table-points-col">
                  <>
                    {myPos === 'player1' && (
                      <>{(totalPnts && totalPnts.player1) ? totalPnts.player1 : '0'}</>
                    )}
                    {myPos === 'player2' && (
                      <>{(totalPnts && totalPnts.player2) ? totalPnts.player2 : '0'}</>
                    )}
                  </>
                </th>
                <th className="score-table-points-col">
                  <>
                    {myPos === 'player1' && (
                      <>{(totalPnts && totalPnts.player2) ? totalPnts.player2 : '0'}</>
                    )}
                    {myPos === 'player2' && (
                      <>{(totalPnts && totalPnts.player1) ? totalPnts.player1 : '0'}</>
                    )}
                  </>
                </th>
                <th className="score-table-points-col-pules" />
              </tr>
            </tbody>
          </table>
        </div>
        <div className="score-table-bottom">
          <table className="score-table-sum">
            <thead />
            <tbody style={{ width: '100%' }}>
              <tr>
                <th className="score-table-sum-col-id">
                  <div>
                    <Media src={coinImg} className="player-balance-coin" />
                  </div>
                </th>
                <th className="score-table-points-col">
                  <>
                    {myPos === 'player1' && (
                      <>
                        {totalPnts && totalPnts.player1 && betRatio
                          ? betRatio * totalPnts.player1
                          : 0}
                      </>
                    )}
                    {myPos === 'player2' && (
                      <>
                        {totalPnts && totalPnts.player2 && betRatio
                          ? betRatio * totalPnts.player2
                          : 0}
                      </>
                    )}
                  </>
                </th>
                <th className="score-table-points-col">
                  <>
                    {myPos === 'player1' && (
                      <>
                        {totalPnts && totalPnts.player2 && betRatio
                          ? betRatio * totalPnts.player2
                          : 0}
                      </>
                    )}
                    {myPos === 'player2' && (
                      <>
                        {totalPnts && totalPnts.player1 && betRatio
                          ? betRatio * totalPnts.player1
                          : 0}
                      </>
                    )}
                  </>
                </th>
                <th className="score-table-sum-col-pules" />
              </tr>
            </tbody>
          </table>
        </div>
        {isEndResultTable ? (
          <>
            {gameType && gameType.includes('P') ? (
              <EndResultPules t={t} />
            ) : null}
            {closeReason && closeReason.reason && (closeReason.reason === 'missedTurn' || closeReason.reason === 'leftRoom') ? (
              <EndResultFines t={t} />
            ) : null}
          </>
        ) : (null)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if (state.game) {
    return ({
      totalPnts: state.points.totalPoints || {},
      points: state.points.points || {},

      myPos: state.game.myPos || 'player1',
      bet: state.game.globalParams.bet || null,
      party: state.game.globalParams.party || null,
      gameType: state.game.globalParams.gameType || null,
      closeReason: state.game.globalParams.closeReason || null,

      player1ShortName: state.game.players.player1 ? state.game.players.player1.shortName : '',
      player2ShortName: state.game.players.player2 ? state.game.players.player2.shortName : '',

      player1Uid: state.game.players.player1 ? state.game.players.player1.uid : '',
      player2Uid: state.game.players.player2 ? state.game.players.player2.uid : '',
    });
  }
  return null;
};

const mapDispatchToProps = {
  fetchPoints: getPoints,
  fetchPules: getPules,
  cancelListeners: cancelPointsListeners,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('game')(ScoreTable2p));
