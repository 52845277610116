import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';

const PlayerEmotion = React.memo(({
  emotion, posClassName, newDesign, userGift, hidden,
}) => {
  if (hidden) {
    return null;
  }

  return (
    <div className={posClassName ? `${posClassName}-emotion-new ${userGift && `${posClassName}-emotion-new-gift-enabled`}` : null}>
      <Media
        className="player-emotion-image-new"
        src={emotion || ''}
      />
    </div>
  );
});

PlayerEmotion.propTypes = {
  emotion: PropTypes.string,
  posClassName: PropTypes.string,
};

PlayerEmotion.defaultProps = {
  emotion: null,
  posClassName: null,
};

export default PlayerEmotion;
