import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';

import CustomModal from '../../Components/Components/Modal';

// import previousRoundImg from '../../../../../images/Game/previous_round.webp';

// import previousRoundDefault from '../../../../../images/redesign/game/Last game cards.svg';
// import previousRoundHover from '../../../../../images/redesign/game/Last game cards hover.svg';

// import previousRoundHeaderImg from '../../../../../images/Game/previous_round_header.png';
import defaultImageLight from '../../../../../images/redesign/light-mode/common/default_image.svg';
import defaultImageNormal from '../../../../../images/redesign/normal-mode/common/default_image.webp';
import defaultImageDark from '../../../../../images/redesign/common/default_image.svg';

import {
  getPreviousRound,
} from '../../../../../actions/game';
import IconPopover from '../../Components/Components/IconPopover';
import { SCREEN_MODE } from '../../../../../constants/constants';

class PreviousRound extends React.Component {
  static propTypes = {
    cards: PropTypes.arrayOf(PropTypes.string),
    t: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    fetchPreviousRound: PropTypes.func.isRequired,
    fourPRoom: PropTypes.bool,
    roomId: PropTypes.string,
    previousRound: PropTypes.shape(),
    players: PropTypes.shape(),
    sittingOut: PropTypes.string,
    screenMode: PropTypes.string,
    studentRoom: PropTypes.bool,
  };

  static defaultProps = {
    cards: [],
    roomId: null,
    fourPRoom: false,
    previousRound: null,
    players: null,
    sittingOut: null,
    screenMode: SCREEN_MODE.dark,
    studentRoom: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      hover: false,
    };
  }

  componentDidMount() {
  }

/*  shouldComponentUpdate(nextProps, nextState) {
    const { previousRound } = this.props;

    const { modalOpen } = this.state;

    if (nextState.modalOpen !== modalOpen) {
      return true;
    }

    if (!isEqual(nextProps.previousRound, previousRound)) {
      return true;
    }

    return false;
  } */

  toggle = () => {
    const { fetchPreviousRound, playButtonSound, roomId } = this.props;
    const { modalOpen } = this.state;

    playButtonSound();
    if (modalOpen) {
      this.setState({
        modalOpen: false,
      });
    } else {
      fetchPreviousRound(roomId).then(() => {
        ReactGA.event({
          category: 'Game',
          action: 'Open previous round',
        });

        this.setState({
          modalOpen: true,
        });
      });
    }
  };

  onHover = () => {
    this.setState({ hover: true });
  }

  onHoverOut = () => {
    this.setState({ hover: false });
  }

  render() {
    const {
      previousRound, players, t, fourPRoom, sittingOut, screenMode, studentRoom,
    } = this.props;
    const { modalOpen, hover } = this.state;

    const { lastDealtCards, beatCardPoints, playedTricks } = previousRound;

    if (!players) {
      return null;
    }
    const defaultImage = screenMode === SCREEN_MODE.light ? defaultImageLight : (screenMode === SCREEN_MODE.normal ? defaultImageNormal : defaultImageDark);

    let previousSittingOut = null;
    if (fourPRoom && previousRound && previousRound.lastDealtCards) {
      if (!previousRound.lastDealtCards['player1']) {
        previousSittingOut = 'player1';
      } else if (!previousRound.lastDealtCards['player2']) {
        previousSittingOut = 'player2';
      } else if (!previousRound.lastDealtCards['player3']) {
        previousSittingOut = 'player3';
      } else if (!previousRound.lastDealtCards['player4']) {
        previousSittingOut = 'player4';
      }
    }

    // console.log(players, sittingOut, previousRound, "fourDealer===");

    return (
      <>
        <div
          className={`previous-round ${studentRoom ? 'previous-round-student-room' : ''}`}
          onClick={this.toggle}
          onMouseOver={this.onHover}
          onFocus={this.onHover}
          onMouseOut={this.onHoverOut}
          onBlur={this.onHoverOut}
          id="previous-round-popover"
        >
        {/*  <Media
            src={hover ? previousRoundHover : previousRoundDefault}
            className={hover ? "previous-round-image" : "previous-round-image-hover" }
            onClick={this.toggle}
            onMouseOver={this.onHover}
            onFocus={this.onHover}
            onMouseOut={this.onHoverOut}
            onBlur={this.onHoverOut}
          /> */}
          <div
            className={hover ? "previous-round-image-hover" : "previous-round-image" }
          />
        </div>
        <IconPopover text={t('common:popovers.previousRound')} placement="top" targetId="previous-round-popover" popoverOpen={hover} />

        <CustomModal
          isOpen={modalOpen}
          toggle={this.toggle}
          size="lg"
          title={t('previousRoundHeader')}
          addClassName="previous-round-modal"
          footer={(
            <>
              <Button className="question-modal-decline-btn" onClick={this.toggle}>
                {t('ok')}
              </Button>
            </>
          )}
          body={(
            <>
              <Row className='previous-round-section'>
                {(!fourPRoom || (fourPRoom && previousSittingOut && previousSittingOut !== 'player1')) ? (
                    <Col>
                      <Row>
                        <Col xs="3" className="pr-0">
                          <Media className={`previous-round-player-photo previous-round-player-photo-${screenMode}`} src={(players.player1 && players.player1.photo) ? players.player1.photo : defaultImage} alt="" />
                        </Col>
                        <Col xs="9" className="previous-round-player-name">
                          {players.player1 && players.player1.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-cards">
                            {(lastDealtCards && lastDealtCards.player1) && lastDealtCards.player1.map((card, index) => (
                              <div
                                className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                              />
                            ))}
                          </div>
                        </Col>
                    </Row>
                    {studentRoom ? (
                      <>
                        <Row>
                          <Col xs="12" className="pr-0">
                            <div className="previous-round-cards">
                              {(lastDealtCards && lastDealtCards.visible && lastDealtCards.visible.player1) && lastDealtCards.visible.player1.map((card, index) => (
                                <div
                                  className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                                />
                              ))}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" className="pr-0">
                            <div className="previous-round-cards">
                              {(lastDealtCards && lastDealtCards.hidden && lastDealtCards.hidden.player1) && lastDealtCards.hidden.player1.map((card, index) => (
                                <div
                                  className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                                />
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (null)}
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-text mr-1"> {t('tricksCount')} </div>
                          <div className="previous-round-value">
                            {(beatCardPoints && beatCardPoints.tricks) ? (beatCardPoints.tricks.player1 || 0) : 0}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-text mr-1"> {t('pointsCount')} </div>
                          <div className="previous-round-value">
                            {beatCardPoints ? (beatCardPoints.player1 || 0) : 0}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="12" className="previous-round-tricks-text">
                          {t('previousRoundTakenTricks')}
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-played-tricks">
                            {playedTricks ? (Object.keys(playedTricks).map((key, index) => (
                              <>
                                {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player1' ? (
                                  <>
                                    {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                      <div className="previous-round-taken-cards">
                                        {playedTricks[key].cards.map((card, index2) => (
                                          <div
                                            className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                          />
                                        ))}
                                      </div>
                                    ) : (
                                      <div className="previous-round-burried-cards">
                                        {playedTricks[key].cards.map((card, index2) => (
                                          <div
                                            style={{ zIndex: 15 + index }}
                                            className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </>
                                ) : (null)}
                              </>
                            ))) : (null)}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ) : (null)}
                {(!fourPRoom || (fourPRoom && previousSittingOut && previousSittingOut !== 'player2')) ? (
                    <Col>
                      <Row>
                        <Col xs="3" className="pr-0">
                          <Media className={`previous-round-player-photo previous-round-player-photo-${screenMode}`} src={(players.player2 && players.player2.photo) ? players.player2.photo : defaultImage} alt="" />
                        </Col>
                        <Col xs="9" className="previous-round-player-name">
                          {players.player2 && players.player2.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-cards">
                            {(lastDealtCards && lastDealtCards.player2) && lastDealtCards.player2.map((card, index) => (
                              <div
                                className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                              />
                            ))}
                          </div>
                        </Col>
                      </Row>
                    {studentRoom ? (
                        <>
                          <Row>
                            <Col xs="12" className="pr-0">
                              <div className="previous-round-cards">
                                {(lastDealtCards && lastDealtCards.visible && lastDealtCards.visible.player2) && lastDealtCards.visible.player2.map((card, index) => (
                                  <div
                                    className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                                  />
                                ))}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="pr-0">
                              <div className="previous-round-cards">
                                {(lastDealtCards && lastDealtCards.hidden && lastDealtCards.hidden.player2) && lastDealtCards.hidden.player2.map((card, index) => (
                                  <div
                                    className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                                  />
                                ))}
                              </div>
                            </Col>
                          </Row>
                        </>
                      ): (null)}
                      <Row>
                        <Col xs="12" >
                          <div className="previous-round-text mr-1"> {t('tricksCount')} </div>
                          <div className="previous-round-value">
                            {(beatCardPoints && beatCardPoints.tricks) ? (beatCardPoints.tricks.player2 || 0) : 0}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-text mr-1"> {t('pointsCount')} </div>
                          <div className="previous-round-value">
                            {beatCardPoints ? (beatCardPoints.player2 || 0) : 0}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="12" className="previous-round-tricks-text">
                          {t('previousRoundTakenTricks')}
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-played-tricks">
                            {playedTricks ? (Object.keys(playedTricks).map((key, index) => (
                              <>
                                {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player2' ? (
                                  <>
                                    {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                      <div className="previous-round-taken-cards">
                                        {playedTricks[key].cards.map((card, index2) => (
                                          <div
                                            className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                          />
                                        ))}
                                      </div>
                                    ) : (
                                      <div className="previous-round-burried-cards">
                                        {playedTricks[key].cards.map((card, index2) => (
                                          <div
                                            style={{ zIndex: 15 + index }}
                                            className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </>
                                ) : (null)}
                              </>
                            ))) : (null)}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ) : (null)}
                {(!studentRoom && (!fourPRoom || (fourPRoom && previousSittingOut && previousSittingOut !== 'player3'))) ? (
                    <Col>
                      <Row>
                        <Col xs="3" className="pr-0">
                          <Media className={`previous-round-player-photo previous-round-player-photo-${screenMode}`} src={(players.player3 && players.player3.photo) ? players.player3.photo : defaultImage} alt="" />
                        </Col>
                        <Col xs="9" className="previous-round-player-name">
                          {players.player3 && players.player3.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-cards">
                            {(lastDealtCards && lastDealtCards.player3) && lastDealtCards.player3.map((card, index) => (
                              <div
                                className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                              />
                            ))}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-text mr-1"> {t('tricksCount')} </div>
                          <div className="previous-round-value">
                            {(beatCardPoints && beatCardPoints.tricks) ? (beatCardPoints.tricks.player3 || 0) : 0}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-text mr-1"> {t('pointsCount')} </div>
                          <div className="previous-round-value">
                            {beatCardPoints ? (beatCardPoints.player3 || 0) : 0}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="12" className="previous-round-tricks-text">
                          {t('previousRoundTakenTricks')}
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-played-tricks">
                            {playedTricks ? (Object.keys(playedTricks).map((key, index) => (
                              <>
                                {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player3' ? (
                                  <>
                                    {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                      <div className="previous-round-taken-cards">
                                        {playedTricks[key].cards.map((card, index2) => (
                                          <div
                                            className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                          />
                                        ))}
                                      </div>
                                    ) : (
                                      <div className="previous-round-burried-cards">
                                        {playedTricks[key].cards.map((card, index2) => (
                                          <div
                                            style={{ zIndex: 15 + index }}
                                            className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </>
                                ) : (null)}
                              </>
                            ))) : (null)}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ): (null)}
                {
                  // (fourPRoom && players.player4 && !sittingOut) || (fourPRoom && players.player4 && sittingOut && sittingOut !== 'player4') ? (
                  (!studentRoom && fourPRoom && players.player4 && previousSittingOut && previousSittingOut !== 'player4') ? (
                    <Col>
                      <Row>
                        <Col xs="3" className="pr-0">
                          <Media className={`previous-round-player-photo previous-round-player-photo-${screenMode}`} src={(players.player4 && players.player4.photo) ? players.player4.photo : defaultImage} alt="" />
                        </Col>
                        <Col xs="9" className="previous-round-player-name">
                          {players.player4 && players.player4.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-cards">
                            {(lastDealtCards && lastDealtCards.player4) && lastDealtCards.player4.map((card, index) => (
                              <div
                                className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                              />
                            ))}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-text mr-1"> {t('tricksCount')} </div>
                          <div className="previous-round-value">
                            {(beatCardPoints && beatCardPoints.tricks) ? (beatCardPoints.tricks.player4 || 0) : 0}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-text mr-1"> {t('pointsCount')} </div>
                          <div className="previous-round-value">
                            {beatCardPoints ? (beatCardPoints.player4 || 0) : 0}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="12" className="previous-round-tricks-text">
                          {t('previousRoundTakenTricks')}
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="12" className="pr-0">
                          <div className="previous-round-played-tricks">
                            {playedTricks ? (Object.keys(playedTricks).map((key, index) => (
                              <>
                                {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player4' ? (
                                  <>
                                    {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                      <div className="previous-round-taken-cards">
                                        {playedTricks[key].cards.map((card, index2) => (
                                          <div
                                            className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                          />
                                        ))}
                                      </div>
                                    ) : (
                                      <div className="previous-round-burried-cards">
                                        {playedTricks[key].cards.map((card, index2) => (
                                          <div
                                            style={{ zIndex: 15 + index }}
                                            className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </>
                                ) : (null)}
                              </>
                            ))) : (null)}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ) : (null)}
                <Col>
                  <Row>
                    <Col xs="12" className="pr-0">
                      {t('tableCards')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="pr-0">
                      <div className="previous-round-table-cards">
                        {lastDealtCards && lastDealtCards.cardsOnTable && (
                          <>
                            {lastDealtCards.cardsOnTable.map((card, index) => (
                              <div
                                className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                              />
                            ))}
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

          {/*    {lastDealtCards && lastDealtCards.player1 && (
                <div className="previous-round-player1">
                  <Row>
                    <Col sm="4" className="previous-round-player-cards">
                      {t('previousRoundDealtCards')}
                    </Col>
                    <Col sm="4" className="previous-round-player-name">
                      {players && players.player1 && players.player1.name}
                    </Col>
                    <Col sm="4" className="previous-round-player-tricks">
                      {t('previousRoundTakenTricks')}
                    </Col>
                  </Row>
                  <Row className="previous-round-row">
                    <Col sm="3">
                      <div className="previous-round-cards">
                        {lastDealtCards.player1.map((card, index) => (
                          <div
                            className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                          />
                        ))}
                      </div>
                    </Col>
                    <Col sm="9">
                      <div className="previous-round-played-tricks">
                        {playedTricks ? (Object.keys(playedTricks).reverse().map((key, index) => (
                          <>
                            {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player1' ? (
                              <>
                                {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                  <div className="previous-round-taken-cards">
                                    {playedTricks[key].cards.map((card, index2) => (
                                      <div
                                        style={{ zIndex: 15 - index }}
                                        className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                      />
                                    ))}
                                  </div>
                                ) : (
                                  <div className="previous-round-burried-cards">
                                    {playedTricks[key].cards.map((card, index2) => (
                                      <div
                                        style={{ zIndex: 15 - index }}
                                        className={`previous-round-taken-card previous-round-taken-card-${index2} previous-round-card-${card.card}`}
                                      />
                                    ))}
                                  </div>
                                )}
                              </>
                            ) : (null)}
                          </>
                        ))) : (null)}
                      </div>
                    </Col>
                  </Row>
                  <div className="previous-round-points">
                    {beatCardPoints && beatCardPoints.tricks && (
                      <>
                        <b className="display-inline">
                          {`${beatCardPoints.player1 || 0} `}
                        </b>
                        <p className="display-inline">{t('pointsWith')}</p>
                        <b className="display-inline">
                          {` ${beatCardPoints.tricks.player1 || 0} `}
                        </b>
                        <p className="display-inline">
                          {`${
                            beatCardPoints.tricks.player1 === 1
                              ? t('trick')
                              : t('tricks')
                          }`}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              )}
              {lastDealtCards && lastDealtCards.player2 && (
                <div className="previous-round-player2">
                  <Row>
                    <Col sm="4" className="previous-round-player-cards">
                      {t('previousRoundDealtCards')}
                    </Col>
                    <Col sm="4" className="previous-round-player-name">
                      {players && players.player2 && players.player2.name}
                    </Col>
                    <Col sm="4" className="previous-round-player-tricks">
                      {t('previousRoundTakenTricks')}
                    </Col>
                  </Row>
                  <Row className="previous-round-row">
                    <Col sm="3">
                      <div className="previous-round-cards">
                        {lastDealtCards.player2.map((card, index) => (
                          <div
                            className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                          />
                        ))}
                      </div>
                    </Col>
                    <Col sm="9">
                      <div className="previous-round-played-tricks">
                        {playedTricks ? (Object.keys(playedTricks).reverse().map(key => (
                          <>
                            {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player2' ? (
                              <>
                                {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                  <div className="previous-round-taken-cards">
                                    {playedTricks[key].cards.map((card, index) => (
                                      <div
                                        className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                      />
                                    ))}
                                  </div>
                                ) : (
                                  <div className="previous-round-burried-cards">
                                    {playedTricks[key].cards.map((card, index) => (
                                      <div
                                        className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                      />
                                    ))}
                                  </div>
                                )}
                              </>
                            ) : (null)}
                          </>
                        ))) : (null)}
                      </div>
                    </Col>
                  </Row>
                  <div className="previous-round-points">
                    {beatCardPoints && beatCardPoints.tricks && (
                      <>
                        <b className="display-inline">
                          {`${beatCardPoints.player2 || 0} `}
                        </b>
                        <p className="display-inline">{t('pointsWith')}</p>
                        <b className="display-inline">
                          {` ${beatCardPoints.tricks.player2 || 0} `}
                        </b>
                        <p className="display-inline">
                          {`${
                            beatCardPoints.tricks.player2 === 1
                              ? t('trick')
                              : t('tricks')
                          }`}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              )}
              {lastDealtCards && lastDealtCards.player3 && (
                <div className="previous-round-player3">
                  <Row>
                    <Col sm="4" className="previous-round-player-cards">
                      {t('previousRoundDealtCards')}
                    </Col>
                    <Col sm="4" className="previous-round-player-name">
                      {players && players.player3 && players.player3.name}
                    </Col>
                    <Col sm="4" className="previous-round-player-tricks">
                      {t('previousRoundTakenTricks')}
                    </Col>
                  </Row>
                  <Row className="previous-round-row">
                    <Col sm="3">
                      <div className="previous-round-cards">
                        {lastDealtCards.player3.map((card, index) => (
                          <div
                            className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                          />
                        ))}
                      </div>
                    </Col>
                    <Col sm="9">
                      <div className="previous-round-played-tricks">
                        {playedTricks ? (Object.keys(playedTricks).reverse().map(key => (
                          <>
                            {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player3' ? (
                              <>
                                {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                  <div className="previous-round-taken-cards">
                                    {playedTricks[key].cards.map((card, index) => (
                                      <div
                                        className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                      />
                                    ))}
                                  </div>
                                ) : (
                                  <div className="previous-round-burried-cards">
                                    {playedTricks[key].cards.map((card, index) => (
                                      <div
                                        className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                      />
                                    ))}
                                  </div>
                                )}
                              </>
                            ) : (null)}
                          </>
                        ))) : (null)}
                      </div>
                    </Col>
                  </Row>
                  <div className="previous-round-points">
                    {beatCardPoints && beatCardPoints.tricks && (
                      <>
                        <b className="display-inline">
                          {`${beatCardPoints.player3 || 0} `}
                        </b>
                        <p className="display-inline">{t('pointsWith')}</p>
                        <b className="display-inline">
                          {` ${beatCardPoints.tricks.player3 || 0} `}
                        </b>
                        <p className="display-inline">
                          {`${
                            beatCardPoints.tricks.player3 === 1
                              ? t('trick')
                              : t('tricks')
                          }`}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              )}
              {fourPRoom && lastDealtCards && lastDealtCards.player4 && (
                <div className="previous-round-player4">
                  <Row>
                    <Col sm="4" className="previous-round-player-cards">
                      {t('previousRoundDealtCards')}
                    </Col>
                    <Col sm="4" className="previous-round-player-name">
                      {players && players.player4 && players.player4.name}
                    </Col>
                    <Col sm="4" className="previous-round-player-tricks">
                      {t('previousRoundTakenTricks')}
                    </Col>
                  </Row>
                  <Row className="previous-round-row">
                    <Col sm="3">
                      <div className="previous-round-cards">
                        {lastDealtCards.player4.map((card, index) => (
                          <div
                            className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                          />
                        ))}
                      </div>
                    </Col>
                    <Col sm="9">
                      <div className="previous-round-played-tricks">
                        {playedTricks ? (Object.keys(playedTricks).reverse().map(key => (
                          <>
                            {playedTricks[key] && playedTricks[key].cards && playedTricks[key].takeBy === 'player4' ? (
                              <>
                                {(playedTricks[key].cards && playedTricks[key].cards.length > 1) ? (
                                  <div className="previous-round-taken-cards">
                                    {playedTricks[key].cards.map((card, index) => (
                                      <div
                                        className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                      />
                                    ))}
                                  </div>
                                ) : (
                                  <div className="previous-round-burried-cards">
                                    {playedTricks[key].cards.map((card, index) => (
                                      <div
                                        className={`previous-round-taken-card previous-round-taken-card-${index} previous-round-card-${card.card}`}
                                      />
                                    ))}
                                  </div>
                                )}
                              </>
                            ) : (null)}
                          </>
                        ))) : (null)}
                      </div>
                    </Col>
                  </Row>
                  <div className="previous-round-points">
                    {beatCardPoints && beatCardPoints.tricks && (
                      <>
                        <b className="display-inline">
                          {`${beatCardPoints.player4 || 0} `}
                        </b>
                        <p className="display-inline">{t('pointsWith')}</p>
                        <b className="display-inline">
                          {` ${beatCardPoints.tricks.player4 || 0} `}
                        </b>
                        <p className="display-inline">
                          {`${
                            beatCardPoints.tricks.player4 === 1
                              ? t('trick')
                              : t('tricks')
                          }`}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="previous-round-tableCards">
                <div className="previous-round-player-name">
                  {t('tableCards')}
                </div>
                <div className="previous-round-table-cards">
                  {lastDealtCards && lastDealtCards.cardsOnTable && (
                    <>
                      {lastDealtCards.cardsOnTable.map((card, index) => (
                        <div
                          className={`previous-round-card previous-round-card-${index} previous-round-card-${card}`}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>  */}
            </>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  previousRound: state.game.previousRound || {},
  players: state.game.players,
  fourPRoom: state.game.globalParams.fourPRoom,
  sittingOut: state.game.sittingOut,
});
const mapDispatchToProps = {
  fetchPreviousRound: getPreviousRound,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('previousRound')(PreviousRound));
