import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import classNames from 'classnames';

import pro from '../../../../../../images/redesign/common/PRO.svg';
import regularSpeed from '../../../../../../images/redesign/common/karavs.svg';
import speed from '../../../../../../images/redesign/common/Room types speed.svg';
import lightning from '../../../../../../images/redesign/common/Room types lightning.svg';
import unlimited from '../../../../../../images/redesign/common/Room types unlimited.svg';

import defaultImage from '../../../../../../images/redesign/mobile/icons/default_avatar.svg';
import normalDefaultImage from '../../../../../../images/redesign/mobile/normal-mode/icons/default_avatar.svg';
import lightDefaultImage from '../../../../../../images/redesign/mobile/light-mode/icons/default_avatar.svg';

import waitingImage from '../../../../../../images/redesign/mobile/icons/waiting_avatar.svg';
import normalWaitingImage from '../../../../../../images/redesign/mobile/normal-mode/icons/waiting_avatar.svg';
import lightWaitingImage from '../../../../../../images/redesign/mobile/light-mode/icons/waiting_avatar.svg';

import minGamesBell from '../../../../../../images/redesign/rooms-table/Call bell.png';

import joinRoomIcon from '../../../../../../images/redesign/icons/join-room-icon.svg';
import PrivateRoomButton from '../../../../../../images/redesign/icons/private-room-icon.svg';
import leaveRoomIcon from '../../../../../../images/redesign/icons/leave-room-icon.svg';

import joinRoomLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/join-room-icon.svg';
import PrivateRoomLightButton from '../../../../../../images/redesign/mobile/light-mode/icons/private-room-icon.svg';
import leaveRoomLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/leave-room-icon.svg';

import MenuRow from '../../../Components/Components/MenuRow';

import * as constants from '../../../../../../constants/constants';
import { isRegularRoom } from '../../../../../../utils/roomUtils';
import { trimString } from '../../../../../../utils/stringUtils';
import IconPopover from '../../../Components/Components/IconPopover';

const RoomsTableRowRender = React.memo(({
  tooltipId, room, removal, joinedRoom, uid, leaveRoom, activeTournament, popoverOpen, joinRoomClicked, onHover, onHoverLeave, joinRoomClickedFunc, joinPrivateRoomConfirm, screenMode
}) => {
  const { t } = useTranslation('common');

  if (!room || !room.globalParams || !room.playersList) {
    return null;
  }

  if (!room) {
    return null;
  }

  if (removal) {
    console.log('RoomsTableRowRender removal', { room: room.key, removal2: removal, removal: room.removal });
  }

  return (
    <MenuRow key={room.key} screenMode={screenMode}>
      <div key={room.key} data-roomkey={room.key} style={removal ? { opacity: 0 } : { opacity: 1 }} className={`rooms-table-row rooms-table-row-${screenMode}`}>
        <div className='position-relative'>
          <>
            <div className="rooms-table-row-speed">
              {isRegularRoom(room) && (
                <Media className="rooms-table-row-speed-img-regular" src={regularSpeed} alt="" />
              )}
              {room.globalParams.fastGame && (
                <Media className="rooms-table-row-speed-img" src={speed} alt="Ātrā" />
              )}
              {room.globalParams.lightningGame && (
                <Media className="rooms-table-row-speed-img" src={lightning} alt="Zibens" />
              )}
              {room.globalParams.unlimitedGame && ( // TODO 'unlimitedGame' path might change
                <Media className="rooms-table-row-speed-img" src={unlimited} alt="Bez limita" />
              )}
            </div>
            <div className="rooms-table-row-type-wrapper">
              <div className="rooms-table-row-bet">
                {room.globalParams.bet}
              </div>
              <div className="rooms-table-row-type">
                {`${room.globalParams.gameType === 'P' ? t('common.parastas') : t('common.galds')}${room.globalParams.smallGame ? `, ${t('common:newGame.smallGame')}` : ''}`}
              </div>
            </div>

            <div className="rooms-table-row-minGames">
              {room.globalParams.minGames > 1 ? (
                <div className="rooms-table-row-minGames-wrapper">
                  <Media className="rooms-table-row-minGames-bell-img" src={minGamesBell} alt="Ātrā" />
                  <div className="rooms-table-row-minGames-bell-count">{room.globalParams.minGames}</div>
                </div>
              ) : (null)}
            </div>

            <div className="rooms-table-row-pro">
              {room.globalParams.proGame ? (
                <div className="rooms-table-row-pro-wrapper">
                  <Media className="rooms-table-row-pro-img" src={pro} alt="Ātrā" />
                </div>
              ) : (null)}
            </div>
          </>
        </div>
        <div className='players-row-wrapper'>
          <div>
            {(room.playersList && room.playersList.player1) ? (
              <>
                <div className="rooms-table-row-player">
                  <div className={`rooms-table-row-player-image-frame ${room.playersList.player1.photo ? '' : 'rooms-table-row-player-image-frame-default'}`}>
                    <Media className={`rooms-table-row-player-image ${room.playersList.player1.photo ? '' : 'rooms-table-row-player-image-default'}`} src={room.playersList.player1.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                  </div>
                  <div className="mx-2 rooms-table-row-player-name">{trimString(room.playersList.player1.shortName, 10)}</div>
                </div>
              </>
            ) : (
              <div className="rooms-table-row-player">
                <div className={`rooms-table-row-player-image-frame rooms-table-row-player-image-frame-default`}>
                  <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightWaitingImage : (screenMode === constants.SCREEN_MODE.normal ? normalWaitingImage : waitingImage)} />
                </div>
                {/* <div className="mx-2 rooms-table-row-player-name">{`${t('roomsTable.waiting')}...`}</div> */}
              </div>
            )}
          </div>
          <div>
            {(room.playersList && room.playersList.player2) ? (
              <>
                <div className="rooms-table-row-player">
                  <div className={`rooms-table-row-player-image-frame ${room.playersList.player2.photo ? '' : 'rooms-table-row-player-image-frame-default'}`}>
                    <Media className={`rooms-table-row-player-image ${room.playersList.player2.photo ? '' : 'rooms-table-row-player-image-default'}`} src={room.playersList.player2.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                  </div>
                  <div className="mx-2 rooms-table-row-player-name">{trimString(room.playersList.player2.shortName, 10)}</div>
                </div>
              </>
            ) : (
              <div className="rooms-table-row-player">
                <div className={`rooms-table-row-player-image-frame rooms-table-row-player-image-frame-default`}>
                  <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightWaitingImage : (screenMode === constants.SCREEN_MODE.normal ? normalWaitingImage : waitingImage)} />
                </div>
                {/* <div className="mx-2 rooms-table-row-player-name">{`${t('roomsTable.waiting')}...`}</div> */}
              </div>
            )}
          </div>
          <div>
            {(room.globalParams && !room.globalParams.studentRoom) ? (
              <>
                {(room.playersList && room.playersList.player3) ? (
                  <>
                    <div className="rooms-table-row-player">
                      <div className={`rooms-table-row-player-image-frame ${room.playersList.player3.photo ? '' : 'rooms-table-row-player-image-frame-default'}`}>
                        <Media className={`rooms-table-row-player-image ${room.playersList.player3.photo ? '' : 'rooms-table-row-player-image-default'}`} src={room.playersList.player3.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                      </div>
                      <div className="mx-2 rooms-table-row-player-name">{trimString(room.playersList.player3.shortName, 10)}</div>
                    </div>
                  </>
                ) : (
                  <div className="rooms-table-row-player">
                    <div className={`rooms-table-row-player-image-frame rooms-table-row-player-image-frame-default`}>
                      <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightWaitingImage : (screenMode === constants.SCREEN_MODE.normal ? normalWaitingImage : waitingImage)} />
                    </div>
                    {/* <div className="mx-2 rooms-table-row-player-name">{`${t('roomsTable.waiting')}...`}</div> */}
                  </div>
                )}
              </>
            ) : (null)}
          </div>
          <div>
            {(room.globalParams && room.globalParams.fourPRoom) ? (
              <>
                {(room.playersList && room.playersList.player4) ? (
                  <>
                    <div className="rooms-table-row-player">
                      <div className={`rooms-table-row-player-image-frame ${room.playersList.player4.photo ? '' : 'rooms-table-row-player-image-frame-default'}`}>
                        <Media className={`rooms-table-row-player-image ${room.playersList.player4.photo ? '' : 'rooms-table-row-player-image-default'}`} src={room.playersList.player4.photo || (screenMode === constants.SCREEN_MODE.light ? lightDefaultImage : (screenMode === constants.SCREEN_MODE.normal ? normalDefaultImage : defaultImage))} />
                      </div>
                      <div className="mx-2 rooms-table-row-player-name">{trimString(room.playersList.player4.shortName, 10)}</div>
                    </div>
                  </>
                ) : (
                  <div className="rooms-table-row-player">
                    <div className={`rooms-table-row-player-image-frame rooms-table-row-player-image-frame-default`}>
                      <Media className="rooms-table-row-player-image rooms-table-row-player-image-default" src={screenMode === constants.SCREEN_MODE.light ? lightWaitingImage : (screenMode === constants.SCREEN_MODE.normal ? normalWaitingImage : waitingImage)} />
                    </div>
                    {/* <div className="mx-2 rooms-table-row-player-name">{`${t('roomsTable.waiting')}...`}</div> */}
                  </div>
                )}
              </>
            ) : (null)}
          </div>
        </div>

        <div className='rooms-table-row-join-button-wrapper'>
          {(room.playersList.player1 && room.playersList.player1.uid === uid)
            || (room.playersList.player2 && room.playersList.player2.uid === uid)
            || (room.playersList.player3 && room.playersList.player3.uid === uid)
            || (room.playersList.player4 && room.playersList.player4.uid === uid)
            ? (
              <Fragment>
                {room.playersList.player1 && room.playersList.player2 && room.playersList.player3 ? (
                  <Link
                    to={`/zole/${room.key}`}
                    className={classNames(`layout-float-right layout-default-button-${screenMode} room-return-button`, {
                      'private-room-button': room?.globalParams.privateRoom
                    })}
                  >
                    {
                      (room?.globalParams.privateRoom) ? <Media src={screenMode === constants.SCREEN_MODE.light ? PrivateRoomLightButton : PrivateRoomButton} alt={"private-room-button"} className='' /> : <></>
                    }
                    <Media src={screenMode === constants.SCREEN_MODE.light ? joinRoomLightIcon : joinRoomIcon} alt={"enter-room-button"} className='' />
                  </Link>
                ) : (
                  <Button
                    disabled={room.filled}
                    color="link"
                    className={classNames(`room-leave-button layout-float-right layout-default-button-${screenMode}`, {
                      'private-room-button': room?.globalParams.privateRoom
                    })}
                    onClick={() => leaveRoom(room.key)}
                  >
                    {
                      (room?.globalParams.privateRoom) ? <Media src={screenMode === constants.SCREEN_MODE.light ? PrivateRoomLightButton : PrivateRoomButton} alt={"private-room-button"} className='' /> : <></>
                    }
                    <Media src={screenMode === constants.SCREEN_MODE.light ? leaveRoomLightIcon : leaveRoomIcon} alt={"leave-room-button"} className='' />
                  </Button>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <div
                  id={`join-room-button-${room.key}`}
                  onMouseEnter={() => onHover(room.key)}
                  onMouseLeave={onHoverLeave}
                  name={room.key}
                  style={{width: 'max-content'}}
                >
                  {room.globalParams.privateRoom ? (
                    <Button
                      color="link"
                      className={classNames(`room-join-button layout-float-right layout-default-button-${screenMode}`, {
                        'disabled': (activeTournament || joinRoomClicked || joinedRoom),
                        'private-room-button': room?.globalParams.privateRoom,
                      })}
                      onClick={joinPrivateRoomConfirm}
                      name={room.key}
                    >
                      {
                        (room?.globalParams.privateRoom) ? <Media src={screenMode === constants.SCREEN_MODE.light ? PrivateRoomLightButton : PrivateRoomButton} alt={"private-room-button"} className='' /> : <></>
                      }
                      <Media src={screenMode === constants.SCREEN_MODE.light ? joinRoomLightIcon : joinRoomIcon} alt={"enter-room-button"} className='' />
                    </Button>
                  ) : (
                    <Button
                      className={`room-join-button layout-float-right layout-default-button-${screenMode} ${(activeTournament || joinRoomClicked || joinedRoom) && 'disabled'}`}
                      color="link"
                      onClick={joinRoomClickedFunc}
                      name={room.key}
                    >
                      <Media src={screenMode === constants.SCREEN_MODE.light ? joinRoomLightIcon : joinRoomIcon} alt={"enter-room-button"} className='' />
                    </Button>
                  )}
                </div>

                {tooltipId && (
                  <>
                    {(!activeTournament && !joinedRoom) && (
                      <IconPopover text={t('common:popovers.joinRoom')} popoverOpen={!!(popoverOpen && popoverOpen[room?.key])} targetId={tooltipId} />
                    )}
                    {(activeTournament/* && !finishedTournamentLastRound*/) && (
                      <IconPopover text={t('common.inTournamentMessage')} popoverOpen={!!(popoverOpen && popoverOpen[room?.key])} targetId={tooltipId} />
                    )}

                    {(joinedRoom && !activeTournament) && (
                      <IconPopover text={t('common:popovers.alreadyInRoom')} popoverOpen={!!(popoverOpen && popoverOpen[room?.key])} targetId={tooltipId} />
                    )}
                  </>
                )}
              </Fragment>
            )}
        </div>
      </div>
    </MenuRow>
  );
});

RoomsTableRowRender.propTypes = {
  room: PropTypes.shape(),
  joinedRoom: PropTypes.shape(),
  uid: PropTypes.string,
  removal: PropTypes.bool,
  leaveRoom: PropTypes.func.isRequired,
  activeTournament: PropTypes.string,
  popoverOpen: PropTypes.shape(),
  joinRoomClicked: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  onHoverLeave: PropTypes.func.isRequired,
  joinRoomClickedFunc: PropTypes.func.isRequired,
  joinPrivateRoomConfirm: PropTypes.func.isRequired,
  tooltipId: PropTypes.string,
};

RoomsTableRowRender.defaultProps = {
  room: {},
  joinedRoom: null,
  removal: false,
  uid: null,
  activeTournament: null,
  popoverOpen: {},
  joinRoomClicked: false,
  tooltipId: null,
};

export default RoomsTableRowRender;
