import React from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import { withTranslation } from 'react-i18next';

const ChooseGameType = React.memo(({
  chooseTypeClicked, chooseType, t, smallGame, show, studentRoom,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className={`select-type ${studentRoom ? 'select-type-student-room' : ''}`} >
      <div className="select-type-header">
        <div className="modal-title">
          <div className="default-modal-title choose-game-type-header">
            {t('selectAction')}
          </div>
        </div>
      </div>
      <div className="select-type-body">
        <Row>
          <Col xs="12" className="select-type-text mb-2">
            {t('selectGameTypeContent')}
          </Col>
        </Row>
        <Row>
          <Button
            className="layout-default-button select-type-button position-relative room-select-action-modal-btn"
            disabled={chooseTypeClicked}
            onClick={() => chooseType('zole')}
          >
            {t('zole')}
          </Button>
        </Row>
        {smallGame && (
          <Row>
            <Button
              className="layout-default-button select-type-button position-relative room-select-action-modal-btn"
              disabled={chooseTypeClicked}
              onClick={() => chooseType('maza')}
            >
              {t('smallZole')}
            </Button>
          </Row>
        )}
        <Row>
          <Button
            className="layout-default-button select-type-button position-relative room-select-action-modal-btn"
            disabled={chooseTypeClicked}
            onClick={() => chooseType('parasta')}
          >
            {t('takeTable')}
          </Button>
        </Row>
        <Row>
          <Button
            className="layout-default-button select-type-button position-relative room-select-action-modal-btn"
            disabled={chooseTypeClicked}
            onClick={() => chooseType('garam')}
          >
            {t('pass')}
          </Button>
        </Row>
      </div>
    </div>
  );
});

ChooseGameType.propTypes = {
  chooseTypeClicked: PropTypes.bool,
  smallGame: PropTypes.bool,
  show: PropTypes.bool,
  chooseType: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ChooseGameType.defaultProps = {
  chooseTypeClicked: null,
  smallGame: null,
  show: null,
};

export default withTranslation('game')(ChooseGameType);
