import React from 'react';
import PropTypes, { func } from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';

// import ReactStars from "react-rating-stars-component";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
  Media,
} from 'reactstrap';
import moment from 'moment';

import momentLocalizer from 'react-widgets-moment';
import InlineModal from '../../Components/Components/InlineModal';

import * as constants from '../../../../../constants/constants';

import cookiesImg from '../../../../../images/redesign/cookies.svg';
import spadesWhiteImg from '../../../../../images/redesign/common/spadesWhite.svg';
import kreistsWhiteImg from '../../../../../images/redesign/common/kreistsWhite.svg';
import diamondsImg from '../../../../../images/redesign/common/karavs.svg';

const Cookies = ({ cookiesModalOpen, closeCookiesModal, handleCookiesSet, toggleHomeContainer, screenMode, run }) => {
  const { t } = useTranslation('common');
  return (
    <InlineModal
      isOpen={cookiesModalOpen && !run}
      onButtonClose={/*closeCookiesModal*/() => handleCookiesSet(false)}
      inlineClassName={'cookies-new-section'}
      size="lg"
      title={t('home.cookies')}
      footer={null}
      screenMode={screenMode}
      body={(
        <>
          <div className="cookies-new-section-cookies-body">
            <div className='cookies-new-section-cookies-body-header'>
              <div className="cookies-icon">
                <Media src={cookiesImg} className="cookies-icon-main" alt="x" />
                <Media src={spadesWhiteImg} className="cookies-icon-spades" alt="x" />
                <Media src={kreistsWhiteImg} className="cookies-icon-clubs" alt="x" />
                <Media src={diamondsImg} className="cookies-icon-diamonds" alt="x" />
              </div>
            </div>
            <div className='cookies-new-section-cookies-body-rating-comments-section'>
              <p>{t('home.cookiesContent')}</p>
              <label onClick={() => toggleHomeContainer(constants.FOOTER_NAVIGATION_LINK.termsOfUse, true)}>{t('home.cookiesLink')}</label>
            </div>
          </div>
          <div className="cookies-new-section-cookies-footer">
            <Button className="cookies-new-section-cookies-footer-submit-button" onClick={() => handleCookiesSet(true)}>{t('home.acceptCookies')}</Button>
            <Button className="cookies-new-section-cookies-footer-close-button" onClick={() => handleCookiesSet(false)}>{t('home.declineCookies')}</Button>
          </div>
        </>
      )}
    />
  );
};

Cookies.propTypes = {
  cookiesModalOpen: PropTypes.bool,
  handleCookiesSet: PropTypes.func.isRequired,
  closeCookiesModal: PropTypes.func.isRequired,
  toggleHomeContainer: PropTypes.func.isRequired,
  run: PropTypes.bool,
};

Cookies.defaultProps = {
  cookiesModalOpen: false,
  run: false,
};

export default Cookies;
