export const vh = (percent) => {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (percent * h) / 100;
}
  
export const vw = (percent) => {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (percent * w) / 100;
}

export const vmin = (percent) => {
    return Math.min(vh(percent), vw(percent));
}