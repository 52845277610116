// import isEqual from 'react-fast-compare';

import Store from '../store/room';

export const initialState = Store;

const _roomFilled = (room) => {
  if (room.playersList && room.globalParams) {
    if (!room.globalParams.fourPRoom
      && room.playersList.player1 && room.playersList.player1.uid
      && room.playersList.player2 && room.playersList.player2.uid
      && room.playersList.player3 && room.playersList.player3.uid) {
      return true;
    } if (room.globalParams.fourPRoom
    && room.playersList.player1 && room.playersList.player1.uid
    && room.playersList.player2 && room.playersList.player2.uid
    && room.playersList.player3 && room.playersList.player3.uid
    && room.playersList.player4 && room.playersList.player4.uid) {
      return true;
    }
    return false;
  }
  return false;
};

export default function roomReducer(state = initialState, action) {
  switch (action.type) {
    case 'ROOMS_REPLACE': {
      const rooms = {};

      Object.keys(action.data).map((key) => {
        if (!action.data[key].tournamentRoom && !_roomFilled(action.data[key])) {
          rooms[key] = action.data[key];
        }
        return null;
      });

      console.log('ROOMS_REPLACE rooms', rooms);

      return {
        ...state,
        rooms,
      };
    }

    case 'ROOMS_REPLACE_CHANGE': {
      const { key, data } = action;
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [key]: data,
        },
      };
    }

    case 'ROOMS_REPLACE_SET_FILLED': {
      const { key } = action;

      return {
        ...state,
        rooms: {
          ...state.rooms,
          [key]: {
            ...state.rooms[key],
            removal: true,
          },
        },
      };
    }

    case 'ROOMS_REPLACE_REMOVE': {
      const { key } = action;
      const rooms = { ...state.rooms };

      delete rooms[key];
      return {
        ...state,
        rooms,
      };
    }

    case 'STUDENT_ROOMS_REPLACE': {
      const studentRooms = {};

      Object.keys(action.data).map((key) => {
        if (!action.data[key].tournamentRoom && !_roomFilled(action.data[key])) {
          studentRooms[key] = action.data[key];
        }
        return null;
      });

      console.log('STUDENT_ROOMS_REPLACE rooms', studentRooms);

      return {
        ...state,
        studentRooms,
      };
    }

    case 'STUDENT_ROOMS_REPLACE_CHANGE': {
      const { key, data } = action;

      console.log('STUDENT_ROOMS_REPLACE_CHANGE data', key, data);

      return {
        ...state,
        studentRooms: {
          ...state.studentRooms,
          [key]: data,
        },
      };
    }

    case 'STUDENT_ROOMS_REPLACE_SET_FILLED': {
      const { key } = action;

      return {
        ...state,
        studentRooms: {
          ...state.studentRooms,
          [key]: {
            ...state.studentRooms[key],
            removal: true,
          },
        },
      };
    }

    case 'STUDENT_ROOMS_REPLACE_REMOVE': {
      const { key } = action;
      const studentRooms = { ...state.studentRooms };

      delete studentRooms[key];
      return {
        ...state,
        studentRooms,
      };
    }

    case 'RESET_ROOMS': {
      return {
        ...state,
        rooms: {},
        myRooms: {},
        studentRooms: {},
      };
    }

    case 'END_ROOM': {
      return state;
    }

    case 'CREATED_ROOM': {
      console.log('CREATED_ROOM', action);
      if (action && action.data && action.data.data && action.data.data.status === 'success') {
        console.log('test 1');
        return {
          ...state,
          lastJoinedRoom: action.data.data.key,
          joinedRoomPassword: action.data.data.password,
          roomChats: {
            status: null,
            messages: {},
          },
        };
      }

      console.log('test 2');

      return {
        ...state,
        roomChats: {
          status: null,
          messages: {},
        },
      };
    }

    case 'IS_JOINING_ROOM': {
      return {
        ...state,
        isJoiningRoom: action.data,
      };
    }

    case 'JOINED_ROOM': {
      if (action.data.data && action.data.data.status === 'success') {
        return {
          ...state,
          lastJoinedRoom: action.roomId,
          joinedRoomPassword: action.password,
          roomChats: {
            status: null,
            messages: {},
          },
        };
      }

      return state;
    }

    case 'ROOM_CHAT': {
      if (action.data.data && action.data.roomId) {
        return {
          ...state,
          roomChats: {
            ...state.roomChats,
            [action.data.roomId]: {
              messages: action.data.data || {},
              status: action.data.status || null,
            },
          },
        };
      }

      return state;
    }

    case 'ROOM_CHAT_RESET': {
      return {
        ...state,
        roomChats: {
          messages: {},
          status: null,
        },
      };
    }

    case 'ROOM_CHAT_MESSAGES': {
      return {
        ...state,
        roomChats: {
        //  ...state.roomChats,
          status: state.roomChats ? state.roomChats.status : null,
          messages: action.data.messages || {},
        },
      };
    }

    case 'ROOM_CHAT_MESSAGE_ADDED': {
      if (action.data.message && action.data.messageId && (!state.roomChats || !state.roomChats.messages)) {
        const { message, messageId } = action.data;

        return {
          ...state,
          roomChats: {
            status: null,
            messages: {
              [messageId]: message,
            },
          },
        };
      } if (action.data.message && action.data.messageId && !state.roomChats.messages[action.data.messageId]) {
        const { message, messageId } = action.data;

        return {
          ...state,
          roomChats: {
            status: state.roomChats.status,
            messages: {
              ...state.roomChats.messages,
              [messageId]: message,
            },
          },
        };
      }

      return state;
    }

    case 'PROFANITIES_LIST': {
      if (action.data) {
        return {
          ...state,
          profanitiesList: action.data,
        };
      }
      return state;
    }

    case 'PROFANITIES_NOTIFICATION': {
      if (action.data) {
        return {
          ...state,
          profanitiesNotification: action.data,
        };
      }
      return state;
    }

    case 'GIFTS': {
      return {
        ...state,
        gifts: action.data,
      };
    }

    case 'EMOTIONS': {
      return {
        ...state,
        emotions: action.data,
      };
    }

    case 'ROOM_GIFTS': {
      if (action.data && action.data.data && action.data.roomId) { // state.roomGifts && !isEqual(action.data.data, state.roomGifts[action.data.roomId])
        return {
          ...state,
          roomGifts: {
            [action.data.roomId]: action.data.data,
          },
        };
      }
      return state;
    }

    case 'PARTY_LOGS': {
      const array = [];

      Object.keys(action.data).map(((key) => {
        array.push({ ...action.data[key], key });
        return null;
      }));

      array.sort((a, b) => a.time - b.time);

      return {
        ...state,
        partyLog: array,
      };
    }

    case 'OTHER_USER_DATA': {
      console.log('OTHER_USER_DATA', action.data);
      if (action.data) {
        const { uid } = action.data;

        //  lastUpdate = state.playerInfoUpdates[uid];

        //  if (lastUpdate + 1000 * 10 < Date.now()) {
        return {
          ...state,
          playerInfo: action.data,
          playerInfoUpdates: {
            //  ...state.playerInfoUpdates,
            [uid]: Date.now(),
          },
        };
      //  }
      }
      return state;
    }

    case 'OTHER_USER_VIP_DATA': {
      console.log('OTHER_USER_VIP_DATA', action.data);
      if (action.data) {
        return {
          ...state,
          playerVIPInfo: {
            [action.uid]: action.data,
          }
        };
      }
      return state;
    }

    case 'GET_NOTIFICATIONS_REPLACE': {
      return {
        ...state,
        notifications: action.data,
      };
    }

    case 'ROOM_ENDED': {
      return {
        ...state,
        roomEnded: action.data,
      };
    }

    case 'TOURNAMENT_ROOM_ENDED': {
      return {
        ...state,
        tournamentRoomEnded: action.data,
      };
    }

    case 'GET_REPORTED_MESSAGES_REPLACE': {
      return {
        ...state,
        reportedMessages: action.data,
      };
    }

    //  case 'LEAVE_ROOM_MENU': {
    //
    //  }

    default:
      return state;
  }
}
