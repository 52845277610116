import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Select from 'react-select';
import moment from 'moment';
import { size, map, get, forEach, findKey, filter, sumBy, includes, replace, values, flatMap, entries, groupBy, mapValues } from 'lodash';
import DatePicker from 'react-widgets/lib/DatePicker';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import { CSVLink } from 'react-csv';

import * as statisticsConst from '../../../constants/statisticsTypes';
import * as constants from '../../../constants/constants';
// import { version } from 'mathjs';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { UncontrolledPopover } from 'reactstrap/lib';
import { getGifts } from '../../../actions/admin';

class AdminsList extends React.Component {
  static propTypes = {
    statistics: PropTypes.shape({}),
    fetchStatistic: PropTypes.func.isRequired,
    allGifts: PropTypes.shape({}),
  }

  static defaultProps = {
    statistics: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      id: 'rooms',
      length: 0,
      data: [],
      roundStart: '',
      roundTo: '',
      selectedOption: null,
      startDate: new Date(),
      csvData: [],
      csvStartDate: new Date(),
      csvEndDate: new Date(),
      balanceType: '1',
      achievementLevel: 1,
      achievementType: constants.ACHIEVEMENT_FOR_STATISTICS[0],
      selectedViewOption: [{ ...statisticsConst.STATISTICS_VIEW_SCREEN_OPTIONS[0] }],
      selectedEnvOption: statisticsConst.STATISTICS_ENV_MODE[0],
      selectedDesignOption: statisticsConst.STATISTICS_DESIGN_MODE[0],
      selectedThemeOption: statisticsConst.STATISTICS_THEME_MODE[0],
      openChart: false,
      roundRange: statisticsConst.STATISTICS_ROUND_RANGE[0],
      selectedGiftType: statisticsConst.GIFTS_TYPE[0],
      giftsSendData: {},
      giftsRemoveData: {},
    };
    this.roundS = 0;
    this.roundT = 20;
  }


  componentDidMount() {
    //  console.log('getStatistic componentDidMount');
    //  const { fetchStatistic } = this.props;

    //  fetchStatistic('rooms', 1);
  }

  componentDidUpdate(nextProps) {
    // const { id } = this.state;
    // const { statistics } = this.props;

    // if (nextProps.statistics !== statistics && id === statisticsConst.BALANCEPERPLAYERS) {
    //   const balancePerPlayers = { ...statistics.balancePerPlayers };
    //   const dayString = moment(Date.now()).format(constants.FORMAT_DATE_TYPE.fullDayMonthYear);
    //   const dayTotals={};
    //   const dataArray=[];
    //   const csvData = [];
    //   // console.log({balancePerPlayers}, balancePerPlayers.facebook, "test");
    //   dayTotals[dayString] = {
    //     apple: size(balancePerPlayers.apple),
    //     email: size(balancePerPlayers.email),
    //     draugiem: size(balancePerPlayers.draugiem),
    //     facebook: size(balancePerPlayers.facebook),
    //     inbox: size(balancePerPlayers.inbox),
    //     google: size(balancePerPlayers.google),
    //   };
    //   dataArray.push({ key: `${dayString}`, day: dayString, hour: `${dayString}`, draugiem: size(balancePerPlayers.draugiem), facebook: size(balancePerPlayers.facebook), email: size(balancePerPlayers.email), apple: size(balancePerPlayers.apple), inbox: size(balancePerPlayers.inbox), google: size(balancePerPlayers.google) });
    //   if (id === statisticsConst.BALANCEPERPLAYERS) {
    //     forEach(dayTotals, (item, key) => {
    //       csvData.push({ day: key, draugiem: item.draugiem, facebook: item.facebook, email: item.email, apple: item.apple, inbox: item.inbox, google: item.google });
    //     });
    //   }
    //   this.setState({ data: dataArray, dayTotals, csvData});
    // }
  }

  changePurchaseOption = (selectedOption) => {
    this.setState({
      selectedOption,
    });
  }

  changeViewOption = (selectedViewOption) => {
    this.setState({
      selectedViewOption,
    });
  }

  changeGiftOption = (selectedGiftType) => {
    this.setState({
      selectedGiftType,
    });
  }

  getCurrentMinuteQuarter = () => {
    const currentMinute = new Date().getMinutes();
    return Math.ceil((currentMinute + 1) / 15);
  }

  getStatistic = async () => {
    const { fetchStatistic, allGifts } = this.props;
    const {
      id,
      length,
      roundRange,
      selectedOption,
      startDate,
      balanceType,
      achievementLevel,
      achievementType,
      selectedViewOption,
      selectedDesignOption,
      selectedEnvOption,
      selectedThemeOption,
    } = this.state;
    this.roundS = roundRange.value.roundStart || 1;
    this.roundT = roundRange.value.roundEnd || 20;
    this.setState({ data: [] });
    const roundStart = roundRange.value.roundStart || 1;
    const roundTo = roundRange.value.roundEnd || 20;
    console.log(roundStart, roundTo, "checkROundSSS")

    const selectDate = moment(startDate).format(constants.FORMAT_DATE_TYPE.fullMonthYear);
    if (length.toString() !== statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
      const endString = moment(new Date(Date.now()).toLocaleString(statisticsConst.STATISTICS_ADMIN_TIMEZONE.timelocal, { timeZone: statisticsConst.STATISTICS_ADMIN_TIMEZONE.timezone })).format(constants.FORMAT_DATE_TYPE.fullDayMonth);
      const startString = moment(new Date(constants.CALC_ACTIVATE_DATE_BY_TIME(length)).toLocaleString(statisticsConst.STATISTICS_ADMIN_TIMEZONE.timelocal, { timeZone: statisticsConst.STATISTICS_ADMIN_TIMEZONE.timezone })).format(constants.FORMAT_DATE_TYPE.fullDayMonth);
      this.setState({
        csvStartDate: startString,
        csvEndDate: endString,
      });
    }

    if (id === statisticsConst.PLAYERSVIEWSPERSCREEN && size(selectedViewOption) === 0) return;

    fetchStatistic(id, length, roundStart, roundTo).then(async () => {
      const { statistics } = this.props;
      const dataArray = [];
      const dayTotals = {};
      let monthTotals = {};
      let monthTotalsCount = 0;
      const csvData = [];
      const browserKeys = [];
      if (id === statisticsConst.DELETEDPLAYERS) {
        const { draugiem, facebook, email, apple, inbox, google, deletedUsersTotal } = statistics;

        Object.keys(deletedUsersTotal).sort().map(key => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);
              console.log(spiltDate, "===splitDate")
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (deletedUsersTotal[key] || 0),
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (draugiem[key] || 0),
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (facebook[key] || 0),
                email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + (email[key] || 0),
                apple: get(dayTotals, `${spiltDate.dayString}.apple`, 0) + (apple[key] || 0),
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (inbox[key] || 0),
                google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + (google[key] || 0),
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: deletedUsersTotal[key], draugiem: draugiem[key], facebook: facebook[key], email: email[key], apple: apple[key], inbox: inbox[key], google: google[key] });
              return null;
            }
          } else {
            const spiltDate = constants.getSplitDate(key);
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (deletedUsersTotal[key] || 0),
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (draugiem[key] || 0),
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (facebook[key] || 0),
              email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + (email[key] || 0),
              apple: get(dayTotals, `${spiltDate.dayString}.apple`, 0) + (apple[key] || 0),
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (inbox[key] || 0),
              google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + (google[key] || 0),
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: deletedUsersTotal[key], draugiem: draugiem[key], facebook: facebook[key], email: email[key], apple: apple[key], inbox: inbox[key], google: google[key] });
            return null;
          }
        });
      } else if (id === statisticsConst.RETURNEDPLAYERS) {
        const { returnedPlayers } = statistics;
        map(returnedPlayers, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item?.draugiem || 0) + (item?.email || 0) + (item?.facebook || 0) + (item?.google || 0) + (item?.inbox || 0) + (item?.apple || 0)),
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (item?.draugiem || 0),
                email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + (item?.email || 0),
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (item?.facebook || 0),
                google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + (item?.google || 0),
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (item?.inbox || 0),
                apple: get(dayTotals, `${spiltDate.dayString}.apple`, 0) + (item?.apple || 0),
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item?.draugiem || 0) + (item?.email || 0) + (item?.facebook || 0) + (item?.google || 0) + (item?.inbox || 0) + (item?.apple || 0)), draugiem: item?.draugiem || 0, facebook: item?.facebook || 0, email: item?.email || 0, google: item?.google || 0, inbox: item?.inbox || 0, apple: item?.apple || 0 });
              return null;
            }
          } else {
            const spiltDate = constants.getSplitDate(key);
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item?.draugiem || 0) + (item?.email || 0) + (item?.facebook || 0) + (item?.google || 0) + (item?.inbox || 0) + (item?.apple || 0)),
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (item?.draugiem || 0),
              email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + (item?.email || 0),
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (item?.facebook || 0),
              google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + (item?.google || 0),
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (item?.inbox || 0),
              apple: get(dayTotals, `${spiltDate.dayString}.apple`, 0) + (item?.apple || 0),
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item?.draugiem || 0) + (item?.email || 0) + (item?.facebook || 0) + (item?.google || 0) + (item?.inbox || 0) + (item?.apple || 0)), draugiem: item?.draugiem || 0, facebook: item?.facebook || 0, email: item?.email || 0, google: item?.google || 0, inbox: item?.inbox || 0, apple: item?.apple || 0 });
            return null;
          }
        });
      } else if (id === statisticsConst.BALANCEPERPLAYERS) {
        const { balances } = statistics;
        const balanceRange = filter(constants.BALANCE_PER_RANGE, ['id', balanceType])[0].range;
        console.log(statistics, balanceRange, "balancePerPlayers")
        map(balances, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);
              const totalV = (item.draugiem[balanceRange] || 0) + (item.facebook[balanceRange] || 0) + (item.password[balanceRange] || 0) + (item.password[balanceRange] || 0) + (item.google[balanceRange] || 0) + (item.inbox[balanceRange] || 0) + (item.apple[balanceRange] || 0);
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + totalV,
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (item.draugiem[balanceRange] || 0),
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (item.facebook[balanceRange] || 0),
                email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + (item.password[balanceRange] || 0),
                google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + (item.google[balanceRange] || 0),
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (item.inbox[balanceRange] || 0),
                apple: get(dayTotals, `${spiltDate.dayString}.apple`, 0) + (item.apple[balanceRange] || 0),
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: totalV, draugiem: item.draugiem[balanceRange], facebook: item.facebook[balanceRange], email: item.password[balanceRange], google: item.google[balanceRange], inbox: item.inbox[balanceRange], apple: item.apple[balanceRange] });
              return null;
            }
          } else {
            const spiltDate = constants.getSplitDate(key);
            const totalV = (item.draugiem[balanceRange] || 0) + (item.facebook[balanceRange] || 0) + (item.password[balanceRange] || 0) + (item.password[balanceRange] || 0) + (item.google[balanceRange] || 0) + (item.inbox[balanceRange] || 0) + (item.apple[balanceRange] || 0);

            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + totalV,
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (item.draugiem[balanceRange] || 0),
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (item.facebook[balanceRange] || 0),
              email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + (item.password[balanceRange] || 0),
              google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + (item.google[balanceRange] || 0),
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (item.inbox[balanceRange] || 0),
              apple: get(dayTotals, `${spiltDate.dayString}.apple`, 0) + (item.apple[balanceRange] || 0),
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: totalV, draugiem: item.draugiem[balanceRange], facebook: item.facebook[balanceRange], email: item.password[balanceRange], google: item.google[balanceRange], inbox: item.inbox[balanceRange], apple: item.apple[balanceRange] });
            return null;
          }
        });
      } else if (id === statisticsConst.PLAYERACHIEVEMENTS) {
        const { playerAchievements } = statistics;

        map(playerAchievements, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);
              if (achievementType === 'singleAchievements') {
                map(constants.UNIQUE_ACHIEVEMNT_LEVEL, (achvLevel, idx) => {
                  dayTotals[spiltDate.dayString] = {
                    ...dayTotals[spiltDate.dayString],
                    [achvLevel.id]: item[achvLevel.id] && item[achvLevel.id][1] ? item[achvLevel.id][1] : 0
                  }
                })
              } else {
                map(constants.ACHIEVEMENT_LEVEL, (achvLevel, idx) => {
                  /* dayTotals[spiltDate.dayString] = {
                    ...dayTotals[spiltDate.dayString],
                    [achvLevel.label]: item[achvLevel.id] && item[achvLevel.id][1] ? item[achvLevel.id][1] : 0
                  }*/
                  dayTotals[spiltDate.dayString] = {
                    ...dayTotals[spiltDate.dayString],
                    [achvLevel.label]: item[achievementType] && item[achievementType][achvLevel.id] ? item[achievementType][achvLevel.id] : 0
                  }
                })
              }

              dataArray.push({
                key: spiltDate.dateKey,
                day: spiltDate.dayString,
                hour: `${spiltDate.time}:00`,
                ...dayTotals[spiltDate.dayString]
              })
              return null;
            }
          } else {
            const spiltDate = constants.getSplitDate(key);
            map(constants.ACHIEVEMENT_LEVEL, (achvLevel, idx) => {
              dayTotals[spiltDate.dayString] = {
                ...dayTotals[spiltDate.dayString],
                [achvLevel.label]: item[achievementType][achvLevel.id] || 0
              }
            })
            dataArray.push({
              key: spiltDate.dateKey,
              day: spiltDate.dayString,
              hour: `${spiltDate.time}:00`,
              ...dayTotals[spiltDate.dayString]
            })
            return null;
          }
        });
      } else if (id === statisticsConst.LASTONLINEPLAYERS) {
        const { draugiem, facebook, email, apple, inbox, google } = statistics;
        const dayString = moment(Date.now()).format('YYYY-MM-DD');
        dayTotals[dayString] = {
          apple: size(apple),
          email: size(email),
          draugiem: size(draugiem),
          facebook: size(facebook),
          inbox: size(inbox),
          google: size(google),
        };
        dataArray.push({ key: `${dayString}`, day: dayString, hour: `${dayString}`, draugiem: size(draugiem), facebook: size(facebook), email: size(email), apple: size(apple), inbox: size(inbox), google: size(google) });
      } else if (id === statisticsConst.USERSCOINANALYSIS) {
        const { all, draugiem, facebook, mobile, stripe, inbox, activePlayersDay, everyPay } = statistics;
        const arrayAll = {};

        console.log('USERSCOINANALYSIS statistics', statistics);

        await map(all, (item) => {
          if (!arrayAll[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]) {
            arrayAll[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)] = 0;
          }
          arrayAll[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]++;
        });
        const arrayDraugiem = {};

        await map(draugiem, (item) => {
          if (!arrayDraugiem[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]) {
            arrayDraugiem[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)] = 0;
          }
          arrayDraugiem[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]++;
        });
        let arrayFacebook = {};

        await map(facebook, (item) => {
          if (!arrayFacebook[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]) {
            arrayFacebook[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)] = 0;
          }
          arrayFacebook[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]++;
        });
        const arrayMobile = {};

        await map(mobile, (item) => {
          if (!arrayMobile[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]) {
            arrayMobile[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)] = 0;
          }
          arrayMobile[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]++;
        });
        const arrayStripe = {};

        await map(stripe, (item) => {
          if (!arrayStripe[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]) {
            arrayStripe[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)] = 0;
          }
          arrayStripe[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]++;
        });
        const arrayInbox = {};

        await map(inbox, (item) => {
          if (!arrayInbox[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]) {
            arrayInbox[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)] = 0;
          }
          arrayInbox[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]++;
        });
        const arrayEveryPay = {};

        await map(everyPay, (item) => {
          if (!arrayEveryPay[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]) {
            arrayEveryPay[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)] = 0;
          }
          arrayEveryPay[moment(item.date).format(constants.FORMAT_DATE_TYPE.statisticsDate)]++;
        });
        // arrayAll.sort((a, b) => a.dayString - b.dayString);

        await Object.keys(arrayAll).sort().map(key => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (arrayAll[key] || 0),
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (arrayDraugiem[key] || 0),
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (arrayFacebook[key] || 0),
                mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + (arrayMobile[key] || 0),
                web: get(dayTotals, `${spiltDate.dayString}.web`, 0) + (arrayEveryPay[key] || 0),
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (arrayInbox[key] || 0),
                totalPlayers: activePlayersDay[key] || Object.keys(activePlayersDay)[Object.keys(activePlayersDay).length - 1],
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: arrayAll[key], draugiem: arrayDraugiem[key], facebook: arrayFacebook[key], web: arrayEveryPay[key], mobile: arrayMobile[key], inbox: arrayInbox[key] });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (arrayAll[key] || 0),
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (arrayDraugiem[key] || 0),
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (arrayFacebook[key] || 0),
              mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + (arrayMobile[key] || 0),
              web: get(dayTotals, `${spiltDate.dayString}.web`, 0) + (arrayEveryPay[key] || 0),
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (arrayInbox[key] || 0),
              totalPlayers: activePlayersDay[key] || Object.keys(activePlayersDay)[Object.keys(activePlayersDay).length - 1],
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: arrayAll[key], draugiem: arrayDraugiem[key], facebook: arrayFacebook[key], web: arrayEveryPay[key], mobile: arrayMobile[key], inbox: arrayInbox[key] });
            return null;
          }
        });
      } else if (id === statisticsConst.GAMESINROOM) {
        const { roundsPlayed } = statistics;
        map(roundsPlayed, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);
              const dayOfMonth = moment().daysInMonth(selectDate);
              console.log(dayOfMonth, "===================================1")

              const arrayTemp = { key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00` };
              const objectAdd = {};
              for (let i = parseInt(roundStart); i <= parseInt(roundTo); i++) {
                objectAdd[i] = parseInt(get(dayTotals, `${spiltDate.dayString}.${i}`, 0)) + parseInt(roundsPlayed[key][i] || 0);
                arrayTemp[i] = roundsPlayed[key][i] || 0;
              }
              dayTotals[spiltDate.dayString] = objectAdd;
              dataArray.push(arrayTemp);
              return null;
            }
          } else {
            const spiltDate = constants.getSplitDate(key);

            const arrayTemp = { key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00` };
            const objectAdd = {};
            for (let i = parseInt(roundStart); i <= parseInt(roundTo); i++) {
              objectAdd[`day-${i}`] = parseInt(get(dayTotals, `${spiltDate.dayString}.day-${i}`, 0)) + parseInt(roundsPlayed[key][i] || 0);
              arrayTemp[`day-${i}`] = roundsPlayed[key][i] || 0;
            }
            dayTotals[spiltDate.dayString] = objectAdd;
            dataArray.push(arrayTemp);
            return null;
          }
        });
      } else if (id === statisticsConst.PURCHASEDMONEY) {
        const { typesDev, typesProd } = statistics;
        const purchasedTypes = selectedEnvOption.value === 'prod' ? typesProd : typesDev;

        map(purchasedTypes, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);
              if (selectedOption != null && selectedOption.length != 0) {
                let selectedMoney = {};
                forEach(selectedOption, (element) => {
                  if (item[element.value] != undefined) {
                    dayTotals[spiltDate.dayString] = {
                      money1: get(dayTotals, `${spiltDate.dayString}.money1`, 0) + (item[element.value].money1 || 0),
                      money2: get(dayTotals, `${spiltDate.dayString}.money2`, 0) + (item[element.value].money2 || 0),
                      money3: get(dayTotals, `${spiltDate.dayString}.money3`, 0) + (item[element.value].money3 || 0),
                      money4: get(dayTotals, `${spiltDate.dayString}.money4`, 0) + (item[element.value].money4 || 0),
                      money5: get(dayTotals, `${spiltDate.dayString}.money5`, 0) + (item[element.value].money5 || 0),
                      money6: get(dayTotals, `${spiltDate.dayString}.money6`, 0) + (item[element.value].pack1 || 0),
                      money7: get(dayTotals, `${spiltDate.dayString}.money7`, 0) + (item[element.value].pack2 || 0),
                      money8: get(dayTotals, `${spiltDate.dayString}.money8`, 0) + (item[element.value].pack3 || 0),
                      unban: get(dayTotals, `${spiltDate.dayString}.unban`, 0) + (item[element.value].unban || 0),
                      removeAdsMonth: get(dayTotals, `${spiltDate.dayString}.removeAdsMonth`, 0) + (item[element.value].removeAdsMonth || 0),
                      removeAdsYear: get(dayTotals, `${spiltDate.dayString}.removeAdsYear`, 0) + (item[element.value].removeAdsYear || 0),
                      total: (parseFloat(get(dayTotals, `${spiltDate.dayString}.total`, 0))
                        + 0.85 * parseFloat(item[element.value].money1 || 0)
                        + 1.41 * parseFloat(item[element.value].money2 || 0)
                        + 2.85 * parseFloat(item[element.value].money3 || 0)
                        + 4.27 * parseFloat(item[element.value].money4 || 0)
                        + 14.23 * parseFloat(item[element.value].money5 || 0)
                        + 5.0 * parseFloat(item[element.value].pack1 || 0)
                        + 5.0 * parseFloat(item[element.value].pack2 || 0)
                        + 5.0 * parseFloat(item[element.value].pack3 || 0)
                        + 3 * parseFloat(item[element.value].removeAdsMonth || 0)
                        + 25 * parseFloat(item[element.value].removeAdsYear || 0)
                        + 10 * parseFloat(item[element.value].unban || 0)).toFixed(2),
                    };

                    if (item[element.value].removeAdsMonth) {
                      console.log('has item[element.value].removeAdsMonth', element.value, item, key, parseFloat(item[element.value].pack1), parseFloat(item[element.value].pack3), parseFloat(item[element.value].removeAdsMonth));
                    }

                    selectedMoney = {
                      money1: get(selectedMoney, 'money1', 0) + item[element.value].money1 || 0,
                      money2: get(selectedMoney, 'money2', 0) + item[element.value].money2 || 0,
                      money3: get(selectedMoney, 'money3', 0) + item[element.value].money3 || 0,
                      money4: get(selectedMoney, 'money4', 0) + item[element.value].money4 || 0,
                      money5: get(selectedMoney, 'money5', 0) + item[element.value].money5 || 0,
                      money6: get(selectedMoney, 'money6', 0) + item[element.value].pack1 || 0,
                      money7: get(selectedMoney, 'money7', 0) + item[element.value].pack2 || 0,
                      money8: get(selectedMoney, 'money8', 0) + item[element.value].pack3 || 0,
                      unban: get(selectedMoney, 'unban', 0) + item[element.value].unban || 0,
                      removeAdsMonth: get(selectedMoney, 'removeAdsMonth', 0) + item[element.value].removeAdsMonth || 0,
                      removeAdsYear: get(selectedMoney, 'removeAdsYear', 0) + item[element.value].removeAdsYear || 0,
                      total: (parseFloat(get(selectedMoney, 'total', 0))
                        + 0.85 * parseFloat(item[element.value].money1 || 0)
                        + 1.41 * parseFloat(item[element.value].money2 || 0)
                        + 2.85 * parseFloat(item[element.value].money3 || 0)
                        + 4.27 * parseFloat(item[element.value].money4 || 0)
                        + 14.23 * parseFloat(item[element.value].money5 || 0)
                        + 5 * parseFloat(item[element.value].pack1 || 0)
                        + 5 * parseFloat(item[element.value].pack2 || 0)
                        + 5 * parseFloat(item[element.value].pack3 || 0)
                        + 3 * parseFloat(item[element.value].removeAdsMonth || 0)
                        + 25 * parseFloat(item[element.value].removeAdsYear || 0)
                        + 10 * parseFloat(item[element.value].unban || 0)).toFixed(2),
                    };

                    console.log('selectedMoney 1', key, selectedMoney);
                  }
                });

                console.log('selectedMoney 2', key, selectedMoney);
                csvData.push({ day: key, 'Total amount(EUR)': item.total, '250z-0.85EUR': item.money1, '500Z-1.41EUR': item.money2, '1000Z-2.85EUR': item.money3, '4000z-4.27EUR': item.money4, '15000z-14.23EUR': item.money5, 'Reset-balance': item.money6, 'Reset-points': item.money7, 'Reset-profile': item.money8, 'Unban account': item.unban, 'Hide ads for month': item.removeAdsMonth, 'Hide ads for year': item.removeAdsYear });
                dataArray.push({
                  key: spiltDate.dateKey,
                  day: spiltDate.dayString,
                  hour: `${spiltDate.time}:00`,
                  total: (selectedMoney.money1 || 0) + (selectedMoney.money2 || 0) + (selectedMoney.money3 || 0) + (selectedMoney.money4 || 0) + (selectedMoney.money5 || 0) + (selectedMoney.money6 || 0) + (selectedMoney.money7 || 0) + (selectedMoney.money8 || 0) + (selectedMoney.unban || 0) + (selectedMoney.removeAdsMonth || 0) + (selectedMoney.removeAdsYear || 0),
                  '250z-0.85EUR': selectedMoney.money1,
                  '500Z-1.41EUR': selectedMoney.money2,
                  '1000Z-2.85EUR': selectedMoney.money3,
                  '4000z-4.27EUR': selectedMoney.money4,
                  '15000z-14.23EUR': selectedMoney.money5,
                  'Reset-balance': selectedMoney.money6,
                  'Reset-points': selectedMoney.money7,
                  'Reset-profile': selectedMoney.money8,
                  'Unban account': selectedMoney.unban,
                  'Hide ads for month': selectedMoney.removeAdsMonth,
                  'Hide ads for year': selectedMoney.removeAdsYear,
                  // total: selectedMoney.total,
                });
              } else {
                dayTotals[spiltDate.dayString] = {
                  money1: get(dayTotals, `${spiltDate.dayString}.money1`, 0) + (item.money1 || 0),
                  money2: get(dayTotals, `${spiltDate.dayString}.money2`, 0) + (item.money2 || 0),
                  money3: get(dayTotals, `${spiltDate.dayString}.money3`, 0) + (item.money3 || 0),
                  money4: get(dayTotals, `${spiltDate.dayString}.money4`, 0) + (item.money4 || 0),
                  money5: get(dayTotals, `${spiltDate.dayString}.money5`, 0) + (item.money5 || 0),
                  money6: get(dayTotals, `${spiltDate.dayString}.money6`, 0) + (item.pack1 || 0),
                  money7: get(dayTotals, `${spiltDate.dayString}.money7`, 0) + (item.pack2 || 0),
                  money8: get(dayTotals, `${spiltDate.dayString}.money8`, 0) + (item.pack3 || 0),
                  unban: get(dayTotals, `${spiltDate.dayString}.unban`, 0) + (item.unban || 0),
                  removeAdsMonth: get(dayTotals, `${spiltDate.dayString}.removeAdsMonth`, 0) + (item.removeAdsMonth || 0),
                  removeAdsYear: get(dayTotals, `${spiltDate.dayString}.removeAdsYear`, 0) + (item.removeAdsYear || 0),
                  total: (parseFloat(get(dayTotals, `${spiltDate.dayString}.total`, 0))
                    + 0.85 * parseFloat(item.money1 || 0)
                    + 1.41 * parseFloat(item.money2 || 0)
                    + 2.85 * parseFloat(item.money3 || 0)
                    + 4.27 * parseFloat(item.money4 || 0)
                    + 14.23 * parseFloat(item.money5 || 0)
                    + 5 * parseFloat(item.pack1 || 0)
                    + 5 * parseFloat(item.pack2 || 0)
                    + 5 * parseFloat(item.pack3 || 0)
                    + 25 * parseFloat(item.removeAdsYear || 0)
                    + 3 * parseFloat(item.removeAdsMonth || 0)
                    + 10 * parseFloat(item.unban || 0)).toFixed(2),
                };
                dataArray.push({
                  key: spiltDate.dateKey,
                  day: spiltDate.dayString,
                  hour: `${spiltDate.time}:00`,
                  '250z-0.85EUR': item.money1,
                  '500Z-1.41EUR': item.money2,
                  '1000Z-2.85EUR': item.money3,
                  '4000z-4.27EUR': item.money4,
                  '15000z-14.23EUR': item.money5,
                  'Reset-balance': item.money6,
                  'Reset-points': item.money7,
                  'Reset-profile': item.money8,
                  'Unban account': item.unban,
                  'Hide ads for month': item.removeAdsMonth,
                  'Hide ads for year': item.removeAdsYear,
                  total: (item.money1 || 0) + (item.money2 || 0) + (item.money3 || 0) + (item.money4 || 0) + (item.money5 || 0) + (item.money6 || 0) + (item.money7 || 0) + (item.money8 || 0) + (item.unban || 0) + (item.removeAdsMonth || 0) + (item.removeAdsYear || 0),
                  // total: (
                  //   0.85 * parseFloat(item.money1 || 0)
                  //   + 1.41 * parseFloat(item.money2 || 0)
                  //   + 2.85 * parseFloat(item.money3 || 0)
                  //   + 4.27 * parseFloat(item.money4 || 0)
                  //   + 14.23 * parseFloat(item.money5 || 0)
                  //   + 5 * parseFloat(item.pack1 || 0)
                  //   + 5 * parseFloat(item.pack2 || 0)
                  //   + 5 * parseFloat(item.pack3 || 0)
                  //   + 25 * parseFloat(item.removeAdsYear || 0)
                  //   + 3 * parseFloat(item.removeAdsMonth || 0)
                  //   + 10 * parseFloat(item.unban || 0)).toFixed(2)
                });
              }
              return null;
            }
          } else {
            const spiltDate = constants.getSplitDate(key);
            if (selectedOption != null && selectedOption.length != 0) {
              let selectedMoney = {};
              forEach(selectedOption, (element) => {
                if (item[element.value] != undefined) {
                  dayTotals[spiltDate.dayString] = {
                    money1: get(dayTotals, `${spiltDate.dayString}.money1`, 0) + (item[element.value].money1 || 0),
                    money2: get(dayTotals, `${spiltDate.dayString}.money2`, 0) + (item[element.value].money2 || 0),
                    money3: get(dayTotals, `${spiltDate.dayString}.money3`, 0) + (item[element.value].money3 || 0),
                    money4: get(dayTotals, `${spiltDate.dayString}.money4`, 0) + (item[element.value].money4 || 0),
                    money5: get(dayTotals, `${spiltDate.dayString}.money5`, 0) + (item[element.value].money5 || 0),
                    money6: get(dayTotals, `${spiltDate.dayString}.money6`, 0) + (item[element.value].pack1 || 0),
                    money7: get(dayTotals, `${spiltDate.dayString}.money7`, 0) + (item[element.value].pack2 || 0),
                    money8: get(dayTotals, `${spiltDate.dayString}.money8`, 0) + (item[element.value].pack3 || 0),
                    unban: get(dayTotals, `${spiltDate.dayString}.unban`, 0) + (item[element.value].unban || 0),
                    removeAdsMonth: get(dayTotals, `${spiltDate.dayString}.removeAdsMonth`, 0) + (item[element.value].removeAdsMonth || 0),
                    removeAdsYear: get(dayTotals, `${spiltDate.dayString}.removeAdsYear`, 0) + (item[element.value].removeAdsYear || 0),
                    total: (parseFloat(get(dayTotals, `${spiltDate.dayString}.total`, 0))
                      + 0.85 * parseFloat(item[element.value].money1 || 0)
                      + 1.41 * parseFloat(item[element.value].money2 || 0)
                      + 2.85 * parseFloat(item[element.value].money3 || 0)
                      + 4.27 * parseFloat(item[element.value].money4 || 0)
                      + 14.23 * parseFloat(item[element.value].money5 || 0)
                      + 5.0 * parseFloat(item[element.value].pack1 || 0)
                      + 5.0 * parseFloat(item[element.value].pack2 || 0)
                      + 5.0 * parseFloat(item[element.value].pack3 || 0)
                      + 3 * parseFloat(item[element.value].removeAdsMonth || 0)
                      + 25 * parseFloat(item[element.value].removeAdsYear || 0)
                      + 10 * parseFloat(item[element.value].unban || 0)).toFixed(2),
                  };

                  selectedMoney = {
                    money1: get(selectedMoney, 'money1', 0) + item[element.value].money1 || 0,
                    money2: get(selectedMoney, 'money2', 0) + item[element.value].money2 || 0,
                    money3: get(selectedMoney, 'money3', 0) + item[element.value].money3 || 0,
                    money4: get(selectedMoney, 'money4', 0) + item[element.value].money4 || 0,
                    money5: get(selectedMoney, 'money5', 0) + item[element.value].money5 || 0,
                    money6: get(selectedMoney, 'money6', 0) + item[element.value].pack1 || 0,
                    money7: get(selectedMoney, 'money7', 0) + item[element.value].pack2 || 0,
                    money8: get(selectedMoney, 'money8', 0) + item[element.value].pack3 || 0,
                    unban: get(selectedMoney, 'unban', 0) + item[element.value].unban || 0,
                    removeAdsMonth: get(selectedMoney, 'removeAdsMonth', 0) + item[element.value].removeAdsMonth || 0,
                    removeAdsYear: get(selectedMoney, 'removeAdsYear', 0) + item[element.value].removeAdsYear || 0,
                    total: (parseFloat(get(selectedMoney, 'total', 0))
                      + 0.85 * parseFloat(item[element.value].money1 || 0)
                      + 1.41 * parseFloat(item[element.value].money2 || 0)
                      + 2.85 * parseFloat(item[element.value].money3 || 0)
                      + 4.27 * parseFloat(item[element.value].money4 || 0)
                      + 14.23 * parseFloat(item[element.value].money5 || 0)
                      + 5 * parseFloat(item[element.value].pack1 || 0)
                      + 5 * parseFloat(item[element.value].pack2 || 0)
                      + 5 * parseFloat(item[element.value].pack3 || 0)
                      + 3 * parseFloat(item[element.value].removeAdsMonth || 0)
                      + 25 * parseFloat(item[element.value].removeAdsYear || 0)
                      + 10 * parseFloat(item[element.value].unban || 0)).toFixed(2),
                  };
                }
              });
              dataArray.push({ key, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, money1: selectedMoney.money1, money2: selectedMoney.money2, money3: selectedMoney.money3, money4: selectedMoney.money4, money5: selectedMoney.money5, money6: selectedMoney.money6, money7: selectedMoney.money7, money8: selectedMoney.money8, unban: selectedMoney.unban, removeAdsMonth: selectedMoney.removeAdsMonth, removeAdsYear: selectedMoney.removeAdsYear, total: selectedMoney.total });
            } else {
              dayTotals[spiltDate.dayString] = {
                money1: get(dayTotals, `${spiltDate.dayString}.money1`, 0) + (item.money1 || 0),
                money2: get(dayTotals, `${spiltDate.dayString}.money2`, 0) + (item.money2 || 0),
                money3: get(dayTotals, `${spiltDate.dayString}.money3`, 0) + (item.money3 || 0),
                money4: get(dayTotals, `${spiltDate.dayString}.money4`, 0) + (item.money4 || 0),
                money5: get(dayTotals, `${spiltDate.dayString}.money5`, 0) + (item.money5 || 0),
                money6: get(dayTotals, `${spiltDate.dayString}.money6`, 0) + (item.pack1 || 0),
                money7: get(dayTotals, `${spiltDate.dayString}.money7`, 0) + (item.pack2 || 0),
                money8: get(dayTotals, `${spiltDate.dayString}.money8`, 0) + (item.pack3 || 0),
                unban: get(dayTotals, `${spiltDate.dayString}.unban`, 0) + (item.unban || 0),
                removeAdsMonth: get(dayTotals, `${spiltDate.dayString}.removeAdsMonth`, 0) + (item.removeAdsMonth || 0),
                removeAdsYear: get(dayTotals, `${spiltDate.dayString}.removeAdsYear`, 0) + (item.removeAdsYear || 0),
                total: (parseFloat(get(dayTotals, `${spiltDate.dayString}.total`, 0))
                  + 0.85 * parseFloat(item.money1 || 0)
                  + 1.41 * parseFloat(item.money2 || 0)
                  + 2.85 * parseFloat(item.money3 || 0)
                  + 4.27 * parseFloat(item.money4 || 0)
                  + 14.23 * parseFloat(item.money5 || 0)
                  + 5 * parseFloat(item.pack1 || 0)
                  + 5 * parseFloat(item.pack2 || 0)
                  + 5 * parseFloat(item.pack3 || 0)
                  + 3 * parseFloat(item.removeAdsMonth || 0)
                  + 25 * parseFloat(item.removeAdsYear || 0)
                  + 10 * parseFloat(item.unban || 0)).toFixed(2),
              };
              dataArray.push({
                key, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, money1: item.money1, money2: item.money2, money3: item.money3, money4: item.money4, money5: item.money5, money6: item.pack1, money7: item.pack2, money8: item.pack3, unban: item.unban, removeAdsMonth: item.removeAdsMonth, removeAdsYear: item.removeAdsYear, total: (
                  0.85 * parseFloat(item.money1 || 0)
                  + 1.41 * parseFloat(item.money2 || 0)
                  + 2.85 * parseFloat(item.money3 || 0)
                  + 4.27 * parseFloat(item.money4 || 0)
                  + 14.23 * parseFloat(item.money5 || 0)
                  + 5 * parseFloat(item.pack1 || 0)
                  + 5 * parseFloat(item.pack2 || 0)
                  + 5 * parseFloat(item.pack3 || 0)
                  + 3 * parseFloat(item.removeAdsMonth || 0)
                  + 25 * parseFloat(item.removeAdsYear || 0)
                  + 10 * parseFloat(item.unban || 0)).toFixed(2)
              });
            }
            return null;
          }
        });
        console.log(dayTotals, dataArray, "checkAlllData");

      } else if (id === statisticsConst.TRANSACTIONS) {
        const { platformsDev, platformsProd, totalDev, totalProd } = statistics;
        const platforms = selectedEnvOption?.value === 'prod' ? platformsProd : platformsDev;
        const total = selectedEnvOption?.value === 'prod' ? totalProd : totalDev;

        map(platforms, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);

              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (total[key] || 0),
                stripe: get(dayTotals, `${spiltDate.dayString}.stripe`, 0) + (item.stripe || 0),
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (item.draugiem || 0),
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (item.facebook || 0),
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (item.inbox || 0),
                mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + (item.mobile || 0),
                everyPay: get(dayTotals, `${spiltDate.dayString}.everyPay`, 0) + (item.everyPay || 0),
              };
              dataArray.push({
                key: spiltDate.dateKey,
                day: spiltDate.dayString,
                hour: `${spiltDate.time}:00`,
                total: total[key],
                draugiem: item.draugiem,
                facebook: item.facebook,
                stripe: item.stripe,
                everyPay: item.everyPay,
                inbox: item.inbox,
                mobile: item.mobile,
              });
              return null;
            }
          } else {
            const spiltDate = constants.getSplitDate(key);

            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (total[key] || 0),
              stripe: get(dayTotals, `${spiltDate.dayString}.stripe`, 0) + (item.stripe || 0),
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (item.draugiem || 0),
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (item.facebook || 0),
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (item.inbox || 0),
              mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + (item.mobile || 0),
              everyPay: get(dayTotals, `${spiltDate.dayString}.everyPay`, 0) + (item.everyPay || 0),
            };
            dataArray.push({
              key: spiltDate.dateKey,
              day: spiltDate.dayString,
              hour: `${spiltDate.time}:00`,
              total: total[key],
              draugiem: item.draugiem,
              facebook: item.facebook,
              stripe: item.stripe,
              everyPay: item.everyPay,
              inbox: item.inbox,
              mobile: item.mobile,
            });
            return null;
          }
        });
      } else if (id === statisticsConst.TOTALPLAYERS) {
        const { totalPlayersHistory } = statistics;
        map(totalPlayersHistory, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);

              dayTotals[spiltDate.dayString] = {
                total: (item.draugiem || 0) + (item.facebook || 0) + (item.email || 0) + (item.google || 0) + (item.inbox || 0) + (item.apple || 0),
                draugiem: (item.draugiem || 0),
                facebook: (item.facebook || 0),
                email: (item.email || 0),
                google: (item.google || 0),
                inbox: (item.inbox || 0),
                apple: (item.apple || 0),
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: (item.draugiem || 0) + (item.facebook || 0) + (item.email || 0) + (item.google || 0) + (item.inbox || 0) + (item.apple || 0), draugiem: item.draugiem, facebook: item.facebook, email: item.email, google: item.google, inbox: item.inbox, apple: item.apple });
              return null;
            }
          } else {
            const spiltDate = constants.getSplitDate(key);

            dayTotals[spiltDate.dayString] = {
              total: (item.draugiem || 0) + (item.facebook || 0) + (item.email || 0) + (item.google || 0) + (item.inbox || 0) + (item.apple || 0),
              draugiem: (item.draugiem || 0),
              facebook: (item.facebook || 0),
              email: (item.email || 0),
              google: (item.google || 0),
              inbox: (item.inbox || 0),
              apple: (item.apple || 0),
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: (item.draugiem || 0) + (item.facebook || 0) + (item.email || 0) + (item.google || 0) + (item.inbox || 0) + (item.apple || 0), draugiem: item.draugiem, facebook: item.facebook, email: item.email, google: item.google, inbox: item.inbox, apple: item.apple });
            return null;
          }
        });
      } else if (id === statisticsConst.LOGINS) {
        const { logins, loginsTotal } = statistics;

        map(loginsTotal, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (item || 0),
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (logins[key].draugiem || 0),
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (logins[key].facebook || 0),
                email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + (logins[key].email || 0),
                google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + (logins[key].google || 0),
                appleId: get(dayTotals, `${spiltDate.dayString}.appleId`, 0) + (logins[key].apple || 0),
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (logins[key].inbox || 0),
              };

              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: item, draugiem: logins[key].draugiem, facebook: logins[key].facebook, email: logins[key].email, google: logins[key].google, appleId: logins[key].apple, inbox: logins[key].inbox });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (item || 0),
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + (logins[key].draugiem || 0),
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + (logins[key].facebook || 0),
              email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + (logins[key].email || 0),
              google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + (logins[key].google || 0),
              appleId: get(dayTotals, `${spiltDate.dayString}.appleId`, 0) + (logins[key].apple || 0),
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + (logins[key].inbox || 0),
            };

            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: item, draugiem: logins[key].draugiem, facebook: logins[key].facebook, email: logins[key].email, google: logins[key].google, appleId: logins[key].apple, inbox: logins[key].inbox });
            return null;
          }
        })
      } else if (id === statisticsConst.NEWUSERS) {
        const { newUsers, newUsersTotal } = statistics;
        map(newUsersTotal, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (item || 0),
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + get(newUsers[key], 'draugiem', 0),
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + get(newUsers[key], 'facebook', 0),
                email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + get(newUsers[key], 'email', 0),
                google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + get(newUsers[key], 'google', 0),
                appleId: get(dayTotals, `${spiltDate.dayString}.appleId`, 0) + get(newUsers[key], 'apple', 0),
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + get(newUsers[key], 'inbox', 0),
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: item, draugiem: get(newUsers[key], 'draugiem', 0), facebook: get(newUsers[key], 'facebook', 0), email: get(newUsers[key], 'email', 0), google: get(newUsers[key], 'google', 0), appleId: get(newUsers[key], 'apple', 0), inbox: get(newUsers[key], 'inbox', 0) });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (item || 0),
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + get(newUsers[key], 'draugiem', 0),
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + get(newUsers[key], 'facebook', 0),
              email: get(dayTotals, `${spiltDate.dayString}.email`, 0) + get(newUsers[key], 'email', 0),
              google: get(dayTotals, `${spiltDate.dayString}.google`, 0) + get(newUsers[key], 'google', 0),
              appleId: get(dayTotals, `${spiltDate.dayString}.appleId`, 0) + get(newUsers[key], 'apple', 0),
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + get(newUsers[key], 'inbox', 0),
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: item, draugiem: get(newUsers[key], 'draugiem', 0), facebook: get(newUsers[key], 'facebook', 0), email: get(newUsers[key], 'email', 0), google: get(newUsers[key], 'google', 0), appleId: get(newUsers[key], 'apple', 0), inbox: get(newUsers[key], 'inbox', 0) });
            return null;
          }
        })
      } else if (id === statisticsConst.BETS) {
        const { bets } = statistics;
        map(bets, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item[10] || 0) + (item[25] || 0) + (item[50] || 0) + (item[100] || 0) + (item[250] || 0) + (item[500] || 0) + (item[1000] || 0) + (item[5000] || 0) + (item[10000] || 0)),
                10: get(dayTotals, `${spiltDate.dayString}.10`, 0) + item[10] || 0,
                25: get(dayTotals, `${spiltDate.dayString}.25`, 0) + item[25] || 0,
                50: get(dayTotals, `${spiltDate.dayString}.50`, 0) + item[50] || 0,
                100: get(dayTotals, `${spiltDate.dayString}.100`, 0) + item[100] || 0,
                250: get(dayTotals, `${spiltDate.dayString}.250`, 0) + item[250] || 0,
                500: get(dayTotals, `${spiltDate.dayString}.500`, 0) + item[500] || 0,
                1000: get(dayTotals, `${spiltDate.dayString}.1000`, 0) + item[1000] || 0,
                5000: get(dayTotals, `${spiltDate.dayString}.5000`, 0) + item[5000] || 0,
                10000: get(dayTotals, `${spiltDate.dayString}.10000`, 0) + item[10000] || 0,
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item[10] || 0) + (item[25] || 0) + (item[50] || 0) + (item[100] || 0) + (item[250] || 0) + (item[500] || 0) + (item[1000] || 0) + (item[5000] || 0) + (item[10000] || 0)), 10: item[10] || 0, 25: item[25] || 0, 50: item[50] || 0, 100: item[100] || 0, 250: item[250] || 0, 500: item[500] || 0, 1000: item[1000] || 0, 5000: item[5000] || 0, 10000: item[10000] || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item[10] || 0) + (item[25] || 0) + (item[50] || 0) + (item[100] || 0) + (item[250] || 0) + (item[500] || 0) + (item[1000] || 0) + (item[5000] || 0) + (item[10000] || 0)),
              10: get(dayTotals, `${spiltDate.dayString}.10`, 0) + item[10] || 0,
              25: get(dayTotals, `${spiltDate.dayString}.25`, 0) + item[25] || 0,
              50: get(dayTotals, `${spiltDate.dayString}.50`, 0) + item[50] || 0,
              100: get(dayTotals, `${spiltDate.dayString}.100`, 0) + item[100] || 0,
              100: get(dayTotals, `${spiltDate.dayString}.250`, 0) + item[250] || 0,
              500: get(dayTotals, `${spiltDate.dayString}.500`, 0) + item[500] || 0,
              1000: get(dayTotals, `${spiltDate.dayString}.1000`, 0) + item[1000] || 0,
              5000: get(dayTotals, `${spiltDate.dayString}.5000`, 0) + item[5000] || 0,
              10000: get(dayTotals, `${spiltDate.dayString}.10000`, 0) + item[10000] || 0,
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item[10] || 0) + (item[25] || 0) + (item[50] || 0) + (item[100] || 0) + (item[250] || 0) + (item[500] || 0) + (item[1000] || 0) + (item[5000] || 0) + (item[10000] || 0)), 10: item[10] || 0, 25: item[25] || 0, 50: item[50] || 0, 100: item[100] || 0, 250: item[250] || 0, 500: item[500] || 0, 1000: item[1000] || 0, 5000: item[5000] || 0, 10000: item[10000] || 0 });
            return null;
          }
        });
      } else if (id === statisticsConst.GAMETYPE) {
        const { P, PM, G, MG, Student } = statistics;

        map(P, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item || 0) + (PM[key] || 0) + (G[key] || 0) + (MG[key] || 0) + (Student[key] || 0)),
                P: get(dayTotals, `${spiltDate.dayString}.P`, 0) + item,
                PM: get(dayTotals, `${spiltDate.dayString}.PM`, 0) + PM[key],
                G: get(dayTotals, `${spiltDate.dayString}.G`, 0) + G[key],
                MG: get(dayTotals, `${spiltDate.dayString}.MG`, 0) + MG[key],
                Student: get(dayTotals, `${spiltDate.dayString}.Student`, 0) + Student[key],
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item || 0) + (PM[key] || 0) + (G[key] || 0) + (MG[key] || 0) + (Student[key] || 0)), P: item, PM: PM[key], G: G[key], MG: MG[key], Student: Student[key] });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item || 0) + (PM[key] || 0) + (G[key] || 0) + (MG[key] || 0) + (Student[key] || 0)),
              P: get(dayTotals, `${spiltDate.dayString}.P`, 0) + item,
              PM: get(dayTotals, `${spiltDate.dayString}.PM`, 0) + PM[key],
              G: get(dayTotals, `${spiltDate.dayString}.G`, 0) + G[key],
              MG: get(dayTotals, `${spiltDate.dayString}.MG`, 0) + MG[key],
              Student: get(dayTotals, `${spiltDate.dayString}.Student`, 0) + Student[key],
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item || 0) + (PM[key] || 0) + (G[key] || 0) + (MG[key] || 0) + (Student[key] || 0)), P: item, PM: PM[key], G: G[key], MG: MG[key], Student: Student[key] });
            return null;
          }
        });
      } else if (id === statisticsConst.ACTIVITY) {
        const { activePlayersDay, activePlayersWeek, activePlayersMonth, activePlayersYear, playersYearOld } = statistics;
        console.log(playersYearOld, "checkaldkasdjfklaf=======111")
        const hourCount = {};
        map(activePlayersDay, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                playersDay: get(dayTotals, `${spiltDate.dayString}.playersDay`, 0) + item,
                playersWeek: get(dayTotals, `${spiltDate.dayString}.playersWeek`, 0) + activePlayersWeek[key] || 0,
                playersMonth: get(dayTotals, `${spiltDate.dayString}.playersMonth`, 0) + activePlayersMonth[key] || 0,
                playersYear: get(dayTotals, `${spiltDate.dayString}.playersYear`, 0) + activePlayersYear[key] || 0,
                playersYearOld: get(dayTotals, `${spiltDate.dayString}.playersYearOld`, 0) + playersYearOld[key] || 0,
                playerTotal: get(dayTotals, `${spiltDate.dayString}.playerTotal`, 0) + (activePlayersYear[key] || 0) + (playersYearOld[key] || 0),
              };
              console.log("*************************************", dayTotals)
              hourCount[spiltDate.dayString] = get(hourCount, `${spiltDate.dayString}`, 0) + 1;

              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: (activePlayersYear[key] || 0) + (playersYearOld[key] || 0), playersDay: item || 0, playersWeek: activePlayersWeek[key] || 0, playersMonth: activePlayersMonth[key] || 0, playersYear: activePlayersYear[key] || 0, playersYearOld: playersYearOld[key] || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((activePlayersYear[key] || 0) + (playersYearOld[key] || 0)),
              playersDay: get(dayTotals, `${spiltDate.dayString}.playersDay`, 0) + item,
              playersWeek: get(dayTotals, `${spiltDate.dayString}.playersWeek`, 0) + activePlayersWeek[key] || 0,
              playersMonth: get(dayTotals, `${spiltDate.dayString}.playersMonth`, 0) + activePlayersMonth[key] || 0,
              playersYear: get(dayTotals, `${spiltDate.dayString}.playersYear`, 0) + activePlayersYear[key] || 0,
              playersYearOld: get(dayTotals, `${spiltDate.dayString}.playersYear`, 0) + playersYearOld[key] || 0,
            };
            hourCount[spiltDate.dayString] = get(hourCount, `${spiltDate.dayString}`, 0) + 1;
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: activePlayersYear[key] || 0 + playersYearOld[key] || 0, playersDay: item || 0, playersWeek: activePlayersWeek[key] || 0, playersMonth: activePlayersMonth[key] || 0, playersYear: activePlayersWeek[key] || 0, playersYearOld: playersYearOld[key] || 0 });
            return null;
          }
        });
        map(dayTotals, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[key] = {
                playersDay: parseFloat(item.playersDay / hourCount[key]).toFixed(0),
                playersWeek: parseFloat(item.playersWeek / hourCount[key]).toFixed(0),
                playersMonth: parseFloat(item.playersMonth / hourCount[key]).toFixed(0),
                playersYear: parseFloat(item.playersYear / hourCount[key]).toFixed(0),
                playersYearOld: parseFloat(item.playersYearOld / hourCount[key]).toFixed(0)
              };
            }
          } else {
            dayTotals[key] = {
              playersDay: parseFloat(item.playersDay / hourCount[key]).toFixed(0),
              playersWeek: parseFloat(item.playersWeek / hourCount[key]).toFixed(0),
              playersMonth: parseFloat(item.playersMonth / hourCount[key]).toFixed(0),
              playersYear: parseFloat(item.playersYear / hourCount[key]).toFixed(0),
              playersYearOld: parseFloat(item.playersYearOld / hourCount[key]).toFixed(0)
            };
          }
        });
      } else if (id === statisticsConst.GAMESPEED) {
        const { fastGame, lightningGame, unlimitedGame, slowGame } = statistics;
        map(fastGame, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item || 0) + (slowGame[key] || 0) + (lightningGame[key] || 0) + (unlimitedGame[key] || 0)),
                fast: get(dayTotals, `${spiltDate.dayString}.fast`, 0) + item || 0,
                slow: get(dayTotals, `${spiltDate.dayString}.slow`, 0) + slowGame[key] || 0,
                lightning: get(dayTotals, `${spiltDate.dayString}.lightning`, 0) + lightningGame[key] || 0,
                unlimited: get(dayTotals, `${spiltDate.dayString}.unlimited`, 0) + unlimitedGame[key] || 0,
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item || 0) + (slowGame[key] || 0) + (lightningGame[key] || 0) + (unlimitedGame[key] || 0)), fast: item || 0, slow: slowGame[key] || 0, unlimited: unlimitedGame[key] || 0, lightning: lightningGame[key] || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item || 0) + (slowGame[key] || 0) + (lightningGame[key] || 0) + (unlimitedGame[key] || 0)),
              fast: get(dayTotals, `${spiltDate.dayString}.fast`, 0) + item || 0,
              slow: get(dayTotals, `${spiltDate.dayString}.slow`, 0) + slowGame[key] || 0,
              lightning: get(dayTotals, `${spiltDate.dayString}.lightning`, 0) + lightningGame[key] || 0,
              unlimited: get(dayTotals, `${spiltDate.dayString}.unlimited`, 0) + unlimitedGame[key] || 0,
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item || 0) + (slowGame[key] || 0) + (lightningGame[key] || 0) + (unlimitedGame[key] || 0)), fast: item || 0, slow: slowGame[key] || 0, unlimited: unlimitedGame[key] || 0, lightning: lightningGame[key] || 0 });
            return null;
          }
        });
      } else if (id === statisticsConst.MISSEDROOMS) {
        const { missedRooms, missedRoomsAutomated, startedAutomated, missedRoomsont, missedRoomsond, missedRoomsofft, missedRoomsoffd, missedRoomsWeb, missedRoomsApp } = statistics;

        map(missedRooms, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (missedRoomsWeb[key] + missedRoomsApp[key]),
                missedRooms: get(dayTotals, `${spiltDate.dayString}.missedRooms`, 0) + item,
                missedRoomsAutomated: get(dayTotals, `${spiltDate.dayString}.missedRoomsAutomated`, 0) + (missedRoomsAutomated[key] || 0),
                startedAutomated: get(dayTotals, `${spiltDate.dayString}.startedAutomated`, 0) + (startedAutomated[key] || 0),
                missedRoomsont: get(dayTotals, `${spiltDate.dayString}.missedRoomsont`, 0) + missedRoomsont[key],
                missedRoomsond: get(dayTotals, `${spiltDate.dayString}.missedRoomsond`, 0) + missedRoomsond[key],
                missedRoomsofft: get(dayTotals, `${spiltDate.dayString}.missedRoomsofft`, 0) + missedRoomsofft[key],
                missedRoomsoffd: get(dayTotals, `${spiltDate.dayString}.missedRoomsoffd`, 0) + missedRoomsoffd[key],
                missedRoomsWeb: get(dayTotals, `${spiltDate.dayString}.missedRoomsWeb`, 0) + missedRoomsWeb[key],
                missedRoomsApp: get(dayTotals, `${spiltDate.dayString}.missedRoomsApp`, 0) + missedRoomsApp[key],
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, 'Total': (missedRoomsWeb[key] + missedRoomsApp[key]), 'Auto-play': missedRoomsAutomated[key], startedAutomated: startedAutomated[key], 'On (move)': missedRoomsont[key], 'On (no move)': missedRoomsond[key], 'Off (move)': missedRoomsofft[key], 'Off (no move)': missedRoomsoffd[key], 'Web': missedRoomsWeb[key], 'App': missedRoomsApp[key] });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (missedRoomsWeb[key] + missedRoomsApp[key]),
              missedRooms: get(dayTotals, `${spiltDate.dayString}.missedRooms`, 0) + item,
              missedRoomsAutomated: get(dayTotals, `${spiltDate.dayString}.missedRoomsAutomated`, 0) + (missedRoomsAutomated[key] || 0),
              startedAutomated: get(dayTotals, `${spiltDate.dayString}.startedAutomated`, 0) + (startedAutomated[key] || 0),
              missedRoomsont: get(dayTotals, `${spiltDate.dayString}.missedRoomsont`, 0) + missedRoomsont[key],
              missedRoomsond: get(dayTotals, `${spiltDate.dayString}.missedRoomsond`, 0) + missedRoomsond[key],
              missedRoomsofft: get(dayTotals, `${spiltDate.dayString}.missedRoomsofft`, 0) + missedRoomsofft[key],
              missedRoomsoffd: get(dayTotals, `${spiltDate.dayString}.missedRoomsoffd`, 0) + missedRoomsoffd[key],
              missedRoomsWeb: get(dayTotals, `${spiltDate.dayString}.missedRoomsWeb`, 0) + missedRoomsWeb[key],
              missedRoomsApp: get(dayTotals, `${spiltDate.dayString}.missedRoomsApp`, 0) + missedRoomsApp[key],
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, 'Total': (missedRoomsWeb[key] + missedRoomsApp[key]), 'Auto-play': missedRoomsAutomated[key], startedAutomated: startedAutomated[key], 'On (move)': missedRoomsont[key], 'On (no move)': missedRoomsond[key], 'Off (move)': missedRoomsofft[key], 'Off (no move)': missedRoomsoffd[key], 'Web': missedRoomsWeb[key], 'App': missedRoomsApp[key] });
            return null;
          }
        });
      } else if (id === statisticsConst.ENVPERROOM) {
        const { webRooms, appRooms } = statistics;

        map(webRooms, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + item + appRooms[key],
                webRooms: get(dayTotals, `${spiltDate.dayString}.webRooms`, 0) + item,
                appRooms: get(dayTotals, `${spiltDate.dayString}.appRooms`, 0) + appRooms[key],
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: item + appRooms[key], webRooms: item, appRooms: appRooms[key] });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + item + appRooms[key],
              webRooms: get(dayTotals, `${spiltDate.dayString}.webRooms`, 0) + item,
              appRooms: get(dayTotals, `${spiltDate.dayString}.appRooms`, 0) + appRooms[key],
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: item + appRooms[key], webRooms: item, appRooms: appRooms[key] });
            return null;
          }
        });
      } else if (id === statisticsConst.ROOMPLAYERCOUNT) {
        const { roomPlayerCount } = statistics;
        map(roomPlayerCount, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item.four || 0) + (item.three || 0) + (item.two || 0)),
                four: get(dayTotals, `${spiltDate.dayString}.four`, 0) + (item.four || 0),
                three: get(dayTotals, `${spiltDate.dayString}.three`, 0) + (item.three || 0),
                two: get(dayTotals, `${spiltDate.dayString}.two`, 0) + (item.two || 0),
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: item.four + item.three + (item.two || 0), four: item.four, three: item.three, two: item.two });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (item.four + item.three || 0),
              four: get(dayTotals, `${spiltDate.dayString}.four`, 0) + (item.four || 0),
              three: get(dayTotals, `${spiltDate.dayString}.three`, 0) + (item.three || 0),
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: item.four + item.three, four: item.four, three: item.three });
            return null;
          }
        });
      } else if (id === statisticsConst.PLAYERSPERLANGUAGE) {
        const { playersPerLanguage } = statistics;
        Object.keys(playersPerLanguage).sort().map(key => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (playersPerLanguage[key].en + playersPerLanguage[key].lv + playersPerLanguage[key].ru || 0),
                lv: get(dayTotals, `${spiltDate.dayString}.lv`, 0) + (playersPerLanguage[key].lv || 0),
                en: get(dayTotals, `${spiltDate.dayString}.en`, 0) + (playersPerLanguage[key].en || 0),
                ru: get(dayTotals, `${spiltDate.dayString}.ru`, 0) + (playersPerLanguage[key].ru || 0),
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: playersPerLanguage[key].lv + playersPerLanguage[key].en + playersPerLanguage[key].ru, lv: playersPerLanguage[key].lv, en: playersPerLanguage[key].en, ru: playersPerLanguage[key].ru });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + (playersPerLanguage[key].en + playersPerLanguage[key].lv + playersPerLanguage[key].ru || 0),
              lv: get(dayTotals, `${spiltDate.dayString}.lv`, 0) + (playersPerLanguage[key].lv || 0),
              en: get(dayTotals, `${spiltDate.dayString}.en`, 0) + (playersPerLanguage[key].en || 0),
              ru: get(dayTotals, `${spiltDate.dayString}.ru`, 0) + (playersPerLanguage[key].ru || 0),
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: playersPerLanguage[key].lv + playersPerLanguage[key].en + playersPerLanguage[key].ru, lv: playersPerLanguage[key].lv, en: playersPerLanguage[key].en, ru: playersPerLanguage[key].ru });
            return null;
          }
        });
      } else if (id === statisticsConst.COINPERCHANGES) {
        const {
          roomFee,
          lost,
          won,
          buyGift,
          removeGift,
          purchased,
          resetBalance,
          resetProfile,
          dailyBonus,
          dailyBonusAdded,
          dailyBonusRemoved,
          tournamentEntryFee,
          tournamentWon,
          friendInviteBonus,
          claimedBonus,
          claimedGiftCode,
          claimedAchievement,
          emailBonus,
          claimedTutorialBonus,
          claimedOSBonus,
          claimedAndroidBonus,
          claimediOSBonus,
          autoCompensation,
          minGamesPenalty,
          minGamesReward,
          leftRoomPenalty,
          leftRoomReward,
          admin,
          profanity,
          adminAdded,
          adminRemoved,
          botsTopUp,
          missedTurn,
          missedTurnReward,
          startBonus,
          deletedProfile
        } = statistics;

        console.log('test statistics', statistics);

        map(lost, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              monthTotalsCount = monthTotalsCount + 1;
              monthTotals = {
                roomFee: (monthTotals.roomFee || 0) + (roomFee[key] || 0),
                lost: (monthTotals.lost || 0) + (lost[key] || 0),
                won: (monthTotals.won || 0) + (won[key] || 0),
                buyGift: (monthTotals.buyGift || 0) + (buyGift[key] || 0),
                removeGift: (monthTotals.removeGift || 0) + (removeGift[key] || 0),
                purchased: (monthTotals.purchased || 0) + (purchased[key] || 0),
                resetBalance: (monthTotals.resetBalance || 0) + (resetBalance[key] || 0),
                resetProfile: (monthTotals.resetProfile || 0) + (resetProfile[key] || 0),
                dailyBonus: (monthTotals.dailyBonus || 0) + (dailyBonus[key] || 0),
                dailyBonusAdded: (monthTotals.dailyBonusAdded || 0) + (dailyBonusAdded[key] || 0),
                dailyBonusRemoved: (monthTotals.dailyBonusRemoved || 0) + (dailyBonusRemoved[key] || 0),
                tournamentEntryFee: (monthTotals.tournamentEntryFee || 0) + (tournamentEntryFee[key] || 0),
                tournamentWon: (monthTotals.tournamentWon || 0) + (tournamentWon[key] || 0),
                friendInviteBonus: (monthTotals.friendInviteBonus || 0) + (friendInviteBonus[key] || 0),
                claimedBonus: (monthTotals.claimedBonus || 0) + (claimedBonus[key] || 0),
                claimedGiftCode: (monthTotals.claimedGiftCode || 0) + (claimedGiftCode[key] || 0),
                claimedAchievement: (monthTotals.claimedAchievement || 0) + (claimedAchievement[key] || 0),
                emailBonus: (monthTotals.emailBonus || 0) + (emailBonus[key] || 0),
                claimedTutorialBonus: (monthTotals.claimedTutorialBonus || 0) + (claimedTutorialBonus[key] || 0),
                claimedOSBonus: (monthTotals.claimedOSBonus || 0) + (claimedOSBonus[key] || 0),
                claimedAndroidBonus: (monthTotals.claimedAndroidBonus || 0) + (claimedAndroidBonus[key] || 0),
                claimediOSBonus: (monthTotals.claimediOSBonus || 0) + (claimediOSBonus[key] || 0),
                autoCompensation: (monthTotals.autoCompensation || 0) + (autoCompensation[key] || 0),
                minGamesPenalty: (monthTotals.minGamesPenalty || 0) + (minGamesPenalty[key] || 0),
                minGamesReward: (monthTotals.minGamesReward || 0) + (minGamesReward[key] || 0),
                leftRoomPenalty: (monthTotals.leftRoomPenalty || 0) + (leftRoomPenalty[key] || 0),
                leftRoomReward: (monthTotals.leftRoomReward || 0) + (leftRoomReward[key] || 0),
                admin: (monthTotals.admin || 0) + (admin[key] || 0),
                profanity: (monthTotals.profanity || 0) + (profanity[key] || 0),
                adminAdded: (monthTotals.adminAdded || 0) + (adminAdded[key] || 0),
                adminRemoved: (monthTotals.adminRemoved || 0) + (adminRemoved[key] || 0),
                botsTopUp: (monthTotals.botsTopUp || 0) + (botsTopUp[key] || 0),
                missedTurn: (monthTotals.missedTurn || 0) + (missedTurn[key] || 0),
                missedTurnReward: (monthTotals.missedTurnReward || 0) + (missedTurnReward[key] || 0),
                startBonus: (monthTotals.startBonus || 0) + (startBonus[key] || 0),
                deletedProfile: (monthTotals.deletedProfile || 0) + (deletedProfile[key] || 0),
              };

              dayTotals[spiltDate.dayString] = {
                roomFee: get(dayTotals, `${spiltDate.dayString}.roomFee`, 0) + roomFee[key] || 0,
                lost: get(dayTotals, `${spiltDate.dayString}.lost`, 0) + item || 0,
                won: get(dayTotals, `${spiltDate.dayString}.won`, 0) + won[key] || 0,
                buyGift: get(dayTotals, `${spiltDate.dayString}.buyGift`, 0) + buyGift[key] || 0,
                removeGift: get(dayTotals, `${spiltDate.dayString}.removeGift`, 0) + removeGift[key] || 0,
                purchased: get(dayTotals, `${spiltDate.dayString}.purchased`, 0) + purchased[key] || 0,
                resetBalance: get(dayTotals, `${spiltDate.dayString}.resetBalance`, 0) + resetBalance[key] || 0,
                resetProfile: get(dayTotals, `${spiltDate.dayString}.resetProfile`, 0) + resetProfile[key] || 0,
                dailyBonus: get(dayTotals, `${spiltDate.dayString}.dailyBonus`, 0) + dailyBonus[key] || 0,
                dailyBonusAdded: get(dayTotals, `${spiltDate.dayString}.dailyBonusAdded`, 0) + dailyBonusAdded[key] || 0,
                dailyBonusRemoved: get(dayTotals, `${spiltDate.dayString}.dailyBonusRemoved`, 0) + dailyBonusRemoved[key] || 0,
                tournamentEntryFee: get(dayTotals, `${spiltDate.dayString}.tournamentEntryFee`, 0) + tournamentEntryFee[key] || 0,
                tournamentWon: get(dayTotals, `${spiltDate.dayString}.tournamentWon`, 0) + tournamentWon[key] || 0,
                friendInviteBonus: get(dayTotals, `${spiltDate.dayString}.friendInviteBonus`, 0) + friendInviteBonus[key] || 0,
                claimedBonus: get(dayTotals, `${spiltDate.dayString}.claimedBonus`, 0) + claimedBonus[key] || 0,
                claimedGiftCode: get(dayTotals, `${spiltDate.dayString}.claimedGiftCode`, 0) + claimedGiftCode[key] || 0,
                claimedAchievement: get(dayTotals, `${spiltDate.dayString}.claimedAchievement`, 0) + claimedAchievement[key] || 0,
                emailBonus: get(dayTotals, `${spiltDate.dayString}.emailBonus`, 0) + emailBonus[key] || 0,
                claimedTutorialBonus: get(dayTotals, `${spiltDate.dayString}.claimedTutorialBonus`, 0) + claimedTutorialBonus[key] || 0,
                claimedOSBonus: get(dayTotals, `${spiltDate.dayString}.claimedOSBonus`, 0) + claimedOSBonus[key] || 0,
                claimedAndroidBonus: get(dayTotals, `${spiltDate.dayString}.claimedAndroidBonus`, 0) + claimedAndroidBonus[key] || 0,
                claimediOSBonus: get(dayTotals, `${spiltDate.dayString}.claimediOSBonus`, 0) + claimediOSBonus[key] || 0,
                autoCompensation: get(dayTotals, `${spiltDate.dayString}.autoCompensation`, 0) + autoCompensation[key] || 0,
                minGamesPenalty: get(dayTotals, `${spiltDate.dayString}.minGamesPenalty`, 0) + minGamesPenalty[key] || 0,
                minGamesReward: get(dayTotals, `${spiltDate.dayString}.minGamesReward`, 0) + minGamesReward[key] || 0,
                leftRoomPenalty: get(dayTotals, `${spiltDate.dayString}.leftRoomPenalty`, 0) + leftRoomPenalty[key] || 0,
                leftRoomReward: get(dayTotals, `${spiltDate.dayString}.leftRoomReward`, 0) + leftRoomReward[key] || 0,
                admin: get(dayTotals, `${spiltDate.dayString}.admin`, 0) + admin[key] || 0,
                profanity: get(dayTotals, `${spiltDate.dayString}.profanity`, 0) + profanity[key] || 0,
                adminAdded: get(dayTotals, `${spiltDate.dayString}.adminAdded`, 0) + adminAdded[key] || 0,
                adminRemoved: get(dayTotals, `${spiltDate.dayString}.adminRemoved`, 0) + adminRemoved[key] || 0,
                botsTopUp: get(dayTotals, `${spiltDate.dayString}.botsTopUp`, 0) + botsTopUp[key] || 0,
                missedTurn: get(dayTotals, `${spiltDate.dayString}.missedTurn`, 0) + missedTurn[key] || 0,
                missedTurnReward: get(dayTotals, `${spiltDate.dayString}.missedTurnReward`, 0) + missedTurnReward[key] || 0,
                startBonus: get(dayTotals, `${spiltDate.dayString}.startBonus`, 0) + startBonus[key] || 0,
                deletedProfile: get(dayTotals, `${spiltDate.dayString}.deletedProfile`, 0) + deletedProfile[key] || 0,
              };
              dataArray.push({
                key: spiltDate.dateKey,
                day: spiltDate.dayString,
                hour: `${spiltDate.time}:00`,
                roomFee: roomFee[key],
                lost: item,
                won: won[key],
                buyGift: buyGift[key],
                removeGift: removeGift[key],
                purchased: purchased[key],
                resetBalance: resetBalance[key],
                resetProfile: resetProfile[key],
                dailyBonus: dailyBonus[key],
                dailyBonusAdded: dailyBonusAdded[key],
                dailyBonusRemoved: dailyBonusRemoved[key],
                tournamentEntryFee: tournamentEntryFee[key],
                tournamentWon: tournamentWon[key],
                friendInviteBonus: friendInviteBonus[key],
                claimedBonus: claimedBonus[key],
                claimedGiftCode: claimedGiftCode[key],
                claimedAchievement: claimedAchievement[key],
                emailBonus: emailBonus[key],
                claimedTutorialBonus: claimedTutorialBonus[key],
                claimedOSBonus: claimedOSBonus[key],
                claimedAndroidBonus: claimedAndroidBonus[key],
                claimediOSBonus: claimediOSBonus[key],
                autoCompensation: autoCompensation[key],
                minGamesPenalty: minGamesPenalty[key],
                minGamesReward: minGamesReward[key],
                leftRoomPenalty: leftRoomPenalty[key],
                leftRoomReward: leftRoomReward[key],
                admin: admin[key],
                profanity: profanity[key],
                adminAdded: adminAdded[key],
                adminRemoved: adminRemoved[key],
                botsTopUp: botsTopUp[key],
                missedTurn: missedTurn[key],
                missedTurnReward: missedTurnReward[key],
                startBonus: startBonus[key],
                deletedProfile: deletedProfile[key],
              });
              return null;
            }
          } else {
            monthTotalsCount = monthTotalsCount + 1;
            monthTotals = {
              roomFee: (monthTotals.roomFee || 0) + (roomFee[key] || 0),
              lost: (monthTotals.lost || 0) + (lost[key] || 0),
              won: (monthTotals.won || 0) + (won[key] || 0),
              buyGift: (monthTotals.buyGift || 0) + (buyGift[key] || 0),
              removeGift: (monthTotals.removeGift || 0) + (removeGift[key] || 0),
              purchased: (monthTotals.purchased || 0) + (purchased[key] || 0),
              resetBalance: (monthTotals.resetBalance || 0) + (resetBalance[key] || 0),
              resetProfile: (monthTotals.resetProfile || 0) + (resetProfile[key] || 0),
              dailyBonus: (monthTotals.dailyBonus || 0) + (dailyBonus[key] || 0),
              dailyBonusAdded: (monthTotals.dailyBonusAdded || 0) + (dailyBonusAdded[key] || 0),
              dailyBonusRemoved: (monthTotals.dailyBonusRemoved || 0) + (dailyBonusRemoved[key] || 0),
              tournamentEntryFee: (monthTotals.tournamentEntryFee || 0) + (tournamentEntryFee[key] || 0),
              tournamentWon: (monthTotals.tournamentWon || 0) + (tournamentWon[key] || 0),
              friendInviteBonus: (monthTotals.friendInviteBonus || 0) + (friendInviteBonus[key] || 0),
              claimedBonus: (monthTotals.claimedBonus || 0) + (claimedBonus[key] || 0),
              claimedGiftCode: (monthTotals.claimedGiftCode || 0) + (claimedGiftCode[key] || 0),
              claimedAchievement: (monthTotals.claimedAchievement || 0) + (claimedAchievement[key] || 0),
              emailBonus: (monthTotals.emailBonus || 0) + (emailBonus[key] || 0),
              claimedTutorialBonus: (monthTotals.claimedTutorialBonus || 0) + (claimedTutorialBonus[key] || 0),
              claimedOSBonus: (monthTotals.claimedOSBonus || 0) + (claimedOSBonus[key] || 0),
              claimedAndroidBonus: (monthTotals.claimedAndroidBonus || 0) + (claimedAndroidBonus[key] || 0),
              claimediOSBonus: (monthTotals.claimediOSBonus || 0) + (claimediOSBonus[key] || 0),
              autoCompensation: (monthTotals.autoCompensation || 0) + (autoCompensation[key] || 0),
              minGamesPenalty: (monthTotals.minGamesPenalty || 0) + (minGamesPenalty[key] || 0),
              minGamesReward: (monthTotals.minGamesReward || 0) + (minGamesReward[key] || 0),
              leftRoomPenalty: (monthTotals.leftRoomPenalty || 0) + (leftRoomPenalty[key] || 0),
              leftRoomReward: (monthTotals.leftRoomReward || 0) + (leftRoomReward[key] || 0),
              admin: (monthTotals.admin || 0) + (admin[key] || 0),
              profanity: (monthTotals.profanity || 0) + (profanity[key] || 0),
              adminAdded: (monthTotals.adminAdded || 0) + (adminAdded[key] || 0),
              adminRemoved: (monthTotals.adminRemoved || 0) + (adminRemoved[key] || 0),
              botsTopUp: (monthTotals.botsTopUp || 0) + (botsTopUp[key] || 0),
              missedTurn: (monthTotals.missedTurn || 0) + (missedTurn[key] || 0),
              missedTurnReward: (monthTotals.missedTurnReward || 0) + (missedTurnReward[key] || 0),
              startBonus: (monthTotals.startBonus || 0) + (startBonus[key] || 0),
              deletedProfile: (monthTotals.deletedProfile || 0) + (deletedProfile[key] || 0),
            };

            dayTotals[spiltDate.dayString] = {
              roomFee: get(dayTotals, `${spiltDate.dayString}.roomFee`, 0) + roomFee[key] || 0,
              lost: get(dayTotals, `${spiltDate.dayString}.lost`, 0) + item || 0,
              won: get(dayTotals, `${spiltDate.dayString}.won`, 0) + won[key] || 0,
              buyGift: get(dayTotals, `${spiltDate.dayString}.buyGift`, 0) + buyGift[key] || 0,
              removeGift: get(dayTotals, `${spiltDate.dayString}.removeGift`, 0) + removeGift[key] || 0,
              purchased: get(dayTotals, `${spiltDate.dayString}.purchased`, 0) + purchased[key] || 0,
              resetBalance: get(dayTotals, `${spiltDate.dayString}.resetBalance`, 0) + resetBalance[key] || 0,
              resetProfile: get(dayTotals, `${spiltDate.dayString}.resetProfile`, 0) + resetProfile[key] || 0,
              dailyBonus: get(dayTotals, `${spiltDate.dayString}.dailyBonus`, 0) + dailyBonus[key] || 0,
              dailyBonusAdded: get(dayTotals, `${spiltDate.dayString}.dailyBonusAdded`, 0) + dailyBonusAdded[key] || 0,
              dailyBonusRemoved: get(dayTotals, `${spiltDate.dayString}.dailyBonusRemoved`, 0) + dailyBonusRemoved[key] || 0,
              tournamentEntryFee: get(dayTotals, `${spiltDate.dayString}.tournamentEntryFee`, 0) + tournamentEntryFee[key] || 0,
              tournamentWon: get(dayTotals, `${spiltDate.dayString}.tournamentWon`, 0) + tournamentWon[key] || 0,
              friendInviteBonus: get(dayTotals, `${spiltDate.dayString}.friendInviteBonus`, 0) + friendInviteBonus[key] || 0,
              claimedBonus: get(dayTotals, `${spiltDate.dayString}.claimedBonus`, 0) + claimedBonus[key] || 0,
              claimedGiftCode: get(dayTotals, `${spiltDate.dayString}.claimedGiftCode`, 0) + claimedGiftCode[key] || 0,
              claimedAchievement: get(dayTotals, `${spiltDate.dayString}.claimedAchievement`, 0) + claimedAchievement[key] || 0,
              emailBonus: get(dayTotals, `${spiltDate.dayString}.emailBonus`, 0) + emailBonus[key] || 0,
              claimedTutorialBonus: get(dayTotals, `${spiltDate.dayString}.claimedTutorialBonus`, 0) + claimedTutorialBonus[key] || 0,
              claimedOSBonus: get(dayTotals, `${spiltDate.dayString}.claimedOSBonus`, 0) + claimedOSBonus[key] || 0,
              claimedAndroidBonus: get(dayTotals, `${spiltDate.dayString}.claimedAndroidBonus`, 0) + claimedAndroidBonus[key] || 0,
              claimediOSBonus: get(dayTotals, `${spiltDate.dayString}.claimediOSBonus`, 0) + claimediOSBonus[key] || 0,
              autoCompensation: get(dayTotals, `${spiltDate.dayString}.autoCompensation`, 0) + autoCompensation[key] || 0,
              minGamesPenalty: get(dayTotals, `${spiltDate.dayString}.minGamesPenalty`, 0) + minGamesPenalty[key] || 0,
              minGamesReward: get(dayTotals, `${spiltDate.dayString}.minGamesReward`, 0) + minGamesReward[key] || 0,
              leftRoomPenalty: get(dayTotals, `${spiltDate.dayString}.leftRoomPenalty`, 0) + leftRoomPenalty[key] || 0,
              leftRoomReward: get(dayTotals, `${spiltDate.dayString}.leftRoomReward`, 0) + leftRoomReward[key] || 0,
              admin: get(dayTotals, `${spiltDate.dayString}.admin`, 0) + admin[key] || 0,
              profanity: get(dayTotals, `${spiltDate.dayString}.profanity`, 0) + profanity[key] || 0,
              adminAdded: get(dayTotals, `${spiltDate.dayString}.adminAdded`, 0) + adminAdded[key] || 0,
              adminRemoved: get(dayTotals, `${spiltDate.dayString}.adminRemoved`, 0) + adminRemoved[key] || 0,
              botsTopUp: get(dayTotals, `${spiltDate.dayString}.botsTopUp`, 0) + botsTopUp[key] || 0,
              missedTurn: get(dayTotals, `${spiltDate.dayString}.missedTurn`, 0) + missedTurn[key] || 0,
              missedTurnReward: get(dayTotals, `${spiltDate.dayString}.missedTurnReward`, 0) + missedTurnReward[key] || 0,
              startBonus: get(dayTotals, `${spiltDate.dayString}.startBonus`, 0) + startBonus[key] || 0,
              deletedProfile: get(dayTotals, `${spiltDate.dayString}.deletedProfile`, 0) + deletedProfile[key] || 0,
            };
            dataArray.push({
              key: spiltDate.dateKey,
              day: spiltDate.dayString,
              hour: `${spiltDate.time}:00`,
              roomFee: roomFee[key],
              lost: item,
              won: won[key],
              buyGift: buyGift[key],
              removeGift: removeGift[key],
              purchased: purchased[key],
              resetBalance: resetBalance[key],
              resetProfile: resetProfile[key],
              dailyBonus: dailyBonus[key],
              dailyBonusAdded: dailyBonusAdded[key],
              dailyBonusRemoved: dailyBonusRemoved[key],
              tournamentEntryFee: tournamentEntryFee[key],
              tournamentWon: tournamentWon[key],
              friendInviteBonus: friendInviteBonus[key],
              claimedBonus: claimedBonus[key],
              claimedGiftCode: claimedGiftCode[key],
              claimedAchievement: claimedAchievement[key],
              emailBonus: emailBonus[key],
              claimedTutorialBonus: claimedTutorialBonus[key],
              claimedOSBonus: claimedOSBonus[key],
              claimedAndroidBonus: claimedAndroidBonus[key],
              claimediOSBonus: claimediOSBonus[key],
              autoCompensation: autoCompensation[key],
              minGamesPenalty: minGamesPenalty[key],
              minGamesReward: minGamesReward[key],
              leftRoomPenalty: leftRoomPenalty[key],
              leftRoomReward: leftRoomReward[key],
              admin: admin[key],
              profanity: profanity[key],
              adminAdded: adminAdded[key],
              adminRemoved: adminRemoved[key],
              botsTopUp: botsTopUp[key],
              missedTurn: missedTurn[key],
              missedTurnReward: missedTurnReward[key],
              startBonus: startBonus[key],
              deletedProfile: deletedProfile[key],
            });
            return null;
          }
        });

        console.log('monthTotalsCount', monthTotalsCount, monthTotals);

        this.setState({ monthTotals: monthTotals || null });
      } else if (id === statisticsConst.PLAYERSPERFONTMODE) {
        const { playersPerFontMode } = statistics;
        map(playersPerFontMode, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                normal: get(dayTotals, `${spiltDate.dayString}.normal`, 0) + item?.normal || 0,
                large: get(dayTotals, `${spiltDate.dayString}.large`, 0) + item?.large || 0,
                xLarge: get(dayTotals, `${spiltDate.dayString}.xLarge`, 0) + item?.xLarge || 0,
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, normal: item?.normal || 0, large: item?.large || 0, xLarge: item?.xLarge || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              normal: get(dayTotals, `${spiltDate.dayString}.normal`, 0) + item?.normal || 0,
              large: get(dayTotals, `${spiltDate.dayString}.large`, 0) + item?.large || 0,
              xLarge: get(dayTotals, `${spiltDate.dayString}.xLarge`, 0) + item?.xLarge || 0,
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, normal: item?.normal || 0, large: item?.large || 0, xLarge: item?.xLarge || 0 });
            return null;
          }
        });
      } else if (id === statisticsConst.PLAYERSPERDESIGN) {
        const { playersPerDesign, playersPerDesignDev } = statistics;
        const playersPerDesignByEnv = selectedEnvOption.value === 'prod' ? playersPerDesign : playersPerDesignDev;

        map(playersPerDesignByEnv, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                old: get(dayTotals, `${spiltDate.dayString}.old`, 0) + item?.old || 0,
                new: get(dayTotals, `${spiltDate.dayString}.new`, 0) + item?.new || 0,
                desktopNew: get(dayTotals, `${spiltDate.dayString}.desktopNew`, 0) + item?.desktopNew || 0,
                desktopOld: get(dayTotals, `${spiltDate.dayString}.desktopOld`, 0) + item?.desktopOld || 0,
                draugiemNew: get(dayTotals, `${spiltDate.dayString}.draugiemNew`, 0) + item?.draugiemNew || 0,
                draugiemOld: get(dayTotals, `${spiltDate.dayString}.draugiemOld`, 0) + item?.draugiemOld || 0,
                facebookNew: get(dayTotals, `${spiltDate.dayString}.facebookNew`, 0) + item?.facebookNew || 0,
                facebookOld: get(dayTotals, `${spiltDate.dayString}.facebookOld`, 0) + item?.facebookOld || 0,
                inboxNew: get(dayTotals, `${spiltDate.dayString}.inboxNew`, 0) + item?.inboxNew || 0,
                inboxOld: get(dayTotals, `${spiltDate.dayString}.inboxOld`, 0) + item?.inboxOld || 0,
                mobileNew: get(dayTotals, `${spiltDate.dayString}.mobileNew`, 0) + item?.mobileNew || 0,
                mobileOld: get(dayTotals, `${spiltDate.dayString}.mobileOld`, 0) + item?.mobileOld || 0,
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, old: item?.old || 0, new: item?.new || 0, desktopNew: item?.desktopNew, desktopOld: item?.desktopOld, draugiemNew: item?.draugiemNew, draugiemOld: item?.draugiemOld, facebookNew: item?.facebookNew, facebookOld: item?.facebookOld, inboxNew: item?.inboxNew, inboxOld: item?.inboxOld, mobileNew: item?.mobileNew, mobileOld: item?.mobileOld });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              old: get(dayTotals, `${spiltDate.dayString}.old`, 0) + item?.old || 0,
              new: get(dayTotals, `${spiltDate.dayString}.new`, 0) + item?.new || 0,
              desktopNew: get(dayTotals, `${spiltDate.dayString}.desktopNew`, 0) + item?.desktopNew || 0,
              desktopOld: get(dayTotals, `${spiltDate.dayString}.desktopOld`, 0) + item?.desktopOld || 0,
              draugiemNew: get(dayTotals, `${spiltDate.dayString}.draugiemNew`, 0) + item?.draugiemNew || 0,
              draugiemOld: get(dayTotals, `${spiltDate.dayString}.draugiemOld`, 0) + item?.draugiemOld || 0,
              facebookNew: get(dayTotals, `${spiltDate.dayString}.facebookNew`, 0) + item?.facebookNew || 0,
              facebookOld: get(dayTotals, `${spiltDate.dayString}.facebookOld`, 0) + item?.facebookOld || 0,
              inboxNew: get(dayTotals, `${spiltDate.dayString}.inboxNew`, 0) + item?.inboxNew || 0,
              inboxOld: get(dayTotals, `${spiltDate.dayString}.inboxOld`, 0) + item?.inboxOld || 0,
              mobileNew: get(dayTotals, `${spiltDate.dayString}.mobileNew`, 0) + item?.mobileNew || 0,
              mobileOld: get(dayTotals, `${spiltDate.dayString}.mobileOld`, 0) + item?.mobileOld || 0,
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, old: item?.old || 0, new: item?.new || 0, desktopNew: item?.desktopNew, desktopOld: item?.desktopOld, draugiemNew: item?.draugiemNew, draugiemOld: item?.draugiemOld, facebookNew: item?.facebookNew, facebookOld: item?.facebookOld, inboxNew: item?.inboxNew, inboxOld: item?.inboxOld, mobileNew: item?.mobileNew, mobileOld: item?.mobileOld });
            return null;
          }
        });
      } else if (id === statisticsConst.PLAYERACTIVETHEME) {
        const { playersPerScreenMode, playersPerScreenModeDev } = statistics;
        const playersPerDesignByEnv = selectedEnvOption.value === 'prod' ? playersPerScreenMode : playersPerScreenModeDev;

        map(playersPerDesignByEnv, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                dark: get(dayTotals, `${spiltDate.dayString}.dark`, 0) + item?.dark || 0,
                light: get(dayTotals, `${spiltDate.dayString}.light`, 0) + item?.light || 0,
                normal: get(dayTotals, `${spiltDate.dayString}.normal`, 0) + item?.normal || 0,
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, dark: item?.dark || 0, light: item?.light || 0, normal: item?.normal || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              dark: get(dayTotals, `${spiltDate.dayString}.dark`, 0) + item?.dark || 0,
              light: get(dayTotals, `${spiltDate.dayString}.light`, 0) + item?.light || 0,
              normal: get(dayTotals, `${spiltDate.dayString}.normal`, 0) + item?.normal || 0,
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, dark: item?.dark || 0, light: item?.light || 0, normal: item?.normal || 0 });
            return null;
          }
        });
      } else if (id === statisticsConst.PLAYERSPERAUTOMERGEROOMS) {
        const { playersPerAutoMergeRooms } = statistics;
        map(playersPerAutoMergeRooms, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                off: get(dayTotals, `${spiltDate.dayString}.off`, 0) + item?.off || 0,
                on: get(dayTotals, `${spiltDate.dayString}.on`, 0) + item?.on || 0,
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, off: item?.off || 0, on: item?.on || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              off: get(dayTotals, `${spiltDate.dayString}.off`, 0) + item?.off || 0,
              on: get(dayTotals, `${spiltDate.dayString}.on`, 0) + item?.on || 0,
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, off: item?.off || 0, on: item?.on || 0 });
            return null;
          }
        });
      } else if (id === statisticsConst.PLAYERSPERSOUND) {
        const { playersPerSound } = statistics;
        map(playersPerSound, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                off: get(dayTotals, `${spiltDate.dayString}.off`, 0) + item?.off || 0,
                on: get(dayTotals, `${spiltDate.dayString}.on`, 0) + item?.on || 0,
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, off: item?.off || 0, on: item?.on || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              off: get(dayTotals, `${spiltDate.dayString}.off`, 0) + item?.off || 0,
              on: get(dayTotals, `${spiltDate.dayString}.on`, 0) + item?.on || 0,
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, off: item?.off || 0, on: item?.on || 0 });
            return null;
          }
        });
      } else if (id === statisticsConst.PLAYERSFULLSCREEN) {
        const { fullscreen } = statistics;

        console.log('PLAYERSFULLSCREEN', fullscreen);

        map(fullscreen, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item?.newDesign || 0) + (item?.oldDesign || 0) + (item.iframeOldDesign || 0) + (item.iframeNewDesign || 0)),
                newDesign: get(dayTotals, `${spiltDate.dayString}.newDesign`, 0) + (item?.newDesign || 0),
                oldDesign: get(dayTotals, `${spiltDate.dayString}.oldDesign`, 0) + (item?.oldDesign || 0),
                iframeOldDesign: get(dayTotals, `${spiltDate.dayString}.iframeOldDesign`, 0) + (item?.iframeOldDesign || 0),
                iframeNewDesign: get(dayTotals, `${spiltDate.dayString}.iframeNewDesign`, 0) + (item?.iframeNewDesign || 0),
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item.newDesign || 0) + (item.oldDesign || 0) + (item.iframeOldDesign || 0) + (item.iframeNewDesign || 0)), 'dWeb (new)': item?.newDesign || 0, 'dWeb (old)': item?.oldDesign || 0, 'iframe (old)': item?.iframeOldDesign || 0, 'iframe (new)': item?.iframeNewDesign || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item.newDesign || 0) + (item.oldDesign || 0) + (item.iframeOldDesign) || 0 + (item.iframeNewDesign || 0)),
              newDesign: get(dayTotals, `${spiltDate.dayString}.newDesign`, 0) + item?.newDesign || 0,
              oldDesign: get(dayTotals, `${spiltDate.dayString}.oldDesign`, 0) + item?.oldDesign || 0,
              iframeOldDesign: get(dayTotals, `${spiltDate.dayString}.iframeOldDesign`, 0) + item?.iframeOldDesign || 0,
              iframeNewDesign: get(dayTotals, `${spiltDate.dayString}.iframeNewDesign`, 0) + item?.iframeNewDesign || 0,
            };
            console.log(dataArray, "checkFull", get(dayTotals, `${spiltDate.dayString}.total`, 0) + item?.newDesign || 0 + item?.oldDesign || 0 + item?.iframe || 0)
            dataArray.push({
              key: spiltDate.dateKey,
              day: spiltDate.dayString,
              hour: `${spiltDate.time}:00`,
              total: ((item.newDesign || 0) + (item.oldDesign || 0) + (item.iframeOldDesign || 0) + (item.iframeNewDesign || 0)),
             // newDesign: item?.newDesign || 0,
              //oldDesign: item?.oldDesign || 0,
              'dWeb (new)': item?.newDesign || 0,
              'dWeb (old)': item?.oldDesign || 0,
              'iframe (old)': item?.iframeOldDesign || 0,
              'iframe (new)': item?.iframeNewDesign || 0,
            });
            return null;
          }
        });

        console.log('dataArray', dataArray, dayTotals);

      } else if (id === statisticsConst.PLAYERSVIEWSPERSCREEN) {
        const { viewsPerScreen } = statistics;
        map(viewsPerScreen, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);
              let selectedView = {};
              forEach(selectedViewOption, (element) => {
                if (item[element.value] != undefined) {
                  dayTotals[spiltDate.dayString] = {
                    createRoom: get(dayTotals, `${spiltDate.dayString}.createRoom`, 0) + (item[element.value].createRoom || 0),
                    loading: get(dayTotals, `${spiltDate.dayString}.loading`, 0) + (item[element.value].loading || 0),
                    myInfo: get(dayTotals, `${spiltDate.dayString}.myInfo`, 0) + (item[element.value].myInfo || 0),
                    powerRankings: get(dayTotals, `${spiltDate.dayString}.powerRankings`, 0) + (item[element.value].powerRankings || 0),
                    rooms: get(dayTotals, `${spiltDate.dayString}.rooms`, 0) + (item[element.value].rooms || 0),
                    top: get(dayTotals, `${spiltDate.dayString}.top`, 0) + (item[element.value].top || 0),
                    tournaments: get(dayTotals, `${spiltDate.dayString}.tournaments`, 0) + (item[element.value].tournaments || 0),
                  };

                  selectedView = {
                    createRoom: get(selectedView, 'createRoom', 0) + item[element.value].createRoom || 0,
                    loading: get(selectedView, 'loading', 0) + item[element.value].loading || 0,
                    myInfo: get(selectedView, 'myInfo', 0) + item[element.value].myInfo || 0,
                    powerRankings: get(selectedView, 'powerRankings', 0) + item[element.value].powerRankings || 0,
                    rooms: get(selectedView, 'moroomsney5', 0) + item[element.value].rooms || 0,
                    top: get(selectedView, 'top', 0) + item[element.value].top || 0,
                    tournaments: get(selectedView, 'tournaments', 0) + item[element.value].tournaments || 0,
                  };
                }
              });

              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, createRoom: selectedView.createRoom, loading: selectedView.loading, myInfo: selectedView.myInfo, powerRankings: selectedView.powerRankings, rooms: selectedView.rooms, top: selectedView.top, tournaments: selectedView.tournaments });
            }
          } else {
            const spiltDate = constants.getSplitDate(key);
            let selectedView = {};
            forEach(selectedViewOption, (element) => {
              if (item[element.value] != undefined) {
                dayTotals[spiltDate.dayString] = {
                  createRoom: get(dayTotals, `${spiltDate.dayString}.createRoom`, 0) + (item[element.value].createRoom || 0),
                  loading: get(dayTotals, `${spiltDate.dayString}.loading`, 0) + (item[element.value].loading || 0),
                  myInfo: get(dayTotals, `${spiltDate.dayString}.myInfo`, 0) + (item[element.value].myInfo || 0),
                  powerRankings: get(dayTotals, `${spiltDate.dayString}.powerRankings`, 0) + (item[element.value].powerRankings || 0),
                  rooms: get(dayTotals, `${spiltDate.dayString}.rooms`, 0) + (item[element.value].rooms || 0),
                  top: get(dayTotals, `${spiltDate.dayString}.top`, 0) + (item[element.value].top || 0),
                  tournaments: get(dayTotals, `${spiltDate.dayString}.tournaments`, 0) + (item[element.value].tournaments || 0),
                };

                selectedView = {
                  createRoom: get(selectedView, 'createRoom', 0) + item[element.value].createRoom || 0,
                  loading: get(selectedView, 'loading', 0) + item[element.value].loading || 0,
                  myInfo: get(selectedView, 'myInfo', 0) + item[element.value].myInfo || 0,
                  powerRankings: get(selectedView, 'powerRankings', 0) + item[element.value].powerRankings || 0,
                  rooms: get(selectedView, 'moroomsney5', 0) + item[element.value].rooms || 0,
                  top: get(selectedView, 'top', 0) + item[element.value].top || 0,
                  tournaments: get(selectedView, 'tournaments', 0) + item[element.value].tournaments || 0,
                };
              }
            });
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, createRoom: selectedView.createRoom, loading: selectedView.loading, myInfo: selectedView.myInfo, powerRankings: selectedView.powerRankings, rooms: selectedView.rooms, top: selectedView.top, tournaments: selectedView.tournaments });
          }
        });
      } else if (id === statisticsConst.PLAYERSPERBROWSER) {
        const { playersPerBrowser } = statistics;
        console.log(playersPerBrowser, "checkBrowser===")
        map(playersPerBrowser, (item, key) => {
          let browserInfo = {};
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);
              let selectedView = {};

              if (!item) {
                return;
              }
              if (!item[selectedViewOption.value]) {
                return;
              }
              let totalInfo = 0
              forEach(item[selectedViewOption.value], (broItem, broIdx) => {
                if (!broItem) {
                  return;
                }

                if (!dayTotals[spiltDate.dayString]) {
                  dayTotals[spiltDate.dayString] = {};
                }

                if (!dayTotals[spiltDate.dayString]['total']) {
                  dayTotals[spiltDate.dayString]['total'] = 0;
                }

                if (!dayTotals[spiltDate.dayString]['others']) {
                  dayTotals[spiltDate.dayString]['others'] = 0;
                }

                console.log(broIdx, broItem, "checkIIIII")
                dayTotals[spiltDate.dayString][broIdx] =
                  (dayTotals[spiltDate.dayString][broIdx] || 0) + (broItem.total || 0);

                if (includes(statisticsConst.UN_CORRESPONDING_BROWSERS, broIdx.toLowerCase())) {
                  dayTotals[spiltDate.dayString]['others'] = dayTotals[spiltDate.dayString]['others'] + broItem.total;
                  browserInfo['others'] = browserInfo['others'] + broItem.total;
                }

                dayTotals[spiltDate.dayString]['total'] = (dayTotals[spiltDate.dayString]['total'] || 0) + broItem.total;
                browserInfo[broIdx] = broItem.total;
                totalInfo = totalInfo + broItem.total;

                if (!browserKeys.includes(broIdx)) {
                  browserKeys.push(broIdx);
                }

                if (!dayTotals[spiltDate.dayString]['versionInfo']) {
                  dayTotals[spiltDate.dayString]['versionInfo'] = {};
                }
                if (!dayTotals[spiltDate.dayString]['versionInfo'][broIdx]) {
                  dayTotals[spiltDate.dayString]['versionInfo'][broIdx] = {};
                }
                map(broItem, (versionItem, versionIdx) => {

                  if (!dayTotals[spiltDate.dayString]['versionInfo'][broIdx][versionIdx.toString()]) {
                    dayTotals[spiltDate.dayString]['versionInfo'][broIdx][versionIdx.toString()] = 0;
                  }

                  dayTotals[spiltDate.dayString]['versionInfo'][broIdx][versionIdx.toString()] = (dayTotals[spiltDate.dayString]['versionInfo'][broIdx][versionIdx.toString()] || 0) + versionItem;
                })

              });
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: totalInfo, ...browserInfo, });
            }
          } else {
            const spiltDate = constants.getSplitDate(key);
            let selectedView = {};

            if (!item) {
              return;
            }
            if (!item[selectedViewOption.value]) {
              return;
            }
            let totalInfo = 0
            forEach(item[selectedViewOption.value], (broItem, broIdx) => {
              if (!broItem) {
                return;
              }

              if (!dayTotals[spiltDate.dayString]) {
                dayTotals[spiltDate.dayString] = {};
              }

              if (!dayTotals[spiltDate.dayString]['total']) {
                dayTotals[spiltDate.dayString]['total'] = 0;
              }

              if (!dayTotals[spiltDate.dayString]['others']) {
                dayTotals[spiltDate.dayString]['others'] = 0;
              }

              console.log(broIdx, broItem, "checkIIIII")
              dayTotals[spiltDate.dayString][broIdx] =
                (dayTotals[spiltDate.dayString][broIdx] || 0) + (broItem.total || 0);

              if (includes(statisticsConst.UN_CORRESPONDING_BROWSERS, broIdx.toLowerCase())) {
                dayTotals[spiltDate.dayString]['others'] = dayTotals[spiltDate.dayString]['others'] + broItem.total;
                browserInfo['others'] = browserInfo['others'] + broItem.total;
              }

              dayTotals[spiltDate.dayString]['total'] = (dayTotals[spiltDate.dayString]['total'] || 0) + broItem.total;
              browserInfo[broIdx] = broItem.total;
              totalInfo = totalInfo + broItem.total;

              if (!browserKeys.includes(broIdx)) {
                browserKeys.push(broIdx);
              }

              if (!dayTotals[spiltDate.dayString]['versionInfo']) {
                dayTotals[spiltDate.dayString]['versionInfo'] = {};
              }
              if (!dayTotals[spiltDate.dayString]['versionInfo'][broIdx]) {
                dayTotals[spiltDate.dayString]['versionInfo'][broIdx] = {};
              }
              map(broItem, (versionItem, versionIdx) => {

                if (!dayTotals[spiltDate.dayString]['versionInfo'][broIdx][versionIdx.toString()]) {
                  dayTotals[spiltDate.dayString]['versionInfo'][broIdx][versionIdx.toString()] = 0;
                }

                dayTotals[spiltDate.dayString]['versionInfo'][broIdx][versionIdx.toString()] = (dayTotals[spiltDate.dayString]['versionInfo'][broIdx][versionIdx.toString()] || 0) + versionItem;
              })

            });
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: totalInfo, ...browserInfo, });
          }
        });
      } else if (id === statisticsConst.PLAYERSPERSCREENSIZEFOREACHDESIGN) {
        const { playersPerScreenSizeForEachDesign } = statistics;
        map(playersPerScreenSizeForEachDesign, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);

              if (!item) {
                return;
              }
              if (!item[selectedEnvOption.value][selectedDesignOption.value]) {
                return;
              }

              const platformItem = item[selectedEnvOption.value][selectedDesignOption.value];

              if (!dayTotals[spiltDate.dayString]) {
                dayTotals[spiltDate.dayString] = {};
              }

              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((platformItem.desktop || 0) + (platformItem.draugiem || 0) + (platformItem.facebook || 0) + (platformItem?.inbox || 0) + (platformItem?.mobile || 0)),
                desktop: get(dayTotals, `${spiltDate.dayString}.desktop`, 0) + platformItem?.desktop || 0,
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + platformItem?.draugiem || 0,
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + platformItem?.facebook || 0,
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + platformItem?.inbox || 0,
                mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + platformItem?.mobile || 0,
              };

              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((platformItem.desktop || 0) + (platformItem.draugiem || 0) + (platformItem.facebook || 0) + (platformItem?.inbox || 0) + (platformItem?.mobile || 0)), desktop: platformItem?.desktop || 0, draugiem: platformItem?.draugiem || 0, facebook: platformItem?.facebook || 0, inbox: platformItem?.inbox || 0, mobile: platformItem?.mobile || 0 });
            }
          } else {
            const spiltDate = constants.getSplitDate(key);

            if (!item) {
              return;
            }
            if (!item[selectedEnvOption.value][selectedDesignOption.value]) {
              return;
            }

            const platformItem = item[selectedEnvOption.value][selectedDesignOption.value];

            if (!dayTotals[spiltDate.dayString]) {
              dayTotals[spiltDate.dayString] = {};
            }

            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((platformItem.desktop || 0) + (platformItem.draugiem || 0) + (platformItem.facebook || 0) + (platformItem?.inbox || 0) + (platformItem?.mobile || 0)),
              desktop: get(dayTotals, `${spiltDate.dayString}.desktop`, 0) + platformItem?.desktop || 0,
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + platformItem?.draugiem || 0,
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + platformItem?.facebook || 0,
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + platformItem?.inbox || 0,
              mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + platformItem?.mobile || 0,
            };

            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((platformItem.desktop || 0) + (platformItem.draugiem || 0) + (platformItem.facebook || 0) + (platformItem?.inbox || 0) + (platformItem?.mobile || 0)), desktop: platformItem?.desktop || 0, draugiem: platformItem?.draugiem || 0, facebook: platformItem?.facebook || 0, inbox: platformItem?.inbox || 0, mobile: platformItem?.mobile || 0 });
          }
        });
      } else if (id === statisticsConst.PLAYERSPERSCREENSIZEFOREACHSCREENMODE) {
        const { playersPerScreenSizeForEachScreenMode } = statistics;
        map(playersPerScreenSizeForEachScreenMode, (item, key) => {
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              const spiltDate = constants.getSplitDate(key);

              if (!item) {
                return;
              }
              if (!item[selectedEnvOption.value][selectedThemeOption.value]) {
                return;
              }

              const platformItem = item[selectedEnvOption.value][selectedThemeOption.value];

              if (!dayTotals[spiltDate.dayString]) {
                dayTotals[spiltDate.dayString] = {};
              }

              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((platformItem.desktop || 0) + (platformItem.draugiem || 0) + (platformItem.facebook || 0) + (platformItem?.inbox || 0) + (platformItem?.mobile || 0)),
                desktop: get(dayTotals, `${spiltDate.dayString}.desktop`, 0) + platformItem?.desktop || 0,
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + platformItem?.draugiem || 0,
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + platformItem?.facebook || 0,
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + platformItem?.inbox || 0,
                mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + platformItem?.mobile || 0,
              };

              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((platformItem.desktop || 0) + (platformItem.draugiem || 0) + (platformItem.facebook || 0) + (platformItem?.inbox || 0) + (platformItem?.mobile || 0)), desktop: platformItem?.desktop || 0, draugiem: platformItem?.draugiem || 0, facebook: platformItem?.facebook || 0, inbox: platformItem?.inbox || 0, mobile: platformItem?.mobile || 0 });
            }
          } else {
            const spiltDate = constants.getSplitDate(key);

            if (!item) {
              return;
            }
            if (!item[selectedEnvOption.value][selectedThemeOption.value]) {
              return;
            }

            const platformItem = item[selectedEnvOption.value][selectedThemeOption.value];

            if (!dayTotals[spiltDate.dayString]) {
              dayTotals[spiltDate.dayString] = {};
            }

            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((platformItem.desktop || 0) + (platformItem.draugiem || 0) + (platformItem.facebook || 0) + (platformItem?.inbox || 0) + (platformItem?.mobile || 0)),
              desktop: get(dayTotals, `${spiltDate.dayString}.desktop`, 0) + platformItem?.desktop || 0,
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + platformItem?.draugiem || 0,
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + platformItem?.facebook || 0,
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + platformItem?.inbox || 0,
              mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + platformItem?.mobile || 0,
            };

            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((platformItem.desktop || 0) + (platformItem.draugiem || 0) + (platformItem.facebook || 0) + (platformItem?.inbox || 0) + (platformItem?.mobile || 0)), desktop: platformItem?.desktop || 0, draugiem: platformItem?.draugiem || 0, facebook: platformItem?.facebook || 0, inbox: platformItem?.inbox || 0, mobile: platformItem?.mobile || 0 });
          }
        });
      } else if (id === statisticsConst.PLAYERSPERSCREENSIZE) {
        const { playersPerScreenSize } = statistics;

        map(playersPerScreenSize, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item.desktop || 0) + (item.draugiem || 0) + (item.facebook || 0) + (item?.inbox || 0) + (item?.mobile || 0)),
                desktop: get(dayTotals, `${spiltDate.dayString}.desktop`, 0) + item?.desktop || 0,
                draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + item?.draugiem || 0,
                facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + item?.facebook || 0,
                inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + item?.inbox || 0,
                mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + item?.mobile || 0,
              };
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item.desktop || 0) + (item.draugiem || 0) + (item.facebook || 0) + (item?.inbox || 0) + (item?.mobile || 0)), desktop: item?.desktop || 0, draugiem: item?.draugiem || 0, facebook: item?.facebook || 0, inbox: item?.inbox || 0, mobile: item?.mobile || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item.desktop || 0) + (item.draugiem || 0) + (item.facebook || 0) + (item?.inbox || 0) + (item?.mobile || 0)),
              desktop: get(dayTotals, `${spiltDate.dayString}.desktop`, 0) + item?.desktop || 0,
              draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + item?.draugiem || 0,
              facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + item?.facebook || 0,
              inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + item?.inbox || 0,
              mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + item?.mobile || 0,
            };
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item.desktop || 0) + (item.draugiem || 0) + (item.facebook || 0) + (item?.inbox || 0) + (item?.mobile || 0)), desktop: item?.desktop || 0, draugiem: item?.draugiem || 0, facebook: item?.facebook || 0, inbox: item?.inbox || 0, mobile: item?.mobile || 0 });
            return null;
          }
        })
        // } else if (id === statisticsConst.ROOMS) {
        //    const { rooms, totalPlayers } = statistics;
        //   console.log(statistics, "totalPlayersHistory")

        // map(playersPerScreenSize, (item, key) => {
        //   const spiltDate = constants.getSplitDate(key);
        //   if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
        //     if (key.includes(selectDate)) {
        //       dayTotals[spiltDate.dayString] = {
        //         total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item.desktop || 0) + (item.draugiem || 0) + (item.facebook || 0) + (item?.inbox || 0) + (item?.mobile || 0)),
        //         desktop: get(dayTotals, `${spiltDate.dayString}.desktop`, 0) + item?.desktop || 0,
        //         draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + item?.draugiem || 0,
        //         facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + item?.facebook || 0,
        //         inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + item?.inbox || 0,
        //         mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + item?.mobile || 0,
        //       };
        //       dataArray.push({ key, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item.desktop || 0) + (item.draugiem || 0) + (item.facebook || 0) + (item?.inbox || 0) + (item?.mobile || 0)), desktop: item?.desktop || 0, draugiem: item?.draugiem || 0, facebook: item?.facebook || 0, inbox: item?.inbox || 0, mobile: item?.mobile || 0 });
        //       return null;
        //     }
        //   } else {
        //     dayTotals[spiltDate.dayString] = {
        //       total: get(dayTotals, `${spiltDate.dayString}.total`, 0) + ((item.desktop || 0) + (item.draugiem || 0) + (item.facebook || 0) + (item?.inbox || 0) + (item?.mobile || 0)),
        //       desktop: get(dayTotals, `${spiltDate.dayString}.desktop`, 0) + item?.desktop || 0,
        //       draugiem: get(dayTotals, `${spiltDate.dayString}.draugiem`, 0) + item?.draugiem || 0,
        //       facebook: get(dayTotals, `${spiltDate.dayString}.facebook`, 0) + item?.facebook || 0,
        //       inbox: get(dayTotals, `${spiltDate.dayString}.inbox`, 0) + item?.inbox || 0,
        //       mobile: get(dayTotals, `${spiltDate.dayString}.mobile`, 0) + item?.mobile || 0,
        //     };
        //     dataArray.push({ key, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, total: ((item.desktop || 0) + (item.draugiem || 0) + (item.facebook || 0) + (item?.inbox || 0) + (item?.mobile || 0)), desktop: item?.desktop || 0, draugiem: item?.draugiem || 0, facebook: item?.facebook || 0, inbox: item?.inbox || 0, mobile: item?.mobile || 0 });
        //     return null;
        //   }
        // })
      } else if (id === statisticsConst.ROOMS) {
        const hourCount = {};
        map(statistics[id], (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                rooms: get(dayTotals, `${spiltDate.dayString}.rooms`, 0) + item || 0,
              }
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, rooms: item });
              hourCount[spiltDate.dayString] = get(hourCount, `${spiltDate.dayString}`, 0) + 1;
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              rooms: get(dayTotals, `${spiltDate.dayString}.rooms`, 0) + item || 0,
            }
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, rooms: item });
            hourCount[spiltDate.dayString] = get(hourCount, `${spiltDate.dayString}`, 0) + 1;
            return null;
          }
        });
      } else if (id === statisticsConst.GIFTSHISTORY) {
        const { send, remove } = statistics;
        const giftsSendArrayData = [];
        const giftsRemoveArrayData = [];

        await map(send, async (item, key) => {
          let allGiftsSendByTime = {};
          const spiltDate = constants.getSplitDate(key);
          if (key.includes(selectDate)) {
            await map(allGifts, (gift, giftKey) => {
              if (item[giftKey]) {
                if (!dayTotals[spiltDate.dayString]) {
                  dayTotals[spiltDate.dayString] = {}
                }

                if (!dayTotals[spiltDate.dayString][giftKey]) {
                  dayTotals[spiltDate.dayString][giftKey] = {}
                }
                if (!dayTotals[spiltDate.dayString][giftKey].send) {
                  dayTotals[spiltDate.dayString][giftKey].send = 0;
                }
                dayTotals[spiltDate.dayString][giftKey] = {
                  ...dayTotals[spiltDate.dayString][giftKey],
                  'send': get(dayTotals, `${spiltDate.dayString}.${giftKey}.send`, 0) + item[giftKey] || 0,
                }
                allGiftsSendByTime[filter(statisticsConst.GIFTS_DATA, item => item.value ===giftKey)[0] ? filter(statisticsConst.GIFTS_DATA, item => item.value ===giftKey)[0].label : giftKey] = item[giftKey] || 0;
              }
            })
            giftsSendArrayData.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, ...allGiftsSendByTime });
          }
        })
        await map(remove, (item, key) => {
          let allGiftsRemoveByTime = {};
          const spiltDate = constants.getSplitDate(key);
          if (key.includes(selectDate)) {
            map(allGifts, (gift, giftKey) => {
              if (item[giftKey]) {
                if (!dayTotals[spiltDate.dayString]) {
                  dayTotals[spiltDate.dayString] = {}
                }

                if (!dayTotals[spiltDate.dayString][giftKey]) {
                  dayTotals[spiltDate.dayString][giftKey] = {}
                }
                if (!dayTotals[spiltDate.dayString][giftKey].remove) {
                  dayTotals[spiltDate.dayString][giftKey].remove = 0;
                }

                dayTotals[spiltDate.dayString][giftKey] = {
                  ...dayTotals[spiltDate.dayString][giftKey],
                  'remove': get(dayTotals, `${spiltDate.dayString}.${giftKey}.remove`, 0) + item[giftKey] || 0,
                }
                allGiftsRemoveByTime[filter(statisticsConst.GIFTS_DATA, item => item.value ===giftKey)[0] ? filter(statisticsConst.GIFTS_DATA, item => item.value ===giftKey)[0].label : giftKey] = item[giftKey] || 0;
              }
            })
            giftsRemoveArrayData.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, ...allGiftsRemoveByTime });
          }
        })

        this.setState({
          giftsSendData: giftsSendArrayData,
          giftsRemoveData: giftsRemoveArrayData,
        })
      } else if (id === statisticsConst.ONELINEPLAYERS) {
        const { online } = statistics;
        map(online, (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = {
                players: get(dayTotals, `${spiltDate.dayString}.players`, 0) + item?.players || 0,
                rooms: get(dayTotals, `${spiltDate.dayString}.rooms`, 0) + item?.rooms || 0,
                lastTime: spiltDate.time,
              };
              dataArray.push({ key: `${spiltDate.dateKey}-${spiltDate.minTime}`, day: spiltDate.dayString, hour: `${spiltDate.time}:${spiltDate.minTime}`, players: item?.players || 0, rooms: item?.rooms || 0 });
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = {
              players: get(dayTotals, `${spiltDate.dayString}.players`, 0) + item?.players || 0,
              rooms: get(dayTotals, `${spiltDate.dayString}.rooms`, 0) + item?.rooms || 0,
            };
            dataArray.push({ key: `${spiltDate.dateKey}-${spiltDate.minTime}`, day: spiltDate.dayString, hour: `${spiltDate.time}:${spiltDate.minTime}`, players: item?.players || 0, rooms: item?.rooms || 0 });
            return null;
          }
        });
      } else {
        const hourCount = {};
        map(statistics[id], (item, key) => {
          const spiltDate = constants.getSplitDate(key);
          if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
            if (key.includes(selectDate)) {
              dayTotals[spiltDate.dayString] = get(dayTotals, `${spiltDate.dayString}`, 0) + item;
              dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, value: item });
              hourCount[spiltDate.dayString] = get(hourCount, `${spiltDate.dayString}`, 0) + 1;
              return null;
            }
          } else {
            dayTotals[spiltDate.dayString] = get(dayTotals, `${spiltDate.dayString}`, 0) + item;
            dataArray.push({ key: spiltDate.dateKey, day: spiltDate.dayString, hour: `${spiltDate.time}:00`, value: item });
            hourCount[spiltDate.dayString] = get(hourCount, `${spiltDate.dayString}`, 0) + 1;
            return null;
          }
        });
        if (id === statisticsConst.ACTIVEPLAYERDAY || id === statisticsConst.ACTIVEPLAYERSWEEK || id === statisticsConst.ACTIVEPLAYERSMONTH || id === statisticsConst.ACTIVEPLAYERSYEAR) {
          map(dayTotals, (item, key) => {
            if (length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
              if (key.includes(selectDate)) {
                dayTotals[key] = parseFloat(item / hourCount[key]).toFixed(0);
              }
            } else {
              dayTotals[key] = parseFloat(item / hourCount[key]).toFixed(0);
            }
          });
        }
      }

      if (id === statisticsConst.DELETEDPLAYERS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, draugiem: item.draugiem, facebook: item.facebook, email: item.email, apple: item.apple, inbox: item.inbox, google: item.google });
        });
      } else if (id === statisticsConst.RETURNEDPLAYERS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, draugiem: item.draugiem, facebook: item.facebook, email: item.email, apple: item.apple, inbox: item.inbox, google: item.google });
        });
      } else if (id === statisticsConst.BALANCEPERPLAYERS) {
        forEach(dayTotals, (item, key) => {
          const totalV = item.draugiem + item.facebook + item.email + item.apple + item.inbox + item.google;
          csvData.push({ day: key, total: totalV, draugiem: item.draugiem, facebook: item.facebook, email: item.email, apple: item.apple, inbox: item.inbox, google: item.google });
        });
      } else if (id === statisticsConst.PLAYERACHIEVEMENTS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({
            day: key,
            ...item,
          });
        });
      } else if (id === statisticsConst.LASTONLINEPLAYERS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, draugiem: item.draugiem, facebook: item.facebook, email: item.email, apple: item.apple, inbox: item.inbox, google: item.google });
        });
      } else if (id === statisticsConst.USERSCOINANALYSIS) {
        console.log('USERSCOINANALYSISdayTotals', dayTotals);
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, totalPlayers: parseFloat((item.total * 100) / (item.totalPlayers)).toFixed(2) + "%", total: item.total, draugiem: item.draugiem, facebook: item.facebook, mobile: item.mobile, web: item.web, inbox: item.inbox });
        });
      } else if (id === statisticsConst.GAMESINROOM) {
        forEach(dayTotals, (items, key) => {
          csvData.push({ day: key, ...items });
        });
      } else if (id === statisticsConst.PURCHASEDMONEY) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, 'Total amount(EUR)': item.total, '250z-0.85EUR': item.money1, '500Z-1.41EUR': item.money2, '1000Z-2.85EUR': item.money3, '4000z-4.27EUR': item.money4, '15000z-14.23EUR': item.money5, 'Reset-balance': item.money6, 'Reset-points': item.money7, 'Reset-profile': item.money8, 'Unban account': item.unban, 'Hide ads for month': item.removeAdsMonth, 'Hide ads for year': item.removeAdsYear });
        });
      } else if (id === statisticsConst.TRANSACTIONS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, stripe: item.stripe, everyPay: item.everyPay, draugiem: item.draugiem, facebook: item.facebook, inbox: item.inbox, mobile: item.mobile });
        });
      } else if (id === statisticsConst.TOTALPLAYERS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, draugiem: item.draugiem, facebook: item.facebook, email: item.email, inbox: item.inbox, google: item.google, apple: item.apple });
        });
      } else if (id === statisticsConst.LOGINS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, draugiem: item.draugiem, facebook: item.facebook, email: item.email, google: item.google, appleId: item.apple, inbox: item.inbox });
        });
      } else if (id === statisticsConst.NEWUSERS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, draugiem: item.draugiem, facebook: item.facebook, email: item.email, google: item.google, appleId: item.appleId, inbox: item.inbox });
        });
      } else if (id === statisticsConst.PLAYERSPERFONTMODE) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, normal: item.normal, large: item.large, xLarge: item.xLarge, });
        });
      } else if (id === statisticsConst.PLAYERSPERDESIGN) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, old: item.old, new: item.new, desktopNew: item?.desktopNew, desktopOld: item?.desktopOld, draugiemNew: item?.draugiemNew, draugiemOld: item?.draugiemOld, facebookNew: item?.facebookNew, facebookOld: item?.facebookOld, inboxNew: item?.inboxNew, inboxOld: item?.inboxOld, mobileNew: item?.mobileNew, mobileOld: item?.mobileOld });
        });
      } else if (id === statisticsConst.PLAYERACTIVETHEME) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, dark: item.dark, light: item.light, normal: item.normal });
        });
      } else if (id === statisticsConst.BETS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, 'total': ((item[10] || 0) + (item[25] || 0) + (item[50] || 0) + (item[100] || 0) + (item[250] || 0) + (item[500] || 0) + (item[1000] || 0) + (item[5000] || 0) + (item[10000] || 0)), '1:10': item[10], '1:25': item[25], '1:50': item[50], '1:100': item[100], '1:250': item[250] || 0, '1:500': item[500], '1:1000': item[1000], '1:5000': item[5000], '1:10000': item[10000] });
        });
      } else if (id === statisticsConst.GAMETYPE) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: (item.P + item.PM + item.G + item.MG + item.Student), P: item.P, PM: item.PM, G: item.G, MG: item.MG, Student: item.Student });
        });
      } else if (id === statisticsConst.GAMESPEED) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: (item.fast + item.slow + item.unlimited + item.lightning), fast: item.fast, slow: item.slow, unlimited: item.unlimited, lightning: item.lightning });
        });
      } else if (id === statisticsConst.ACTIVITY) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, 'Last 24 hours': item.playersDay, 'Last 7 days': item.playersWeek, 'Last 30 days': item.playersMonth, 'Last year': item.playersYear, 'More than 1 year ago': item.playersYearOld, });
        });
      } else if (id === statisticsConst.MISSEDROOMS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({
            day: key,
            'Total': (item.missedRoomsWeb + item.missedRoomsApp),
            'Auto-play': item.missedRoomsAutomated, startedAutomated: item.startedAutomated,
            'On (move)': item.missedRoomsont,
            'On (no move)': item.missedRoomsond,
            'Off (move)': item.missedRoomsofft,
            'Off (no move)': item.missedRoomsoffd,
            'Web': item.missedRoomsWeb,
            'App': item.missedRoomsApp
          });
        });
      } else if (id === statisticsConst.ENVPERROOM) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, 'Total': item.webRooms || 0 + item.appRooms || 0, 'Web': item.webRooms, 'App': item.appRooms });
        });
      } else if (id === statisticsConst.ROOMPLAYERCOUNT) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, four: item.four, three: item.three, two: item.two });
        });
      } else if (id === statisticsConst.PLAYERSPERLANGUAGE) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, lv: item.lv, en: item.en, ru: item.ru });
        });
      } else if (id === statisticsConst.PLAYERSPERSOUND) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, off: item.off, on: item.on });
        });
      } else if (id === statisticsConst.PLAYERSPERAUTOMERGEROOMS) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, off: item.off, on: item.on });
        });
      } else if (id === statisticsConst.PLAYERSVIEWSPERSCREEN) {
        forEach(dayTotals, (item, key) => {
          csvData.push({
            day: key,
            createRoom: item.createRoom || 0,
            loading: item.loading || 0,
            myInfo: item.myInfo || 0,
            powerRankings: item.powerRankings || 0,
            rooms: item.rooms || 0,
            top: item.top || 0,
            tournaments: item.tournaments || 0,
          });
        });
      } else if (id === statisticsConst.COINPERCHANGES) {

        console.log('monthTotals', monthTotals, dayTotals);

        forEach(dayTotals, (item, key) => {
          const totalPos = item.won + item.autoCompensation + item.missedTurnReward + item.minGamesReward + item.leftRoomReward + item.adminAdded + item.botsTopUp + item.dailyBonusAdded
            + item.tournamentWon + item.purchased + item.resetBalance + item.resetProfile + (item.claimedGiftCode || 0) + item.claimedAchievement + item.claimedTutorialBonus + item.claimediOSBonus
            + item.claimedAndroidBonus + item.emailBonus + item.claimedBonus + item.startBonus;

          const totalNeg = item.roomFee + item.lost + item.missedTurn + item.minGamesPenalty + item.leftRoomPenalty + item.profanity + item.adminRemoved + item.dailyBonusRemoved
            + item.tournamentEntryFee + item.buyGift + item.removeGift + item.deletedProfile;

          csvData.push({
            day: key,
            'Room Fee': this.coinPerRowNumber(item.roomFee, true),
            'Room Lost': this.coinPerRowNumber(item.lost, true),
            'Room Won': this.coinPerRowNumber(item.won, false),
            'Room Compensation': this.coinPerRowNumber(item.autoCompensation, false),

            'Penalty Missed move': this.coinPerRowNumber(item.missedTurn, true),
            'Penalty Min games': this.coinPerRowNumber(item.minGamesPenalty, true),
            'Penalty Left room': this.coinPerRowNumber(item.leftRoomPenalty, true),
            'Penalty Profanity': this.coinPerRowNumber(item.profanity, true),

            'Compensation Missed move': this.coinPerRowNumber(item.missedTurnReward, false),
            'Compensation Min games': this.coinPerRowNumber(item.minGamesReward, false),
            'Compensation Left room': this.coinPerRowNumber(item.leftRoomReward, false),

            'Admin Added': this.coinPerRowNumber(item.adminAdded, false),
            'Admin Deducted': this.coinPerRowNumber(-item.adminRemoved, true),
            'Admin Bots top up': this.coinPerRowNumber(item.botsTopUp, false),

            'Lucky wheel Positive': this.coinPerRowNumber(item.dailyBonusAdded, false),
            'Lucky wheel Negative': this.coinPerRowNumber(-item.dailyBonusRemoved, true),

            'Tournament Fee': this.coinPerRowNumber(item.tournamentEntryFee, true),
            'Tournament Win': this.coinPerRowNumber(item.tournamentWon, false),

            'Shop Purchase': this.coinPerRowNumber(item.purchased, false),
            'Shop Reset balance': this.coinPerRowNumber(item.resetBalance, false),
            'Shop Reset profile': this.coinPerRowNumber(item.resetProfile, false),
            'Shop Coupon': this.coinPerRowNumber(item.claimedGiftCode, false),

            'Gift Buy': this.coinPerRowNumber(item.buyGift, true),
            'Gift Remove': this.coinPerRowNumber(item.removeGift, true),

            'Other Achievements': this.coinPerRowNumber(item.claimedAchievement, false),
            'Other Tutorial': this.coinPerRowNumber(item.claimedTutorialBonus, false),
            'Other App (iOS)': this.coinPerRowNumber(item.claimediOSBonus, false),
            'Other App (Android)': this.coinPerRowNumber(item.claimedAndroidBonus, false),
            'Other Email': this.coinPerRowNumber(item.emailBonus, false),
            'Other Holidays': this.coinPerRowNumber(item.claimedBonus, false),
            'Other Start bonus': this.coinPerRowNumber(item.startBonus, false),
            'Other Deleted profiles': this.coinPerRowNumber(item.deletedProfile, 'both'),

            'Total': this.coinPerRowNumber((totalPos - totalNeg), 'both'),
          });
        });

        if (monthTotals) {
          const item = monthTotals;

          const totalPos = item.won + item.autoCompensation + item.missedTurnReward + item.minGamesReward + item.leftRoomReward + item.adminAdded + item.botsTopUp + item.dailyBonusAdded
            + item.tournamentWon + item.purchased + item.resetBalance + item.resetProfile + (item.claimedGiftCode || 0) + item.claimedAchievement + item.claimedTutorialBonus + item.claimediOSBonus
            + item.claimedAndroidBonus + item.emailBonus + item.claimedBonus + item.startBonus;

          const totalNeg = item.roomFee + item.lost + item.missedTurn + item.minGamesPenalty + item.leftRoomPenalty + item.profanity + item.adminRemoved + item.dailyBonusRemoved
            + item.tournamentEntryFee + item.buyGift + item.removeGift + item.deletedProfile;

          csvData.push({
            day: 'Total',
            'Room Fee': this.coinPerRowNumber(item.roomFee, true),
            'Room Lost': this.coinPerRowNumber(item.lost, true),
            'Room Won': this.coinPerRowNumber(item.won, false),
            'Room Compensation': this.coinPerRowNumber(item.autoCompensation, false),

            'Penalty Missed move': this.coinPerRowNumber(item.missedTurn, true),
            'Penalty Min games': this.coinPerRowNumber(item.minGamesPenalty, true),
            'Penalty Left room': this.coinPerRowNumber(item.leftRoomPenalty, true),
            'Penalty Profanity': this.coinPerRowNumber(item.profanity, true),

            'Compensation Missed move': this.coinPerRowNumber(item.missedTurnReward, false),
            'Compensation Min games': this.coinPerRowNumber(item.minGamesReward, false),
            'Compensation Left room': this.coinPerRowNumber(item.leftRoomReward, false),

            'Admin Added': this.coinPerRowNumber(item.adminAdded, false),
            'Admin Deducted': this.coinPerRowNumber(-item.adminRemoved, true),
            'Admin Bots top up': this.coinPerRowNumber(item.botsTopUp, false),

            'Lucky wheel Positive': this.coinPerRowNumber(item.dailyBonusAdded, false),
            'Lucky wheel Negative': this.coinPerRowNumber(-item.dailyBonusRemoved, true),

            'Tournament Fee': this.coinPerRowNumber(item.tournamentEntryFee, true),
            'Tournament Win': this.coinPerRowNumber(item.tournamentWon, false),

            'Shop Purchase': this.coinPerRowNumber(item.purchased, false),
            'Shop Reset balance': this.coinPerRowNumber(item.resetBalance, false),
            'Shop Reset profile': this.coinPerRowNumber(item.resetProfile, false),
            'Shop Coupon': this.coinPerRowNumber(item.claimedGiftCode, false),

            'Gift Buy': this.coinPerRowNumber(item.buyGift, true),
            'Gift Remove': this.coinPerRowNumber(item.removeGift, true),

            'Other Achievements': this.coinPerRowNumber(item.claimedAchievement, false),
            'Other Tutorial': this.coinPerRowNumber(item.claimedTutorialBonus, false),
            'Other App (iOS)': this.coinPerRowNumber(item.claimediOSBonus, false),
            'Other App (Android)': this.coinPerRowNumber(item.claimedAndroidBonus, false),
            'Other Email': this.coinPerRowNumber(item.emailBonus, false),
            'Other Holidays': this.coinPerRowNumber(item.claimedBonus, false),
            'Other Start bonus': this.coinPerRowNumber(item.startBonus, false),
            'Other Deleted profiles': this.coinPerRowNumber(item.deletedProfile, 'both'),

            'Total': this.coinPerRowNumber((totalPos - totalNeg), 'both'),
          });
        }
      } else if (id === statisticsConst.PLAYERSFULLSCREEN) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, newDesign: item.newDesign, oldDesign: item.oldDesign, iframeOldDesign: item.iframeOldDesign, iframeNewDesign: item.iframeNewDesign });
        });
      } else if (id === statisticsConst.PLAYERSPERBROWSER) {
        forEach(dayTotals, (item, key) => {
          let csvBrowser = {}
          forEach(browserKeys, (item2) => {
            csvBrowser[item2] = item[item2] || 0;
          });

          csvBrowser.others = dayTotals.others || 0;
          csvData.push({ day: key, total: item.total, ...csvBrowser });
        });

      } else if ((id === statisticsConst.PLAYERSPERSCREENSIZE || id === statisticsConst.PLAYERSPERSCREENSIZEFOREACHDESIGN || id === statisticsConst.PLAYERSPERSCREENSIZEFOREACHSCREENMODE)) {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, total: item.total, desktop: item.desktop, draugiem: item.draugiem, facebook: item.facebook, inbox: item.inbox, mobile: item.mobile });
        });
      } else if (id === statisticsConst.GIFTSHISTORY) {
        forEach(dayTotals, (dayTotal, key) => {
          let giftsData = {};
          map(statisticsConst.GIFTS_DATA, (item) => {
            giftsData[`${item?.label}-buy`] = filter(allGifts, (gift, giftKey) => giftKey === item.value)[0] ? (dayTotal[item.value]?.send || 0) : 0;
            giftsData[`${item?.label}-remove`] = filter(allGifts, (gift, giftKey) => giftKey === item.value)[0] ? (dayTotal[item.value]?.remove || 0) : 0;
          })
          csvData.push({ day: key, ...giftsData, });
        });

        let aggregated = mapValues(
          groupBy(
            flatMap(values(dayTotals), entries),
            0
          ),
          (entries) => {
            return entries.reduce(
              (acc, [, values]) => {
                acc.send += values.send || 0;
                acc.remove += values.remove || 0;
                return acc;
              },
              { send: 0, remove: 0 }
            );
          }
        );

        let giftsData = {};
        {statisticsConst.GIFTS_DATA.map(item => {
          giftsData[`${item?.label}-buy`] = filter(allGifts, (gift) => gift.name === item.value)[0] ? (aggregated[item.value]?.send || 0) : 0;
          giftsData[`${item?.label}-remove`] = filter(allGifts, (gift, giftKey) => gift.name === item.value)[0] ? (aggregated[item.value]?.remove || 0) : 0;
        })}
        csvData.push({ day: 'Total', ...giftsData, });
      } else if (id === statisticsConst.ONELINEPLAYERS) {
        forEach(dayTotals, (item, key) => {
          const nowDate = moment(new Date()).format(constants.FORMAT_DATE_TYPE.fullMonthYear);

          if (nowDate.dayString === key) {
            csvData.push({
              day: key,
              players: Math.floor(item.players / ((parseInt(item.lastTime) * 4) + this.getCurrentMinuteQuarter()), 2),
              rooms: Math.floor(item.rooms / ((parseInt(item.lastTime) * 4) + this.getCurrentMinuteQuarter()), 2)
            });
          } else {
            csvData.push({
              day: key,
              players: Math.floor(item.players / ((parseInt(item.lastTime) + 1) * 4), 2),
              rooms: Math.floor(item.rooms / ((parseInt(item.lastTime) + 1) * 4), 2),
            });
          }
        });
      } else {
        forEach(dayTotals, (item, key) => {
          csvData.push({ day: key, value: item });
        });
      }
      this.setState({ data: dataArray, dayTotals, csvData, browserKeys, });
    });
  }

  changeSelectedId = (e) => {
    this.setState({
      id: e.target.value,
      openChart: false,
    });

    if (e.target.value === statisticsConst.BALANCEPERPLAYERS) {
      this.setState({
        length: statisticsConst.STATISTICS_PERIOD_TIME.month,
      });
    } else if (e.target.value === statisticsConst.PLAYERSPERBROWSER) {
      this.setState({
        selectedViewOption: statisticsConst.STATISTICS_VIEW_DEVICE_OPTIONS[0],
      });
    } else if (e.target.value === statisticsConst.PLAYERSVIEWSPERSCREEN) {
      this.setState({
        selectedViewOption: [{ ...statisticsConst.STATISTICS_VIEW_SCREEN_OPTIONS[0] }],
      })
    }
  }

  changeSelectedLength = (e) => {
    this.setState({ length: e.target.value });
  }

  changeBalanceType = (e) => {
    this.setState({ balanceType: e.target.value });
  }

  changeRoundStart = (e) => {
    this.setState({ roundStart: e.target.value, roundTo: parseInt(e.target.value) + constants.CHANGE_ROUND });
    // this.setState({ roundStart: e.target.value });
  }

  changeRoundTo = (e) => {
    this.setState({ roundTo: e.target.value, roundStart: parseInt(e.target.value) - constants.CHANGE_ROUND > 0 ? parseInt(e.target.value) - constants.CHANGE_ROUND : 1 });
    // this.setState({ roundTo: e.target.value });
  }

  changeachievementLevel = (e) => {
    this.setState({ achievementType: e.target.value });
  }

  changeRoundRange = (selectedRound) => {
    this.setState({
      roundRange: selectedRound
    })
  }

  openSubTable = (id) => {
    const { selectedDay } = this.state;
    if (selectedDay === id) {
      this.setState({ selectedDay: null });
    } else {
      this.setState({ selectedDay: id });
    }
  }

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEnvOption = (selectedEnvOption) => {
    this.setState({
      selectedEnvOption,
    })
  }

  changeDesignOption = (selectedDesignOption) => {
    this.setState({
      selectedDesignOption,
    })
  }

  changeThemeOption = (selectedThemeOption) => {
    this.setState({
      selectedThemeOption,
    })
  }

  onHover = (date, browser) => {
    const { browserKeys, dayTotals } = this.state;
    this.onHoverLeave();
    this.setState({
      targetId: `admin-statistics-browser-version-popover-${date}-${browser.toLowerCase().replace(/\s/g, '')}`,
      popoverOpen: true,
      selectedBrowserVersion: { ...dayTotals[date]['versionInfo'][browser] },
      selectedDate: date,
      selectedBrowser: browser,
    });
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
      selectedBrowser: null,
      targetId: null,
      selectedDate: null,
      selectedBrowser: null,
    });
  }

  toggleChart = () => {
    this.setState(prevState => ({
      openChart: !prevState.openChart,
    }))
  }

  coinPerRowNumber = (number, negative) => {
    if (number === 0 || !number) {
      return 0;
    } else if (negative === 'both') {
      if (number > 0) {
        return `+${number}`;
      } if (number < 0) {
        return number;
      } else {
        return 0;
      }
    } else if (negative) {
      return -number;
    } else {
      return `+${number}`
    }
  }

  coinPerChangeRow = (key, rowData) => {
    console.log('coinPerChangeRow', key, rowData);

    /*
    const totalPos = rowData.autoCompensation
      + rowData.minGamesReward
      + rowData.dailyBonusAdded
      + rowData.purchased
      + rowData.claimedAchievement
      + rowData.startBonus;


    const totalNeg = rowData.roomFee
      + rowData.minGamesPenalty
      + rowData.dailyBonusRemoved
      + rowData.buyGift


    console.log('rowData 1', {
      autoCompensation: rowData.autoCompensation,
      minGamesReward: rowData.minGamesReward,
      dailyBonusAdded: rowData.dailyBonusAdded,
      purchased: rowData.purchased,
      claimedAchievement: rowData.claimedAchievement,
      startBonus: rowData.startBonus,
    });

    console.log('rowData 2', {
      roomFee: rowData.roomFee,
      minGamesPenalty: rowData.minGamesPenalty,
      dailyBonusRemoved: rowData.dailyBonusRemoved,
      buyGift: rowData.purchased,
    });
    */

    let totalPos = rowData.won
      + rowData.autoCompensation
      + rowData.missedTurnReward
      + rowData.minGamesReward
      + rowData.leftRoomReward
      + rowData.adminAdded
      + rowData.botsTopUp
      + rowData.dailyBonusAdded
      + rowData.tournamentWon
      + rowData.purchased
      + rowData.resetBalance
      + rowData.resetProfile
      + (rowData.claimedGiftCode || 0)
      + rowData.claimedAchievement
      + rowData.claimedTutorialBonus
      + rowData.claimediOSBonus
      + rowData.claimedAndroidBonus
      + rowData.emailBonus
      + rowData.claimedBonus
      + rowData.startBonus;


    let totalNeg = rowData.roomFee
      + rowData.lost
      + rowData.missedTurn
      + rowData.minGamesPenalty
      + rowData.leftRoomPenalty
      + rowData.profanity
      - rowData.adminRemoved
      - rowData.dailyBonusRemoved
      + rowData.tournamentEntryFee
      + rowData.buyGift
      + rowData.removeGift;
      // + rowData.deletedProfile;

    if (rowData.deletedProfile > 0) {
      totalPos = totalPos + rowData.deletedProfile;
    } else if (rowData.deletedProfile && rowData.deletedProfile < 0) {
      totalNeg = totalNeg - rowData.deletedProfile;
    }

    console.log('totalPos', totalPos, totalNeg, (totalPos - totalNeg));

    // console.log('totalPos 2', totalPos2, totalNeg2, (totalPos2 - totalNeg2));

    return (
      <>
        <td>
          {key}
        </td>

        <td>
          {this.coinPerRowNumber(rowData.roomFee, true)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.lost, true)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.won, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.autoCompensation, false)}
        </td>

        <td>
          {this.coinPerRowNumber(rowData.missedTurn, true)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.minGamesPenalty, true)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.leftRoomPenalty, true)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.profanity, true)}
        </td>

        <td>
          {this.coinPerRowNumber(rowData.missedTurnReward, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.minGamesReward, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.leftRoomReward, false)}
        </td>

        <td>
          {this.coinPerRowNumber(rowData.adminAdded, false)}
        </td>
        <td>
          {this.coinPerRowNumber(-rowData.adminRemoved, true)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.botsTopUp, false)}
        </td>

        <td>
          {this.coinPerRowNumber(rowData.dailyBonusAdded, false)}
        </td>
        <td>
          {this.coinPerRowNumber(-rowData.dailyBonusRemoved, true)}
        </td>

        <td>
          {this.coinPerRowNumber(rowData.tournamentEntryFee, true)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.tournamentWon, false)}
        </td>

        <td>
          {this.coinPerRowNumber(rowData.purchased, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.resetBalance, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.resetProfile, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.claimedGiftCode, false)}
        </td>

        <td>
          {this.coinPerRowNumber(rowData.buyGift, true)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.removeGift, true)}
        </td>

        <td>
          {this.coinPerRowNumber(rowData.claimedAchievement, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.claimedTutorialBonus, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.claimediOSBonus, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.claimedAndroidBonus, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.emailBonus, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.claimedBonus, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.startBonus, false)}
        </td>
        <td>
          {this.coinPerRowNumber(rowData.deletedProfile, 'both')}
        </td>

        <td>
          {this.coinPerRowNumber((totalPos - totalNeg), 'both')}
        </td>


        {/* <td>
          {-rowData.roomFee}
        </td>
        <td>
          {-rowData.lost}
        </td>
        <td>
          {`+${rowData.won}`}
        </td>
        <td>
          {`+${rowData.autoCompensation}`}
        </td>

        <td>
          {-rowData.missedTurn}
        </td>
        <td>
          {-rowData.minGamesPenalty}
        </td>
        <td>
          {-rowData.leftRoomPenalty}
        </td>
        <td>
          {-rowData.profanity}
        </td>

        <td>
          {`+${rowData.missedTurnReward}`}
        </td>
        <td>
          {`+${rowData.minGamesReward}`}
        </td>
        <td>
          {`+${rowData.leftRoomReward}`}
        </td>

        <td>
          {`+${rowData.adminAdded}`}
        </td>
        <td>
          {-rowData.adminRemoved}
        </td>
        <td>
          {`+${rowData.botsTopUp}`}
        </td>
        <td>
          {`+${rowData.dailyBonusAdded}`}
        </td>
        <td>
          {-rowData.dailyBonusRemoved}
        </td>

        <td>
          {-rowData.tournamentEntryFee}
        </td>
        <td>
          {`+${rowData.tournamentWon}`}
        </td>

        <td>
          {`+${rowData.purchased}`}
        </td>
        <td>
          {`+${rowData.resetBalance}`}
        </td>
        <td>
          {`+${rowData.resetProfile}`}
        </td>
        <td>
          {`+${rowData.claimedGiftCode}`}
        </td>

        <td>
          {-rowData.buyGift}
        </td>
        <td>
          {-rowData.removeGift}
        </td>

        <td>
          {`+${rowData.claimedAchievement}`}
        </td>
        <td>
          {`+${rowData.claimedTutorialBonus}`}
        </td>
        <td>
          {`+${rowData.claimediOSBonus}`}
        </td>
        <td>
          {`+${rowData.claimedAndroidBonus}`}
        </td>
        <td>
          {`+${rowData.emailBonus}`}
        </td>
        <td>
          {`+${rowData.claimedBonus}`}
        </td>
        <td>
          {`+${rowData.startBonus}`}
        </td>
        <td>
          {`+${rowData.deletedProfile}`}
        </td> */}
      </>
    )
  }

  render() {
    const {
      t,
      allGifts
      //  statistics,
    } = this.props;

    const {
      id,
      length,
      data,
      dayTotals,
      selectedDay,
      roundStart,
      roundTo,
      selectedOption,
      startDate,
      csvData,
      csvStartDate,
      csvEndDate,
      balanceType,
      achievementLevel,
      achievementType,
      selectedViewOption,
      browserKeys,
      selectedEnvOption,
      selectedDesignOption,
      selectedThemeOption,
      popoverOpen,
      targetId,
      selectedBrowser,
      selectedBrowserVersion,
      selectedDate,
      openChart,
      roundRange,
      monthTotals,
      selectedGiftType,
      giftsSendData,
      giftsRemoveData,
    } = this.state;

    const options = [
      { value: 'draugiem', label: 'draugiem' },
      { value: 'stripe', label: 'stripe' },
      { value: 'everyPay', label: 'everyPay' },
      { value: 'facebook', label: 'facebook' },
      { value: 'google', label: 'google' },
      { value: 'inbox', label: 'inbox' },
      { value: 'mobile', label: 'mobile' },
    ];

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'gray',
        padding: 10,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
      }
    }
    let aggregated = {};
    if (dayTotals && id === statisticsConst.GIFTSHISTORY) {
      aggregated = mapValues(
        groupBy(
          flatMap(values(dayTotals), entries), // Flatten and create key-value pairs
          0 // Group by the field names (e.g., 'cirvis', 'ezelis')
        ),
        (entries) => {
          // Sum 'send' and 'remove' values for each field
          return entries.reduce(
            (acc, [, values]) => {
              acc.send += values.send || 0;
              acc.remove += values.remove || 0;
              return acc;
            },
            { send: 0, remove: 0 }
          );
        }
      );
    }
    console.log(achievementType, "=========checkDayTotals", id);
    console.log(allGifts, selectedViewOption,"allGiftsallGifts===")

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-statistics">
          <PopoverBody className="popover-body">
            A lot of different statistics about the game, settings, players, and other things. A detailed description is provided for each type of statistic individually.
          </PopoverBody>
        </UncontrolledPopover>
        <div className='mt-4' style={{ color: '#fff' }}>
          <h2>
            Statistics
            <Button className="admin-help-button" id="admin-help-button-statistics">
              ?
            </Button>
          </h2>
          <Label for="id">
            Statistic type
          </Label>
          <Input type="select" name="id" className="game-bet-select" value={id} onChange={this.changeSelectedId}>
            {
              statisticsConst.statisticsTypes.map((item, i) => {
                return (
                  <option className="background-222" key={i} value={item.id}>
                    {t(item.tLabel)}
                  </option>
                );
              })
            }
          </Input>
          <p>
            {filter(statisticsConst.statisticsTypes, (item) => item.id === id)[0]?.description || ''}
          </p>
          {/* {id !== statisticsConst.TOTALPLAYERS && (
            <Label for="length">
              Length
            </Label>
          )} */}
          {/* {id !== statisticsConst.TOTALPLAYERS && id !== statisticsConst.LASTONLINEPLAYERS && (
            <Input type="select" name="length" className="game-bet-select" value={length} onChange={this.changeSelectedLength}>
              <option className="background-222" value={statisticsConst.STATISTICS_PERIOD_TIME.day}>Day</option>
              <option className="background-222" value={statisticsConst.STATISTICS_PERIOD_TIME.week}>Week</option>
              <option className="background-222" value={statisticsConst.STATISTICS_PERIOD_TIME.month}>Month</option>
            </Input>
          )} */}
          {id === statisticsConst.LASTONLINEPLAYERS && (
            <Input type="select" name="length" className="game-bet-select" value={length} onChange={this.changeSelectedLength}>
              <option className="background-222" value="1">Last 7 Days</option>
              <option className="background-222" value="2">Between 7 and 30 days</option>
              <option className="background-222" value="3">Between 30 and 90 days</option>
              <option className="background-222" value="4">Between 90 and 180 days</option>
              <option className="background-222" value="5">Between 180 and 360 days</option>
              <option className="background-222" value="6">360 and more days ago</option>
            </Input>
          )}
          {id === statisticsConst.BALANCEPERPLAYERS && (
            <Input type="select" name="balanceType" className="game-bet-select mt-4" value={balanceType} onChange={this.changeBalanceType}>
              {
                map(constants.BALANCE_PER_RANGE, item => (
                  <option className="background-222" value={item.id}>{item.label}</option>
                ))
              }
            </Input>
          )}

          {id === statisticsConst.PLAYERACHIEVEMENTS && (
            <div>
              <Label for="length">
                Achievement
              </Label>
              <Input type="select" name="achievementType" className="game-bet-select mt-4" value={achievementType} onChange={this.changeachievementLevel}>
                {
                  map(constants.ACHIEVEMENT_FOR_STATISTICS, item => (
                    <option className="background-222" value={item}>{constants.ACHIEVEMENT_LABEL[item]}</option>
                  ))
                }
              </Input>
            </div>
          )}

          {id === statisticsConst.GAMESINROOM && (
            <Select closeMenuOnSelect={true} options={statisticsConst.STATISTICS_ROUND_RANGE} styles={customStyles} value={roundRange} onChange={this.changeRoundRange} />
          )}

          {id === statisticsConst.TRANSACTIONS && (
            <div>
              <Label for="length">
                Environment
              </Label>
              <Select closeMenuOnSelect={true} options={statisticsConst.STATISTICS_ENV_MODE} styles={customStyles} value={selectedEnvOption} onChange={this.changeEnvOption} />
            </div>
          )}

          {id === statisticsConst.PURCHASEDMONEY && (
            <>
            <div>
              <Label for="length">
                {/* {t('platform')} */}
                Platform
              </Label>
              <Select closeMenuOnSelect={false} options={options} isMulti styles={customStyles} value={selectedOption} onChange={this.changePurchaseOption} />
            </div>
            <div>
              <Label for="length">
                Environment
              </Label>
              <Select closeMenuOnSelect={true} options={statisticsConst.STATISTICS_ENV_MODE} styles={customStyles} value={selectedEnvOption} onChange={this.changeEnvOption} />
            </div>
            </>
          )}

          {id === statisticsConst.PLAYERSVIEWSPERSCREEN && (
            <div>
              <Label for="length">
                Platform
              </Label>
              <Select closeMenuOnSelect={false} options={statisticsConst.STATISTICS_VIEW_SCREEN_OPTIONS} isMulti styles={customStyles} value={selectedViewOption} onChange={this.changeViewOption} />
            </div>
          )}

          {id === statisticsConst.PLAYERSPERBROWSER && (
            <div>
              <Label for="length">
                Device
              </Label>
              <Select closeMenuOnSelect={true} options={statisticsConst.STATISTICS_VIEW_DEVICE_OPTIONS} styles={customStyles} value={selectedViewOption} onChange={this.changeViewOption} />
            </div>
          )}

          {(id === statisticsConst.PLAYERSPERDESIGN || id === statisticsConst.PLAYERACTIVETHEME || id === statisticsConst.PLAYERSPERSCREENSIZEFOREACHDESIGN || id === statisticsConst.PLAYERSPERSCREENSIZEFOREACHSCREENMODE) && (
            <div className='d-flex flex-row'>
              <div>
                <Label for="length">
                  Environment
                </Label>
                <Select closeMenuOnSelect={true} options={statisticsConst.STATISTICS_ENV_MODE} styles={customStyles} value={selectedEnvOption} onChange={this.changeEnvOption} />
              </div>
              {
                (id !== statisticsConst.PLAYERSPERDESIGN && id !== statisticsConst.PLAYERACTIVETHEME) && (
                  id === statisticsConst.PLAYERSPERSCREENSIZEFOREACHSCREENMODE ? (
                    <div>
                      <Label for="length">
                        Theme
                      </Label>
                      <Select closeMenuOnSelect={true} options={statisticsConst.STATISTICS_THEME_MODE} styles={customStyles} value={selectedThemeOption} onChange={this.changeThemeOption} />
                    </div>
                  ) : (
                    <div>
                      <Label for="length">
                        Design
                      </Label>
                      <Select closeMenuOnSelect={true} options={statisticsConst.STATISTICS_DESIGN_MODE} styles={customStyles} value={selectedDesignOption} onChange={this.changeDesignOption} />
                    </div>
                  )
                )
              }
            </div>
          )}
          {id === statisticsConst.GIFTSHISTORY && (
            <div>
              <Label for="length">
                Type
              </Label>
              <Select closeMenuOnSelect={true} options={statisticsConst.GIFTS_TYPE} styles={customStyles} value={selectedGiftType} onChange={this.changeGiftOption} />
            </div>
          )}

          <Row className='mt-4 mb-4'>
            <Col sm="4">
              <Button color="primary" onClick={this.getStatistic}>
                {/* {t('getStatistic')} */}
                Calculate
              </Button>
            </Col>
            <Col sm="4">
              {
                length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString() && (
                  <DatePicker
                    format={constants.FORMAT_DATE_TYPE.pointsMonthYear}
                    culture={constants.sendLanguage.lv}
                    onChange={this.changeStartDate}
                    value={startDate}
                    defaultValue={new Date()}
                    views={['year', 'decade']}
                  />
                )
              }
            </Col>
            <Col sm="4" style={{ textAlign: 'right' }}>
              <Button className='primary' onClick={() => this.toggleChart()}>
                Chart
              </Button>
              <CSVLink
                data={csvData}
                filename={length.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString() ? `${id}-${moment(startDate).format(constants.FORMAT_DATE_TYPE.fullMonthYear)}` : `${id}-${csvStartDate}-${csvEndDate}`}
                className="btn btn-primary"
                target="_blank"
              >
                Export CSV
              </CSVLink>
            </Col>
          </Row>

          {(data && id && filter(statisticsConst.statisticsTypes, ['id', id])[0].chartType === statisticsConst.STATISTICS_CHART_TYPE.common) ? (
            <Fragment>
              {openChart && (
                <div className='d-flex w-full justify-center'>
                  <LineChart
                    width={900}
                    height={400}
                    data={data}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                  >
                    <XAxis dataKey="key" />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="value" stroke="#ff7300" />
                  </LineChart>
                </div>
              )}
              <table className="admin-statistics-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {dayTotals && map(dayTotals, (dayTotal, key) => {
                    return (
                      <Fragment>
                        <tr key={key} onClick={() => this.openSubTable(key)}>
                          <td>
                            {key}
                          </td>
                          <td>
                            {(dayTotals && typeof dayTotal !== 'object') ? dayTotal : null}
                          </td>
                        </tr>
                        {(selectedDay && selectedDay === key) ? (
                          <Fragment>
                            {data && size(data) > 0 && map(data, (item, index) => {
                              if (item.day !== selectedDay) {
                                return null;
                              }
                              return (
                                <tr key={item.key}>
                                  <td>
                                    {item.hour}
                                  </td>
                                  <td>
                                    {item.value}
                                  </td>
                                </tr>
                              );
                            })}
                          </Fragment>
                        ) : null}
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </Fragment>
          ) : (
            <Fragment>
              {data && (id === statisticsConst.DELETEDPLAYERS) && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        hide={!openChart}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="#ff3203" />
                        <Line type="monotone" dataKey="draugiem" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="facebook" stroke="#006e04" />
                        <Line type="monotone" dataKey="email" stroke="blue" />
                        <Line type="monotone" dataKey="apple" stroke="#ff73ee" />
                        <Line type="monotone" dataKey="inbox" stroke="#6f42c1" />
                        <Line type="monotone" dataKey="google" stroke="#343a40" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Email
                        </th>
                        <th>
                          Apple
                        </th>
                        <th>
                          Inbox
                        </th>
                        <th>
                          Google
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.email}
                              </td>
                              <td>
                                {dayTotal.apple}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                              <td>
                                {dayTotal.google}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.draugiem || 0}
                                      </td>
                                      <td>
                                        {item.facebook || 0}
                                      </td>
                                      <td>
                                        {item.email || 0}
                                      </td>
                                      <td>
                                        {item.apple || 0}
                                      </td>
                                      <td>
                                        {item.inbox || 0}
                                      </td>
                                      <td>
                                        {item.google || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && (id === statisticsConst.RETURNEDPLAYERS) && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="#ff3203" />
                        <Line type="monotone" dataKey="draugiem" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="facebook" stroke="#006e04" />
                        <Line type="monotone" dataKey="email" stroke="blue" />
                        <Line type="monotone" dataKey="apple" stroke="#ff73ee" />
                        <Line type="monotone" dataKey="inbox" stroke="#6f42c1" />
                        <Line type="monotone" dataKey="google" stroke="#343a40" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Email
                        </th>
                        <th>
                          Apple
                        </th>
                        <th>
                          Inbox
                        </th>
                        <th>
                          Google
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.email}
                              </td>
                              <td>
                                {dayTotal.apple}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                              <td>
                                {dayTotal.google}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.draugiem || 0}
                                      </td>
                                      <td>
                                        {item.facebook || 0}
                                      </td>
                                      <td>
                                        {item.email || 0}
                                      </td>
                                      <td>
                                        {item.apple || 0}
                                      </td>
                                      <td>
                                        {item.inbox || 0}
                                      </td>
                                      <td>
                                        {item.google || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.ROOMPLAYERCOUNT && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="#ff3203" />
                        <Line type="monotone" dataKey="four" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="three" stroke="#006e04" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Four
                        </th>
                        <th>
                          Three
                          </th>
                          <th>
                            Two
                          </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.four}
                              </td>
                              <td>
                                {dayTotal.three}
                              </td>
                              <td>
                                {dayTotal.two || 0}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.four || 0}
                                      </td>
                                      <td>
                                        {item.three || 0}
                                      </td>
                                      <td>
                                        {item.two || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERSPERLANGUAGE && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="#ff3203" />
                        <Line type="monotone" dataKey="lv" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="en" stroke="#006e04" />
                        <Line type="monotone" dataKey="ru" stroke="#ff73ee" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Latvian
                        </th>
                        <th>
                          English
                        </th>
                        <th>
                          Russian
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.lv}
                              </td>
                              <td>
                                {dayTotal.en}
                              </td>
                              <td>
                                {dayTotal.ru}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && (data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.lv || 0}
                                      </td>
                                      <td>
                                        {item.en || 0}
                                      </td>
                                      <td>
                                        {item.ru || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.BALANCEPERPLAYERS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="#3373ee" />
                        <Line type="monotone" dataKey="draugiem" stroke="#ff3203" />
                        <Line type="monotone" dataKey="facebook" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="email" stroke="#006e04" />
                        <Line type="monotone" dataKey="apple" stroke="blue" />
                        <Line type="monotone" dataKey="google" stroke="#ff73ee" />
                        <Line type="monotone" dataKey="inbox" stroke="#3373ee" />
                        {/* <Line type="monotone" dataKey="total" stroke="#ff0000" /> */}
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Email
                        </th>
                        <th>
                          Apple
                        </th>
                        <th>
                          Google
                        </th>
                        <th>
                          Inbox
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.email}
                              </td>
                              <td>
                                {dayTotal.apple}
                              </td>
                              <td>
                                {dayTotal.google}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERACHIEVEMENTS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        {
                          achievementType === 'singleAchievements' ? (
                            map(constants.UNIQUE_ACHIEVEMNT_LEVEL, (item, idx) => (
                              <Line type="monotone" dataKey={item.id} stroke={item.color} />
                            ))
                          ) : (
                            map(constants.ACHIEVEMENT_LEVEL, (item, idx) => (
                              <Line type="monotone" dataKey={item.label} stroke={item.color} />
                            ))
                          )
                        }
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        {
                          achievementType === 'singleAchievements' ? (
                            map(constants.UNIQUE_ACHIEVEMNT_LEVEL, (item, idx) => (
                              <th>{item.label}</th>
                            ))
                          ) : (
                            map(constants.ACHIEVEMENT_LEVEL, (item, idx) => (
                              <th>{item.label}</th>
                            ))
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              {
                                achievementType === 'singleAchievements' ? (
                                  map(constants.UNIQUE_ACHIEVEMNT_LEVEL, (item, idx) => (
                                    <td>{dayTotal[item.id]}</td>
                                  ))
                                ) : (
                                  map(constants.ACHIEVEMENT_LEVEL, (item, idx) => (
                                    <td>{dayTotal[item.label]}</td>
                                  ))
                                )
                              }
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      {
                                        achievementType === 'singleAchievements' ? (
                                          map(constants.UNIQUE_ACHIEVEMNT_LEVEL, (hourItem, idx) => (
                                            <td>{item[hourItem.id] || 0}</td>
                                          ))
                                        ) : (
                                          map(constants.ACHIEVEMENT_LEVEL, (hourItem, idx) => (
                                            <td>{item[hourItem.label] || 0}</td>
                                          ))
                                        )
                                      }
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.LASTONLINEPLAYERS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="draugiem" stroke="#ff3203" />
                        <Line type="monotone" dataKey="facebook" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="email" stroke="#006e04" />
                        <Line type="monotone" dataKey="apple" stroke="blue" />
                        <Line type="monotone" dataKey="google" stroke="#ff73ee" />
                        <Line type="monotone" dataKey="inbox" stroke="#3373ee" />
                        {/* <Line type="monotone" dataKey="total" stroke="#ff0000" /> */}
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Email
                        </th>
                        <th>
                          Apple
                        </th>
                        <th>
                          Google
                        </th>
                        <th>
                          Inbox
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.email}
                              </td>
                              <td>
                                {dayTotal.apple}
                              </td>
                              <td>
                                {dayTotal.google}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.USERSCOINANALYSIS && (
                <Fragment>
                  {console.log('data USERSCOINANALYSIS', dayTotals, data)}
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="#ff3203" />
                        <Line type="monotone" dataKey="draugiem" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="facebook" stroke="#006e04" />
                        <Line type="monotone" dataKey="mobile" stroke="blue" />
                        <Line type="monotone" dataKey="web" stroke="#ff73ee" />
                        <Line type="monotone" dataKey="inbox" stroke="yellow" />
                        {/* <Line type="monotone" dataKey="total" stroke="#ff0000" /> */}
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          % of TotalPlayers
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Mobile
                        </th>
                        <th>
                          Web
                        </th>
                        <th>
                          Inbox
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total ? (isNaN((dayTotal.total || 0 * 100) / (dayTotal.totalPlayers)) ? "0%" : parseFloat((dayTotal.total || 0 * 100) / (dayTotal.totalPlayers)).toFixed(4) + "%") : ''}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.mobile}
                              </td>
                              <td>
                                {dayTotal.web}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td />
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.draugiem || 0}
                                      </td>
                                      <td>
                                        {item.facebook || 0}
                                      </td>
                                      <td>
                                        {item.mobile || 0}
                                      </td>
                                      <td>
                                        {item.web || 0}
                                      </td>
                                      <td>
                                        {item.inbox || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.GAMESINROOM && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        {this.roundS && this.roundT && [...Array(this.roundT - this.roundS + 1)].map((value, index) => {
                          const valueDataKey = index + parseInt(this.roundS);
                          const colorR = Math.floor(Math.random() * 256);
                          const colorG = Math.floor(Math.random() * 256);
                          const colorB = Math.floor(Math.random() * 256);
                          return <Line type="monotone" dataKey={valueDataKey} stroke={`rgb(${colorR},${colorG},${colorB})`} />
                        })}
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        {this.roundS && this.roundT && [...Array(this.roundT - this.roundS + 1)].map((value, index) => (
                          <th>{index + parseInt(this.roundS)}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              {this.roundS && this.roundT && [...Array(this.roundT - this.roundS + 1)].map((value, index) => (
                                <td>{dayTotal[index + parseInt(this.roundS)]}</td>
                              ))}
                            </tr>
                            {/* {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.money1 || 0}
                                      </td>
                                      <td>
                                        {item.money2 || 0}
                                      </td>
                                      <td>
                                        {item.money3 || 0}
                                      </td>
                                      <td>
                                        {item.money4 || 0}
                                      </td>
                                      <td>
                                        {item.money5 || 0}
                                      </td>
                                      <td>
                                        {item.money6 || 0}
                                      </td>
                                      <td>
                                        {item.money7 || 0}
                                      </td>
                                      <td>
                                        {item.money8 || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null} */}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PURCHASEDMONEY && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="#303293" />
                        <Line type="monotone" dataKey="250z-0.85EUR" stroke="#303293" />
                        <Line type="monotone" dataKey="500Z-1.41EUR" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="1000Z-2.85EUR" stroke="#006e04" />
                        <Line type="monotone" dataKey="4000z-4.27EUR" stroke="blue" />
                        <Line type="monotone" dataKey="15000z-14.23EUR" stroke="#ff7300" />
                        <Line type="monotone" dataKey="Reset-balance" stroke="#6610f2" />
                        <Line type="monotone" dataKey="Reset-points" stroke="#ffc107" />
                        <Line type="monotone" dataKey="Reset-profile" stroke="#343a40" />
                        <Line type="monotone" dataKey="Unban account" stroke="#ff7300" />
                        <Line type="monotone" dataKey="Hide ads for month" stroke="#6610f2" />
                        <Line type="monotone" dataKey="Hide ads for year" stroke="#ffc107" />
                        {/* <Line type="monotone" dataKey="total" stroke="#ff0000" /> */}
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          250Z - 0.85EUR
                        </th>
                        <th>
                          500Z - 1.41EUR
                        </th>
                        <th>
                          1000Z - 2.85EUR
                        </th>
                        <th>
                          4000Z - 4.27EUR
                        </th>
                        <th>
                          15000Z - 14.23EUR
                        </th>
                        <th>
                          Reset balance - 5.00EUR
                        </th>
                        <th>
                          Reset points - 5.00EUR
                        </th>
                        <th>
                          Reset profile - 5.00EUR
                        </th>
                        <th>
                          Unban account - 10.00EUR
                        </th>
                        <th>
                          Remove ads for 1 month - 3.00EUR
                        </th>
                        <th>
                          Remove ads for 1 year - 25.00EUR
                        </th>
                        <th>
                          Total amount (EUR)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.money1}
                              </td>
                              <td>
                                {dayTotal.money2}
                              </td>
                              <td>
                                {dayTotal.money3}
                              </td>
                              <td>
                                {dayTotal.money4}
                              </td>
                              <td>
                                {dayTotal.money5}
                              </td>
                              <td>
                                {dayTotal.money6}
                              </td>
                              <td>
                                {dayTotal.money7}
                              </td>
                              <td>
                                {dayTotal.money8}
                              </td>
                              <td>
                                {dayTotal.unban}
                              </td>
                              <td>
                                {dayTotal.removeAdsMonth}
                              </td>
                              <td>
                                {dayTotal.removeAdsYear}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {console.log('selectedDay data', data)}
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.money1 ]|| 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.money2 ] || 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.money3 ] || 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.money4 ] || 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.money5 ] || 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.money6 ] || 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.money7 ] || 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.money8 ] || 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.unban ] || 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.removeAdsMonth ] || 0}
                                      </td>
                                      <td>
                                        {item[statisticsConst.PURCHASE_TYPE.removeAdsYear ] || 0}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.TRANSACTIONS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="red" />
                        <Line type="monotone" dataKey="draugiem" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="email" stroke="#006e04" />
                        <Line type="monotone" dataKey="facebook" stroke="blue" />
                        <Line type="monotone" dataKey="google" stroke="#ff7300" />
                        <Line type="monotone" dataKey="inbox" stroke="#6610f2" />
                        <Line type="monotone" dataKey="mobile" stroke="#ffc107" />
                        <Line type="monotone" dataKey="everyPay" stroke="#343a40" />
                        <Line type="monotone" dataKey="stripe" stroke="yellow" />

                      </LineChart>
                    </div>
                  )}

                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Stripe
                        </th>
                        <th>
                          Every pay
                        </th>
                        <th>
                          Inbox
                        </th>
                        <th>
                          Mobile
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.stripe}
                              </td>
                              <td>
                                {dayTotal.everyPay}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                              <td>
                                {dayTotal.mobile}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.draugiem || 0}
                                      </td>
                                      <td>
                                        {item.facebook || 0}
                                      </td>
                                      <td>
                                        {item.stripe || 0}
                                      </td>
                                      <td>
                                        {item.everyPay || 0}
                                      </td>
                                      <td>
                                        {item.inbox || 0}
                                      </td>
                                      <td>
                                        {item.mobile || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.TOTALPLAYERS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="red" />
                        <Line type="monotone" dataKey="draugiem" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="email" stroke="#006e04" />
                        <Line type="monotone" dataKey="facebook" stroke="blue" />
                        <Line type="monotone" dataKey="google" stroke="#ff7300" />
                        <Line type="monotone" dataKey="inbox" stroke="#6610f2" />
                        <Line type="monotone" dataKey="apple" stroke="#17a2b8" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Email
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Google
                        </th>
                        <th>
                          Inbox
                        </th>
                        <th>
                          Apple
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.email}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.google}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                              <td>
                                {dayTotal.apple}
                              </td>
                            </tr>
                            {/* {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.draugiem || 0}
                                      </td>
                                      <td>
                                        {item.email || 0}
                                      </td>
                                      <td>
                                        {item.facebook || 0}
                                      </td>
                                      <td>
                                        {item.google || 0}
                                      </td>
                                      <td>
                                        {item.inbox || 0}
                                      </td>
                                      <td>
                                        {item.apple || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null} */}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.ENVPERROOM && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="total" stroke="blue" />
                        <Line type="monotone" dataKey="webPlays" stroke="red" />
                        <Line type="monotone" dataKey="appPlays" stroke="#ff7300" />
                      </LineChart>
                    </div>
                  )}

                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Web
                        </th>
                        <th>
                          App
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.webRooms}
                              </td>
                              <td>
                                {dayTotal.appRooms}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.webRooms || 0}
                                      </td>
                                      <td>
                                        {item.appRooms || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.MISSEDROOMS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="Total" label="Total" stroke="red" />
                        <Line type="monotone" dataKey="Auto-play" stroke="green" />
                        <Line type="monotone" dataKey="startedAutomated" stroke="#8cff00" />
                        <Line type="monotone" dataKey="On (move)" stroke="#ff7300" />
                        <Line type="monotone" dataKey="On (no move)" stroke="blue" />
                        <Line type="monotone" dataKey="Off (move)" stroke="#ff7300" />
                        <Line type="monotone" dataKey="Off (no move)" stroke="#ff7300" />
                        <Line type="monotone" dataKey="Web" stroke="#006e04" />
                        <Line type="monotone" dataKey="App" stroke="#8cff00" />
                      </LineChart>
                    </div>
                  )}

                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Auto-play
                        </th>
                        <th>
                          startedAutomated
                        </th>
                        <th>
                          On (move)
                        </th>
                        <th>
                          On (no move)
                        </th>
                        <th>
                          Off (move)
                        </th>
                        <th>
                          Off (no move)
                        </th>
                        <th>
                          Web
                        </th>
                        <th>
                          App
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.missedRoomsAutomated}
                              </td>
                              <td>
                                {dayTotal.startedAutomated}
                              </td>
                              <td>
                                {dayTotal.missedRoomsont}
                              </td>
                              <td>
                                {dayTotal.missedRoomsond}
                              </td>
                              <td>
                                {dayTotal.missedRoomsofft}
                              </td>
                              <td>
                                {dayTotal.missedRoomsoffd}
                              </td>
                              <td>
                                {dayTotal.missedRoomsWeb}
                              </td>
                              <td>
                                {dayTotal.missedRoomsApp}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && data.length > 0 && data.map((item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.Total || 0}
                                      </td>
                                      <td>
                                        {item['Auto-play'] || 0}
                                      </td>
                                      <td>
                                        {item.startedAutomated || 0}
                                      </td>
                                      <td>
                                        {item['On (move)'] || 0}
                                      </td>
                                      <td>
                                        {item['On (no move)'] || 0}
                                      </td>
                                      <td>
                                        {item['Off (move)'] || 0}
                                      </td>
                                      <td>
                                        {item['Off (no move)'] || 0}
                                      </td>
                                      <td>
                                        {item['Web'] || 0}
                                      </td>
                                      <td>
                                        {item['App'] || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.ROOMS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="rooms" label="Total" stroke="red" />
                      </LineChart>
                    </div>
                  )}

                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        console.log('dayTotaldayTotal', dayTotal);
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {(dayTotal && dayTotal.rooms) ? dayTotal.rooms : 0}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && data.length > 0 && data.map((item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }

                                  console.log('item', item);

                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.rooms || item.value || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.BETS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" label="Total" stroke="red" />
                        <Line type="monotone" dataKey={10} stroke="#8cff00" />
                        <Line type="monotone" dataKey={25} stroke="#006e04" />
                        <Line type="monotone" dataKey={50} stroke="blue" />
                        <Line type="monotone" dataKey={100} stroke="purple" />
                        <Line type="monotone" dataKey={250} stroke="blue" />
                        <Line type="monotone" dataKey={500} stroke="green" />
                        <Line type="monotone" dataKey={1000} stroke="blue" />
                        <Line type="monotone" dataKey={5000} stroke="#006e04" />
                        <Line type="monotone" dataKey={10000} stroke="#8cff00" />
                      </LineChart>
                    </div>
                  )}

                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          total
                        </th>
                        <th>
                          1:10
                        </th>
                        <th>
                          1:25
                        </th>
                        <th>
                          1:50
                        </th>
                        <th>
                          1:100
                        </th>
                        <th>
                          1:250
                        </th>
                        <th>
                          1:500
                        </th>
                        <th>
                          1:1000
                        </th>
                        <th>
                          1:5000
                        </th>
                        <th>
                          1:10000
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal['total']}
                              </td>
                              <td>
                                {dayTotal[10]}
                              </td>
                              <td>
                                {dayTotal[25]}
                              </td>
                              <td>
                                {dayTotal[50]}
                              </td>
                              <td>
                                {dayTotal[100]}
                              </td>
                              <td>
                                {dayTotal[250]}
                              </td>
                              <td>
                                {dayTotal[500]}
                              </td>
                              <td>
                                {dayTotal[1000]}
                              </td>
                              <td>
                                {dayTotal[5000]}
                              </td>
                              <td>
                                {dayTotal[10000]}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item['total']}
                                      </td>
                                      <td>
                                        {item[10]}
                                      </td>
                                      <td>
                                        {item[25]}
                                      </td>
                                      <td>
                                        {item[50]}
                                      </td>
                                      <td>
                                        {item[100]}
                                      </td>
                                      <td>
                                        {item[250]}
                                      </td>
                                      <td>
                                        {item[500]}
                                      </td>
                                      <td>
                                        {item[1000]}
                                      </td>
                                      <td>
                                        {item[5000]}
                                      </td>
                                      <td>
                                        {item[10000]}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.LOGINS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="total" stroke="red" />
                        <Line type="monotone" dataKey="draugiem" stroke="#ff7300" />
                        <Line type="monotone" dataKey="facebook" stroke="blue" />
                        <Line type="monotone" dataKey="google" stroke="#ff7300" />
                        <Line type="monotone" dataKey="email" stroke="yellow" />
                        <Line type="monotone" dataKey="appleId" stroke="ffc900" />
                        <Line type="monotone" dataKey="inbox" stroke="blue" />
                      </LineChart>
                    </div>
                  )}

                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Google
                        </th>
                        <th>
                          Email
                        </th>
                        <th>
                          AppleId
                        </th>
                        <th>
                          Inbox
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} className={key === selectedDay ? 'selected-row' : ''} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.google}
                              </td>
                              <td>
                                {dayTotal.email}
                              </td>
                              <td>
                                {dayTotal.appleId}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key} className={key === selectedDay ? 'selected-row' : ''}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.draugiem || 0}
                                      </td>
                                      <td>
                                        {item.facebook || 0}
                                      </td>
                                      <td>
                                        {item.google || 0}
                                      </td>
                                      <td>
                                        {item.email || 0}
                                      </td>
                                      <td>
                                        {item.appleId || 0}
                                      </td>
                                      <td>
                                        {item.inbox || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.NEWUSERS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="total" stroke="red" />
                        <Line type="monotone" dataKey="draugiem" stroke="#ff7300" />
                        <Line type="monotone" dataKey="facebook" stroke="blue" />
                        <Line type="monotone" dataKey="google" stroke="#ff7300" />
                        <Line type="monotone" dataKey="email" stroke="yellow" />
                        <Line type="monotone" dataKey="appleId" stroke="#ffc900" />
                        <Line type="monotone" dataKey="inbox" stroke="blue" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Google
                        </th>
                        <th>
                          Email
                        </th>
                        <th>
                          AppleId
                        </th>
                        <th>
                          Inbox
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.google}
                              </td>
                              <td>
                                {dayTotal.email}
                              </td>
                              <td>
                                {dayTotal.appleId}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.draugiem || 0}
                                      </td>
                                      <td>
                                        {item.facebook || 0}
                                      </td>
                                      <td>
                                        {item.google || 0}
                                      </td>
                                      <td>
                                        {item.email || 0}
                                      </td>
                                      <td>
                                        {item.appleId || 0}
                                      </td>
                                      <td>
                                        {item.inbox || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERSPERFONTMODE && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="normal" stroke="red" />
                        <Line type="monotone" dataKey="large" stroke="#ff7300" />
                        <Line type="monotone" dataKey="xLarge" stroke="blue" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Normal
                        </th>
                        <th>
                          Large
                        </th>
                        <th>
                          Very Large
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (playerFontMode, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {playerFontMode.normal}
                              </td>
                              <td>
                                {playerFontMode.large}
                              </td>
                              <td>
                                {playerFontMode.xLarge}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.normal || 0}
                                      </td>
                                      <td>
                                        {item.large || 0}
                                      </td>
                                      <td>
                                        {item.xLarge || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERSPERDESIGN && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="old" stroke="red" />
                        <Line type="monotone" dataKey="new" stroke="#ff7300" />
                        <Line type="monotone" dataKey="desktopNew" stroke="yellow" />
                        <Line type="monotone" dataKey="desktopOld" stroke="blue" />
                        <Line type="monotone" dataKey="draugiemNew" stroke="blue" />
                        <Line type="monotone" dataKey="draugiemOld" stroke="blue" />
                        <Line type="monotone" dataKey="facebookNew" stroke="#ff7300" />
                        <Line type="monotone" dataKey="facebookNew" stroke="#ffc900" />
                        <Line type="monotone" dataKey="inboxNew" stroke="#ffc900" />
                        <Line type="monotone" dataKey="inboxOld" stroke="#ffc900" />
                        <Line type="monotone" dataKey="mobileNew" stroke="#ffc900" />
                        <Line type="monotone" dataKey="mobileOld" stroke="#ffc900" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Old Design
                        </th>
                        <th>
                          Desktop
                        </th>
                        <th>
                          Mobile
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Inbox
                        </th>
                        <th>
                          New Design
                        </th>
                        <th>
                          Desktop
                        </th>
                        <th>
                          Mobile
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Inbox
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (playerDesign, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {playerDesign.old}
                              </td>
                              <td>
                                {playerDesign.desktopOld}
                              </td>
                              <td>
                                {playerDesign.mobileOld}
                              </td>
                              <td>
                                {playerDesign.draugiemOld}
                              </td>
                              <td>
                                {playerDesign.facebookOld}
                              </td>
                              <td>
                                {playerDesign.inboxOld}
                              </td>
                              <td>
                                {playerDesign.new}
                              </td>
                              <td>
                                {playerDesign.desktopNew}
                              </td>
                              <td>
                                {playerDesign.mobileNew}
                              </td>
                              <td>
                                {playerDesign.draugiemNew}
                              </td>
                              <td>
                                {playerDesign.facebookNew}
                              </td>
                              <td>
                                {playerDesign.inboxNew}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.old || 0}
                                      </td>
                                      <td>
                                        {item.desktopOld || 0}
                                      </td>
                                      <td>
                                        {item.mobileOld || 0}
                                      </td>
                                      <td>
                                        {item.draugiemOld || 0}
                                      </td>
                                      <td>
                                        {item.facebookOld || 0}
                                      </td>
                                      <td>
                                        {item.inboxOld || 0}
                                      </td>
                                      <td>
                                        {item.new || 0}
                                      </td>
                                      <td>
                                        {item.desktopNew || 0}
                                      </td>
                                      <td>
                                        {item.mobileNew || 0}
                                      </td>
                                      <td>
                                        {item.draugiemNew || 0}
                                      </td>
                                      <td>
                                        {item.facebookNew || 0}
                                      </td>
                                      <td>
                                        {item.inboxNew || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERACTIVETHEME && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="dark" stroke="red" />
                        <Line type="monotone" dataKey="light" stroke="#ff7300" />
                        <Line type="monotone" dataKey="normal" stroke="blue" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Dark Theme
                        </th>
                        <th>
                          Light Theme
                        </th>
                        <th>
                          Normal Theme
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (playerTheme, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {playerTheme.dark}
                              </td>
                              <td>
                                {playerTheme.light}
                              </td>
                              <td>
                                {playerTheme.normal}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.dark || 0}
                                      </td>
                                      <td>
                                        {item.light || 0}
                                      </td>
                                      <td>
                                        {item.normal || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERSPERSOUND && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="off" stroke="red" />
                        <Line type="monotone" dataKey="on" stroke="#ff7300" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Off
                        </th>
                        <th>
                          On
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (playerSound, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {playerSound.off}
                              </td>
                              <td>
                                {playerSound.on}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.off || 0}
                                      </td>
                                      <td>
                                        {item.on || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERSPERAUTOMERGEROOMS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="off" stroke="red" />
                        <Line type="monotone" dataKey="on" stroke="#ff7300" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Off
                        </th>
                        <th>
                          On
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (playerSound, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {playerSound.off}
                              </td>
                              <td>
                                {playerSound.on}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.off || 0}
                                      </td>
                                      <td>
                                        {item.on || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERSFULLSCREEN && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="total" stroke="red" />
                        <Line type="monotone" dataKey="dWeb (old)" stroke="blue" />
                        <Line type="monotone" dataKey="dWeb (new)" stroke="#000" />
                        <Line type="monotone" dataKey="iframe (old)" stroke="green" />
                        <Line type="monotone" dataKey="iframe (new)" stroke="blue" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          dWeb (old)
                        </th>
                        <th>
                          dWeb (new)
                        </th>
                        <th>
                          iframe (old)
                        </th>
                        <th>
                          iframe (new)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (fullScreen, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {fullScreen.total}
                              </td>
                              <td>
                                {fullScreen.oldDesign}
                              </td>
                              <td>
                                {fullScreen.newDesign}
                              </td>
                              <td>
                                {fullScreen.iframeOldDesign}
                              </td>
                              <td>
                                {fullScreen.iframeNewDesign}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total}
                                      </td>
                                      <td>
                                        {item['dWeb (old)'] || 0}
                                      </td>
                                      <td>
                                        {item['dWeb (new)'] || 0}
                                      </td>
                                      <td>
                                        {item['iframe (old)'] || 0}
                                      </td>
                                      <td>
                                        {item['iframe (new)'] || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.GAMETYPE && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="blue" />
                        <Line type="monotone" dataKey="P" stroke="red" />
                        <Line type="monotone" dataKey="PM" stroke="#ff7300" />
                        <Line type="monotone" dataKey="G" stroke="blue" />
                        <Line type="monotone" dataKey="MG" stroke="#ff7300" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          P
                        </th>
                        <th>
                          PM
                        </th>
                        <th>
                          G
                        </th>
                        <th>
                          MG
                          </th>
                          <th>
                            Student
                          </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.P}
                              </td>
                              <td>
                                {dayTotal.PM}
                              </td>
                              <td>
                                {dayTotal.G}
                              </td>
                              <td>
                                {dayTotal.MG}
                              </td>
                              <td>
                                {dayTotal.Student || 0}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {console.log('data', data)}
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total}
                                      </td>
                                      <td>
                                        {item.P}
                                      </td>
                                      <td>
                                        {item.PM}
                                      </td>
                                      <td>
                                        {item.G}
                                      </td>
                                      <td>
                                        {item.MG}
                                      </td>
                                      <td>
                                        {item.Student || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.ACTIVITY && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="blue" />
                        <Line type="monotone" dataKey="playersDay" stroke="red" />
                        <Line type="monotone" dataKey="playersWeek" stroke="#ff7300" />
                        <Line type="monotone" dataKey="playersMonth" stroke="blue" />
                        <Line type="monotone" dataKey="playersYear" stroke="#ff7300" />
                        <Line type="monotone" dataKey="playersYearOld" stroke="#ff7300" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Last 24 hours
                        </th>
                        <th>
                          Last 7 days
                        </th>
                        <th>
                          Last 30 days
                        </th>
                        <th>
                          Last Year
                        </th>
                        <th>
                          More than 1 year ago
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {(parseInt(dayTotal.playersYear || 0) + parseInt(dayTotal.playersYearOld || 0))}
                              </td>
                              <td>
                                {dayTotal.playersDay}
                              </td>
                              <td>
                                {dayTotal.playersWeek}
                              </td>
                              <td>
                                {dayTotal.playersMonth}
                              </td>
                              <td>
                                {dayTotal.playersYear}
                              </td>
                              <td>
                                {dayTotal.playersYearOld}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total}
                                      </td>
                                      <td>
                                        {item.playersDay}
                                      </td>
                                      <td>
                                        {item.playersWeek}
                                      </td>
                                      <td>
                                        {item.playersMonth}
                                      </td>
                                      <td>
                                        {item.playersYear}
                                      </td>
                                      <td>
                                        {item.playersYearOld}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.GAMESPEED && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="total" stroke="blue" />
                        <Line type="monotone" dataKey="unlimited" stroke="blue" />
                        <Line type="monotone" dataKey="slow" stroke="#ff7300" />
                        <Line type="monotone" dataKey="fast" stroke="red" />
                        <Line type="monotone" dataKey="lightning" stroke="#ff7300" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Unlimited
                        </th>
                        <th>
                          Slow (25 sec)
                        </th>
                        <th>
                          Fast (15 sec)
                        </th>
                        <th>
                          Lightning (5 sec)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.unlimited}
                              </td>
                              <td>
                                {dayTotal.slow}
                              </td>
                              <td>
                                {dayTotal.fast}
                              </td>
                              <td>
                                {dayTotal.lightning}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total}
                                      </td>
                                      <td>
                                        {item.unlimited}
                                      </td>
                                      <td>
                                        {item.slow}
                                      </td>
                                      <td>
                                        {item.fast}
                                      </td>
                                      <td>
                                        {item.lightning}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.COINPERCHANGES && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Line type="monotone" dataKey="lost" stroke="red" />
                        <Line type="monotone" dataKey="won" stroke="blue" />
                        <Line type="monotone" dataKey="purchased" stroke="#006e04" />
                        <Line type="monotone" dataKey="admin" stroke="#ff7300" />
                        <Line type="monotone" dataKey="resetBalance" stroke="#ffc900" />
                        <Line type="monotone" dataKey="missedTurn" stroke="#ff7300" />
                        <Line type="monotone" dataKey="buyGift" stroke="blue" />
                        <Line type="monotone" dataKey="removeGift" stroke="#006e04" />
                        <Line type="monotone" dataKey="profanity" stroke="#8cff00" />
                        <Line type="monotone" dataKey="dailyBonus" stroke="blue" />
                        <Line type="monotone" dataKey="roomFee" stroke="#006e04" />
                        <Line type="monotone" dataKey="tournamentEntryFee" stroke="#8cff00" />
                        <Line type="monotone" dataKey="friendInviteBonus" stroke="blue" />
                        <Line type="monotone" dataKey="claimedBonus" stroke="#006e04" />
                        <Line type="monotone" dataKey="claimedGiftCode" stroke="#8cff00" />
                        <Line type="monotone" dataKey="claimedAchievement" stroke="blue" />
                        <Line type="monotone" dataKey="emailBonus" stroke="#006e04" />
                        <Line type="monotone" dataKey="claimedOSBonus" stroke="#8cff00" />
                        <Line type="monotone" dataKey="startBonus" stroke="#blue" />
                        <Line type="monotone" dataKey="deletedProfile" stroke="#006e04" />
                      </LineChart>
                    </div>
                  )}
                  <div className="admin-statistics-table-scrol-wrapper" style={{ maxWidth: '97vw', overflow: 'scroll' }} >
                    <table className="admin-statistics-table admin-statistics-table-coins-per-change">
                      <thead>
                        <tr>
                          <th colspan="1">
                            Date
                          </th>
                          <th colspan="4">
                            Room
                          </th>
                          <th colspan="4">
                            Penalty
                          </th>
                          <th colspan="3">
                            Compensation
                          </th>
                          <th colspan="3">
                            Admin
                          </th>
                          <th colspan="2">
                            Lucky Wheel
                          </th>
                          <th colspan="2">
                            Tournament
                          </th>
                          <th colspan="4">
                            Shop
                          </th>
                          <th colspan="2">
                            Gift
                          </th>
                          <th colspan="8">
                            Other
                          </th>
                          <th colspan="1">
                            Total
                          </th>

                        </tr>
                        <tr className="admin-statistics-table-subheaders">
                          <th>
                          </th>
                          <th>
                            Fee
                          </th>
                          <th>
                            Lost
                          </th>
                          <th>
                            Won
                          </th>
                          <th>
                            Compensation
                          </th>

                          <th>
                            Missed move
                          </th>
                          <th>
                            Min games
                          </th>
                          <th>
                            Left room
                          </th>
                          <th>
                            Profanity
                          </th>

                          <th>
                            Missed move
                          </th>
                          <th>
                            Min games
                          </th>
                          <th>
                            Left room
                          </th>

                          <th>
                            Added
                          </th>
                          <th>
                            Deducted
                          </th>
                          <th>
                            Bots top up
                          </th>

                          <th>
                            Positive
                          </th>
                          <th>
                            Negative
                          </th>

                          <th>
                            Fee
                          </th>
                          <th>
                            Win
                          </th>

                          <th>
                            Purchases
                          </th>
                          <th>
                            Reset balance
                          </th>
                          <th>
                            Reset Profile
                          </th>
                          <th>
                            Coupon
                          </th>

                          <th>
                            Buy
                          </th>
                          <th>
                            Remove
                          </th>

                          <th>
                            Achievements
                          </th>
                          <th>
                            Tutorial
                          </th>
                          <th>
                            App (iOS)
                          </th>
                          <th>
                            App (Android)
                          </th>
                          <th>
                            Email
                          </th>
                          <th>
                            Holidays
                          </th>
                          <th>
                            Start bonus
                          </th>
                          <th>
                            Deleted profiles
                            </th>
                            <th>
                              Total
                            </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dayTotals && map(dayTotals, (dayTotal, key) => {
                          return (
                            <Fragment>
                              <tr key={key} onClick={() => this.openSubTable(key)}>
                                {this.coinPerChangeRow(key, dayTotal)}
                                {/*<td>
                                  {key}
                                </td>
                                <td>
                                  {-dayTotal.roomFee}
                                </td>
                                <td>
                                  {-dayTotal.lost}
                                </td>
                                <td>
                                  {`+${dayTotal.won}`}
                                </td>
                                <td>
                                  {`+${dayTotal.autoCompensation}`}
                                </td>

                                <td>
                                  {-dayTotal.missedTurn}
                                </td>
                                <td>
                                  {-dayTotal.minGamesPenalty}
                                </td>
                                <td>
                                  {-dayTotal.leftRoomPenalty}
                                </td>
                                <td>
                                  {-dayTotal.profanity}
                                </td>

                                <td>
                                  {`+${dayTotal.missedTurnReward}`}
                                </td>
                                <td>
                                  {`+${dayTotal.minGamesReward}`}
                                </td>
                                <td>
                                  {`+${dayTotal.leftRoomReward}`}
                                </td>

                                <td>
                                  {`+${dayTotal.adminAdded}`}
                                </td>
                                <td>
                                  {-dayTotal.adminRemoved}
                                </td>
                                <td>
                                  {`+${dayTotal.botsTopUp}`}
                                </td>
                                <td>
                                  {`+${dayTotal.dailyBonusAdded}`}
                                </td>
                                <td>
                                  {-dayTotal.dailyBonusRemoved}
                                </td>

                                <td>
                                  {-dayTotal.tournamentEntryFee}
                                </td>
                                <td>
                                  {`+${dayTotal.tournamentWon}`}
                                </td>

                                <td>
                                  {`+${dayTotal.purchased}`}
                                </td>
                                <td>
                                  {`+${dayTotal.resetBalance}`}
                                </td>
                                <td>
                                  {`+${dayTotal.resetProfile}`}
                                </td>
                                <td>
                                  {`+${dayTotal.coupon}`}
                                </td>

                                <td>
                                  {-dayTotal.buyGift}
                                </td>
                                <td>
                                  {-dayTotal.removeGift}
                                </td>

                                <td>
                                  {`+${dayTotal.claimedAchievement}`}
                                </td>
                                <td>
                                  {`+${dayTotal.claimedTutorialBonus}`}
                                </td>
                                <td>
                                  {`+${dayTotal.claimediOSBonus}`}
                                </td>
                                <td>
                                  {`+${dayTotal.claimedAndroidBonus}`}
                                </td>
                                <td>
                                  {`+${dayTotal.emailBonus}`}
                                </td>
                                <td>
                                  {`+${dayTotal.claimedBonus}`}
                                </td>
                                <td>
                                  {`+${dayTotal.startBonus}`}
                                </td>
                                <td>
                                  {`+${dayTotal.deletedProfile}`}
                                </td> */}
                              </tr>
                              {(selectedDay && selectedDay === key) && (
                                <Fragment>
                                  {data && size(data) > 0 && map(data, (item, index) => {
                                    if (item.day !== selectedDay) {
                                      return null;
                                    }
                                    return (
                                      <tr key={item.key}>
                                        {this.coinPerChangeRow(item.hour, item)}
                                        {/*<td>
                                          {item.hour}
                                        </td>
                                        <td>
                                          {-item.roomFee}
                                        </td>
                                        <td>
                                          {-item.lost}
                                        </td>
                                        <td>
                                          {`+${item.won}`}
                                        </td>
                                        <td>
                                          {`+${item.autoCompensation}`}
                                        </td>

                                        <td>
                                          {-item.missedTurn}
                                        </td>
                                        <td>
                                          {-item.minGamesPenalty}
                                        </td>
                                        <td>
                                          {-item.leftRoomPenalty}
                                        </td>
                                        <td>
                                          {-item.profanity}
                                        </td>

                                        <td>
                                          {`+${item.missedTurnReward}`}
                                        </td>
                                        <td>
                                          {`+${item.minGamesReward}`}
                                        </td>
                                        <td>
                                          {`+${item.leftRoomReward}`}
                                        </td>

                                        <td>
                                          {`+${item.adminAdded}`}
                                        </td>
                                        <td>
                                          {-item.adminRemoved}
                                        </td>
                                        <td>
                                          {`+${item.botsTopUp}`}
                                        </td>

                                        <td>
                                          {`+${item.dailyBonusAdded}`}
                                        </td>
                                        <td>
                                          {-item.dailyBonusRemoved}
                                        </td>

                                        <td>
                                          {-item.tournamentEntryFee}
                                        </td>
                                        <td>
                                          {`+${item.tournamentWon}`}
                                        </td>

                                        <td>
                                          {`+${item.purchased}`}
                                        </td>
                                        <td>
                                          {`+${item.resetBalance}`}
                                        </td>
                                        <td>
                                          {`+${item.resetProfile}`}
                                        </td>
                                        <td>
                                          {`+${item.coupon}`}
                                        </td>

                                        <td>
                                          {-item.buyGift}
                                        </td>
                                        <td>
                                          {-item.removeGift}
                                        </td>

                                        <td>
                                          {`+${item.claimedAchievement}`}
                                        </td>
                                        <td>
                                          {`+${item.claimedTutorialBonus}`}
                                        </td>
                                        <td>
                                          {`+${item.claimediOSBonus}`}
                                        </td>
                                        <td>
                                          {`+${item.claimedAndroidBonus}`}
                                        </td>
                                        <td>
                                          {`+${item.emailBonus}`}
                                        </td>
                                        <td>
                                          {`+${item.claimedBonus}`}
                                        </td>
                                        <td>
                                          {`+${item.startBonus}`}
                                        </td>
                                        <td>
                                          {`+${item.deletedProfile}`}
                                        </td> */}
                                      </tr>
                                    );
                                  })}
                                </Fragment>
                              )}
                            </Fragment>
                          );
                        })}
                        {monthTotals && Object.keys(monthTotals).length ? (
                            <tr key={'totals'}>
                              {this.coinPerChangeRow('Total', monthTotals)}
                              {/* <td>
                              Total
                            </td>
                            <td>
                              {-monthTotals.roomFee}
                            </td>
                            <td>
                              {-monthTotals.lost}
                            </td>
                            <td>
                              {`+${monthTotals.won}`}
                            </td>
                            <td>
                              {`+${monthTotals.autoCompensation}`}
                            </td>

                            <td>
                              {-monthTotals.missedTurn}
                            </td>
                            <td>
                              {-monthTotals.minGamesPenalty}
                            </td>
                            <td>
                              {-monthTotals.leftRoomPenalty}
                            </td>
                            <td>
                              {-monthTotals.profanity}
                            </td>

                            <td>
                              {`+${monthTotals.missedTurnReward}`}
                            </td>
                            <td>
                              {`+${monthTotals.minGamesReward}`}
                            </td>
                            <td>
                              {`+${monthTotals.leftRoomReward}`}
                            </td>

                            <td>
                              {`+${monthTotals.adminAdded}`}
                            </td>
                            <td>
                              {-monthTotals.adminRemoved}
                            </td>
                            <td>
                              {`+${monthTotals.botsTopUp}`}
                            </td>

                            <td>
                              {`+${monthTotals.dailyBonusAdded}`}
                            </td>
                            <td>
                              {-monthTotals.dailyBonusRemoved}
                            </td>

                            <td>
                              {-monthTotals.tournamentEntryFee}
                            </td>
                            <td>
                              {`+${monthTotals.tournamentWon}`}
                            </td>

                            <td>
                              {`+${monthTotals.purchased}`}
                            </td>
                            <td>
                              {`+${monthTotals.resetBalance}`}
                            </td>
                            <td>
                              {`+${monthTotals.resetProfile}`}
                            </td>
                            <td>
                              {`+${monthTotals.coupon}`}
                            </td>

                            <td>
                              {-monthTotals.buyGift}
                            </td>
                            <td>
                              {-monthTotals.removeGift}
                            </td>

                            <td>
                              {`+${monthTotals.claimedAchievement}`}
                            </td>
                            <td>
                              {`+${monthTotals.claimedTutorialBonus}`}
                            </td>
                            <td>
                              {`+${monthTotals.claimediOSBonus}`}
                            </td>
                            <td>
                              {`+${monthTotals.claimedAndroidBonus}`}
                            </td>
                            <td>
                              {`+${monthTotals.emailBonus}`}
                            </td>
                            <td>
                              {`+${monthTotals.claimedBonus}`}
                            </td>
                            <td>
                              {`+${monthTotals.startBonus}`}
                            </td>
                            <td>
                              {`+${monthTotals.deletedProfile}`}
                            </td> */}
                          </tr>
                        ) : (null)}
                      </tbody>
                    </table>
                  </div>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERSVIEWSPERSCREEN && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="createRoom" stroke="#303293" />
                        <Line type="monotone" dataKey="loading" stroke="#17a2b8" />
                        <Line type="monotone" dataKey="myInfo" stroke="#006e04" />
                        <Line type="monotone" dataKey="powerRankings" stroke="blue" />
                        <Line type="monotone" dataKey="rooms" stroke="#ff7300" />
                        <Line type="monotone" dataKey="top" stroke="#6610f2" />
                        <Line type="monotone" dataKey="tournaments" stroke="#ffc107" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Create Room
                        </th>
                        <th>
                          Loading
                        </th>
                        <th>
                          My Info
                        </th>
                        <th>
                          Power Rankings
                        </th>
                        <th>
                          Rooms
                        </th>
                        <th>
                          Top
                        </th>
                        <th>
                          Tournaments
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.createRoom}
                              </td>
                              <td>
                                {dayTotal.loading}
                              </td>
                              <td>
                                {dayTotal.myInfo}
                              </td>
                              <td>
                                {dayTotal.powerRankings}
                              </td>
                              <td>
                                {dayTotal.rooms}
                              </td>
                              <td>
                                {dayTotal.top}
                              </td>
                              <td>
                                {dayTotal.tournaments}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.createRoom || 0}
                                      </td>
                                      <td>
                                        {item.loading || 0}
                                      </td>
                                      <td>
                                        {item.myInfo || 0}
                                      </td>
                                      <td>
                                        {item.powerRankings || 0}
                                      </td>
                                      <td>
                                        {item.rooms || 0}
                                      </td>
                                      <td>
                                        {item.top || 0}
                                      </td>
                                      <td>
                                        {item.tournaments || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.PLAYERSPERBROWSER && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey='total' stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
                        {
                          browserKeys && map(browserKeys, (item) => {
                            return (
                              <Line type="monotone" dataKey={item} stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
                            )
                          })
                        }
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        {
                          browserKeys && map(browserKeys, (item) => {
                            return (
                              <th>
                                {item}
                              </th>
                            )
                          })
                        }
                        <th>
                          Others
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (playerBrowser, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {playerBrowser.total}
                              </td>
                              {
                                browserKeys && map(browserKeys, (item) => {
                                  return (
                                    <td
                                      id={`admin-statistics-browser-version-popover-${key}-${item.toLowerCase().replace(/\s/g, '')}`}
                                      onMouseEnter={() => this.onHover(key, item)}
                                      onMouseLeave={() => this.onHoverLeave()}
                                    >
                                      {playerBrowser[item] || 0}
                                    </td>
                                  )
                                })
                              }
                              <td>
                                {playerBrowser.others}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total}
                                      </td>
                                      {
                                        browserKeys && map(browserKeys, (bIdx) => {
                                          return (
                                            <td>
                                              {item[bIdx] || 0}
                                            </td>
                                          )
                                        })
                                      }
                                      <td>
                                        {item.others}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && (id === statisticsConst.PLAYERSPERSCREENSIZE || id === statisticsConst.PLAYERSPERSCREENSIZEFOREACHDESIGN || id === statisticsConst.PLAYERSPERSCREENSIZEFOREACHSCREENMODE) && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey='total' stroke="yellow" />
                        <Line type="monotone" dataKey="desktop" stroke="blue" />
                        <Line type="monotone" dataKey="draugiem" stroke="#ff7300" />
                        <Line type="monotone" dataKey="facebook" stroke="#ffc900" />
                        <Line type="monotone" dataKey="inbox" stroke="red" />
                        <Line type="monotone" dataKey="mobile" stroke="purple" />
                        {
                          browserKeys && map(browserKeys, (item) => {
                            return (
                              <Line type="monotone" dataKey={item} stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
                            )
                          })
                        }
                      </LineChart>
                    </div>
                  )}

                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Total
                        </th>
                        <th>
                          Desktop
                        </th>
                        <th>
                          Draugiem
                        </th>
                        <th>
                          Facebook
                        </th>
                        <th>
                          Inbox
                        </th>
                        <th>
                          Mobile
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {dayTotal.total}
                              </td>
                              <td>
                                {dayTotal.desktop}
                              </td>
                              <td>
                                {dayTotal.draugiem}
                              </td>
                              <td>
                                {dayTotal.facebook}
                              </td>
                              <td>
                                {dayTotal.inbox}
                              </td>
                              <td>
                                {dayTotal.mobile}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.total || 0}
                                      </td>
                                      <td>
                                        {item.desktop || 0}
                                      </td>
                                      <td>
                                        {item.draugiem || 0}
                                      </td>
                                      <td>
                                        {item.facebook || 0}
                                      </td>
                                      <td>
                                        {item.inbox || 0}
                                      </td>
                                      <td>
                                        {item.mobile || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {data && id === statisticsConst.ONELINEPLAYERS && (
                <Fragment>
                  {openChart && (
                    <div className='d-flex w-full justify-center'>
                      <LineChart
                        width={900}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                      >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="players" stroke="red" />
                        <Line type="monotone" dataKey="rooms" stroke="#ff7300" />
                      </LineChart>
                    </div>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th>
                          Online Players
                        </th>
                        <th>
                          Online Rooms
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (online, key) => {
                        const nowDate = moment(new Date()).format(constants.FORMAT_DATE_TYPE.fullMonthYear);

                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              <td>
                                {nowDate.dayString === key ? (
                                  Math.floor(online.players / ((parseInt(online.lastTime) * 4) + this.getCurrentMinuteQuarter()), 2)
                                ) : (
                                  Math.floor(online.players / ((parseInt(online.lastTime) + 1) * 4), 2)
                                )}
                              </td>
                              <td>
                                {nowDate.dayString === key ? (
                                  Math.floor(online.rooms / ((parseInt(online.lastTime) * 4) + this.getCurrentMinuteQuarter()), 2)
                                ) : (
                                  Math.floor(online.rooms / ((parseInt(online.lastTime) + 1) * 4), 2)
                                )}
                              </td>
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {data && size(data) > 0 && map(data, (item, index) => {
                                  if (item.day !== selectedDay) {
                                    return null;
                                  }
                                  return (
                                    <tr key={item.key}>
                                      <td>
                                        {item.hour}
                                      </td>
                                      <td>
                                        {item.players || 0}
                                      </td>
                                      <td>
                                        {item.rooms || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Fragment>
              )}
              {(id === statisticsConst.GIFTSHISTORY) && (
                <Fragment>
                  {openChart && (
                    <>
                      <div className='d-flex w-full justify-center'>
                        <LineChart
                          width={900}
                          height={400}
                          data={selectedGiftType?.value === 'send' ? giftsSendData : giftsRemoveData}
                          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        >
                          <XAxis dataKey="key" />
                          <YAxis />
                          <Tooltip />
                          <CartesianGrid stroke="#f5f5f5" />
                          {statisticsConst.GIFTS_DATA.map(item => {
                              return (
                                <Line type="monotone" dataKey={filter(allGifts, (gift) => gift.name === item.value)[0] ? item.label : ''} stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
                              )
                            })
                          }
                        </LineChart>
                      </div>
                    </>
                  )}
                  <table className="admin-statistics-table">
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        {statisticsConst.GIFTS_DATA.map(item => (
                          <>
                            <th className='text-center'>
                              <header style={{textTransform: 'uppercase'}}>{filter(allGifts, (gift) => gift.name === item.value)[0] ? item.label : ''}</header>
                              <div className="d-flex flex-row space-between">
                                <div className='mr-2'>Buy</div>
                                <div>Remove</div>
                              </div>
                            </th>
                          </>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dayTotals && map(dayTotals, (dayTotal, key) => {
                        return (
                          <Fragment>
                            <tr key={key} onClick={() => this.openSubTable(key)}>
                              <td>
                                {key}
                              </td>
                              {statisticsConst.GIFTS_DATA.map(item => (
                                <td className='text-center'>
                                  <div className='d-flex flex-row space-between'>
                                    <div className='mr-2'>
                                      {filter(allGifts, (gift, giftKey) => giftKey === item.value)[0] ? (dayTotal[item.value]?.send || 0) : 0}
                                    </div>
                                    <div>
                                      {filter(allGifts, (gift, giftKey) => giftKey === item.value)[0] ? (dayTotal[item.value]?.remove || 0) : 0}
                                    </div>
                                  </div>
                                </td>
                              ))}
                            </tr>
                            {(selectedDay && selectedDay === key) ? (
                              <Fragment>
                                {(size(giftsSendData) > 0 || size(giftsRemoveData) > 0) && map(statisticsConst.hours, (item, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      {item}
                                    </td>
                                    {allGifts && map(statisticsConst.GIFTS_DATA, (giftItem, giftKey) => {
                                      const sendData = filter(giftsSendData, (sendItem) => sendItem.hour === item && sendItem.day === selectedDay)[0];
                                      const removeData = filter(giftsRemoveData, (sendItem) => sendItem.hour === item && sendItem.day === selectedDay)[0];
                                      const giftLabel = filter(allGifts, (gift) => gift.name === giftItem.value)[0] ? giftItem.label : '';

                                      return (
                                        <td>
                                          <div className='d-flex flex-row space-between'>
                                            <div className='mr-2'>{sendData ? (sendData[giftLabel] || 0) : 0}</div>
                                            <div>{removeData ? (removeData[giftLabel] || 0) : 0}</div>
                                          </div>
                                        </td>
                                      )
                                    })}
                                  </tr>
                                ))}
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                      <Fragment>
                        <tr>
                          <td>
                            Total
                          </td>
                          {aggregated && statisticsConst.GIFTS_DATA.map(item => (
                            <td className='text-center'>
                              <div className='d-flex flex-row space-between'>
                                <div className='mr-2'>
                                  {filter(allGifts, (gift) => gift.name === item.value)[0] ? (aggregated[item.value]?.send || 0) : 0}
                                </div>
                                  {filter(allGifts, (gift) => gift.name === item.value)[0] ? (aggregated[item.value]?.remove || 0) : 0}
                              </div>
                            </td>
                          ))}
                        </tr>
                      </Fragment>
                    </tbody>
                  </table>
                </Fragment>
              )}

            </Fragment>
          )}
        </div>
        {
          popoverOpen && targetId && selectedDate && selectedBrowser && selectedBrowserVersion ? (
            <Popover container={'div > div'} popperClassName="last-round-popover statistics-browser-popver" placement="top" isOpen={popoverOpen} target={targetId}>
              <PopoverHeader style={{ backgroundColor: '#000' }}>
                {`${selectedDate}: ${selectedBrowser}`}
              </PopoverHeader>
              <PopoverBody className="last-round-popover-body">
                <div className='d-flex flex-row'>
                  {selectedBrowserVersion && map(selectedBrowserVersion, (item, key) => (
                    <div className='d-flex flex-column border-1' width={`${100 / size(selectedBrowserVersion)}%`}>
                      <div className='border-bottom-1 p-1 text-center'>{key}</div>
                      <div className='p-1 text-center'>{item}</div>
                    </div>
                  ))}
                </div>
              </PopoverBody>
            </Popover>
          ) : (null)
        }
      </Fragment>
    );
  }
}

export default withTranslation('admin')(AdminsList);
