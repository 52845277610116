import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import ScrollArea from 'react-scrollbar';
import { filter, map, size } from 'lodash';
import { Badge } from 'reactstrap';

import * as constants from '../../../../constants/constants';
import closeImg from '../../../../images/icons/close.png';
import ratingPoints from '../../../../images/icons/ratingPoints.svg';
import gamesPlayed from '../../../../images/icons/gamesPlayed.svg';
import zoleCoins from '../../../../images/icons/zoleCoins.svg';
import {
  ACHIEVEMENT_DATA,
  sendLanguage,
  BIG_SIZE_ACH,
} from '../../../../constants/constants';

import {
  getFriends,
  addFriend,
  removeFriend,
} from '../../../../actions/member';

const PlayerModal = ({ playerInfo, gifts, closePlayerModal, posClassName, mId, uid, addAFriend, removeAFriend, friends }) => {
  //  console.log(playerInfo.userAchievements, "user ach check");
  const { i18n, t } = useTranslation('playerInfo');
  const [loading, setLoading] = useState(false);
  // const findAchievements = (key, item, borders) => {
  //   //  console.log(key, item, borders, "find check ");
  //   let img;
  //   let imgKey;
  //   imgKey = key;
  //   if (key === 'highestEarned') {
  //     imgKey = 'balance';
  //   }
  //   if (size(borders) > 1) {
  //     map(borders, (border, index) => {
  //       if (border <= item) {
  //         if (borders[index + 1]) {
  //           // eslint-disable-next-line
  //           img = require(`../../../../images/Ach/${imgKey}_${
  //             borders[index]
  //           }_${borders[index + 1]}.svg`);
  //         } else {
  //           // eslint-disable-next-line
  //           img = require(`../../../../images/Ach/${imgKey}_${borders[index]}.svg`);
  //         }
  //       }
  //     });
  //   } else {
  //     img = require(`../../../../images/Ach/${imgKey}_1.svg`);
  //   }
  //   return (
  //     <Col className="player-info-gifts-img">
  //       {/* {playerInfo.giftsHistory[key] && ( */}
  //       <img
  //         // id={`giftsHistory-${key}`}
  //         className="giftsHistory"
  //         src={img}
  //         alt="X"
  //       />
  //       {/* )} */}
  //     </Col>
  //   );
  // };

  const generateImageUrl = (key, borders, index) => {
    let img = '';
    let imgkey = '';
    imgkey = key;
    if (key === 'highestEarned') {
      imgkey = 'balance';
    }
    if (size(borders) > 1) {
      if (borders[index + 1]) {
        // eslint-disable-next-line
        img = require(`../../../../images/Ach/${imgkey}_${borders[index]}_${borders[index + 1]
        }.png`);
      } else {
        // eslint-disable-next-line
        img = require(`../../../../images/Ach/${imgkey}_${borders[index]}.png`);
      }
    } else {
      // eslint-disable-next-line import/no-dynamic-require
      if (BIG_SIZE_ACH.includes(imgkey)) {
        img = require(`../../../../images/Ach/${imgkey}_1.svg`);
      } else {
        img = require(`../../../../images/Ach/${imgkey}_1.png`);
      }
    }
    
    return img;
  };

  const generaetImageUrlData = (key, borders, index) => {
    let name = '';
    let firstName = '';
    let thirdName = '';
    firstName = t(ACHIEVEMENT_DATA[key]?.firstName) ?? '';
    name = t(ACHIEVEMENT_DATA[key]?.name) ?? '';
    thirdName = t(ACHIEVEMENT_DATA[key]?.thirdName ?? name);
    const noCounter = [
      'gamesPlayed',
      'gamesWon',
      'fastGamesPlayed',
      'lightningGamesPlayed',
      'unlimitedGamesPlayed',
      'maxDailyLogin',
      'reachedTournamentTop10',
      'fastGamesPlayed',
    ];

    let img;
    let imgKey = key;
    if (key === 'highestEarned') {
      imgKey = 'balance';
    }

    if (size(borders) > 1) {
      if (borders[index + 1]) {
        // eslint-disable-next-line
        img = require(`../../../../images/Ach/${imgKey}_${borders[index]}_${borders[index + 1]
        }.png`);
      } else {
        // eslint-disable-next-line
        img = require(`../../../../images/Ach/${imgKey}_${borders[index]}.png`);
      }
    } else {
      if (BIG_SIZE_ACH.includes(imgKey)) {
        img = require(`../../../../images/Ach/${imgKey}_1.svg`);
      } else {
        img = require(`../../../../images/Ach/${imgKey}_1.png`);
      }
      // eslint-disable-next-line import/no-dynamic-require
    }
    
    const val = borders[index] >= 1000 ? `${borders[index] / 1000}k` : borders[index];
    if (
      val === constants.ACHIEVEMENT_THIRD_VALUE
      && i18n.language === sendLanguage.ru
    ) {
      name = thirdName || name;
    } else if (
      val === constants.ACHIEVEMENT_SECOND_VALUE
      && key === 'storePurchase'
    ) {
      if (i18n.language === sendLanguage.ru) {
        name = thirdName || name;
      }
    } else if (
      val === constants.ACHIEVEMENT_SECOND_VALUE
      && key === 'supportMessagesSent'
    ) {
      if (i18n.language === sendLanguage.ru) {
        name = thirdName || name;
      }
    }
    
    return (
      <div id={`achievements-${key}-${borders[index]}`}>
        <img className="giftsHistory" src={img} alt="X" />

        <UncontrolledTooltip
          className="giftsHistory-tooltip player-receivedGift-tooltip-old"
          placement="bottom"
          target={`achievements-${key}-${borders[index]}`}
          container={'div > div > div > div'}
        >
          <div className="giftsHistory-tooltip-from">
            {key === 'fastGamesPlayed'
              || key === 'gamesPlayed'
              || key === 'lightningGamesPlayed'
              || key === 'unlimitedGamesPlayed'
              || key === 'maxDailyLogin'
              || key === 'reachedTournamentTop10'
              || key === 'gamesWon' ? (
                index === 0 && firstName ? (
                  <span>{`${firstName}!`}</span>
                ) : (
                  <span>
                    {`${noCounter.includes(key) ? val : ''} ${index === 0 && firstName ? firstName : name
                    }!`}
                  </span>
                )
              ) : (
              // eslint-disable-next-line max-len
                <span>
                  {`${noCounter.includes(key) ? '' : val} ${index === 0 && firstName
                    ? key === 'maxSuccessionWins'
                      && i18n.language === sendLanguage.ru
                      ? t('3maxSuccessionWins')
                      : firstName
                    : key === 'earnedInADay'
                      && (i18n.language === sendLanguage.ru
                      || i18n.language === sendLanguage.en)
                      ? name.toLocaleLowerCase()
                      : name
                  }!`}
                </span>
              )}
          </div>
        </UncontrolledTooltip>
      </div>
    );
  };

  const handleSendAddFriend = (selectId) => {
    setLoading(true);
    addAFriend(selectId).then((res) => {
      setLoading(false);
    });
  }

  const handleRemoveFriend = (selectId) => {
    console.log("sent before")
    setLoading(true);
    removeAFriend(selectId).then((res) => {
      setLoading(false);
    });
  }

  return (
    <>
      <div
        className="player-info-modal-backdrop"
        onClick={closePlayerModal}
      />
      <div
        className={`player-info-modal player-info-modal-${posClassName}`}
        toggle={closePlayerModal}
        backdropClassName="player-info-modal-backdrop"
      >
        <div className="player-info-header d-flex justify-between align-center">
          {/* <Media src={infoImg} className="player-info-header-img" /> */}
          <div className="player-info-text player-info-text-header d-flex align-items-center">
            <span className="mr-2">
              {t('info')}
            </span>
            {
              mId && mId !== uid && (
                <div className="player-info-new-player-name-wrapper-friend">
                  {
                    size(friends) > 0 && size(filter(friends, (item, key) => { return item.uid === uid; })) > 0 ? (
                      <>
                        <Badge color="success">{t('friend')}</Badge>
                        <Button className="player-info-button btn btn-link" onClick={() => handleRemoveFriend(uid)}>
                          {t('common:top.removeFriend')}
                        </Button>
                      </>
                    ) : (
                      <Button className="player-info-button btn btn-link" onClick={() => handleSendAddFriend(uid)}>
                        {t('addAsFriend')}
                      </Button>
                    )
                  }
                </div>
              )
            }
          </div>
          <Media
            src={closeImg}
            className="player-info-header-close"
            alt="X"
            onClick={closePlayerModal}
          />
        </div>
        <div className="player-info-body">
          <div className="player-info-row">
            <div className="player-info-level">
              <div className="player-info-level-text">
                {playerInfo.lvl || 0}
              </div>
            </div>
            <div className="player-info-text">{playerInfo.name || ''}</div>
          </div>
          <div className="player-info-row player-info-row-flex">
            <Media
              src={ratingPoints}
              className="player-info-img player-info-img-flex"
            />
            <div sm="7" className="player-info-text player-info-text-flex">
              {`${t('ratingPoints')}:`}
            </div>
            <div sm="3" className="player-info-points-flex">
              {playerInfo.ratingPoints || 0}
            </div>
          </div>
          <div className="player-info-row player-info-row-flex">
            <Media
              src={ratingPoints}
              className="player-info-img player-info-img-flex"
            />
            <div sm="7" className="player-info-text player-info-text-flex">
              {`${t('scorePointsAndPlaceInHighscore')}:`}
            </div>
            <div sm="3" className="player-info-points-flex">
              {`${playerInfo.scoreTotalPnts || 0} / ${playerInfo.scorePointPos || 0
              }`}
            </div>
          </div>
          <div className="player-info-row player-info-row-flex">
            <Media
              src={zoleCoins}
              className="player-info-img player-info-img-flex"
            />
            <div sm="7" className="player-info-text player-info-text-flex">
              {`${t('coinsAndPlaceInHighscore')}:`}
            </div>
            <div sm="3" className="player-info-points-flex">
              {`${playerInfo.coinsBal || 0} / ${playerInfo.coinsbalPos || 0}`}
            </div>
          </div>
          <div className="player-info-row player-info-row-flex">
            <Media
              src={gamesPlayed}
              className="player-info-img player-info-img-flex"
            />
            <div className="player-info-text player-info-text-flex">
              {`${t('gamesPlayedAndPlaceInHighscore')}:`}
            </div>
            <div sm="3" className="player-info-points-flex">
              {`${playerInfo.gamesGplayed || 0} / ${playerInfo.gamesGplayedPos || 0
              }`}
            </div>
          </div>
          <div className="player-info-row player-info-row-flex player-info-row-no-border">
            {/* <Media src={giftsImg} className="player-info-img player-info-img-gifts player-info-img-flex player-info-img-gifts-flex" /> */}
            <div className="player-info-text player-info-text-gifts-flex">
              {`${t('trophy')}:`}
            </div>
          </div>
          <ScrollArea
            speed={0.8}
            className="player-modal-giftsHistory"
            contentClassName="player-modal-gifts-img"
            smoothScrolling
            horizontal
            horizontalContainerStyle={{
              background: 'transparent',
              opacity: 1,
              width: 10,
              height: 15,
              marginRight: 2,
            }}
            horizontalScrollbarStyle={{
              background: '#427C78',
              borderRadius: 1,
              width: 10,
              height: 15,
              marginTop: '6px',
            }}
            vertical={false}
            minScrollSize={25}
          >
            <>
              {
                playerInfo
                  && playerInfo.userAchievements
                  && map(
                    playerInfo.userAchievements,
                    (item, key) => constants.ACHIEVEMENT_DATA[key]
                      // findAchievements(key, item, constants.ACHIEVEMENT_DATA[key].borders)
                      && (size(constants.ACHIEVEMENT_DATA[key].borders) > 1 ? (
                        map(
                          constants.ACHIEVEMENT_DATA[key].borders,
                          (border, index) => border <= item
                            && (constants.ACHIEVEMENT_DATA[key].borders[
                              index + 1
                            ] ? (
                              // eslint-disable-next-line
                              <Col className="player-info-gifts-img">
                                {generaetImageUrlData(
                                  key,
                                  constants.ACHIEVEMENT_DATA[key].borders,
                                  index,
                                )}
                                {/* )} */}
                              </Col>
                              ) : (
                              // eslint-disable-next-line
                              <Col className="player-info-gifts-img">
                                {/* {playerInfo.giftsHistory[key] && ( */}
                                <div id={`achievements-${key}`}>
                                  <img
                                    className="giftsHistory"
                                    src={generateImageUrl(
                                      key,
                                      constants.ACHIEVEMENT_DATA[key].borders,
                                      index,
                                    )}
                                    alt="X"
                                  />
                                  <UncontrolledTooltip
                                    className="giftsHistory-tooltip player-receivedGift-tooltip-old"
                                    placement="bottom"
                                    target={`achievements-${key}`}
                                    container={'div > div > div > div'}
                                  >
                                    <div className="giftsHistory-tooltip-from">
                                      {t(key) || ''}
                                    </div>
                                  </UncontrolledTooltip>
                                </div>
                                {/* )} */}
                              </Col>
                              )),
                        )
                      ) : (
                        <Col
                          className={`player-info-gifts-img player-info-gifts-img-${key}`}
                        >
                          {/* {playerInfo.giftsHistory[key] && ( */}
                          <div id={`achievements-${key}`}>
                            <img
                              className="giftsHistory"
                              src={generateImageUrl(
                                key,
                                constants.ACHIEVEMENT_DATA[key].borders,
                              )}
                              alt="X"
                            />
                            <UncontrolledTooltip
                              className="giftsHistory-tooltip player-receivedGift-tooltip-old"
                              placement="bottom"
                              target={`achievements-${key}`}
                              container={'div > div > div > div'}
                            >
                              <div className="giftsHistory-tooltip-from">
                                {t(key) || ''}
                              </div>
                            </UncontrolledTooltip>
                          </div>
                          {/* )} */}
                        </Col>
                      )),
                  )
              }
            </>
            <>
              {
                constants.ACHIEVEMENT_DATA.gamesWon
                  // findAchievements(key, item, constants.ACHIEVEMENT_DATA[key].borders)
                  && (size(constants.ACHIEVEMENT_DATA.gamesWon.borders) > 1 ? (
                    map(
                      constants.ACHIEVEMENT_DATA.gamesWon.borders,
                      (border, index) => border <= playerInfo.gWon
                        && (constants.ACHIEVEMENT_DATA.gamesWon.borders[
                          index + 1
                        ] ? (
                          // eslint-disable-next-line
                          <Col className="player-info-gifts-img">
                            {generaetImageUrlData(
                              'gamesWon',
                              constants.ACHIEVEMENT_DATA.gamesWon.borders,
                              index,
                            )}
                            {/* )} */}
                          </Col>
                          ) : (
                          // eslint-disable-next-line
                          <Col className="player-info-gifts-img">
                            {/* {playerInfo.giftsHistory[key] && ( */}
                            <div id={`achievements-${'gamesWon'}`}>
                              <img
                                className="giftsHistory"
                                src={generateImageUrl(
                                  'gamesWon',
                                  constants.ACHIEVEMENT_DATA.gamesWon.borders,
                                  index,
                                )}
                                alt="X"
                              />
                              <UncontrolledTooltip
                                className="giftsHistory-tooltip player-receivedGift-tooltip-old"
                                placement="bottom"
                                target={`achievements-${'gamesWon'}`}
                                container={'div > div > div > div'}
                              >
                                <div className="giftsHistory-tooltip-from">
                                  {t('gamesWon') || ''}
                                </div>
                              </UncontrolledTooltip>
                            </div>
                            {/* )} */}
                          </Col>
                          )),
                    )
                  ) : (
                    <Col className="player-info-gifts-img">
                      {/* {playerInfo.giftsHistory[key] && ( */}
                      <div id={`achievements-${'gamesWon'}`}>
                        <img
                          className="giftsHistory"
                          src={generateImageUrl(
                            'gamesWon',
                            constants.ACHIEVEMENT_DATA.gamesWon.borders,
                          )}
                          alt="X"
                        />
                        <UncontrolledTooltip
                          className="giftsHistory-tooltip player-receivedGift-tooltip-old"
                          placement="bottom"
                          target={`achievements-${'gamesWon'}`}
                          container={'div > div > div > div'}
                        >
                          <div className="giftsHistory-tooltip-from">
                            {t('gamesWon') || ''}
                          </div>
                        </UncontrolledTooltip>
                      </div>
                      {/* )} */}
                    </Col>
                  ))
              }
            </>
            <>
              {
                playerInfo
                  && playerInfo.userAchievements
                  && map(
                    playerInfo.userAchievements,
                    (item, key) => (constants.UNIQUE_ACHIEVEMENT_DATA[key]
                      ? (
                        <Col
                          className={`player-info-gifts-img player-info-gifts-img-${key}`}
                        >
                          <div id={`achievements-${key}`}>
                            <img
                              className="giftsHistory"
                              src={generateImageUrl(
                                key,
                                [],
                              )}
                              alt="X"
                            />
                            <UncontrolledTooltip
                              className="giftsHistory-tooltip player-receivedGift-tooltip-old"
                              placement="bottom"
                              target={`achievements-${key}`}
                              container={'div > div > div > div'}
                            >
                              <div className="giftsHistory-tooltip-from">
                                {t(key) || ''}
                              </div>
                            </UncontrolledTooltip>
                          </div>
                        </Col>
                      ) : <></>),
                  )
              }
            </>
          </ScrollArea>
          <div className="player-info-row player-info-row-flex player-info-row-no-border">
            {/* <Media src={giftsImg} className="player-info-img player-info-img-gifts player-info-img-flex player-info-img-gifts-flex" /> */}
            <div className="player-info-text player-info-text-gifts-flex">
              {`${t('lastGifts')}:`}
            </div>
          </div>
          {/* `${key} - ${playerInfo.giftsHistory[key].giftId}` */}
          <ScrollArea
            speed={0.8}
            className="player-modal-giftsHistory"
            contentClassName="player-modal-gifts-img"
            smoothScrolling
            horizontal
            horizontalContainerStyle={{
              background: 'transparent',
              opacity: 1,
              width: 2,
              height: 15,
              marginRight: 2,
            }}
            horizontalScrollbarStyle={{
              background: '#427C78',
              borderRadius: 1,
              width: 10,
              height: 15,
              marginTop: '6px',
            }}
            vertical={false}
            minScrollSize={25}
          >
            {gifts
              && playerInfo.giftsHistory
              && Object.keys(playerInfo.giftsHistory).map(key => (
                <Col
                  key={`${key}`}
                  className="player-info-gifts-img player-info-gifts-img-lg"
                >
                  {playerInfo.giftsHistory[key] && (
                    <div
                      id={`giftsHistory-${key}`}
                      className="giftsHistory giftsHistory-lg"
                      style={{
                        backgroundImage: `url(${gifts[playerInfo.giftsHistory[key].giftId]
                          ? gifts[playerInfo.giftsHistory[key].giftId].image
                          : ''
                        })`,
                      }}
                    >
                      <>
                        <UncontrolledTooltip
                          className="giftsHistory-tooltip player-receivedGift-tooltip-old"
                          placement="bottom"
                          target={`giftsHistory-${key}`}
                          container={'div > div > div > div'}
                        >
                          <div className="giftsHistory-tooltip-from">
                            {playerInfo.giftsHistory[key].fromName || ''}
                          </div>
                          <div className="giftsHistory-tooltip-comment">
                            {playerInfo.giftsHistory[key].comment || ''}
                          </div>
                        </UncontrolledTooltip>
                      </>
                    </div>
                  )}
                </Col>
              ))}
          </ScrollArea>

        </div>
        <div className="player-info-footer">

          <Button
            type="button"
            className="btn notification-footer-button"
            onClick={closePlayerModal}
            style={{marginLeft: '5px'}}
          >
            {t('common:home.close')}
          </Button>
        </div>
      </div>
    </>
  );
};

PlayerModal.propTypes = {
  playerInfo: PropTypes.shape(),
  gifts: PropTypes.shape(),
  closePlayerModal: PropTypes.func.isRequired,
  //  modalOpen: PropTypes.bool,
  posClassName: PropTypes.string,
  //  toggleGiftsModal: PropTypes.func.isRequired,
  uid: PropTypes.string,
  //  ignoredUsers: PropTypes.shape(),
  //  unBlockUser: PropTypes.func.isRequired,
  //  toggleBlockUser: PropTypes.func.isRequired,
  mId: PropTypes.string,
  addAFriend: PropTypes.func.isRequired,
  friends: PropTypes.shape({}),
};

PlayerModal.defaultProps = {
  playerInfo: null,
  gifts: null,
  //  modalOpen: false,
  posClassName: null,
  uid: null,
  //  ignoredUsers: {},
  mId: '',
  friends: {},
};

const mapStateToProps = state => ({
  member: state.member || {},
  mId: state.member.uid,
  currentType: state.game.currentType,
  largePlayer: state.game.largePlayer,
  gameState: state.game.globalParams.gameState,
  sittingOut: state.game.sittingOut,
  friends: state.member.friends || {},
});

const mapDispatchToProps = {
  addAFriend: addFriend,
  removeAFriend: removeFriend,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('notifications')(PlayerModal));
