import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as constants from '../../../constants/constants';

let counter = 0;
let cardGap = 116 / 5;

class PlayerCard extends React.Component {
  static propTypes = {
    card: PropTypes.string,
    allowed: PropTypes.bool,
    gameState: PropTypes.string,
    playCard: PropTypes.func.isRequired,
    currentTurnUid: PropTypes.string,
    memberUid: PropTypes.string,
    selectedCard: PropTypes.string,
    doubleClickSelectedCard: PropTypes.string,
    isWebVersion: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    fourPRoom: PropTypes.bool,
    tableIsInProgress: PropTypes.bool.isRequired,
    cardsLength: PropTypes.number,
    index: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    hasPlayedCard: PropTypes.bool,
    hoveredCard: PropTypes.string,
    changeHoveredCard: PropTypes.func.isRequired,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    currentType: PropTypes.string,
    largePlayer: PropTypes.string,
    myPos: PropTypes.string,
    cardsOnLoading: PropTypes.bool,
    isInFacebookAppFrame: PropTypes.bool,
    isInAppFrame: PropTypes.bool,
  }

  static defaultProps = {
    card: null,
    allowed: false,
    gameState: null,
    currentTurnUid: null,
    memberUid: null,
    isWebVersion: false,
    isFullscreen: false,
    selectedCard: null,
    doubleClickSelectedCard: null,
    fourPRoom: false,
    cardsLength: 0,
    index: null,
    width: 0,
    height: 0,
    hasPlayedCard: null,
    hoveredCard: null,
    currentTable: {},
    cardsOnTable: [],
    currentType: null,
    largePlayer: null,
    myPos: null,
    cardsOnLoading: true,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  hasPlayedCard: false,
    };

  //  window.addEventListener('resize', this.handleResize);
  }

  render() {
    const {
      cardsLength,
      card,
      allowed,
      index,
      gameState,
      playCard,
      selectedCard,
      tableIsInProgress,
      memberUid,
      currentTurnUid,
      isFullscreen,
      isWebVersion,
      fourPRoom,
      doubleClickSelectedCard,
      width,
      height,
      hasPlayedCard,
      hoveredCard,
      changeHoveredCard,
      cardsOnTable,
      currentType,
      largePlayer,
      myPos,
      cardsOnLoading,
      isInFacebookAppFrame,
      isInAppFrame,
    } = this.props;

    counter += 1;
    let startIndex = 0;
    if (cardsLength <= 1) {
      startIndex = 5;
    } else if (cardsLength <= 3) {
      startIndex = 4;
    } else if (cardsLength <= 5) {
      startIndex = 3;
    } else if (cardsLength <= 7) {
      startIndex = 2;
    } else if (cardsLength <= 9) {
      startIndex = 1;
    }

    let hoveredClass = '';
    if (allowed && currentTurnUid === memberUid) {
      if (hoveredCard === index) {
        hoveredClass = 'hovered';
      } else if (hoveredCard === index + 1 || hoveredCard === index - 1) {
        hoveredClass = 'hoveredNeighbour';
      }
    }

    if (isFullscreen || (isWebVersion || isInAppFrame)) {
      let dynamicStyle = {};
      let dynamicStyleOverlay = {};

      if (width > height) {
        // LANDSCAPE MODE
        let cardWidth = width * 0.1;

        let cardHeight = cardWidth * 1.5;

        cardGap = cardWidth / 2;
        let cardHeightOffset = 0;

        if (cardGap < 45) {
          cardGap = 45;
        } else if (cardGap > 70) {
          cardGap = 70;
        }

        let dynamicHeight = false;

        cardHeightOffset = cardHeight - (cardHeight * 1.25);

        if (height < 650) {
          dynamicHeight = true;
        }

        if (isFullscreen && ((height < 650 && cardHeight > height * 0.08) || (height > 650 && height < 850 && cardHeight > height * 0.13))) {
          cardHeight = height * 0.08;

          if (height > 650) {
            cardHeight = height * 0.13;
          }

          if (cardHeight < 96) {
            cardHeight = 96;
          }
          cardWidth = cardHeight / 1.5;

          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);

          dynamicHeight = true;

        } else if (isFullscreen && height > 850 && height < 1000 && cardHeight > height * 0.13) {
          cardHeight = height * 0.13;

          if (cardHeight < 96) {
            cardHeight = 96;
          }
          cardWidth = cardHeight / 1.5;
          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);

          dynamicHeight = true;

        } else if (!isFullscreen && (isWebVersion || isInAppFrame) && height < 1000 && cardHeight > height * 0.13) {
          cardHeight = height * 0.18;

          if (cardHeight < 110) {
            cardHeight = 110;
          }
          cardWidth = cardHeight / 1.5;
          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);

          dynamicHeight = true;

        } else if (cardWidth < 116) {
          cardWidth = 116;
          cardHeight = 116 * 1.5;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);
        //  cardHeightOffset = 0;
        } else if (cardWidth > 150) {
          cardWidth = 150;
          cardHeight = 150 * 1.5;

          cardHeightOffset = cardHeight - (cardHeight * 1.25);
        }

        if (!dynamicHeight && cardHeightOffset < 65 && cardHeightOffset > 0) {
          // if small ofsset gradualy lower removed amount
          cardHeightOffset -= ((65 - cardHeightOffset) * 2);
        }

        if (hoveredClass === 'hovered') {
          cardHeightOffset += 34;
          if (fourPRoom) {
            cardWidth *= 0.80;
            cardHeight *= 0.80;
          }
        } else if (hoveredClass === 'hoveredNeighbour') {
          cardHeightOffset += 20;
          if (fourPRoom) {
            cardWidth *= 0.90;
            cardHeight *= 0.90;
          }
        }
        let left = 0;
        // let left = ((index + (startIndex / 2) - 1) * cardGap) + 23;

        // if (cardsLength <= 1) {
        //   left = ((index + 4) * cardGap) + 23;
        // } else if (cardsLength <= 3) {
        //   left = ((index + 3) * cardGap) + 23;
        // } else if (cardsLength <= 5) {
        //   left = ((index + 2) * cardGap) + 23;
        // } else if (cardsLength <= 7) {
        //   left = ((index + 1) * cardGap) + 23;
        // } else if (cardsLength <= 9) {
        //   left = ((index) * cardGap) + 23;
        // }

        // if (index + startIndex === 5) {
        //   left = cardGap / 2;
        // } else if (index + startIndex === 4) {
        //   left = -cardGap / 2;
        // } else if (index + startIndex > 5) {
        //   left = ((index + startIndex - 5) * cardGap) + cardGap / 2;
        // } else if (index + startIndex < 4) {
        //   left = ((index + startIndex - 4) * cardGap) - cardGap / 2;
        // }

        /*  let marginTop = 60 - cardHeightOffset;

        if (cardHeightOffset < 0) {
          marginTop = 60 + cardHeightOffset;
        }

        if (height < 640) {
          marginTop = -30;
        } else if (height < 700) {
          marginTop = -20;
        } else if (height < 760) {
          marginTop = -10;
        } else if (dynamicHeight && marginTop > 100) {
          marginTop = 80 - (marginTop * 0.1);
        } else if (dynamicHeight && marginTop > 50) {
          marginTop = 40 - (marginTop * 0.05);
        } else if (dynamicHeight && marginTop > 25) {
          marginTop = 20;
        } else if (dynamicHeight && marginTop > 20) {
          marginTop = 10;
        } */

        dynamicStyle = {
          left: (-(cardsLength / 2) - 0.5 + index) * cardWidth / 2,
          width: cardWidth,
          height: cardHeight,
          backgroundSize: `${cardWidth}px ${cardHeight}px`,

          transformOrigin: 'center center',
          transform: `rotate(${(index - ((cardsLength - 1) / 2)) * 4}deg)`,
          top: Math.abs((index - ((cardsLength - 1) / 2))) * Math.abs((index - ((cardsLength - 1) / 2)))  * 2 //+ cardHeight/2,

          //  width: cardWidth,
          //  height: cardHeight,
          //  backgroundSize: `${cardWidth}px ${cardHeight}px`,
        };

        dynamicStyleOverlay = {
          width: cardWidth,
          height: cardHeight,
          backgroundSize: `${cardWidth}px ${cardHeight}px`,
        }
        //  dynamicStyleOverlay = {
        //  width: cardWidth - 6,
        //  height: cardHeight - 6
        //  };
      } else {
        // PORTRAIT MODE

        let cardWidth = width * 0.1;
        let cardHeight = cardWidth * 1.5;

        cardGap = cardWidth / 2;
        let cardHeightOffset = 0;

        if (cardGap < 35) {
          cardGap = 35;
        } else if (cardGap > 60) {
          cardGap = 60;
        }

        let dynamicHeight = false;

        cardHeightOffset = cardHeight - (116 * 1.25);

        if (width < 800 && cardHeight > width * 0.13) {
          cardHeight = height * 0.15;
          cardWidth = cardHeight / 1.5;

          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (116 * 1.25);

          dynamicHeight = true;
        } else if (width < 1000 && cardHeight > width * 0.13) {
          cardHeight = height * 0.14;

          if (height > 900) {
            cardHeight = height * 0.17;
          }

          cardWidth = cardHeight / 1.5;

          cardGap = cardWidth / 2;

          cardHeightOffset = cardHeight - (116 * 1.25);

          dynamicHeight = true;
        } else if (cardWidth < 116) {
          cardWidth = 116;
          cardHeight = 116 * 1.5;
          cardHeightOffset = cardHeight - (116 * 1.25);
        } else if (cardWidth > 150) {
          cardWidth = 150;
          cardHeight = 150 * 1.5;
          cardHeightOffset = cardHeight - (116 * 1.25);
        }

        if (!dynamicHeight && cardHeightOffset < 65 && cardHeightOffset > 0) {
          // if small ofsset gradualy lower removed amount
          cardHeightOffset -= ((65 - cardHeightOffset) * 2);
        }

        if (hoveredClass === 'hovered') {
          cardHeightOffset += 34;
          if (fourPRoom) {
            cardWidth *= 0.80;
            cardHeight *= 0.80;
          }
        } else if (hoveredClass === 'hoveredNeighbour') {
          cardHeightOffset += 20;
          if (fourPRoom) {
            cardWidth *= 0.90;
            cardHeight *= 0.90;
          }
        }
        let left = 0;
        // let left = ((index + (startIndex / 2) - 1) * cardGap) + 23;

        // if (cardsLength <= 1) {
        //   left = ((index + 4) * cardGap) + 23;
        // } else if (cardsLength <= 3) {
        //   left = ((index + 3) * cardGap) + 23;
        // } else if (cardsLength <= 5) {
        //   left = ((index + 2) * cardGap) + 23;
        // } else if (cardsLength <= 7) {
        //   left = ((index + 1) * cardGap) + 23;
        // } else if (cardsLength <= 9) {
        //   left = ((index) * cardGap) + 23;
        // }

        // if (index + startIndex === 5) {
        //   left = cardGap / 2;
        // } else if (index + startIndex === 4) {
        //   left = -cardGap / 2;
        // } else if (index + startIndex > 5) {
        //   left = ((index + startIndex - 5) * cardGap) + cardGap / 2;
        // } else if (index + startIndex < 4) {
        //   left = ((index + startIndex - 4) * cardGap) - cardGap / 2;
        // }

        /*  let marginTop = 60 - cardHeightOffset;

        if (height < 640) {
          marginTop = -20;
          //    } else {
        } else if (height > 900) {
        //  marginTop = marginTop;

        } else if (dynamicHeight && marginTop > 100) {
          if (height < 700) {
            marginTop = 10 - (marginTop * 0.1);
          } else if (height < 840) {
            marginTop = 40;
          } else {
            marginTop = 60;
          }
          //  marginTop = 70 - (marginTop * 0.1);
        } else if (dynamicHeight && marginTop > 50) {
          marginTop = 37 - (marginTop * 0.05);
        } else if (dynamicHeight && marginTop > 25) {
          marginTop = 18;
        } else if (dynamicHeight && marginTop > 20) {
          marginTop = 10;
        } */

        dynamicStyle = {
          left: (-(cardsLength / 2) - 0.5 + index) * cardWidth / 2,
          width: cardWidth,
          height: cardHeight,
          backgroundSize: `${cardWidth}px ${cardHeight}px`,

          transformOrigin: 'center center',
          transform: `rotate(${(index - ((cardsLength - 1) / 2)) * 4}deg)`,
          top: Math.abs((index - ((cardsLength - 1) / 2))) * Math.abs((index - ((cardsLength - 1) / 2)))  * 4 //+ cardHeight/2,

          //  width: cardWidth,
          //  height: cardHeight,
          //  backgroundSize: `${cardWidth}px ${cardHeight}px`,
        };

        dynamicStyleOverlay = {
          width: cardWidth,
          height: cardHeight,
          backgroundSize: `${cardWidth}px ${cardHeight}px`,

        }
        //  dynamicStyleOverlay = {
        //  width: cardWidth - 6,
        //  height: cardHeight - 6
        //  };
      }
      console.log({cardsOnLoading}, {cardsOnTable},{gameState}, "===player card")
      return (
        <div key={`hover-${card}`} className={`${hoveredClass}`}>
          <div
            key={card}
            id={`hand-card-${card}`}
            style={{ ...dynamicStyle }}
            // className={`${hoveredClass} ${cardsOnTable && cardsOnTable.includes(card) && gameState && largePlayer && largePlayer === myPos && (gameState === constants.GMAE_STATE.burry) && 'take-card'} card card-${card} ${(selectedCard === card || (doubleClickSelectedCard === card)) ? 'selected' : ''} card-${startIndex + index} ${(allowed && gameState && gameState !== constants.GMAE_STATE.choose && gameState !== constants.GMAE_STATE.results && !tableIsInProgress) ? 'allowed' : 'blocked'} `}
            className={classNames(`${hoveredClass} card card-${card} card-${startIndex + index}`, {
              'take-card': cardsOnTable && cardsOnTable.includes(card) && gameState && largePlayer && largePlayer === myPos && (gameState === constants.GMAE_STATE.burry),
              'selected': selectedCard === card || doubleClickSelectedCard === card,
              'allowed': (allowed && gameState && gameState !== constants.GMAE_STATE.choose && gameState !== constants.GMAE_STATE.results && !tableIsInProgress),
              'blocked': (!allowed || !gameState || gameState === constants.GMAE_STATE.choose || gameState === constants.GMAE_STATE.results || tableIsInProgress)
            })}
            onClick={e => playCard(e, { card, allowed })}
            onMouseOver={() => changeHoveredCard(index, card, 'over')}
            onFocus={() => changeHoveredCard(index, card, 'over')}
            onMouseOut={() => changeHoveredCard(index, card, 'out')}
            onBlur={() => changeHoveredCard(index, card, 'out')}
            // className={classNames({'table-card':  globalParams.curRnd.includes(card)})}
          >
            <div
              key={`overlay-${card}`}
              id={`hand-card-overlay-${card}`}
              style={{ ...dynamicStyleOverlay}}
            //  style={dynamicStyleOverlay}
              // className={`${(gameState === constants.GMAE_STATE.choose || (!hasPlayedCard && currentTurnUid && memberUid === currentTurnUid && !allowed && gameState && gameState !== constants.GMAE_STATE.choose && !tableIsInProgress)) ? 'blocked-overlay' : 'display-none'}`}
              className={classNames({
                'blocked-overlay': (gameState === constants.GMAE_STATE.choose || (!hasPlayedCard && currentTurnUid && memberUid === currentTurnUid && !allowed && gameState && gameState !== constants.GMAE_STATE.choose && !tableIsInProgress)),
                'display-none': gameState !== constants.GMAE_STATE.choose && (hasPlayedCard || !currentTurnUid || memberUid !== currentTurnUid || allowed || !gameState || gameState === constants.GMAE_STATE.choose || tableIsInProgress),
              })}
              onClick={e => playCard(e, { card, allowed })}
            />
          </div>
        </div>
      );
    }
    return (
      <div key={`hover-${card}`} className={`${hoveredClass}`}>
        <div
          key={card}
          id={`hand-card-${card}`}
          // className={`${hoveredClass} ${cardsOnTable && cardsOnTable.includes(card) && gameState && largePlayer && largePlayer === myPos && (gameState === constants.GMAE_STATE.burry) && 'take-card'} card card-${card} ${(selectedCard === card || (doubleClickSelectedCard === card)) ? 'selected' : ''} card-${startIndex + index} ${(allowed && gameState && gameState !== constants.GMAE_STATE.choose && gameState !== constants.GMAE_STATE.results && !tableIsInProgress) ? 'allowed' : 'blocked'}`}
          className={classNames(`${hoveredClass} card card-${card} card-${startIndex + index}`, {
            'take-card': cardsOnTable && cardsOnTable.includes(card) && gameState && largePlayer && largePlayer === myPos && (gameState === constants.GMAE_STATE.burry),
            'selected': selectedCard === card || doubleClickSelectedCard === card,
            'allowed': (allowed && gameState && gameState !== constants.GMAE_STATE.choose && gameState !== constants.GMAE_STATE.results && !tableIsInProgress),
            'blocked': (!allowed || !gameState || gameState === constants.GMAE_STATE.choose || gameState === constants.GMAE_STATE.results || tableIsInProgress)
          })}
          onClick={e => playCard(e, { card, allowed })}
          onMouseOver={() => changeHoveredCard(index, card, 'over')}
          onFocus={() => changeHoveredCard(index, card, 'over')}
          onMouseOut={() => changeHoveredCard(index, card, 'out')}
          onBlur={() => changeHoveredCard(index, card, 'out')}
        >
          <div
            key={`overlay-${card}`}
            id={`hand-card-overlay-${card}`}
            // className={`${(gameState === constants.GMAE_STATE.choose || (!hasPlayedCard && currentTurnUid && memberUid === currentTurnUid && !allowed && gameState && gameState !== constants.GMAE_STATE.choose && !tableIsInProgress)) ? 'blocked-overlay' : 'display-none'}`}
            className={classNames({
              'blocked-overlay': (gameState === constants.GMAE_STATE.choose || (!hasPlayedCard && currentTurnUid && memberUid === currentTurnUid && !allowed && gameState && gameState !== constants.GMAE_STATE.choose && !tableIsInProgress)),
              'display-none': gameState !== constants.GMAE_STATE.choose && (hasPlayedCard || !currentTurnUid || memberUid !== currentTurnUid || allowed || !gameState || gameState === constants.GMAE_STATE.choose || tableIsInProgress),
            })}
            onClick={e => playCard(e, { card, allowed })}
          />
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  cardsOnTable: state.game.cardsOnTable,
  largePlayer: state.game.largePlayer,
  myPos: state.game.myPos,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, null)(PlayerCard);
