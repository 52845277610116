import React from 'react';
import PropTypes from 'prop-types';
import { isMobileDevice } from '../../../../../utils/deviceUtils';
import { vmin } from '../../../../../utils/screenUtils';

const hiddenCardXOffsets = [2, 4, -1, 7];
const hiddenCardYOffsets = [5, 2, 6, 2];

class VisibleCard extends React.Component {
  static propTypes = {
    card: PropTypes.string,
    allowed: PropTypes.bool,
    gameState: PropTypes.string,
    playCard: PropTypes.func.isRequired,
    currentTurnUid: PropTypes.string,
    memberUid: PropTypes.string,
    selectedCard: PropTypes.string,
    doubleClickSelectedCard: PropTypes.string,
    tableIsInProgress: PropTypes.bool.isRequired,
    cardsLength: PropTypes.number,
    index: PropTypes.number,
    hasPlayedCard: PropTypes.bool,
    hoveredCard: PropTypes.string,
    changeHoveredCard: PropTypes.func.isRequired,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    currentType: PropTypes.string,
    largePlayer: PropTypes.string,
    myPos: PropTypes.string,
    cardsOnLoading: PropTypes.bool,
  }

  static defaultProps = {
    card: null,
    allowed: false,
    gameState: null,
    currentTurnUid: null,
    memberUid: null,
    selectedCard: null,
    doubleClickSelectedCard: null,
    cardsLength: 0,
    index: null,
    hasPlayedCard: null,
    hoveredCard: null,
    cardsOnTable: [],
    currentType: null,
    largePlayer: null,
    myPos: null,
    cardsOnLoading: true,
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      cardsLength,
      card,
      allowed,
      nonPlayable,
      index,
      howerIndex,
      gameState,
      playCard,
      selectedCard,
      tableIsInProgress,
      memberUid,
      currentTurnUid,
      doubleClickSelectedCard,
      hasPlayedCard,
      hoveredCard,
      changeHoveredCard,
      cardsOnTable,
      currentType,
      largePlayer,
      myPos,
      cardsOnLoading,
      hasHiddenCard,
      isVisibleCard,
    } = this.props;

    let startIndex = 0;

    let hoveredClass = '';
    if (!nonPlayable && allowed && currentTurnUid === memberUid) {
      if (hoveredCard === `visible-${index}` && !isMobileDevice()) {
        hoveredClass = 'hovered';
      }
    }

    let cardPos = window.innerWidth > 1280 ? {
      left: ((index) - 2) * vmin(24) / 2 - (vmin(2) / 2),
      // transform: `rotate(0deg)`,
      top: 50,
    } : {}

    return (
      <div key={`hover-${card}`} style={{ zIndex: (990 - index) }}>
        {(card && hasHiddenCard) ? (
          <div
            key={`hidden-card-${card}`}
            id={`hidden-card-${card}`}
            style={cardPos}
            className={`card2-wrapper student-zole-hidden-card student-zole-card-${startIndex + index} ${(allowed && gameState && gameState !== 'choose' && gameState !== 'results' && !tableIsInProgress) ? 'allowed2' : 'blocked'}`}
          >
            <div className="card2-innerWrapper" style={{ top: (-10 + hiddenCardYOffsets[index]), right: (5 + hiddenCardXOffsets[index]) }}>
              {card ? (
                <>
                  <div className={cardsOnTable.length === 2 && cardsOnTable && cardsOnTable.includes(card) && gameState && largePlayer && largePlayer === myPos && (gameState === 'burry') && 'take-redesign-card'} />
                  <div className={`card2 card2-on-table card2-hidden ${(selectedCard === card || (doubleClickSelectedCard === card)) ? 'selected' : ''} `}>
                    <div
                      key={`overlay-${card}`}
                      id={`hand-card-overlay-${card}`}
                      className={`${((!hasPlayedCard && currentTurnUid && memberUid === currentTurnUid && !allowed && gameState && gameState !== 'choose' && !tableIsInProgress)) ? 'blocked-overlay' : 'display-none'}`}
                    />
                  </div>
                </>
              ) : (null)}
            </div>
          </div>
        ) : (null)}

        <div className={`${hoveredClass}`}>
        <div
          key={`visible-card-${card}`}
          id={`hand-card-${card}`}
          style={{ pointerEvents: card ? 'all' : 'none', ...cardPos }}
            className={`${hoveredClass} card2-wrapper student-zole-card-${startIndex + index} ${(allowed && gameState && gameState !== 'choose' && gameState !== 'results' && !tableIsInProgress) ? 'allowed2' : 'blocked'}`}
            onClick={e => playCard(e, { card, allowed: (!nonPlayable && allowed) })}
            onMouseOver={() => changeHoveredCard(`visible-${index}`, card, 'over', (!nonPlayable && allowed))}
            onFocus={() => changeHoveredCard(`visible-${index}`, card, 'over', (!nonPlayable && allowed))}
            onMouseOut={() => changeHoveredCard(`visible-${index}`, card, 'out', (!nonPlayable && allowed))}
            onBlur={() => changeHoveredCard(`visible-${index}`, card, 'out', (!nonPlayable && allowed))}
        >
          <div className="card2-innerWrapper">
            {card ? (
              <>
                <div className={cardsOnTable.length === 2 && cardsOnTable && cardsOnTable.includes(card) && gameState && largePlayer && largePlayer === myPos && (gameState === 'burry') && 'take-redesign-card'} />
                <div className={`card2 card2-on-table card2-${card} ${(selectedCard === card || (doubleClickSelectedCard === card)) ? 'selected' : ''} `}>
                  <div
                    key={`overlay-${card}`}
                    id={`hand-card-overlay-${card}`}
                    className={`${((!hasPlayedCard && currentTurnUid && memberUid === currentTurnUid && !allowed && gameState && gameState !== 'choose' && !tableIsInProgress)) ? 'blocked-overlay' : 'display-none'}`}
                    onClick={e => playCard(e, { card, allowed })}
                  />
                </div>
              </>
            ) : (null)}
          </div>
          </div>
        </div>
      </div>
    );
  }
}


export default (VisibleCard);
