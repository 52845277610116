export default function userReducer(state = {}, action) {
  switch (action.type) {
    case 'MISSED_TURNS_COUNT': {
      if (action.data) {
        return {
          ...state,
          missedTurnsCount: action.data,
        };
      }
      return {
        ...state,
        missedTurnsCount: [],
      };

    //  return state;
    }

    case 'PROFANITIES_LIST': {
      if (action.data) {
        return {
          ...state,
          profanitiesList: action.data,
        };
      }
      return state;
    }

    case 'MISSED_TURNS_DATA': {
      if (action.data) {
        return {
          ...state,
          missedTurnsData: action.data,
        };
      }
      return {
        ...state,
        missedTurnsData: {},
      };

    //  return state;
    }

    case 'ADMIN_USERS_REPLACE': {
      if (action.data) {
        const lastKey = Object.keys(action.data)[4];

        return {
          ...state,
          allUsers: action.data,
          allUsersLastKey: lastKey,
        };
      }
      return state;
    }

    case 'ADMIN_USER_REPLACE': {
      if (action.data) {
        const { key, user } = action.data;

        return {
          ...state,
          allUsers: {
            ...state.allUsers,
            [key]: user,
          },
        };
      }
      return state;
    }

    case 'ENABLED_LOG_LOCKET_USERS': {
      if (action.data) {
        return {
          ...state,
          logRocketUsers: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_FILTERED_USERS': {
      return {
        ...state,
        filteredUsers: action.data,
      };
    }

    case 'ADMIN_NONVERIFIED_REPLACE': {
      if (action.data) {
        return {
          ...state,
          nonVerifiedUsers: action.data,
        };
      }
      return state;
    }

    case 'SEND_NON_VERIFIED_PLAYER': {
      const { uid } = action.data;

      console.log('SEND_NON_VERIFIED_PLAYER', action.data);

      if (state.nonVerifiedUsers && state.nonVerifiedUsers[uid]) {
        return {
          ...state,
          nonVerifiedUsers: {
            ...state.nonVerifiedUsers,
            [uid]: {
              ...state.nonVerifiedUsers[uid],
            },
          },
        };
      }
      return state;
    }

    case 'CHANGE_LOGROCKET': {
      const { enabled, uid } = action.data;

      if (state.filteredUsers && state.filteredUsers[uid]) {
        return {
          ...state,
          filteredUsers: {
            ...state.filteredUsers,
            [uid]: {
              ...state.filteredUsers[uid],
              enableLogRocket: enabled,
            },
          },
        };
      } if (state.allUsers && state.allUsers[uid]) {
        return {
          ...state,
          allUsers: {
            ...state.allUsers,
            [uid]: {
              ...state.allUsers[uid],
              enableLogRocket: enabled,
            },
          },
        };
      }
      return state;
    }

    case 'CHANGE_SMARTLOOK': {
      const { enabled, uid } = action.data;

      if (state.filteredUsers && state.filteredUsers[uid]) {
        return {
          ...state,
          filteredUsers: {
            ...state.filteredUsers,
            [uid]: {
              ...state.filteredUsers[uid],
              enableSmartlook: enabled,
            },
          },
        };
      } if (state.allUsers && state.allUsers[uid]) {
        return {
          ...state,
          allUsers: {
            ...state.allUsers,
            [uid]: {
              ...state.allUsers[uid],
              enableSmartlook: enabled,
            },
          },
        };
      }
      return state;
    }

    case 'CHANGE_BLOCK': {
      const { blocked, uid } = action.data;

      if (state.filteredUsers && state.filteredUsers[uid]) {
        return {
          ...state,
          filteredUsers: {
            ...state.filteredUsers,
            [uid]: {
              ...state.filteredUsers[uid],
              blocked,
            },
          },
        };
      } if (state.allUsers && state.allUsers[uid]) {
        return {
          ...state,
          allUsers: {
            ...state.allUsers,
            [uid]: {
              ...state.allUsers[uid],
              blocked,
            },
          },
        };
      }
      return state;
    }

    case 'ADMIN_USER_COUNT': {
      if (action.data) {
        return {
          ...state,
          userCount: action.data,
        };
      }
      return state;
    }


    case 'TEMPLATE_DATA': {
      if (action.data) {
        return {
          ...state,
          verifyEmail: action.data.verifyEmail,
          resetEmail: action.data.resetEmail,
        };
      }
      return state;
    }


    case 'ADMIN_ROOM_LOGS_REPLACE': {
      if (action.data) {
        console.log('ADMIN_ROOM_LOGS_REPLACE action.data', action.data);

        const array = Object.keys(action.data).reverse().map(key => ({
          roomId: key,
          date: action.data[key].date || null,
          bet: action.data[key].bet || null,
          party: action.data[key].party || null,
          minGames: action.data[key].minGames || null,
          fourPRoom: action.data[key].fourPRoom || null,
          studentRoom: action.data[key].studentRoom || null,
          closeReason: action.data[key].closeReason || null,
          closeTime: action.data[key].closeTime || null,
          tournamentRoom: action.data[key].tournamentRoom || null,
          tournamentId: action.data[key].tournamentId || null,
          index: action.data[key].index || null,
          isOnline: action.data[key].isOnline || null,
          triedToMove: action.data[key].triedToMove || null,
          speed: action.data[key].speed || null,
          partyCount: action.data[key].partyCount || null,
          secondUserJoined: action.data[key].secondUserJoined || null,
          firstRoundStart: action.data[key].firstRoundStart || null,
          smallGame: action.data[key].smallGame || null,
          gameType: action.data[key].gameType || null,
        }));

        return {
          ...state,
          allRoomsLogs: array,
        };
      }
      return state;
    }

    case 'ADMIN_AUTO_COMPENSATIONS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          autoCompensations: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_ROOM_LOGS_COUNT': {
      if (action.data) {
        return {
          ...state,
          roomsPlayedCount: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_FILTERED_ROOM_LOGS': {
      console.log('ADMIN_FILTERED_ROOM_LOGS', action.data);
      let array = [];
      let totalRoomCount = 0;
      if (action.data && action.data.logs && action.data.totalRoomCount) {
        array = action.data.logs.map(item => ({
          roomId: item.key,
          date: item.date || null,
          bet: item.bet || null,
          party: item.party || null,
          minGames: item.minGames || null,
          fourPRoom: item.fourPRoom || null,
          studentRoom: item.studentRoom || null,
          closeReason: item.closeReason || null,
          closeTime: item.closeTime || null,
          tournamentRoom: item.tournamentRoom || null,
          tournamentId: item.tournamentId || null,
          index: item.index || null,
          isOnline: item.isOnline || null,
          triedToMove: item.triedToMove || null,
          speed: item.speed || null,
          partyCount: item.party || null,
          secondUserJoined: item.secondUserJoined || null,
          firstRoundStart: item.firstRoundStart || null,
          smallGame: item.smallGame || null,
          gameType: item.gameType || null,
        }));
        totalRoomCount = action.data.totalRoomCount;

      } else {
        array = null;
        totalRoomCount = 0;
      }

      return {
        ...state,
        filteredRoomsLogs: array,
        totalRoomCount: totalRoomCount
      };
    }

    /*  case 'ADMIN_PAYMENTS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          allPayments: action.data,
        };
      }
      return state;
    } */

    case 'ADMIN_PAYMENTS_REPLACE': {
      if (action.data) {
        const array = Object.keys(action.data)
          .map(key => ({
            id: key,
            date: action.data[key].dateInitiated || null,
            userUid: action.data[key].userUid || null,
            status: action.data[key].status || null,
            index: action.data[key].index || null,
            productNr: action.data[key].productNr || null,
            discountPrice: action.data[key].discountPrice || null,
            provider: action.data[key].provider || null,
          }));

        array.sort((a, b) => b.date - a.date);

        return {
          ...state,
          allPayments: array,
        };
      }
      return state;
    }

    case 'ADMIN_PAYMENTS_COUNT': {
      if (action.data) {
        return {
          ...state,
          paymentsCount: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_REMOVED_PAYMENTS_COUNT': {
      if (action.data) {
        return {
          ...state,
          removedPaymentsCount: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_COMPLETED_PAYMENTS_COUNT': {
      if (action.data) {
        return {
          ...state,
          completedPaymentsCount: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_INITIATED_PAYMENTS_COUNT': {
      if (action.data) {
        return {
          ...state,
          initiatedPaymentsCount: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_VIP_REPLACE': {
      if (action.data) {
        return {
          ...state,
          vipUsers: action.data,
        };
      }
      return state;
    }

    case 'GET_ALL_GIFTS': {
      if (action.data) {
        return {
          ...state,
          gifts: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_BANS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          allBans: action.data,
        };
      }
      return state;
    }

    case 'BANS_COUNT_REPLACE': {
      if (action.data) {
        return {
          ...state,
          bansCount: action.data,
        };
      }
      return state;
    }


    case 'ADMIN_TRANSACTIONS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          allTransactions: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_TOURNAMENTS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          allTournaments: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_CLAIMEDPLAYERS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          claimedPlayers: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_GIFTCODES_REPLACE': {
      if (action.data) {
        return {
          ...state,
          allGiftCodes: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_EVENTS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          allEvents: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_CAMPAIGNS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          allCampaigns: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_CAMPAIGN_HISTORY_REPLACE': {
      if (action.data) {
        return {
          ...state,
          campaignHistory: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_CAMPAIGN_SHOWED_HISTORY_REPLACE': {
      if (action.data) {
        return {
          ...state,
          campaignShowedHistory: action.data,
        };
      }
      return state;
    }

    case 'ADMIN_CAMPAIGN_SHOWED_HISTORY_REPLACE_SINGLE': {
      if (action.data && action.id) {
        return {
          ...state,
          campaignShowedHistory: {
            ...state.campaignShowedHistory,
            [action.id]: action.data,
          },
        };
      }
      return state;
    }


    case 'ADMIN_CAMPAIGN_SHOWED_HISTORY_SINGLE': {
      console.log('ADMIN_CAMPAIGN_SHOWED_HISTORY_SINGLE', action);

      if (action.data && action.id) {
        if (state.campaignShowedHistory) {
          return {
            ...state,
            campaignShowedHistory: {
              ...state.campaignShowedHistory,
              [action.id]: action.data,
            },
          };
        }
        return {
          ...state,
          campaignShowedHistory: {
            [action.id]: action.data,
          },
        };
      }
      return state;
    }

    case 'ADMIN_CAMPAIGN_SHOWED_HISTORY_SINGLE_ADDED': {
      console.log('ADMIN_CAMPAIGN_SHOWED_HISTORY_SINGLE_ADDED', action);

      if (action.data && action.id && action.key) {
        if (state.campaignShowedHistory && state.campaignShowedHistory[action.id]) {
          return {
            ...state,
            campaignShowedHistory: {
              ...state.campaignShowedHistory,
              [action.id]: {
                ...state.campaignShowedHistory[action.id],
                [action.key]: action.data,
              },
            },
          };
        }
        const campaignShowedHistory = state.campaignShowedHistory || {};

        return {
          ...state,
          campaignShowedHistory: {
            ...campaignShowedHistory,
            [action.id]: {
              [action.key]: action.data,
            },
          },
        };
      }
      return state;
    }

    case 'ADMIN_CAMPAIGN_HISTORY_SINGLE': {
      console.log('ADMIN_CAMPAIGN_HISTORY_SINGLE', action);

      if (action.data && action.id) {
        if (state.campaignHistory) {
          return {
            ...state,
            campaignHistory: {
              ...state.campaignHistory,
              [action.id]: action.data,
            },
          };
        }
        return {
          ...state,
          campaignHistory: {
            [action.id]: action.data,
          },
        };
      }
      return state;
    }

    case 'ADMIN_CAMPAIGN_HISTORY_SINGLE_ADDED': {
      console.log('ADMIN_CAMPAIGN_HISTORY_SINGLE_ADDED', action);

      if (action.data && action.id && action.key) {
        if (state.campaignHistory && state.campaignHistory[action.id]) {
          return {
            ...state,
            campaignHistory: {
              ...state.campaignHistory,
              [action.id]: {
                ...state.campaignHistory[action.id],
                [action.key]: action.data,
              },
            },
          };
        }
        const campaignHistory = state.campaignHistory || {};

        return {
          ...state,
          campaignHistory: {
            ...campaignHistory,
            [action.id]: {
              [action.key]: action.data,
            },
          },
        };
      }
      return state;
    }

    case 'ADMIN_DISCOUNTS_REPLACE': {
      if (action.data) {
        return {
          ...state,
          allShopDiscounts: action.data,
        };
      }
      return state;
    }

    case 'TOURNAMENT_PLAYERS': {
      let tourPlayers = {};
      let tourPlayersArr = {};

      if (state) {
        tourPlayers = state.tournamentPlayers;
        tourPlayersArr = state.tournamentPlayersArr;
      }

      if (action.data) {
        return {
          ...state,
          tournamentPlayers: {
            ...tourPlayers,
            [action.data.tournamentId]: action.data.tournamentPlayers,
          },
          tournamentPlayersArr: {
            ...tourPlayersArr,
            [action.data.tournamentId]: action.data.tournamentPlayersArr,
          },
        };
      }
      return state;
    }

    case 'TOURNAMENT_RESULTS': {
      if (action.data) {
        return {
          ...state,
          tournamentResults: action.data.rounds,
        };
      }
      return state;
    }

    case 'ADMIN_LOGS_ROOMS': {
      if (action.data) {
        console.log('ADMIN_ROOM_LOGS_REPLACE action.data 2', action.data);

        const array = Object.keys(action.data).reverse().map(key => ({
          roomId: key,
          date: action.data[key].date || null,
          bet: action.data[key].bet || null,
          minGames: action.data[key].minGames || null,
          fourPRoom: action.data[key].fourPRoom || null,
          studentRoom: action.data[key].studentRoom || null,
          tournamentRoom: action.data[key].tournamentRoom || null,
          tournamentId: action.data[key].tournamentId || null,
          index: action.data[key].index || null,
          isOnline: action.data[key].isOnline || null,
          triedToMove: action.data[key].triedToMove || null,
          speed: action.data[key].speed || null,
          partyCount: action.data[key].partyCount || null,
          secondUserJoined: action.data[key].secondUserJoined || null,
          firstRoundStart: action.data[key].firstRoundStart || null,
          smallGame: action.data[key].smallGame || null,
          gameType: action.data[key].gameType || null,
        }));

        return {
          ...state,
          allRoomsLogs: array,
        };
      }
      return state;
    }

    case 'ADMIN_ROOM_LOGS': {
      if (action.data) {
        console.log('ADMIN_ROOM_LOGS', action);
        const { data, roomId, roomsPoints } = action;
        let { roomData } = state;

        if (!roomData) {
          roomData = {};
        }

        return {
          ...state,
          roomData: {
            ...roomData,
            [roomId]: { data, roomsPoints },
          },
        };
      }
      return state;
    }
    case 'ADMIN_ROOM_OPTIONS_REPLACE': {
      return {
        ...state,
        roomOptions: action.data,
      };
    }
    case 'USER_CHATS': {
    /*  if (action.data) {
        return {
          ...state,
          chatMessages: action.data,
        };
      }
      return state; */

      if (action.data && action.data.messages) {
        const messages = {};
        Object.keys(action.data.messages).map((key) => {
          messages[key] = { ...action.data.messages[key], messageId: key };
          return null;
        });

        return {
          ...state,
          chatMessages: messages,
          chatMessagesUserLastLanguage: action.data.lastLanguage,
        };
      }
      return state;
    }

    case 'ACTIVE_UNREAD_MESSAGES': {
      const unreadMessages = [];

      Object.keys(action.data).map((key) => {
        unreadMessages.push({
          ...action.data[key],
          key,
        });
        return null;
      });

      unreadMessages.sort((a, b) => b.lastResponse - a.lastResponse);

      return {
        ...state,
        unreadMessages: action.data,
      };
    }

    case 'ACTIVE_UNREAD_MESSAGE_CHANGE': {
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          [action.key]: action.data,
        },
      };
    }

    case 'ACTIVE_UNREAD_MESSAGES_REMOVE': {
      const unreadMessages = { ...state.unreadMessages };

      delete unreadMessages[action.key];
      return {
        ...state,
        unreadMessages,
      };
    }

    case 'ACTIVE_READ_MESSAGES': {
      const readMessages = [];

      Object.keys(action.data).map((key) => {
        readMessages.push({
          ...action.data[key],
          key,
        });
        return null;
      });

      readMessages.sort((a, b) => b.lastResponse - a.lastResponse);

      return {
        ...state,
        readMessages: action.data,
      };
    }

    case 'ACTIVE_READ_MESSAGE_CHANGE': {
      return {
        ...state,
        readMessages: {
          ...state.readMessages,
          [action.key]: action.data,
        },
      };
    }

    case 'ACTIVE_READ_MESSAGES_REMOVE': {
      const readMessages = { ...state.readMessages };

      delete readMessages[action.key];
      return {
        ...state,
        readMessages,
      };
    }

    case 'USER_GAMES_HISTORY': {
      let total = {};
      const userGamesHistory = [];

      if (action.data) {
        Object.keys(action.data).map((key) => {
          const data = action.data[key] || {};

          userGamesHistory.push({ date: key, ...data });

          total = {
            ...total,
            wins: (total.wins || 0) + (data.wins || 0),
            largeWins: (total.largeWins || 0) + (data.largeWins || 0),
            smallWins: (total.smallWins || 0) + (data.smallWins || 0),
            zoleWins: (total.zoleWins || 0) + (data.zoleWins || 0),
            loses: (total.loses || 0) + (data.loses || 0),
            largeLoses: (total.largeLoses || 0) + (data.largeLoses || 0),
            smallLoses: (total.smallLoses || 0) + (data.smallLoses || 0),
            zoleLoses: (total.zoleLoses || 0) + (data.zoleLoses || 0),
            galdinsLoses: (total.galdinsLoses || 0) + (data.galdinsLoses || 0),
            galdinsWins: (total.galdinsWins || 0) + (data.galdinsWins || 0),
            roomsPlayed: (total.roomsPlayed || 0) + (data.roomsPlayed || 0),
            //  roundsPlayed: ((total.roundsPlayed) ? total.roundsPlayed : 0) + (data.roundsPlayed ? data.roundsPlayed : 0),
            roundsPlayed: (total.roundsPlayed || 0) + (data.wins || 0) + (data.loses || 0),
            balChange: (total.balChange || 0) + (data.balChange || 0),
            pointsChange: (total.pointsChange || 0) + (data.pointsChange ? data.pointsChange : 0),
            bonusSpin: (total.bonusSpin || 0) + (data.bonusSpin ? data.bonusSpin : 0),
          };
          return null;
        });
      }

      userGamesHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

      return {
        ...state,
        userGamesHistory: [total, ...userGamesHistory],
        userGamesHistoryTotal: total,
      };
    }

    case 'USER_BAL_HISTORY': {
      const array = [];

      Object.keys(action.data).map((key) => {
        array.push({ ...action.data[key], key });
        return null;
      });

      array.sort((a, b) => b.time - a.time);

      return {
        ...state,
        userBalanceHistory: array,
      };
    }

    case 'USER_POINTS_HISTORY': {
      const array = [];

      Object.keys(action.data).map((key) => {
        array.push({ ...action.data[key], key });
        return null;
      });

      array.sort((a, b) => b.time - a.time);

      return {
        ...state,
        userPointsHistory: array,
      };
    }

    case 'SMARTLOOK_STATUS': {
      return {
        ...state,
        smartLookStatus: action.data,
      };
    }

    case 'TOURNAMENT_USERS': {
      return {
        ...state,
        tournamentUsers: action.data.users,
        userTournaments: action.data.userTournaments,
        totalTournamentUserCount: action.data.totalUserCount,
      };
    }

    case 'TOURNAMENT_PLAYER_HISTORY': {
      return {
        ...state,
        tournamentPlayerHistory: action.data,
      };
    }

    case 'SUSPICIOUS_PLAYERS': {
      const suspiciousPlayersArr = [];

      if (action.data) {
        Object.keys(action.data).map((key) => {
          suspiciousPlayersArr.push({ ...action.data[key], key });
        });
      }

      suspiciousPlayersArr.sort((a, b) => b.dateAdded - a.dateAdded);
      const filteredArr = suspiciousPlayersArr.filter(x => x.count >= 5);

      console.log('suspiciousPlayersArr 2', filteredArr);
      return {
        ...state,
        suspiciousPlayers: action.data,
        suspiciousPlayersArr: filteredArr,
      };
    }

    case 'SUSPICIOUS_PLAYERS_ROOMS': {
    /*  const suspiciousPlayersArr = [];

      if (action.data) {
        Object.keys(action.data).map((key) => {
          suspiciousPlayersArr.push({ ...action.data[key], key });
        });
      }

      console.log('suspiciousPlayersArr', suspiciousPlayersArr); */

      return {
        ...state,
        suspiciousPlayersRooms: action.data,
      };
    }

    case 'ADMIN_LIST_REPLACE': {
      return {
        ...state,
        admins: action.data,
      };
    }

    case 'BOTS_LIST_REPLACE': {
      return {
        ...state,
        bots: action.data,
      };
    }

    case 'ADMIN_ACTION_LOGS': {
      return {
        ...state,
        adminActionData: action.data,
      };
    }

    case 'ADMIN_ACTION_LOG': {
      if (action.data) {
        const { key, log } = action.data;
        return {
          ...state,
          adminActionData: {
            [key]: log,
            ...state.adminActionData,
          },
        };
      }
      return state;
    }

    case 'ADMIN_ACTION_LOGS_COUNT': {
      if (action.data) {
        return {
          ...state,
          logsCount: action.data,
        };
      }
      return state;
    }

    case 'USER_ACHIEVEMENTS': {
      return {
        ...state,
        userAchievements: action.data,
      };
    }

    case 'ADMIN_STATISTICS_REPLACE': {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          [action.key]: action.data,
        },
      };
    }

    case 'ADMIN_FEEDBACKS_REPLACE': {
      return {
        ...state,
        allFeedbacks: action.data,
        totalRating: action.totalRating,
      };
    }

    case 'ADMIN_FEEDBACKS_HISTORY_REPLACE': {
      return {
        ...state,
        feedbackHistory: action.data,
        totalRating: action.totalRating,
      };
    }

    case 'ADMIN_MULTIPLE_FEEDBACKS_REPLACE': {
      return {
        ...state,
        multipleFeedbacks: action.data,
        multipleFeedbacksLoading: action.feedbackLoading,
      };
    }

    case 'ADMIN_MULTIPLE_FEEDBACKS_RESULTS_REPLACE': {
      return {
        ...state,
        multipleFeedbacksResults: action.data,
        multipleFeedbacksResultsLoading: action.feedbackResultsLoading,
      };
    }

    case 'ADMIN_NOTIFICATIONS_REPLACE': {
      return {
        ...state,
        allNotifications: action.data,
      };
    }

    case 'ADMIN_LAST_IP_REPLACE': {
      return {
        ...state,
        lastIpData: action.data,
      };
    }

    case 'ADMIN_VERSION_REPLACE': {
      return {
        ...state,
        versionHistories: action.data,
      };
    }

    case 'ADMIN_BLOGS_REPLACE': {
      return {
        ...state,
        blogs: action.data,
      };
    }

    case 'START_FEEDBACK_TIME': {
      return {
        ...state,
        feedbackStartTime: action.data,
        previousFeedbackTime: action.previousFeedbackTime,
      };
    }

    case 'ADMIN_SUSPICISOUS_TRANSACTIONS_REPLACE': {
      return {
        ...state,
        suspiciousTransactionsPlayers: action.data,
        allSuspiciousTransactions: action.allSuspiciousTransactions,
      };
    }

    case 'SUSPICIOUS_FOLD_PLAYERS': {
      return {
        ...state,
        suspiciousFoldPlayers: action.data,
      };
    }

    case 'ADMIN_ROOM_GLOBAL_PARAMS': {
      return {
        ...state,
        globalRoomData: action.data,
      };
    }

    case 'MULTI_ACCOUNTS': {
      return {
        ...state,
        multiAccountPlayers: action.data || {},
      };
    }

    case 'ADMIN_HINTS_REPLACE': {
      return {
        ...state,
        allHints: action.data || [],
      };
    }

    case 'ADMIN_HINTS_OLD_REPLACE': {
      return {
        ...state,
        allOldHints: action.data || [],
      };
    }

    case 'ADMIN_HINTS_NEW_REPLACE': {
      return {
        ...state,
        allNewHints: action.data || [],
      };
    }

    case 'ADMIN_REPORTED_PLAYERS_REPLACE': {
      return {
        ...state,
        allReportedPlayers: action.data || {},
      };
    }

    case 'ADMIN_REPORTED_MESSAGES_REPLACE': {
      return {
        ...state,
        allReportedMessages: action.data || {},
      };
    }

    case 'ADMIN_VERIFIED_COUNT_REPLACE': {
      return {
        ...state,
        confirmedPlayerCount: action.data || 0,
      };
    }

    case 'PLAYER_ROOM_CHAT_MESSAGE': {
      return {
        ...state,
        playerRoomChatMsgs: action.data || [],
        chatLoading: action.chatLoading,
      };
    }

    case 'REPLACE_WARNING_PLAYERS': {
      return {
        ...state,
        warningPlayers: action.data || {},
      };
    }
    case 'REPLACE_DESIGN_PLATFORMS': {
      return {
        ...state,
        designPlatforms: action.data || {},
        designPlatformsLoading: action.designPlatformsLoading || false,
      };
    }
    case 'REPLACE_SURVEY_PLATFORMS': {
      return {
        ...state,
        allSurveys: action.data || {},
        surveysLoading: action.loading || false,
      };
    }
    case 'REPLACE_SURVEY_HISTORY_PLATFORMS': {
      return {
        ...state,
        surveyHistory: action.data || {},
        loading: action.loading || false,
      };
    }
    case 'ADMIN_POSSIBLE_BOT_PLAYERS_REPLACE': {
      return {
        ...state,
        possibleBotPlayers: action.data || {},
        possibleBotLoading: action.possibleBotLoading || false,
      };
    }
    case 'ADMIN_POSSIBLE_BOT_PLAYER_DATA_REPLACE': {
      return {
        ...state,
        possibleBotPlayerData: action.data || {},
      };
    }
    case 'ADMIN_HELP_EDITOR_REPLACE': {
      return {
        ...state,
        helpSections: action.data || '',
        helpEditorLoading: action.helpEditorLoading || false,
      };
    }
    default:
      return state;
  }
}
