import React from 'react';
import PropTypes from 'prop-types';

/* import {
  Media,
} from 'reactstrap'; */

import Media from 'reactstrap/lib/Media';

const PlayerEmotion = React.memo(({
  emotion, posClassName, hidden,
}) => {

  if (hidden) {
    return null;
  }

  return (
    <div className={posClassName ? `${posClassName}-emotion` : null}>
      <Media
        className="player-emotion-image"
        src={emotion || ''}
      />
    </div>
  );
});

PlayerEmotion.propTypes = {
//  index: PropTypes.number,
  //  emotions: PropTypes.shape(),
  emotion: PropTypes.string,
  posClassName: PropTypes.string,
};

PlayerEmotion.defaultProps = {
//  index: null,
  //  emotions: {},
  emotion: null,
  posClassName: null,
};

export default PlayerEmotion;
