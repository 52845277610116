import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import Leaderboard from './TournamentLeaderboard';

import closeImg from '../../../images/icons/close.png';

import {
  getTournamentPlayers,
  closeTournamentResults,
  getTournamentsHistory,
} from '../../../actions/tournaments';

import {
  FONT_MODE
} from '../../../constants/constants';
import { getTournamentTitle } from '../../../utils/tournamentUtils';

class Tournaments extends React.PureComponent {
  static propTypes = {
    tournamentPlayers: PropTypes.shape(),
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    showTournamentResults: PropTypes.func.isRequired,
    closeTournamentResult: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    tournaments: PropTypes.shape(),
    fetchTournamentsHistory: PropTypes.func.isRequired,
  }

  static defaultProps = {
    tournamentPlayers: {},
    uid: null,
    fontMode: FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  openModal: false,
    //  openJoinModal: false,
    //  tournamentId: '',
    //  scrollPos: 0,
    //  tournamentToJoin: null,
    //  tournamentToJoinFee: null,
    //  registerPopoverOpen: {},
    };
  }

  componentWillMount() {
    const { showTournamentResults, fetchTournamentPlayers, fetchTournamentsHistory } = this.props;
    fetchTournamentPlayers(showTournamentResults.tournamentId);

    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }
    fetchTournamentsHistory(devMode);
  }

  // componentWillReceiveProps(nextProps) {

  //  }

  //  updateScrollPos = (val) => {
  //    this.setState({ scrollPos: val.topPosition || 0 });
  //  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  render() {
    const {
      t,
      tournamentPlayers,
      showTournamentResults,
      closeTournamentResult,
      uid,
      fontMode,
      tournaments,
    } = this.props;

    if (!tournamentPlayers || !showTournamentResults) {
      return null;
    }

    if (showTournamentResults.canceled && showTournamentResults.playersCount) {
      return (
        <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={showTournamentResults} toggle={closeTournamentResult} className={`old-modal root-font-${fontMode}`}>
          <ModalHeader
            toggle={closeTournamentResult}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={closeTournamentResult} />
            }
          >{t('home.notifications')}</ModalHeader>
          <ModalBody>
            {t('tournaments.canceled', { playersCount: showTournamentResults.playersCount })}
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="modal-footer-button" onClick={closeTournamentResult}>{t('tournaments.close')}</Button>
          </ModalFooter>
        </Modal>
      );
    }

    return (
      <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={showTournamentResults} toggle={closeTournamentResult} className={`old-modal root-font-${fontMode}`}>
        <ModalHeader
          toggle={closeTournamentResult}
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={closeTournamentResult} />
          }
        >
          {t('tournaments.results', { name: getTournamentTitle(tournaments.find(obj => obj.id === showTournamentResults.tournamentId)) })}
        </ModalHeader>
        <ModalBody>
          <Leaderboard leaderboard={tournamentPlayers} tournamentId={showTournamentResults.tournamentId} uid={uid} />
        </ModalBody>
        <ModalFooter>
          <Button color="link" className="modal-footer-button" onClick={closeTournamentResult}>{t('tournaments.close')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  uid: state.member.uid || null,
  tournaments: (state.tournaments && state.tournaments.tournamentsHistory) ? state.tournaments.tournamentsHistory : [],
  showTournamentResults: state.member.showTournamentResults || false,
  tournamentPlayers: (state.tournaments && state.tournaments.tournamentPlayers) ? state.tournaments.tournamentPlayers : {},
});

const mapDispatchToProps = {
  fetchTournamentsHistory: getTournamentsHistory,
  fetchTournamentPlayers: getTournamentPlayers,
  closeTournamentResult: closeTournamentResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Tournaments));
