import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch, connect } from 'react-redux';
import Spinner from 'reactstrap/lib/Spinner';

import { Helmet } from 'react-helmet';
import { map } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';
import CustomModal from '../../Components/Components/Modal';

import * as constants from '../../../../../constants/constants';

import coin from '../../../../../images/redesign/common/coin.svg';
import rating from '../../../../../images/redesign/common/rating.svg';
import infoImg from '../../../../../images/redesign/player/info.svg';
import lightInfoImg from '../../../../../images/redesign/light-mode/player/info.svg';
import proIcon from '../../../../../images/icons/proIcon.svg';
import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.svg';

import { getRankingsData } from '../../../../../actions/leaderboard';

const PowerRankings = ({ screenMode, checked, togglePWOption, parentActiveTab: activeTab, parentChangeTab: setActiveTab }) => {
  const { t } = useTranslation('common');
  const intvl = useRef(null);
  const dispatch = useDispatch();

  const rankings = useSelector(state => state.leaderboard.rankings);
  const isLoading = useSelector(state => state.leaderboard.isLoading);
  const [infoModal, setInfoModal] = useState(false);

  useEffect(() => {
    dispatch(getRankingsData(checked, menuNavIdToKey(activeTab)));
  }, [checked, activeTab]);

  const menuNavIdToKey = (menuNavId) => {
    return constants.POWER_RANKINGS_FILTER.filter(item => item.id === menuNavId)[0].key;
  }

  const handleChange = () => {
    if (!checked) {
      if (activeTab === constants.MENU_NAVIGATION.powerRankingsWinsCount) {
        setActiveTab(constants.MENU_NAVIGATION.powerRankingsLossCount);
      } else if (activeTab === constants.MENU_NAVIGATION.powerRankingsWinPercentage) {
        setActiveTab(constants.MENU_NAVIGATION.powerRankingsLossPercentage);
      }
    } else {
      if (activeTab === constants.MENU_NAVIGATION.powerRankingsLossCount) {
        setActiveTab(constants.MENU_NAVIGATION.powerRankingsWinsCount);
      } else if (activeTab === constants.MENU_NAVIGATION.powerRankingsLossPercentage) {
        setActiveTab(constants.MENU_NAVIGATION.powerRankingsWinPercentage);
      }
    }
    togglePWOption();
  };

  const changeTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      //console.log("POWER_RANKINGS_SUB_MENU", constants.POWER_RANKINGS_SUB_MENU[tab]);
      // parentChangeTab(constants.POWER_RANKINGS_SUB_MENU[tab]);
    }
  };

  const handleInputRef = () => {
    intvl.current.click();
  };

  const renderRating = (t, rating) => (
    <div className="my-info-ratings-step-wrapper">
      {rating < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-weak my-info-ratings-step-active power-rankings-level-step">{t('myInfo.weak')}</div>) : (null)}
      {(rating >= 10 && rating < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-beginner my-info-ratings-step-active power-rankings-level-step">{t('myInfo.beginner')}</div>) : (null)}
      {(rating >= 20 && rating < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-untrained my-info-ratings-step-active power-rankings-level-step">{t('myInfo.untrained')}</div>) : (null)}
      {(rating >= 30 && rating < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-medium my-info-ratings-step-active power-rankings-level-step">{t('myInfo.medium')}</div>) : (null)}
      {(rating >= 40 && rating < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-good my-info-ratings-step-active power-rankings-level-step">{t('myInfo.good')}</div>) : (null)}
      {(rating >= 50 && rating < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active power-rankings-level-step">{t('myInfo.great')}</div>) : (null)}
      {(rating >= 60 && rating < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-fan my-info-ratings-step-active power-rankings-level-step">{t('myInfo.fan')}</div>) : (null)}
      {rating >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-pro my-info-ratings-step-active"><Media src={proIcon} alt="X" /></div>) : (null)}
    </div>
  );

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };
console.log("====================00000000000", activeTab)
  return (
    <>
      <Helmet>
        <title>
          Zole - {t('route.powerRankings')}
        </title>
      </Helmet>
      <div className="layout-body layout-body-background">
        <div className="layout-body-top">
          <Row className="layout-subheader">
            <Col xs="11" className="power-rankings-filter-options">
              {
                map(constants.POWER_RANKINGS_SUB_MENU, item => (
                  !checked ? (
                    item !== constants.POWER_RANKINGS_SUB_MENU.lossCount && item !== constants.POWER_RANKINGS_SUB_MENU.lossPercentage && (
                      <div className="layout-subheader-link">
                        <Button
                          color="link"
                          className={classNames('layout-subheader-link-text', {
                            'layout-subheader-link-text-active': item === activeTab,
                          })}
                          onClick={() => changeTab(item)}
                        >
                          {t(`powerRankings.${menuNavIdToKey(item)}`)}
                        </Button>
                      </div>
                    )
                  ) : (
                    item !== constants.POWER_RANKINGS_SUB_MENU.winsCount && item !== constants.POWER_RANKINGS_SUB_MENU.winPercentage && (
                      <div className="layout-subheader-link">
                        <Button
                          color="link"
                          className={classNames('layout-subheader-link-text', {
                            'layout-subheader-link-text-active': item === activeTab,
                          })}
                          onClick={() => changeTab(item)}
                        >
                          {t(`powerRankings.${menuNavIdToKey(item)}`)}
                        </Button>
                      </div>
                    )
                  )
                ))
              }
            </Col>
            <Col xs="1">
              <Media className="new-power-rankings-header-tutorial" src={screenMode === constants.SCREEN_MODE.light ? lightInfoImg : infoImg} onClick={() => toggleInfoModal()} />
            </Col>
          </Row>
        </div>
        <div className="layout-body-main new-power-rankings-main-section">
          <Row>
            <Col xs="3" className="new-power-rankings-header-switch-section">
              <Label className={(checked) ? 'disable' : 'active'}>{t(`powerRankings.${constants.POWER_RANKINGS_SWITCH_OPTION.best}`)}</Label>
              <Label className="new-switch-wrapper" onClick={() => handleInputRef}>
                <input
                  type="checkbox"
                  name="switchOption"
                  checked={checked}
                  ref={intvl}
                  onClick={e => handleChange(e)}
                />
                <span
                  className={classNames('switch', {
                    'switch-best': checked,
                    'switch-worst': !checked,
                  })}
                >
                  <span className="switch-handle" />
                </span>
              </Label>
              <Label className={(checked) ? 'active' : 'disable'}>{t(`powerRankings.${constants.POWER_RANKINGS_SWITCH_OPTION.worst}`)}</Label>
            </Col>
          </Row>
          {isLoading ? (
            <div className="power-rankings-list-part-loading d-flex h-100 align-items-center">
              <Spinner color={screenMode === constants.SCREEN_MODE.light ? 'dark' : 'light'} style={{ width: '5rem', height: '5rem' }} />
            </div>
          ) : (
            <div
              className="new-power-rankings-scrollarea overflow-auto"
              contentClassName="new-power-rankings-scrollarea-body"
              show
              rightOffset={0}
              topOffset={30}
              bottomOffset={0}
              // noScrollButton
            >
              <div className="new-power-rankings-body">
                {
                  map(rankings, (item, key) => (
                    <div
                      className={classNames('new-power-rankings-body-item', {
                        'new-power-rankings-body-item-lg': key === 0 && !checked,
                        'new-power-rankings-body-item-lg new-power-rankings-body-item-lg-no-stars': key === 0 && checked,
                        'new-power-rankings-body-item-md': key === 1,
                        'new-power-rankings-body-item-sm': key === 2,
                        'new-power-rankings-body-item-lsm': key === 3,
                      })}
                    >
                      <div className="new-power-rankings-body-item-label">
                        <Label className="new-power-rankings-body-item-label-key">{ key + 1 }</Label>
                        <Label className="new-power-rankings-body-item-label-addition">
                          {
                            activeTab !== constants.MENU_NAVIGATION.powerRankingsLossPercentage && activeTab !== constants.MENU_NAVIGATION.powerRankingsWinPercentage ? (
                              !checked ? (
                                `+ ${item[constants.POWER_RANKINGS_FILTER.filter(tab => tab.id === activeTab)[0].filter] || 0}`
                              ) : (
                                item[constants.POWER_RANKINGS_FILTER.filter(tab => tab.id === activeTab)[0].filter] || 0
                              )
                            ) : (
                              `${Number.parseFloat(item[constants.POWER_RANKINGS_FILTER.filter(tab => tab.id === activeTab)[0].filter] * 100).toFixed(2) || 0}%`
                            )
                          }
                        </Label>
                      </div>
                      <div className="new-power-rankings-body-item-photo">
                        <div className="player-info-player-background">
                          <div className="player-info-player-image-wrapper">
                            <div className="player-info-player-image-background" />
                            <div style={{ backgroundImage: item.photo ? `url(${item.photo})` : (screenMode === constants.SCREEN_MODE.light ? `url(${lightDefaultImage})` : `url(${defaultImage})`), backgroundPosition: 'center', backgroundSize: item.photo ? '100%, 100%' : '80%, 93%' }} className="player-info-player-image" />
                            <div className="player-info-player-image-overlay" />
                            <div className="player-info-player-image-level">
                              {renderRating(t, item.lvl || 10)}
                            </div>
                          </div>
                          <div className="player-info-player-name-wrapper power-ranking-list-player-name">
                            <div className="player-info-player-name ml-1 mr-1">
                              {item.name || 'Zole'}
                            </div>
                          </div>
                          <div className="new-power-rankings-body-item-score">
                            <div className="new-power-rankings-body-item-score-section d-flex align-items-center">
                              <Media className="ml-2 mr-1" src={coin} alt="zole" />
                              <Label className="mr-2">{item.totalBal || 1600}</Label>
                              <Media className="mr-1" src={rating} alt="zole" />
                              <Label className="mr-2">{item.totalRating || '--' }</Label>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          )}

        </div>
        <CustomModal
          isOpen={infoModal}
          className="new-version-modal"
          toggle={toggleInfoModal}
          size="md"
          title={t('powerRankings.infoHeader')}
          body={(
            <div className="new-power-rankings-body-modal-content">
              <p className="mb-3">{t('powerRankings.infoContent1')}</p>
              <p className="mb-3">{t('powerRankings.infoContent2')}</p>
              <p className="mb-3">{t('powerRankings.infoContent3')}</p>
            </div>
          )}
          footer={(
            <Button color="secondary" onClick={toggleInfoModal}>
              {t('common:common.close')}
            </Button>
          )}
        />
      </div>
    </>
  );
};

export default PowerRankings;