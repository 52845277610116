import axios from 'axios';
import { size, filter } from 'lodash';

import { v1 as uuidv1 } from 'uuid';

import {
  Firebase, FirebaseRef, RoomsRef,
} from '../lib/firebase';

import { Event } from '../web/components/Tracking';
import { SERVER_STATUS_CODE } from '../constants/constants';

// const uuidv1 = require('uuid/v1');


export function getCards(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise((resolve) => {
    const { game } = getState();

    const { myPos } = game;

    if (myPos) {
      RoomsRef.child(`rooms/${roomId}/players/player1/cards`).off();
      RoomsRef.child(`rooms/${roomId}/players/player2/cards`).off();
      RoomsRef.child(`rooms/${roomId}/players/player3/cards`).off();
      RoomsRef.child(`rooms/${roomId}/players/player4/cards`).off();

      return RoomsRef.child(`rooms/${roomId}/players/${myPos}/cards`).on('value', (snapshot) => {
        const data = snapshot.val() || [];

        //  console.log('CARDS_REPLACE data', { data });

        return resolve(dispatch({ type: 'CARDS_REPLACE', data, roomId }));
      }, (err) => {
        console.log(err);
      });
    }
    return resolve('no pos');
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getCardsStudentZole(roomId) {
  console.log('getCardsStudentZole', roomId);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise((resolve) => {
    const { game } = getState();

    // const { myPos } = game;

    RoomsRef.child(`rooms/${roomId}/playersList/playerList`).once('value', (playerListSnapshot) => {
      const playerList = playerListSnapshot.val() || {};

      let myPos = playerList[UID];

    //  if (playerList[UID] === 'player1') {
    //    myPos = 'player1';
     // } else {
    //    myPos = 'player2';
    //  }

      console.log('getCardsStudentZole myPos', playerList, myPos);

      if (myPos) {
        RoomsRef.child(`rooms/${roomId}/players/player1/cards`).off();
        RoomsRef.child(`rooms/${roomId}/players/player2/cards`).off();

        RoomsRef.child(`rooms/${roomId}/studentRoomCards/player1/visible`).off();
        RoomsRef.child(`rooms/${roomId}/studentRoomCards/player1/hasHidden`).off();
        RoomsRef.child(`rooms/${roomId}/studentRoomCards/player2/visible`).off();
        RoomsRef.child(`rooms/${roomId}/studentRoomCards/player2/hasHidden`).off();

        let otherPos = 'player1';

        if (myPos === 'player1') {
          otherPos = 'player2';
        }

        console.log('myPos, otherPos', myPos, otherPos);

        RoomsRef.child(`rooms/${roomId}/studentRoomCards/${myPos}/visible`).on('value', (snapshot) => {
          const data = snapshot.val() || [];

          console.log('studentRoomCards/visible', data);

          dispatch({ type: 'CARDS_REPLACE_STUDENT_ZOLE_VISIBLE', data, roomId });
        }, (err) => {
          console.log(err);
        });

        RoomsRef.child(`rooms/${roomId}/studentRoomCards/${myPos}/hasHidden`).on('value', (snapshot) => {
          const data = snapshot.val() || [];

          console.log('studentRoomCards/hasHidden', data);

           dispatch({ type: 'CARDS_REPLACE_STUDENT_ZOLE_HIDDEN', data, roomId });
        }, (err) => {
          console.log(err);
        });

        RoomsRef.child(`rooms/${roomId}/studentRoomCards/${otherPos}/visible`).on('value', (snapshot) => {
          const data = snapshot.val() || [];

          console.log('studentRoomCards/visible other', data);

          dispatch({ type: 'CARDS_REPLACE_STUDENT_ZOLE_OTHER_VISIBLE', data, roomId });
        }, (err) => {
          console.log(err);
        });

        RoomsRef.child(`rooms/${roomId}/studentRoomCards/${otherPos}/hasHidden`).on('value', (snapshot) => {
          const data = snapshot.val() || [];

          console.log('studentRoomCards/hasHidden', data);

          dispatch({ type: 'CARDS_REPLACE_STUDENT_ZOLE_OTHER_HIDDEN', data, roomId });
        }, (err) => {
          console.log(err);
        });

        return RoomsRef.child(`rooms/${roomId}/players/${myPos}/cards`).on('value', (snapshot) => {
          const data = snapshot.val() || [];

          return resolve(dispatch({ type: 'CARDS_REPLACE', data, roomId }));
        }, (err) => {
          console.log(err);
        });
      }

    }).catch((err) => {
      console.log(err.message);

      return resolve('no pos');
    });

    return resolve('no pos');
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getAutomatedStatus(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise((resolve) => {
    const { game } = getState();

    const { myPos } = game;

    if (myPos) {
      RoomsRef.child(`rooms/${roomId}/players/player1/automated`).off();
      RoomsRef.child(`rooms/${roomId}/players/player2/automated`).off();
      RoomsRef.child(`rooms/${roomId}/players/player3/automated`).off();
      RoomsRef.child(`rooms/${roomId}/players/player4/automated`).off();

      return RoomsRef.child(`rooms/${roomId}/players/${myPos}/automated`).on('value', (snapshot) => {
        const data = snapshot.val() || null;

        return resolve(dispatch({ type: 'AUTOMATED_REPLACE', data, roomId }));
      }, (err) => {
        console.log(err);
      });
    }
    return resolve('no pos');
  }).catch((err) => {
    console.log(err.message);
  });
}

export function removeAutomated(roomId, setAutomatedModalShown) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const removeAutomatedFunction = Firebase.app().functions().httpsCallable('removeAutomated');

    removeAutomatedFunction({
      roomId,
    }).then((result) => {
      if (result && result.data === 'success') {
        if (setAutomatedModalShown) {
          try {
            setAutomatedModalShown(false);
          } catch (err) {

          }
        }
        return resolve(dispatch({ type: 'AUTOMATED_REPLACE', data: null, roomId }));
      }
      return resolve();
    });
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getCardsOnce(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise((resolve) => {
    const { game } = getState;
    if (game) {
      const playerPos = game.myPos;

      RoomsRef.child(`rooms/${roomId}/players/${playerPos}/cards`).once('value', (snapshot) => {
        const data = snapshot.val() || [];

        //  console.log('CARDS_REPLACE data', { data });

        return resolve(dispatch({ type: 'CARDS_REPLACE', data, roomId }));
      }, (err) => { console.log(err); });
    }
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getInRoomToken(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());
  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/inRoomTokens/${UID}`).once('value', (snapshot) => {
      const inRoomToken = snapshot.val() || null;

      return resolve(dispatch({ type: 'IN_ROOM_TOKEN', data: inRoomToken }));
    });
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getGameSettings() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('gameSettings').once('value', (snapshot) => {
    const gameSettings = snapshot.val() || {};

    return resolve(dispatch({ type: 'GAME_SETTINGS', data: gameSettings }));
  }));
}

export function getCurrentTable(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/curRnd/currentTable`).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || [];

        if (data.length === 0 || (data.length === 1 && data[0]) || (data.length === 2 && data[0] && data[1]) || (data.length === 3 && data[0] && data[1] && data[2])) {
          return resolve(
            dispatch({ type: 'CURRENT_TABLE_REPLACE', data, roomId }),
          );
        }
        console.log('current Table read failed');
        return resolve();
      }, (errorObject) => {
        console.log(`current Table read failed: ${errorObject.code}`);
      },
    );
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getFirstToGo(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/curRnd/firstToGo`).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || null;
        return resolve(
          dispatch({ type: 'FIRST_TO_GO_REPLACE', data, roomId }),
        );
      }, (error) => {
        console.log(`first to go read failed: ${error}`);
      },
    );
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getCurrentTurn(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/curRnd/currentTurn`).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || null;

        return resolve(
          dispatch({ type: 'CURRENT_TURN_REPLACE', data, roomId }),
        );
      },
      (errorObject) => {
        console.log(`Turn read failed: ${errorObject.code}`);
      },
    );
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getSittingOut(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/curRnd/sittingOut`).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || null;

        return resolve(
          dispatch({ type: 'SITTING_OUT_REPLACE', data, roomId }),
        );
      },
      (errorObject) => {
        console.log(`Turn read failed: ${errorObject.code}`);
      },
    );
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getLargePlayer(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/curRnd/largePlayer`).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || null;

        return resolve(
          dispatch({ type: 'LARGE_PLAYER_REPLACE', data, roomId }),
        );
      },
      (errorObject) => {
        console.log(`Large player read failed: ${errorObject.code}`);
      },
    );
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getCurrentType(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/curRnd/type`).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || null;
        return resolve(
          dispatch({ type: 'CURRENT_TYPE_REPLACE', data, roomId }),
        );
      },
      (errorObject) => {
        console.log(`Type read failed: ${errorObject.code}`);
      },
    );
  }).catch((err) => {
    console.log(err.message);
  });
}

/*
export function getNextTimeStamp(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/nextTimestamp`).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || null;

        return resolve(
          dispatch({ type: 'NEXT_TIMESTAMP_REPLACE', data, roomId }),
        );
      },
      (errorObject) => {
        console.log(`Timestamp read failed: ${errorObject.code}`);
      },
    );
  }).catch((err) => {
    console.log(err.message);
  });
} */

export function getNextTimeStamp(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/nT`).on('value', (snapshot) => {
      const data = snapshot.val() || null;

      return resolve(
        dispatch({ type: 'NEXT_TIMESTAMP_REPLACE', data, roomId }),
      );
    },
    (errorObject) => {
      console.log(`Timestamp read failed: ${errorObject.code}`);
    }
  );
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getUnlimitedTimerStarted(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`roomsTempData/${roomId}/unlimitedTimerStarted`).on(
      'value',
      (snapshot) => {
        const data = snapshot.val() || null;

        return resolve(
          dispatch({ type: 'UNLIMITED_TIMER_DATA', data, roomId }),
        );
      },
      (errorObject) => {
        console.log(`Timestamp read failed: ${errorObject.code}`);
      },
    );
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getGlobalParams(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/globalParams`).on(
      'child_changed',
      (snapshot) => {
        const roomData = snapshot.val() !== undefined ? snapshot.val() : null;
        const { key } = snapshot;

        console.log('testAAAA rooms globalParams child_changed', key, roomData);

        dispatch({
          type: 'GLOBAL_PARAMS_CHANGE',
          data: { roomData, key, roomId },
        });
      },
    );

    RoomsRef.child(`rooms/${roomId}/globalParams`).on(
      'child_added',
      (snapshot) => {
        const roomData = snapshot.val() !== undefined ? snapshot.val() : null;
        const { key } = snapshot;

        console.log('testAAAA rooms globalParams child_added', key, roomData);

        dispatch({
          type: 'GLOBAL_PARAMS_ADDED',
          data: { roomData, key, roomId },
        });
      },
    );

    RoomsRef.child(`rooms/${roomId}/globalParams`).on(
      'child_removed',
      (snapshot) => {
        const { key } = snapshot;

        console.log('testAAAA rooms globalParams child_removed', key);

        dispatch({ type: 'GLOBAL_PARAMS_REMOVED', data: { key, roomId } });
      },
    );

    RoomsRef.child(`roomsStaticData/${roomId}/globalParams`).on(
      'child_changed',
      (snapshot) => {
        const roomData = snapshot.val() !== undefined ? snapshot.val() : null;
        const { key } = snapshot;

        console.log('testAAAA roomsStaticData globalParams child_changed', key);

        dispatch({
          type: 'GLOBAL_PARAMS_CHANGE',
          data: { roomData, key, roomId },
        });
      },
    );

    RoomsRef.child(`roomsStaticData/${roomId}/globalParams`).on(
      'child_added',
      (snapshot) => {
        const roomData = snapshot.val() !== undefined ? snapshot.val() : null;
        const { key } = snapshot;

        console.log('testAAAA roomsStaticData globalParams child_added', key);

        dispatch({
          type: 'GLOBAL_PARAMS_ADDED',
          data: { roomData, key, roomId },
        });
      },
    );

    RoomsRef.child(`roomsStaticData/${roomId}/globalParams`).on(
      'child_removed',
      (snapshot) => {
        const { key } = snapshot;

        dispatch({ type: 'GLOBAL_PARAMS_REMOVED', data: { key, roomId } });
      },
    );

    return resolve();
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getPlayers(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/playersList`)
      .on('child_added', (snapshot) => {
        const data = snapshot.val() || {};
        const { key } = snapshot;

        console.log('testAAAA rooms playersList child_added', key);

        return resolve(dispatch({ type: 'PLAYERS_CHILD_REPLACE', data, key }));
      });

    RoomsRef.child(`rooms/${roomId}/playersList`)
      .on('child_changed', (snapshot) => {
        const data = snapshot.val() || {};
        const { key } = snapshot;

        console.log('testAAAA rooms playersList child_changed', key);

        return resolve(dispatch({ type: 'PLAYERS_CHILD_REPLACE', data, key }));
      });

    RoomsRef.child(`rooms/${roomId}/playersList`)
      .on('child_removed', (snapshot) => {
        const { key } = snapshot;

        console.log('testAAAA rooms playersList child_removed', key);

        return resolve(dispatch({ type: 'PLAYERS_CHILD_REPLACE', data: null, key }));
      });

    RoomsRef.child(`roomsStaticData/${roomId}/playersList`)
      .on('child_added', (snapshot) => {
        const data = snapshot.val() || {};
        const { key } = snapshot;

        console.log('testAAAA roomsStaticData playersList child_added', key);

        return resolve(dispatch({ type: 'PLAYERS_CHILD_REPLACE', data, key }));
      });

    RoomsRef.child(`roomsStaticData/${roomId}/playersList`)
      .on('child_changed', (snapshot) => {
        const data = snapshot.val() || {};
        const { key } = snapshot;

        console.log('testAAAA roomsStaticData playersList child_changed', key);

        return resolve(dispatch({ type: 'PLAYERS_CHILD_REPLACE', data, key }));
      });

    RoomsRef.child(`roomsStaticData/${roomId}/playersList`)
      .on('child_removed', (snapshot) => {
      //  const data = snapshot.val() || {};
        const { key } = snapshot;

        return resolve(dispatch({ type: 'PLAYERS_CHILD_REPLACE', data: null, key }));
      });
  }).catch((err) => {
    console.log(err.message);
  });
}

export function getPlayerPosition(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => RoomsRef.child(`rooms/${roomId}/playersList/playerList/${UID}`).on('value', (snapshot) => {
    const data = snapshot.val() || null;

    console.log('getPlayerPosition', data);

    return resolve(dispatch({ type: 'PLAYER_POSITION_REPLACE', data, roomId }));
  })).catch((err) => {
    console.log(err.message);
  });
}


export function getPreviousRound(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => RoomsRef.child(`roomsTempData/${roomId}/previousRound`).once(
    'value',
    (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(
        dispatch({ type: 'SET_PREVIOUS_ROUND_DATA', data, roomId }),
      );
    },
  )).catch((err) => {
    console.log(err.message);
  });
}

export function refreshLastToken() {
  if (Firebase === null || !Firebase.auth() || !Firebase.auth().currentUser) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => Firebase.auth().currentUser.getIdToken()
    .then(tokenRes => resolve(dispatch({ type: 'SET_LAST_TOKEN', data: tokenRes })))
    .catch((tokenErr) => {
      console.log(tokenErr);
      return resolve();
    })).catch((err) => {
    console.log(err.message);
  });
}


// firebase database:profile --instance zole-app -o tests.txt -d 10


export function roomDataFallback(roomId, passedDispatch) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => resolve());

  if (passedDispatch) {
    return new Promise((resolve) => {
      const roomDataFallbackFunction = Firebase.app()
        .functions()
        .httpsCallable('fetchRoomData');

      roomDataFallbackFunction({
        roomId,
      }).then((res) => {
        if (res && !res.status) {
          return resolve(passedDispatch({ type: 'ROOM_FALLBACK_DATA', data: res.data }));
        }
        return resolve();
      });
    });
  }
  return dispatch => new Promise((resolve) => {
    const roomDataFallbackFunction = Firebase.app()
      .functions()
      .httpsCallable('fetchRoomData');

    roomDataFallbackFunction({
      roomId,
    }).then((res) => {
    //  console.log('fetchRoomData', { res });
      if (res && !res.status) {
        return resolve(dispatch({ type: 'ROOM_FALLBACK_DATA', data: res.data }));
      }
      console.log('room fallback error');
      console.log(res);

      return resolve();
    });
  });
}

export function roomDataFallbackNew(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const roomDataFallbackFunction = Firebase.app()
      .functions('europe-west1')
      .httpsCallable('fetchRoomDataNew');

    roomDataFallbackFunction({
      roomId,
    }).then((response) => {
    //  console.log('roomDataFallback', { response });
      if (response && !response.status) {
        if (response && response.data && response.data.data) {
          const { data } = response.data;

          setTimeout(() => (dispatch({ type: 'ROOM_FALLBACK_DATA', data })), 10);

          return resolve();
        }
        return resolve();
      }
      return resolve();
    }).catch((err) => {
      console.log('fetchRoomDataNew error');
      console.log(err);
    });
  });
}

export function debugSetParam(paramID, value) {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'SET_DEBUG_PARAM', paramID, value })));
}


export function roomDataFallback2(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise(async (resolve) => {
    const { game } = getState();

    let token;

    const {
      nextTimeStamp, cards, myPos, currentTable, currentTurn, globalParams,
    } = game;

    let talking;

    if (globalParams) {
      const { talking: talkingValue } = globalParams;
      talking = talkingValue;
    }

    await Firebase.auth().currentUser.getIdToken().then((tokenRes) => {
      token = tokenRes;

      dispatch({ type: 'SET_LAST_TOKEN', data: tokenRes });
    }).catch(() => {
      if (game && game.lastToken) {
        token = game.lastToken;
      }
    });

    try {
      await axios.post('https://us-central1-zole-app.cloudfunctions.net/fetchRoomData3', {
        data: {
          roomId, token, timestamp: nextTimeStamp, cards, myPos, table: currentTable, curTurn: currentTurn, talking,
        },
      }, {
        headers: {
          'Content-Type': 'text/plain',
        },
        timeout: 15000,
      })
        .then((response) => {
          if (response && response.data && response.data.data) {
            const { data } = response.data;

            setTimeout(() => (dispatch({ type: 'ROOM_FALLBACK_DATA', data })), 100);
            //  return resolve(dispatch({ type: 'ROOM_FALLBACK_DATA', data: data }));
            return resolve();
          }
          console.log('no response.data');
          return resolve();
        })
        .catch((error) => {
          if (error.response) {
            console.log('fetchRoomData error', { data: error.response.data, status: error.response.status });
          } else if (error.request) {
            console.log('fetchRoomData error', { request: error.request });
          } else {
            console.log('fetchRoomData error', { message: error.message });
          }
          return resolve();
        });
    } catch (error) {
      console.error(error);
    }
  });
}


function makeString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


export function playCard(selectedCard, roomId, myPos2, gameState) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise(async (resolve) => {
    if (gameState === 'play' || !gameState) {
      const { game } = getState();

      const { currentTable, myPos } = game;

      let cardNr = 0;

      if (currentTable && currentTable.length) {
        cardNr = currentTable.length;
      }

      if (currentTable && currentTable[cardNr]) {
        if (currentTable[cardNr].player === myPos) {
          return resolve('already made move');
        }
      }

      try {
        let successfullUpdate = false;

        const timer = setTimeout(() => {
          console.log('play card fallback timer executed', { t: successfullUpdate });
          if (!successfullUpdate) {
            const playCardFunction = Firebase.app().functions('europe-west1').httpsCallable('playCardEU');

            playCardFunction({
              roomId,
              playedCard: selectedCard,
            }).then((res) => {
              console.log('playCardFunction res', { res });

              return resolve();
            }).catch((error) => {
              if (error.response) {
                console.log('play card error', { data: error.response.data, status: error.response.status });
              } else if (error.request) {
                console.log('play card error', { request: error.request });
              } else {
                console.log('play card error', { message: error.message });
              }

              return resolve();
            });
          }
        }, 2800);

        RoomsRef.child(`lastAction/${roomId}`).update({
          pl: UID,
          time: Firebase.database.ServerValue.TIMESTAMP,
          type: 'playCard',
        });

        Event('Game', 'Play card', null);

        console.log('playCard', { cardNr, selectedCard, myPos });

        return RoomsRef.child(`rooms/${roomId}/curRnd/currentTable/${cardNr}`).set({
          card: selectedCard,
          player: myPos,
          from: 'cs',
          time: Firebase.database.ServerValue.TIMESTAMP,
        //  lc: Date.now(),
        }).then((res) => {
          console.log('playCard res', res);
          successfullUpdate = true;
          clearTimeout(timer);

          dispatch({ type: 'USER_ACTION_REMOVE_CARD', data: selectedCard });

          return resolve();
        }).catch((err) => {
          console.log('play card write  failed', { err });
          return resolve();
        });
        } catch (error) {
          console.log('playCard error', error);

        return resolve();
      }

      //  return resolve();
    } else if (gameState === 'burry') {
    //  const { game } = getState();

      try {
        let successfullUpdate = false;

        const timer = setTimeout(() => {
          if (!successfullUpdate) {
            const burryCardFunction = Firebase.app().functions('europe-west1').httpsCallable('burryCardEU2');

            burryCardFunction({
              roomId,
              burriedCard: selectedCard,
              //  token,
            }).then(() => resolve()).catch((error) => {
              if (error.response) {
                console.log('burry card error', { data: error.response.data, status: error.response.status });
              } else if (error.request) {
                console.log('burry card error', { request: error.request });
              } else {
                console.log('burry card error', { message: error.message });
              }

              return resolve();
            });
          }
        }, 1800);

        Event('Game', 'Burry card', null);

        RoomsRef.child(`lastAction/${roomId}`).update({
          pl: UID,
          time: Firebase.database.ServerValue.TIMESTAMP,
          type: 'burryCard',
        }).catch((err) => {
          console.log(err);
        });

        return RoomsRef.child(`roomsBurriedCard/${roomId}/card`).set(selectedCard).then(() => {
          successfullUpdate = true;
          clearTimeout(timer);

          return resolve();
        }).catch((err) => {
          console.log('roomsBurriedCard on error', { selectedCard });
          console.log(err);
        });
      } catch (error) {
        console.error(error);
        return resolve();
      }
    } else {
      return resolve();
    }
  });
}

export function playCardStudentZole(selectedCard, roomId, myPos2, gameState) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise(async (resolve) => {
    if (gameState === 'play' || !gameState) {
      const { game } = getState();

      const { currentTable, myPos } = game;

      let cardNr = 0;

      if (currentTable && currentTable.length) {
        cardNr = currentTable.length;
      }

      if (currentTable && currentTable[cardNr]) {
        if (currentTable[cardNr].player === myPos) {
          return resolve('already made move');
        }
      }

      try {
        RoomsRef.child(`lastAction/${roomId}`).update({
          pl: UID,
          time: Firebase.database.ServerValue.TIMESTAMP,
          type: 'playCard',
        });

        Event('Game', 'Play card', null);

        return RoomsRef.child(`rooms/${roomId}/curRnd/currentTable/${cardNr}`).set({
          card: selectedCard,
          player: myPos,
          from: 'cs',
          time: Firebase.database.ServerValue.TIMESTAMP,
        }).then(() => {
          dispatch({ type: 'USER_ACTION_REMOVE_CARD', data: selectedCard });

          return resolve();
        }).catch((err) => {
          console.log('play card write  failed', { err });
          return resolve();
        });
      } catch (error) {
        console.error(error);

        return resolve();
      }

      //  return resolve();
    } else if (gameState === 'burry') {
      //  const { game } = getState();

      try {
        Event('Game', 'Burry card', null);

        RoomsRef.child(`lastAction/${roomId}`).update({
          pl: UID,
          time: Firebase.database.ServerValue.TIMESTAMP,
          type: 'burryCard',
        }).catch((err) => {
          console.log(err);
        });

        return RoomsRef.child(`roomsBurriedCard/${roomId}/card`).set(selectedCard).then(() => {
          return resolve();
        }).catch((err) => {
          console.log('roomsBurriedCard on error', { selectedCard });
          console.log(err);
        });
      } catch (error) {
        console.error(error);
        return resolve();
      }
    } else {
      return resolve();
    }
  });
}

export function selectGameTypeStudentZole({ selectedType }, roomId) {
  console.log('selectGameTypeStudentZole', selectedType, roomId);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(async (resolve) => {
    //  const { game } = getState();

    try {
      Event('Game', 'Choose game type', null);

      RoomsRef.child(`lastAction/${roomId}`).update({
        pl: UID,
        time: Firebase.database.ServerValue.TIMESTAMP,
        type: 'choose',
      }).catch((err) => {
        console.log(err);
      });

      RoomsRef.child(`roomsChosenType/${roomId}/type`).set(selectedType).then(() => {
        return resolve();
      }).catch((err) => {
        console.log('roomsChosenType on error');
        console.log(err);
      });
    } catch (error) {
      console.error(error);
    }
  });
}

export function getCardsOnTable(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('getCardsOnTable');

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const firebaseProm = RoomsRef.child(`rooms/${roomId}/curRnd/cardsOnTable`);
    firebaseProm.once('value', (snap) => {
      const cardsOnTable = snap.val() || [];
      console.log('action cardsOnTable', cardsOnTable);

      return resolve(dispatch({ type: 'CARDS_ON_TABLE', data: cardsOnTable.cards }));
    });
  });
}

export function selectGameType({ selectedType }, roomId) {
  console.log('selectGameType', selectedType, roomId);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(async (resolve) => {
  //  const { game } = getState();

    try {
      let successfullUpdate = false;

      const timer = setTimeout(() => {
        if (!successfullUpdate) {
          const chooseGameTypeFunction = Firebase.app().functions('europe-west1').httpsCallable('chooseGameTypeEU');

          chooseGameTypeFunction({
            roomId,
            selectedType,
            //  token,
          }).then((res) => {
            console.log('chooseGameType res', { res });
            if (res && res.data && res.data.data === 'success' && res.data.roomData) {
              console.log('chooseGameType1', { res });
              getCardsOnTable();
              dispatch({ type: 'CURRENT_TABLE_REPLACE', data: [], roomId });
              dispatch({ type: 'USER_ACTION_RESPONSE', data: res.data.roomData });
            }

            /*  if (res && res.data && (res.data.error === 'selection not commited' || res.data.data === 'not talking' || res.data.data === 'Failed to select' || res.data.data === 'Failed to choose' || res.data.data === 'err: cards not dealt')) {
              roomDataFallback(roomId, dispatch).then((res) => {
                getCardsOnce(roomId);
              });

              setTimeout(() => {
                roomDataFallback(roomId, dispatch);
              }, 2000);
            } */

            return resolve();
          }).catch((err) => {
            console.log('chooseGameType err', { err });
            return resolve();
          });
        }
      }, 1700);

      Event('Game', 'Choose game type', null);

      RoomsRef.child(`lastAction/${roomId}`).update({
        pl: UID,
        time: Firebase.database.ServerValue.TIMESTAMP,
        type: 'choose',
      }).catch((err) => {
        console.log(err);
      });

      RoomsRef.child(`roomsChosenType/${roomId}/type`).set(selectedType).then(() => {
        successfullUpdate = true;
        clearTimeout(timer);

        return resolve();
      }).catch((err) => {
        console.log('roomsChosenType on error');
        console.log(err);
      });
    } catch (error) {
      console.error(error);
    }
  });
}

export function setLastRound(roomId, init) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(() => {
    const setLastRoundFunction = Firebase.app()
      .functions()
      .httpsCallable('setLastRound');

    setLastRoundFunction({
      roomId,
      init: !!init,
    });

    Event('Game', 'Set last round', null);
  });
}

export function startUnlimitedTimer(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(() => {
    const startUnlimitedTimerFunction = Firebase.app()
      .functions()
      .httpsCallable('startUnlimitedTimer');

    startUnlimitedTimerFunction({ roomId });

    // Event('Game', 'Set last round', null);
  });
}

export function quitRound(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const quitRoundFunction = Firebase.app().functions('europe-west1').httpsCallable('quitRoundEU');

    quitRoundFunction({
      roomId,
    }).then((res) => {
      if (res && res.data && res.data.data === 'success' && res.data.roomData) {
        dispatch({ type: 'USER_ACTION_RESPONSE', data: res.data.roomData });

        if (res.data.roomData.currentTable && res.data.roomData.currentTable.length === 3) {
          setTimeout(() => (dispatch({ type: 'USER_ACTION_RESPONSE_TABLE', data: { currentTable: [] } })), 200);
        }
      }

      if (res && res.data && res.data.status === 'error' && (res.data.error === 'not success' || res.data.error === 'not large player' || res.data.error === 'already quit' || res.data.error === 'game state is burry' || res.data.error === 'game state is not play')) {
        roomDataFallback(roomId, dispatch);
      }

      return resolve();
    }).catch((err) => {
      console.log(err);
      return resolve();
    });

    Event('Game', 'Quit round', null);
  });
}

export function closeResultNotification(roomId, init) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(() => {
    const closeResultNotificationFunction = Firebase.app()
      .functions()
      .httpsCallable('closeResultNotification2');

    closeResultNotificationFunction({
      roomId,
      init,
    }).then((res) => {
      dispatch({ type: 'CURRENT_TABLE_REPLACE', data: [], roomId });

      if (res && res.data && res.data.status === 'error' && (res.data.error === 'gameState not results' || res.data.error === 'error storing globalParams' || res.data.error === 'card not commited')) {
        roomDataFallback(roomId, dispatch);
      }
    }).catch((err) => {
      console.log(err);
    });
  });
}

export function resetGameStore(lastRoom, roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
      && Firebase
      && Firebase.auth()
      && Firebase.auth().currentUser
      && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (lastRoom) {
    RoomsRef.child(`rooms/${lastRoom}/points`).off();
    RoomsRef.child(`roomsPoints/${lastRoom}/points`).off();
    RoomsRef.child(`roomsPoints/${lastRoom}/points/total`).off();
    RoomsRef.child(`roomsPoints/${lastRoom}/points/rounds`).off();

    RoomsRef.child(`rooms/${lastRoom}/globalParams`).off();
    RoomsRef.child(`roomsStaticData/${lastRoom}/globalParams`).off();
    RoomsRef.child(`rooms/${lastRoom}/playersList`).off();

    RoomsRef.child(`rooms/${lastRoom}/players/player1/cards`).off();
    RoomsRef.child(`rooms/${lastRoom}/players/player2/cards`).off();
    RoomsRef.child(`rooms/${lastRoom}/players/player3/cards`).off();
    RoomsRef.child(`rooms/${lastRoom}/players/player4/cards`).off();

    RoomsRef.child(`rooms/${lastRoom}/players/player1/automated`).off();
    RoomsRef.child(`rooms/${lastRoom}/players/player2/automated`).off();
    RoomsRef.child(`rooms/${lastRoom}/players/player3/automated`).off();
    RoomsRef.child(`rooms/${lastRoom}/players/player4/automated`).off();

    RoomsRef.child(`rooms/${lastRoom}/playersList/player1`).off();
    RoomsRef.child(`rooms/${lastRoom}/playersList/player2`).off();
    RoomsRef.child(`rooms/${lastRoom}/playersList/player3`).off();
    RoomsRef.child(`rooms/${lastRoom}/playersList/player4`).off();

    RoomsRef.child(`roomsStaticData/${lastRoom}/playersList/player1`).off();
    RoomsRef.child(`roomsStaticData/${lastRoom}/playersList/player2`).off();
    RoomsRef.child(`roomsStaticData/${lastRoom}/playersList/player3`).off();
    RoomsRef.child(`roomsStaticData/${lastRoom}/playersList/player4`).off();

    RoomsRef.child(`rooms/${lastRoom}/curRnd/currentTurn`).off();
    RoomsRef.child(`rooms/${lastRoom}/curRnd/currentTable`).off();
    RoomsRef.child(`rooms/${lastRoom}/curRnd/type`).off();
    RoomsRef.child(`rooms/${lastRoom}/curRnd/largePlayer`).off();
    RoomsRef.child(`rooms/${lastRoom}/curRnd/cardPlayed`).off();
    RoomsRef.child(`rooms/${lastRoom}/nextTimestamp`).off();
    RoomsRef.child(`rooms/${lastRoom}/nT`).off();

    RoomsRef.child(`rooms/${lastRoom}/gifts`).off();

    if (UID) {
      RoomsRef.child(`rooms/${lastRoom}/playersList/playerList/${UID}`).off();
    }

    //  cancelRoomsRef;

    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'RESET_STORE', roomId })));
  }
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'RESET_STORE', roomId })));
}

export function setCurRoomId(roomId) {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'SET_ROOM_ID', data: roomId })));
}

export function getRoomDbStatus() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return () => new Promise((resolve) => {
    const connectedRef = RoomsRef.child('.info/connected');
    connectedRef.on('value', (snap) => {
      if (snap.val() === true) {
        return resolve();
      }
      //  reconnectRoomsRef;

      return resolve();
    });
  });
}


export function fetchInitialRoomData(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise(async (resolve) => {
    const { game } = getState();

    let token;

    await Firebase.auth().currentUser.getIdToken().then((tokenRes) => {
      token = tokenRes;

      dispatch({ type: 'SET_LAST_TOKEN', data: tokenRes });
    }).catch(() => {
      if (game && game.lastToken) {
        token = game.lastToken;
      }
    });

    try {
      await axios.post('https://us-central1-zole-app.cloudfunctions.net/fetchInitialRoomData2', { data: { roomId, token } }, {
        headers: {
          'Content-Type': 'text/plain',
        },
        timeout: 30000,
      })
        .then((response) => {
          if (response && response.data && response.data.data) {
            return resolve(dispatch({ type: 'ROOM_INITIAL_DATA', data: response.data.data, uid: UID }));
          }
          return resolve();
        })
        .catch((error) => {
          console.log('fetchInitialRoomData catch');
          console.log(error);
        });
    } catch (error) {
      console.log('fetchInitialRoomData error');
      console.error(error);
    }
  });
}

export function sendFriend(selectId) {
  console.log(selectId, "sendFriend");
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return () => new Promise(resolve => resolve({ type: SERVER_STATUS_CODE.success, data: {} }));

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(async (resolve, reject) => {
    console.log(selectId, "sendFriend");
    await FirebaseRef.child(`users/${UID}`).once('value', async (snapshot) => {
      const sendUser = await snapshot.val();
      const inviteUserQuery = await FirebaseRef.child(`users/${selectId}`).once('value');
      const inviteUser = await inviteUserQuery.val();

      const inviteUsersListQuery = await FirebaseRef.child('refferals').orderByChild('invitedBy').equalTo(sendUser.uid).once('value');
      const inviteUsersList = await inviteUsersListQuery.val();
      const invitedUsersListQuery = await FirebaseRef.child('refferals').orderByChild('invitedBy').equalTo(inviteUser.uid).once('value');
      const invitedUsersList = await invitedUsersListQuery.val();

      console.log(inviteUser, inviteUsersList, invitedUsersList, "sendFriend");

      if (size(filter(inviteUsersList, (item, key) => { return item.claimedUid === inviteUser.uid; })) > 0
        || size(filter(invitedUsersList, (item, key) => { return item.claimedUid === sendUser.uid; }) > 0)
      ) {
        console.log(inviteUser, inviteUsersList, invitedUsersList, "sendFriend error");
        return resolve({ type: SERVER_STATUS_CODE.error, data: { message: 'already_sent' } });
      }

      const refferalToken = uuidv1();
      console.log(inviteUser, inviteUsersList, invitedUsersList, refferalToken, "sendFriend success");
      await FirebaseRef.child(`refferals/${refferalToken}`).set({
        invitedByName: sendUser.name,
        invitedByEmail: sendUser.email,
        invitedBy: sendUser.uid,
        inviteEmail: inviteUser.email,
        code: refferalToken,
        claimedUid: inviteUser.uid,
        claimedName: inviteUser.name,
        claimed: false,
      }).then(() => resolve({ status: SERVER_STATUS_CODE.success, data: { message: 'sent' } })).catch((err) => {
        console.log(err);
        return reject({ status: SERVER_STATUS_CODE.error });
      });
    });
  });
}
