import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Firebase } from '../../lib/firebase';

import { PageView, initGA } from '../../web/components/Tracking';

import {
  createRoom,
  getRooms,
  getRoomsRework,
  getRoomsStudentZole,
  joinRoom,
  leaveRoomMenu,
  cancelRoomsListeners,
  getGifts,
} from '../../actions/room';

import {
  refreshLastToken,
} from '../../actions/game';

import {
  getTournaments,
  getMyTournamentsData,
  closeTournamentResults,
} from '../../actions/tournaments';

import {
  checkLoginState,
  getTimeOffset,
  disableTutorial,
  disableFirstTimeNotif,
  setSupportAsRead,
  getBalanceHistory,
  getPointsHistory,
  getAchievements,
  updateUserLastLogin,
  updateUserLastAction,
  closeLevelNotification,
  logout,
  cancelMenuListeners,
  setUsersActiveRoom,
  setUsersJoinedRoom,
  getGiftsHistory,
  removeUserStatus,
  requestDeleteUser,
  updateProfile,
  updateAvatarFileUpload,
  checkWeeklyData,
  checkWeeklyDataOpenFunction,
  setUserScreenView,
  checkCursorState,
} from '../../actions/member';

import {
  getUserCount,
  getRoomsCount,
} from '../../actions/users';

import { setLoading } from '../../actions/state';

import {
  getLeaderboard,
  getPositionInLeaderboard,
} from '../../actions/leaderboard';

import startGameSound from '../../sounds/game_start.wav';
import buttonClickedSound from '../../sounds/click_feedback.flac';
import { DWEB_PLATFORM, SCREEN_MODE } from '../../constants/constants';

class Menu extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    state: PropTypes.shape({
      isLoading: PropTypes.bool,
    }),
    member: PropTypes.shape({
      joinedRooms: PropTypes.shape({}),
      uid: PropTypes.string,
      activeRoom: PropTypes.bool,
    }),
    soundOn: PropTypes.bool,
    users: PropTypes.shape({
      roomCount: PropTypes.number,
      userCount: PropTypes.number,
    }),
    //  tournaments: PropTypes.shape({}),
    hasActiveRegistration: PropTypes.bool,
    myTournamentsData: PropTypes.shape({}),
    notificationSettings: PropTypes.shape({}),
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    emailVerifySuccess: PropTypes.bool,
    fetchFreeRooms: PropTypes.func.isRequired,
    fetchRoomsStudentZole: PropTypes.func.isRequired,
    createNewRoom: PropTypes.func.isRequired,
    connectToRoom: PropTypes.func.isRequired,
    checkLogin: PropTypes.func.isRequired,
    fetchLeaderboard: PropTypes.func.isRequired,
    fetchPositionInLeaderboard: PropTypes.func.isRequired,
    fetchTournaments: PropTypes.func.isRequired,
    fetchMyTournamentsData: PropTypes.func.isRequired,
    closeTournamentResult: PropTypes.func.isRequired,
    refreshDbLastToken: PropTypes.func.isRequired,
    fetchRoomsCount: PropTypes.func.isRequired,
    fetchUserCount: PropTypes.func.isRequired,
    disableFirstTime: PropTypes.func.isRequired,
    disableTut: PropTypes.func.isRequired,
    fetchBalanceHistory: PropTypes.func.isRequired,
    fetchPointsHistory: PropTypes.func.isRequired,
    setSupportRead: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    fetchAchievements: PropTypes.func.isRequired,
    leaveRoom: PropTypes.func.isRequired,
    updateLastLogin: PropTypes.func.isRequired,
    closeLevelUpNotification: PropTypes.func.isRequired,
    cancelRoomListeners: PropTypes.func.isRequired,
    cancelMenusListeners: PropTypes.func.isRequired,
    doLogout: PropTypes.func.isRequired,
    doSetLoading: PropTypes.func.isRequired,
    updateLastAction: PropTypes.func.isRequired,
    fetchGiftsHistory: PropTypes.func.isRequired,
    fetchGifts: PropTypes.func.isRequired,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    toggleFullscreen: PropTypes.func.isRequired,
    setActiveRoom: PropTypes.func.isRequired,
    removeUserStatusFunc: PropTypes.func.isRequired,
    requestDeleteUserFunc: PropTypes.func.isRequired,
    updateProfileFunc: PropTypes.func.isRequired,
    updateAvatarFileUploadFunc: PropTypes.func.isRequired,
    toggleNewDesign: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
    toggleScreenMode: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    toggleFont: PropTypes.func.isRequired,
    checkWeeklyDataFunc: PropTypes.func.isRequired,
    checkWeeklyDataOpenFunc: PropTypes.func.isRequired,
    weeklyDataOpen: PropTypes.bool.isRequired,
    weeklyDataCheck: PropTypes.number.isRequired,
    isEventOption: PropTypes.bool,
    toggleEvent: PropTypes.func.isRequired,
    setScreenView: PropTypes.func.isRequired,
    checkCursorStateFunc: PropTypes.func.isRequired,
    designPlatform: PropTypes.shape({}),
    platform: PropTypes.string,
  };

  static defaultProps = {
    match: null,
    state: {},
    member: {},
    users: {},
    //  tournaments: {},
    hasActiveRegistration: false,
    myTournamentsData: {},
    history: {},
    soundOn: false,
    notificationSettings: {},
    isFullscreen: false,
    isFullscreenEnabled: false,
    emailVerifySuccess: false,
    screenMode: SCREEN_MODE.normal,
    weeklyDataOpen: null,
    weeklyDataCheck: null,
    isEventOption: false,
    designPlatform: {},
    platform: DWEB_PLATFORM
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDataLoading: false,
      loadingProgress: 20,
      uid: null,
      errorNotification: '',
      ignoredMessageName: '',
      ignoredMessageUid: '',
      closeErrorSubmit: false,
      privateRoomPassword: '',
      showPrivateRoomPassword: false,
      insufficientBalanceAmount: null,
      insufficientBalanceBet: null,
    };

    this.checkLoginState = this.checkLoginState.bind(this);
    this.handleFBLogin = this.handleFBLogin.bind(this);
    this.resetErrorNotif = this.resetErrorNotif.bind(this);
    //  this.resetCloseErrorSubmit = this.resetCloseErrorSubmit.bind(this);

    this.startGameAudio = new Audio(startGameSound);
    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount = () => {
    const {
      fetchRoomsCount,
      fetchUserCount,
      refreshDbLastToken,
      fetchAchievements,
      updateLastLogin,
      fetchGiftsHistory,
      fetchGifts,
      fetchTournaments,
      fetchMyTournamentsData,
      checkWeeklyDataOpenFunc,
      checkCursorStateFunc,
    } = this.props;

    try {
      initGA('UA-147571548-1');
      PageView();
    } catch (err) {
      console.log('err 1');
    }

    try {
      fetchRoomsCount();
      fetchUserCount();
      fetchGifts();
    } catch (err) {
      console.log('err 2');
    }

    try {
      this.fetchRooms();
      this.fetchLeaderboard();
    } catch (err) {
      console.log('err 3');
    }

    try {
      window.addEventListener('beforeunload', this.leaveRoomOnUnload);
    } catch (err) {
      console.log('err');
    }

    //  window.addEventListener("beforeunload", () => { console.log('added beforeunload'); this.leaveRoomOnUnload; });

    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    this.unsub = Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('user', { user });
        fetchAchievements();
        fetchTournaments(devMode);
        fetchMyTournamentsData();

        refreshDbLastToken();
        updateLastLogin();

        this.fetchPositionInLeaderboard();
        fetchGiftsHistory();
        checkCursorStateFunc();

      //  setTimeout(() => {
          this.setState({
            uid: user.uid,
            //  myTournamentDataLoading: false,
            userDataLoading: false,
          });
      //  }, 20);
      } else {
        this.setState({
          uid: null,
        });
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    const {
      history, member, updateLastAction, refreshDbLastToken, soundOn,
    } = nextProps;
    const { userDataLoading } = this.state;

    if (!userDataLoading && member && member.uid && member.activeRoom) {
      updateLastAction();
      refreshDbLastToken();

      if (soundOn) {
      //  const userSettings2 = userSettings[member.uid] || userSettings.default || {};

        //  if (userSettings2 && userSettings2.soundOn) {
        const playPromise = this.startGameAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      //  }
      }
      console.log('push to room');
      history.push(`/zole/${member.activeRoom}`);
    } else if (member && member.uid && member.activeRoom) {
    //  console.log(member);
    //  console.log('data', { userDataLoading });
    }
  }

  componentWillUnmount() {
    const {
      cancelRoomListeners,
      cancelMenusListeners,
    } = this.props;

    try {
      this.unsub();

      window.removeEventListener('beforeunload', this.leaveRoomOnUnload);

      cancelRoomListeners();
      cancelMenusListeners();
    } catch(err) {
      console.log('err componentWillUnmount');
    }
  }

  leaveRoomOnUnload = () => {
  //  event.preventDefault();
    const { removeUserStatusFunc } = this.props;

    removeUserStatusFunc();

  /*  console.log('leaveRoomOnUnload', { member });

    if (member && member.joinedRooms) {
      console.log('leaveRoomOnUnload', { joinedRooms: member.joinedRooms });

      console.log('leaveRoomOnUnload', { joinedRoom: Object.keys(member.joinedRooms)[0] });

      let headers = {
        type: 'application/json'
      };

      let blob = new Blob([JSON.stringify({ roomId: Object.keys(member.joinedRooms)[0] })], headers);
      navigator.sendBeacon('url', blob);

      navigator.sendBeacon('https://us-central1-zole-app.cloudfunctions.net/leaveRoomMenu', JSON.stringify({ roomId: Object.keys(member.joinedRooms)[0] }));
    //  navigator.sendBeacon('https://us-central1-zole-app.cloudfunctions.net/leaveRoomMenu', { roomId: Object.keys(member.joinedRooms)[0] });

      // more safely (optional...?)
    //  var until = new Date().getTime() + 1000;
    //  while (new Date().getTime() < until);
  } */
  }

  fetchRooms = (data) => {
    const { fetchFreeRooms, fetchRoomsRework, fetchRoomsStudentZole, match } = this.props;

    console.log('fetchRooms match', { match });

    fetchRoomsStudentZole(data);

    return fetchRoomsRework(data)
      .then(() => {
        const { loadingProgress } = this.state;
        this.setState({
          //    roomsLoading: false,
          loadingProgress: loadingProgress + 20,
        });
      })
      .catch((err) => {
        const { loadingProgress } = this.state;
        this.setState({
          //    roomsLoading: false,
          loadingProgress: loadingProgress + 20,
          error: err,
        });
      });
  };

   fetchLeaderboard = () => {
     const {
       fetchLeaderboard,
     } = this.props;

     return fetchLeaderboard()
       .then(() => {
         const { loadingProgress } = this.state;
         this.setState({
           loadingProgress: loadingProgress + 20,
         });
       })
       .catch((err) => {
         const { loadingProgress } = this.state;
         this.setState({
           loadingProgress: loadingProgress + 20,
           error: err,
         });
       });
   };

  fetchPositionInLeaderboard = () => {
    const {
      fetchPositionInLeaderboard,
    } = this.props;

    return fetchPositionInLeaderboard()
      .then(() => {})
      .catch(err => this.setState({
        error: err,
      }));
  };

  createRoom = (parasta, M, atra, pro, bet, maza, privateRoom, minGames, fourPRoom, speed) => {
    const {
      createNewRoom,
    } = this.props;

    return new Promise(resolve => createNewRoom(parasta, M, atra, pro, bet, maza, privateRoom, minGames, fourPRoom, speed)
      .then((res) => {
        if (res.data.data.status === 'success') {
          if (res.data.data.password) {
            this.setState({
              privateRoomPassword: res.data.data.password,
              showPrivateRoomPassword: true,
            });
          }

          return resolve(res.data.data);
        } if (res.data.data.status === 'error') {
          this.setState({
            errorNotification: res.data.data.error,
            insufficientBalanceAmount: res.data.data.balNeeded || null,
            insufficientBalanceBet: bet,
          });

          return resolve(res.data.data);
        }

        return resolve(res.data.data);
      })
      .catch((err) => {
        this.setState({ error: err });

        return resolve({ status: 'error', error: err });
      }));
  };

  closePrivateRoomPassword = () => {
    this.setState({ showPrivateRoomPassword: false });
  };

  joinRoom = ({
    roomId, password, bet, fourPRoom,
  }) => new Promise((resolve) => {
    const {
      connectToRoom, setActiveRoom,
    } = this.props;

    connectToRoom(roomId, password, fourPRoom)
      .then((res) => {
        if (res.data.data.status === 'success') {
          if (res.data.data.isStarted) {
            setActiveRoom(roomId);
          }
        } else if (res.data.data.status === 'error') {
          if (res.data.data.error === 'Ignored') {
            if (res.data.data.type === 'you ignored') {
              this.setState({
                errorNotification: 'You ignored player',
                ignoredMessageName: res.data.data.name,
                ignoredMessageUid: res.data.data.uid,
              });
            } else {
              this.setState({
                errorNotification: 'Player ignored you',
                ignoredMessageName: res.data.data.name,
              });
            }
          } else {
            this.setState({
              errorNotification: res.data.data.error,
              insufficientBalanceAmount: res.data.data.balNeeded || null,
              insufficientBalanceBet: bet || null,
            });
          }
        }

        return resolve(res.data.data);
      })
      .catch((err) => {
        console.log('joinRoom error', { err });

        this.setState({
          error: err,
        });

        return resolve();
      });
  });

  leaveRoom = (roomId) => {
    const { leaveRoom } = this.props;

    leaveRoom(roomId);

    this.setState({ privateRoomPassword: '', showPrivateRoomPassword: false });
  };

  resetErrorNotif = () => {
    this.setState({ errorNotification: '' });
  };

  disableTutorial = () => {
    const { disableTut } = this.props;

    return disableTut().catch((err) => {
      console.log(err);
    });
  };

  disableFirstTimeNotif = () => {
    const { disableFirstTime } = this.props;

    return disableFirstTime().catch((err) => {
      console.log(err);
    });
  };

  fetchBalanceHistory = (time, offset) => {
    const { fetchBalanceHistory } = this.props;

    return fetchBalanceHistory(time, offset).catch((err) => {
      console.log(err);
    });
  };

  fetchPointsHistory = (time, offset) => {
    const { fetchPointsHistory } = this.props;

    return fetchPointsHistory(time, offset).catch((err) => {
      console.log(err);
    });
  };

  playButtonSound = () => {
    const { soundOn } = this.props;
    //  const { uid } = this.state;

    if (soundOn) {
    //  const userSettings2 = userSettings[uid] || userSettings.default || {};

      //  if (userSettings2 && userSettings2.soundOn) {
      const playPromise = this.buttonClickedAudio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
            console.log("audio played auto");
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("playback prevented");
          });
      }
    //  }
    }
  }

  handleFBLogin() {
    const { checkLogin } = this.props;

    window.FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        this.checkLoginState(response);
      } else if (response.status === 'not_authorized') {
        window.FB.login((response2) => {
          if (response2.authResponse) {
            checkLogin(response2).then(() => {});
          } else {
            //  console.log('User cancelled login or did not fully authorize.');
          }
        });
      } else if (window.FB) {
        window.FB.login((response2) => {
          if (response2.authResponse) {
            checkLogin(response2).then(() => {
              //  console.log(res);
            });
          } else {
            checkLogin(response2).then(() => {
              //  console.log(res);
            });
          }
        });
      }
    });
  }

  checkLoginState() {
    window.FB.getLoginStatus((response) => {
      const { checkLogin } = this.props;

      checkLogin(response).then(() => {
        //  console.log(res);
      });
    });
  }

  closeWeeklyData = () => {
    const { checkWeeklyDataFunc } = this.props;
    const nowDate = new Date();
    let first = nowDate.setDate(nowDate.getDate() - nowDate.getDay() + (nowDate.getDay() == 0 ? -6 : 1));

    checkWeeklyDataFunc(false, first);
  }

  render = () => {
    const {
      Layout,
      match,
      member,
      //  tournaments,
      hasActiveRegistration,
      myTournamentsData,
      showNotification,
      soundOn,
      closeLevelUpNotification,
      cancelRoomListeners,
      fetchFreeRooms,
      doLogout,
      history,
      doSetLoading,
      state,
      closeTournamentResult,
      updateLastAction,
      setActiveRoom,
      setSupportRead,
      isFullscreen,
      isFullscreenEnabled,
      toggleFullscreen,
      notificationSettings,
      requestDeleteUserFunc,
      updateProfileFunc,
      updateAvatarFileUploadFunc,
      emailVerifySuccess,
      toggleNewDesign,
      screenMode,
      toggleScreenMode,
      fontMode,
      toggleFont,
      isInAppFrame,
      weeklyDataOpen,
      isEventOption,
      toggleEvent,
      checkWeeklyDataOpenFunc,
      setScreenView,
      designPlatform,
      platform,
    } = this.props;

    console.log('screenMode', screenMode);

    const {
      error,
      uid,
      loadingProgress,
      errorNotification,
      ignoredMessageName,
      ignoredMessageUid,
      closeErrorSubmit,
      privateRoomPassword,
      showPrivateRoomPassword,
      insufficientBalanceAmount,
      insufficientBalanceBet,
    } = this.state;

    const id = match && match.params && match.params.id ? match.params.id : null;

    const loading = !(member && member.uid);

    if (state.isLoading !== loading) {
      setScreenView('loading');
      doSetLoading(loading);
    }

    return (
      <Layout
        isFullscreen={isFullscreen}
        isFullscreenEnabled={isFullscreenEnabled}
        toggleFullscreen={toggleFullscreen}
        isInAppFrame={isInAppFrame}
        uid={uid}
        gameId={id}
        error={error}
        errorNotification={errorNotification}
        insufficientBalanceAmount={insufficientBalanceAmount}
        insufficientBalanceBet={insufficientBalanceBet}
        ignoredMessageName={ignoredMessageName}
        ignoredMessageUid={ignoredMessageUid}
        resetErrorNotif={this.resetErrorNotif}
      //  tournaments={tournaments}
        hasActiveRegistration={hasActiveRegistration}
        myTournamentsData={myTournamentsData}
        loading={loading}
        loadingProgress={loadingProgress}
        member={member}
        privateRoomPassword={privateRoomPassword}
        showPrivateRoomPassword={showPrivateRoomPassword}
        closePrivateRoomPassword={this.closePrivateRoomPassword}
        leaveRoom={this.leaveRoom}
        closeErrorSubmit={closeErrorSubmit}
        showNotification={showNotification}
        createRoom={this.createRoom}
        joinRoom={this.joinRoom}
        handleFBLogin={this.handleFBLogin}
        closeTournamentResults={closeTournamentResult}
        disableTutorial={this.disableTutorial}
        disableFirstTimeNotif={this.disableFirstTimeNotif}
        setSupportAsRead={setSupportRead}
        fetchBalanceHistory={this.fetchBalanceHistory}
        fetchPointsHistory={this.fetchPointsHistory}
        changeSortFilter={this.changeSortFilter}
        changeSortDirection={this.changeSortDirection}
        closeLevelNotification={closeLevelUpNotification}
        soundOn={soundOn}
        cancelRoomListeners={cancelRoomListeners}
        fetchFreeRooms={fetchFreeRooms}
        playButtonSound={this.playButtonSound}
        updateLastAction={updateLastAction}
        logout={doLogout}
        history={history}
        setActiveRoom={setActiveRoom}
        notificationSettings={notificationSettings}
        requestDeleteUser={requestDeleteUserFunc}
        updateProfile={updateProfileFunc}
        updateAvatarFileUpload={updateAvatarFileUploadFunc}
        emailVerifySuccess={emailVerifySuccess}
        toggleNewDesign={toggleNewDesign}
        closeWeeklyData={this.closeWeeklyData}
        screenMode={screenMode}
        toggleScreenMode={toggleScreenMode}
        fontMode={fontMode}
        toggleFont={toggleFont}
        isEventOption={isEventOption}
        toggleEvent={toggleEvent}
        checkWeeklyDataOpenFunc={checkWeeklyDataOpenFunc}
        designPlatform={designPlatform}
        platform={platform}
      />
    );
  };
}

const mapStateToProps = state => ({
  member: state.member || {},
  weeklyDataOpen: state.member.weeklyDataOpen || null,
  weeklyDataCheck: state.member.weeklyDataCheck || null,
  //  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : [],
  hasActiveRegistration: state.tournaments.hasActiveRegistration || false,
  myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
  state: state.state || {},
  notificationSettings: state.userSettings ? (state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : {})) : {},
  // soundOn: state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid].soundOn) : (state.userSettings.default ? state.userSettings.default.soundOn : {}),
  soundOn: state.userSettings.soundOn,
});

const mapDispatchToProps = {
  fetchFreeRooms: getRooms,
  fetchRoomsRework: getRoomsRework,
  fetchRoomsStudentZole: getRoomsStudentZole,
  createNewRoom: createRoom,
  connectToRoom: joinRoom,
  checkLogin: checkLoginState,
  fetchLeaderboard: getLeaderboard,
  fetchPositionInLeaderboard: getPositionInLeaderboard,
  fetchTournaments: getTournaments,
  fetchMyTournamentsData: getMyTournamentsData,
  closeTournamentResult: closeTournamentResults,
  refreshDbLastToken: refreshLastToken,
  fetchUserCount: getUserCount,
  fetchRoomsCount: getRoomsCount,
  getOffset: getTimeOffset,
  disableTut: disableTutorial,
  disableFirstTime: disableFirstTimeNotif,
  setSupportRead: setSupportAsRead,
  fetchBalanceHistory: getBalanceHistory,
  fetchPointsHistory: getPointsHistory,
  fetchAchievements: getAchievements,
  leaveRoom: leaveRoomMenu,
  cancelRoomListeners: cancelRoomsListeners,
  cancelMenusListeners: cancelMenuListeners,
  updateLastLogin: updateUserLastLogin,
  updateLastAction: updateUserLastAction,
  closeLevelUpNotification: closeLevelNotification,
  doLogout: logout,
  doSetLoading: setLoading,
  setActiveRoom: setUsersActiveRoom,
  setJoinedRoom: setUsersJoinedRoom,
  fetchGiftsHistory: getGiftsHistory,
  fetchGifts: getGifts,
  removeUserStatusFunc: removeUserStatus,
  requestDeleteUserFunc: requestDeleteUser,
  updateProfileFunc: updateProfile,
  updateAvatarFileUploadFunc: updateAvatarFileUpload,
  checkWeeklyDataFunc: checkWeeklyData,
  checkWeeklyDataOpenFunc: checkWeeklyDataOpenFunction,
  setScreenView: setUserScreenView,
  checkCursorStateFunc: checkCursorState,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Menu);

export default Container;
