import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { filter } from 'lodash';

import {
  IoMdClose,
} from 'react-icons/io';
import isEqual from 'react-fast-compare';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Form from 'reactstrap/lib/Form';
import Label from 'reactstrap/lib/Label';

import ScrollArea from 'react-scrollbar';
import ScrollAreaButtons from '../UI/ScrollAreaButtons';
import Message from './Message';
import FilteredMessage from './FilteredMessage';

import chatIcon from '../../../images/icons/chat.webp';
import supportIcon from '../../../images/icons/info_chats.svg';
import emotionIcon from '../../../images/icons/emo.svg';
import closeImg from '../../../images/icons/close.png';

import chatInSound from '../../../sounds/chat_notification.wav';
import * as constants from '../../../constants/constants';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DateFormat from '../UI/DateFormat';
import CountDownDate from '../UI/CountDownDate';

const MAX_LENGTH = 200;
class Chat extends React.Component {
  static propTypes = {
    chatMessages: PropTypes.shape(),
    uid: PropTypes.string,
    emotions: PropTypes.shape(),
    toggleChat: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    setEmotion: PropTypes.func.isRequired,
    toggleSupport: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    fetchEmotions: PropTypes.func.isRequired,
    supportChatStatus: PropTypes.shape(),
    i18n: PropTypes.shape(),
    t: PropTypes.func.isRequired,
  //  userSettings: PropTypes.shape().isRequired,
    openChat: PropTypes.bool,
    fontMode: PropTypes.string,
    profanitiesList: PropTypes.shape(),
    cursor: PropTypes.bool,
    cursorExpire: PropTypes.number,
    profanitiesList: PropTypes.shape(),
    reportedMessages: PropTypes.shape({}),
    name: PropTypes.string,
  }

  static defaultProps = {
    chatMessages: {},
    uid: null,
    emotions: {},
    openChat: false,
    supportChatStatus: null,
    i18n: null,
    fontMode: constants.FONT_MODE.normal,
    profanitiesList: {},
    openCursorModal: false,
    cursor: false,
    cursorExpire: null,
    reportedMessages: {},
    name: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      inputMessage: '',
      openEmotions: false,
      unreadMessages: 0,
      firstOpen: false,
      scrollPos: 0,
      smoothScrolling: false,
      isSending: false,
      messagesByZole: true,
      messagesByPlayers: true,
      messagesShow: constants.ALL,
    };


    this.chatInAudio = new Audio(chatInSound);
  }

  componentDidMount() {
    this.scrollToBottom();

    setTimeout(() => {
      this.setState({ smoothScrolling: true });
    }, 3500);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { openChat } = this.props;
    if (nextProps.openChat !== openChat) {
      return true;
    }

    if (!isEqual(nextProps, this.props)) {
      this.scrollToBottom();
      return true;
    }

    if (!isEqual(nextState, this.state)) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const {
      chatMessages, openChat, supportChatStatus, soundOn,
    } = this.props;
    const oldSupportChatStatus = prevProps.supportChatStatus;
    const oldChatMessages = prevProps.chatMessages;
    const oldOpenChat = prevProps.openChat;
    const { unreadMessages } = this.state;

    if (openChat && !oldOpenChat) {
      this.scrollToBottom();
      this.setState({ unreadMessages: 0, openChat });
      return;
    } if (!openChat && oldOpenChat) {
      this.setState({ unreadMessages: 0, openChat });
      return;
    }

    if (!openChat && chatMessages && chatMessages.messages) {
      let newMessages = unreadMessages;
      // const reverseMessageKeys = Object.keys(chatMessages.messages).reverse();
      const reverseMessageKeys = Object.keys(chatMessages.messages);

      /*  for (const key of reverseMessageKeys) {
        if (!oldChatMessages.messages[key] && chatMessages.messages[key].userUid !== 'game') {
          newMessages++;
        } else if (oldChatMessages.messages[key] && oldChatMessages.messages[key].userUid !== 'game') {
          break;
        }
        return null;
      } */
      let breakMap = false;
      reverseMessageKeys.map((key) => {
        if (!breakMap) {
          if (!oldChatMessages.messages[key] && chatMessages.messages[key].userUid !== 'game') {
            newMessages += 1;
          } else if (oldChatMessages.messages[key] && oldChatMessages.messages[key].userUid !== 'game') {
            breakMap = true;
          }
        }
        return null;
      });

      if (soundOn) {
        if (unreadMessages !== newMessages) {
          const playPromise = this.chatInAudio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(_ => {
                // Automatic playback started!
                // Show playing UI.
                console.log("audio played auto");
              })
              .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                console.log("playback prevented");
              });
          }
        } else if ((!oldSupportChatStatus || oldSupportChatStatus.read === true) && supportChatStatus && supportChatStatus.read === false) {
          const playPromise = this.chatInAudio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(_ => {
                // Automatic playback started!
                // Show playing UI.
                console.log("audio played auto");
              })
              .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                console.log("playback prevented");
              });
          }
        }
      }

      this.setState({ unreadMessages: newMessages });
    }
  }

  scrollToBottom = () => {
    if (this.messagesScrollbar) {
      setTimeout(() => {
        if (this.messagesScrollbar) {
          this.messagesScrollbar.scrollBottom();
        }
      }, 0);
    }
  }

  scrollToBottomInstant = () => {
    if (this.messagesScrollbar) {
      this.messagesScrollbar.scrollBottom();
    }
  }

  handleChange = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      if (value.length <= MAX_LENGTH) {
        this.setState({
          [event.target.name]: value,
        });
      }
    }
  }

  handleShowZole = () => {
    const { messagesByPlayers, messagesByZole } = this.state;

    const zoleStatus = !messagesByZole;
    let messageStatus;
    if (messagesByPlayers && zoleStatus) {
      messageStatus = constants.ALL;
    } else if (messagesByPlayers && !zoleStatus) {
      messageStatus = constants.PLAYER;
    } else if (!messagesByPlayers && zoleStatus) {
      messageStatus = constants.GAME;
    } else {
      messageStatus = constants.None;
    }

    this.setState({
      messagesByZole: zoleStatus,
      messagesShow: messageStatus,
    });
  }

  handleShowPlayers = () => {
    const { messagesByPlayers, messagesByZole } = this.state;

    const playerStatus = !messagesByPlayers;
    let messageStatus;
    if (messagesByZole && playerStatus) {
      messageStatus = constants.ALL;
    } else if (messagesByZole && !playerStatus) {
      messageStatus = constants.GAME;
    } else if (!messagesByZole && playerStatus) {
      messageStatus = constants.PLAYER;
    } else {
      messageStatus = constants.None;
    }

    this.setState({
      messagesByPlayers: playerStatus,
      messagesShow: messageStatus,
    });
  }

  handleEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.handleSubmit();
    }
  }

  handleSubmit = () => {
    const { sendMessage } = this.props;
    const { inputMessage, isSending } = this.state;

    if (!isSending) {
      this.setState({ isSending: true });
      console.log(inputMessage, "statusChat");

      sendMessage(inputMessage).then((res) => {
        if (res.status === 'success') {
          this.setState({ inputMessage: '', isSending: false });
        } else {
          if (res.openCursorModal) {
            this.setState({
              openCursorModal: true,
            })
          }
          this.setState({ isSending: false });
        }
      });
    } else {
      setTimeout(() => {
        this.setState({ isSending: false });
      }, 1000);
    }
  }


  openChat = () => {
    const { toggleChat, openChat } = this.props;

    toggleChat();

    this.scrollToBottom();

    if (!openChat) {
      this.setState({ openEmotions: false, prevChatState: null });
    }
  }

  openEmotions = () => {
    const {
      toggleChat, openChat, playButtonSound, fetchEmotions, // emotions,
    } = this.props;
    //  const { openEmotions } = this.state;

    fetchEmotions();

    // if (openChat) {
    //   toggleChat();
    // }

    playButtonSound();
    this.setState(prevState => ({ openEmotions: !prevState.openEmotions, firstOpen: true, prevChatState: openChat }));
  }

  setEmotion = (key) => {
    const { setEmotion } = this.props;
    const { prevChatState } = this.state;

    setEmotion(key);

    // if (prevChatState) {
    //   this.openChat();
    // } else {
    //   this.setState({ openEmotions: false, prevChatState: null });
    // }
    this.setState({ openEmotions: false });
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }

  renderMessages = (key, message, uid) => {
    const { fontMode, profanitiesList, name, reportedMessages, roomId } = this.props;
    const { messagesShow } = this.state;

    const isReportedMessage = filter(reportedMessages, (item) => { return  item.msgKey === key && item.roomId === roomId})[0] ? true : false;

    console.log('renderMessages message', message, messagesShow, constants.PLAYER);

    if (message.isFiltered && message.filteredMessage && (messagesShow === constants.PLAYER || messagesShow === constants.ALL)) {
      return (
        <Fragment key={key}>
          <FilteredMessage
            uid={uid}
            message={message}
            isReportedMessage={isReportedMessage}
            name={name}
            roomId={roomId}
            messageKey={key}
            fontMode={fontMode}
            profanitiesList={profanitiesList} />
        </Fragment>
      );
    }
    return (
      <Fragment key={key}>
        <Message
          uid={uid}
          message={message}
          isReportedMessage={isReportedMessage}
          name={name}
          roomId={roomId}
          messageKey={key}
          messagesShow={messagesShow}
          fontMode={fontMode}
          profanitiesList={profanitiesList}
        />
      </Fragment>
    );
  }

  toggleCursorModal = () => {
    this.setState({
      openCursorModal: false,
    })
  };

  render() {
    const {
      i18n,
      t,
      chatMessages,
      uid,
      toggleChat,
      openChat,
      emotions,
      toggleSupport,
      supportChatStatus,
      fontMode,
      cursor,
      cursorExpire,
      vipUntil,
      disabledEmoticons,
      offset,
    } = this.props;

    console.log('disabledEmoticons', disabledEmoticons, vipUntil);

    const {
      inputMessage, openEmotions, unreadMessages, firstOpen, scrollPos, smoothScrolling, messagesByZole, messagesByPlayers, openCursorModal
    } = this.state;

    return (
      <>
        <div className={classNames(`chat chat-${fontMode}`, {
          'chat-open': openChat,
          'chat-closed': !openChat,
        })}>
          <Row className={`chat-header ${openChat ? ('') : ('chat-header-closed')}`}>
            {openChat && (
              <Media src={closeImg} className="notification-header-close chat-header-button" alt="X" onClick={() => toggleChat()} />
            )}
            <Col xs="12" style={{pointerEvents: 'none'}}>
              <Row>
                <Col xs="9" className="chat-header-name">
                  {t('chat')}
                </Col>
                <Col xs="1" style={{ padding: 0 }}>
                  {!openChat && chatMessages && chatMessages.status
                  && !chatMessages.status[uid] && (
                  <span className="contact-support-button-dot" />
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              <Row className={`chat-header-filteroption chat-header-filteroption-${fontMode}`}>
                <Col className={`chat-filter-name chat-filter-name-${fontMode}`}>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="messagesByZole"
                      checked={messagesByZole}
                      onChange={this.handleShowZole}
                    />
                    {t('common:common.messageByZole')}
                  </Label>
                </Col>
                <Col className={`chat-filter-name chat-filter-name-${fontMode}`}>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="messagesByPlayers"
                      checked={messagesByPlayers}
                      onChange={this.handleShowPlayers}
                    />
                    {t('common:common.messagesByPlayers')}
                  </Label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="chat-body ">
            <Col className="chat-body-wrapper" md="12">
              {openChat && (
                <ScrollAreaButtons
                  scrollPos={scrollPos}
                  show={this.messagesScrollbar && this.messagesScrollbar.state && this.messagesScrollbar.state.realHeight > this.messagesScrollbar.state.containerHeight ? true : null}
                  scroll={this.scroll}
                  speed={30}
                  rightOffset={-1}
                  topOffset={-6}
                  bottomOffset={-6}
                />
              )}
              <ScrollArea
                speed={0.55}
                className="chat-body-scroll-area"
                contentClassName="chat-body-scroll-content chat-body-fontMode-change"
                smoothScrolling={smoothScrolling}
                verticalContainerStyle={{
                  background: 'transparent',
                  opacity: 1,
                  width: 11,
                }}
                verticalScrollbarStyle={{
                  background: '#fff',
                  borderRadius: 1,
                  width: 10,
                }}
                minScrollSize={30}
                horizontal={false}
                onScroll={this.updateScrollPos}
                ref={(el) => { this.messagesScrollbar = el; }}
              >
                {chatMessages && chatMessages.messages && Object.keys(chatMessages.messages).map(key => this.renderMessages(key, chatMessages.messages[key], uid))}
              </ScrollArea>
            </Col>
          </Row>
          <Row className={`chat-footer ${openChat ? ('chat-footer-open') : ('chat-footer-closed')}`}>
            <Form className="chat-footer-input-form">
              <Col className="chat-footer-input-wrapper" md="12">
                <Input
                  className="chat-footer-input h-100"
                  type="textarea"
                  name="inputMessage"
                  id="inputMessage"
                  autoComplete="off"
                  placeholder={`${t('common:common.writeMessage')}...`}
                  value={inputMessage}
                  onChange={this.handleChange}
                  onKeyPress={this.handleEnter}
                />
              </Col>
            </Form>
          </Row>
          <div className="input-message-remain-number">{`${inputMessage.length}/200`}</div>
        </div>

        {(disabledEmoticons && vipUntil && vipUntil >= (Date.now() + offset)) ? (null) : (
          <div className={`emotions ${openEmotions ? ('emotions-open') : ('emotions-closed')}`}>
            {firstOpen && (
              <Fragment>
                <Row className={`emotions-header ${openEmotions ? ('') : ('emotions-header-closed')}`} onClick={this.openEmotions}>
                  {openEmotions && (
                    <Media src={closeImg} className="notification-header-close emotions-header-button" alt="X" onClick={this.toggleEmotions} />
                  )}
                  <Col xs="9" className="emotions-header-name">
                    {t('emotions')}
                  </Col>
                </Row>

                <Row className="emotions-body">
                  <Col className="emotions-body-wrapper" md="12">
                    <Row className="emotions-wrapper">
                      {emotions && Object.keys(emotions).map(key => (
                        <div key={key} className="emotions-emotion">
                          <Media
                            src={emotions[key] ? emotions[key].image : null}
                            className={`emotions-emotion-image ${key === 'E6' ? 'emotions-emotion-image-handshake' : null}`}
                            onClick={() => this.setEmotion(key)}
                          />
                        </div>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Fragment>
            )}
          </div>
        )}

        {/* className={`${unreadMessages > 0 ? 'unread-messages' : ''}`} */}
        {/* className={`${supportChatStatus && supportChatStatus.read === false ? 'support-reply' : ''}`} */}

        <Row className="chat-bar">
          <Col xs="7" sm="7" className="chat-bar-left">
            <div className={`${unreadMessages > 0 ? 'unread-messages' : ''}`}>
              <Media src={chatIcon} className="chat-bar-left-button" onClick={this.openChat} />
              {/* (unreadMessages > 0) ? (
                <div className="unread-messages" />
              ) : (null) */}
            </div>
            <div className={`${supportChatStatus && supportChatStatus.read === false ? 'support-reply' : ''}`}>
              <Media src={supportIcon} className="chat-bar-left-button" onClick={toggleSupport} />
              {/* (supportChatStatus && supportChatStatus.read === false) ? (
                <div className="support-reply" />
              ) : (null) */}
            </div>
            {(disabledEmoticons && vipUntil && vipUntil >= (Date.now() + offset)) ? (null) : (
              <Media src={emotionIcon} className="chat-bar-left-button" onClick={this.openEmotions} />
            )}
          </Col>
          <Col xs="5" sm="5" className="chat-bar-right">
            <Button
              className={`${openChat ? ('chat-bar-right-button') : ('chat-bar-right-button-closed')} ${(i18n && i18n.language === 'ru') ? 'chat-bar-right-button-ru' : null}`}
              onClick={this.handleSubmit}
            >
              {t('common:common.send')}
            </Button>
          </Col>
        </Row>
        <Modal container={'div > div'} isOpen={openCursorModal} toggle={this.toggleCursorModal} className={`notification gifts-send-confirmation old-modal root-font-${fontMode}`} >
          <ModalHeader
            toggle={this.toggleCursorModal}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleCursorModal} />
            }
          >
            {t('common:common.warning')}
          </ModalHeader>
          <ModalBody className="notification-body">
            <div className='d-flex align-center flex-column'>
              <div>{t('common:common.detectMessageByCursor')}</div>
              <div className='d-flex flex-row'>
                <div>{t('common:common.cursorExpireDate')}</div>
                {cursorExpire && (
                  <CountDownDate date={cursorExpire} newDate={Date.now()} showOption={constants.FORMAT_DATE_TYPE.cursorExpireTime}/>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button type="button" className="btn notification-footer-button" onClick={this.toggleCursorModal}>
              {t('common:common.ok')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withTranslation(['game', 'common'])(Chat);
