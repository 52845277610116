import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Render2PlayerCards = React.memo(({
  currentTable, cardsAdded, myPos, currentTurn, tableIsInProgress, gameState, firstToGo, // isFullscreen, isWebVersion,
}) => {
  const items = [];

  console.log('Render2PlayerCards', currentTable, cardsAdded, myPos, currentTurn, tableIsInProgress, gameState, firstToGo,);

  if (myPos === 'player1') {
    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card student-card-0 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card student-card-1 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      return null;
    });

    // table card backgrounds
    if (currentTable && currentTable.length <= 2) {
      console.log('Render2PlayerCards 2', gameState, currentTurn, currentTable.length);

      if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
        items.push(<div className="table-card table-card-bg student-card-0" />);
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after student-card-1" />);
        }
      } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
        items.push(<div className="table-card table-card-bg student-card-0" />);
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after student-card-1" />);
        }
      }
    }

    return (
      <div className="table-cards" style={{ left: '70%' }}>
        {items}
      </div>
    );
  }

  if (myPos === 'player2') {
    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card student-card-1 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card student-card-0 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      return null;
    });

    // table card backgrounds
    if (currentTable && currentTable.length <= 2) {
      console.log('Render2PlayerCards 2', gameState, currentTurn, currentTable.length);

      if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
        items.push(<div className="table-card table-card-bg student-card-1" />);
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after student-card-0" />);
        }
      } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
        items.push(<div className="table-card table-card-bg student-card-0" />);
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after student-card-1" />);
        }
      }
    }

    return (
      <div className="table-cards" style={{ left: '70%' }}>
        {items}
      </div>
    );
  }

  return null;
});

Render2PlayerCards.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  isFullscreen: PropTypes.bool,
  isWebVersion: PropTypes.bool,
};

Render2PlayerCards.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  isFullscreen: false,
  isWebVersion: false,
};

export default Render2PlayerCards;
