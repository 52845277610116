import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
  // isMobileSafari,
  isMobile,
} from 'react-device-detect';

import ReactGA from 'react-ga';
import MobileDetector from '../components/MobileDetector';

import LanguageSelect from './UI/LanguageSelect';

import slide1Img from '../../images/landing-view/slide1.webp';
import slide2Img from '../../images/landing-view/slide2.webp';
import slide3Img from '../../images/landing-view/slide3.webp';
import slide4Img from '../../images/landing-view/slide4.webp';
import loginIconImg from '../../images/landing-view/login-icon.svg';

import logoImg from '../../images/Menu/zole_logo.webp';
import CookiesOld from './Menu/CookiesOld';
// import config from '../../../constants/config';
import config from '../../constants/config';

class Home extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    toggleNewDesign: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      devMode: false,
      cookiesModalOpen: false,
    };

    this.goToLogin = this.goToLogin.bind(this);
  }

  componentDidMount() {
    const { hostname } = window.location;

    let devMode = false;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }

    this.setState({
      devMode,
    });

    try {
      if (!localStorage.getItem('zoleCookies') && !config.isInAppFrame()) { //x
        this.setState({
          cookiesModalOpen: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate() {
    console.log('componentDidUpdate home');
  }

  toggleNewDesignFunc = (e) => {
    e.preventDefault();
    const { toggleNewDesign } = this.props;

    try {
      if (localStorage.getItem('newDesign')) { //x
        localStorage.removeItem('newDesign'); //x
        toggleNewDesign(false);

        ReactGA.event({
          category: 'Menu',
          action: 'Old Design',
        });
      } else {
        localStorage.setItem('newDesign', true); //x
        toggleNewDesign(true);

        ReactGA.event({
          category: 'Menu',
          action: 'New Design',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  goToLogin(e) {
    e.preventDefault();
    const { history } = this.props;

    console.log('history.push to login', { history: history.location });

    history.push('/login');

    console.log('history.push to login 2', { history: history.location });

    this.setState({ date: Date.now() });

  //  window.location.reload();
  }

  goToTermsPage = (e) => {
    e.preventDefault();
    const { history } = this.props;

    console.log('history.push to login', { history: history.location });

    history.push('/terms-of-use');

    console.log('history.push to login 2', { history: history.location });

    this.setState({ date: Date.now() });
  }

  mobileDetectorWrapper = () => {
    const { member } = this.props;

    try {
      if (localStorage.getItem('latestUid') === 'null' && !member.uid) { //x
        return (<MobileDetector />);
      }
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  closeCookiesModal = () => {
    this.setState({
      cookiesModalOpen: false,
    });
  }

  handleCookiesSet = (cookiesSet) => {
    try {
      if (!localStorage.getItem('zoleCookies')) { //x
        localStorage.setItem('zoleCookies', JSON.stringify(cookiesSet)); //x
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({
      cookiesModalOpen: false,
    });
  }

  render() {
    const { t, i18n } = this.props;
    const { devMode, cookiesModalOpen } = this.state;

    let slideHeight = 312;
    let slideWidth = 474;

    const fithScreenH = window.innerHeight / 4;

    if (fithScreenH < slideHeight) {
      const scaleDown = fithScreenH / slideHeight;
      slideHeight = fithScreenH;
      slideWidth *= scaleDown;
    }

    let language;

    if (i18n && i18n.language) {
      language = i18n.language;
    }

    console.log('render landing', language);

    return (
      <div className="landing-container-body">
        <Row className="landing-header">
          <Col sm="10" xs="7">
            <img className="landing-header-logo" src={logoImg} alt="" />
          </Col>
          <Col sm="2" xs="5" className="landing-header-links">
            <a href="#" onClick={this.goToLogin}>
              <img src={loginIconImg} alt="" />
              {t('home.login')}
            </a>
          </Col>
        </Row>

        <div className={!isMobile ? "landing-content" : 'landing-content landing-content-mobile'}>
          <div className="landing-content-title">
            <h1>{t('home.cardGameZole')}</h1>
            <h3>{t('home.cardGameZolePopular')}</h3>

            <button onClick={this.goToLogin} className="common-button lite-shadow">{t('home.login')}</button>
          </div>
          <div className="landing-content-slider">
            <Splide
              options={{
                type: 'loop',
                perPage: 3,
                //  gap: '20',
                width: (slideWidth * 3),
                pauseOnHover: false,
                fixedWidth: slideWidth,
                fixedHeight: slideHeight,
                isNavigation: false,
                gap: 30,
                focus: 'center',
                pagination: false,
                // cover       : true,
                arrows: true,
                autoplay: true,
                trimSpace: false,
              }}
            >
              <SplideSlide>
                <img className="slide-img" src={slide1Img} alt="" />
              </SplideSlide>
              <SplideSlide>
                <img className="slide-img" src={slide2Img} alt="" />
              </SplideSlide>
              <SplideSlide>
                <img className="slide-img" src={slide3Img} alt="" />
              </SplideSlide>
              <SplideSlide>
                <img className="slide-img" src={slide4Img} alt="" />
              </SplideSlide>
            </Splide>
          </div>

          <LanguageSelect />

          <div className="landing-content-termsOfUse">
            {/*  <Link to="/terms-of-use">
              <span>
                {t('home.termsOfUse')}
              </span>
            </Link> */}
            <a href="#" className="mr-2" onClick={this.goToTermsPage}>
              <span>
                {t('home.termsOfUse')}
              </span>
            </a>
          </div>
          <div className="landing-content-homepage">
            <a href="/home/lv" className="mr-2" style={language === 'lv' ? { width: 'fit-content', opacity: 1, paddingLeft: 6 } : { width: 1, opacity: 0, pointerEvents: 'none', display: 'block' }} >
              Sākumlapa
            </a>
          </div>
          <div className="landing-content-homepage">
            <a href="/home/en" className="mr-2" style={language === 'en' ? { width: 'fit-content', opacity: 1, paddingLeft: 6 } : { width: 1, opacity: 0, pointerEvents: 'none', display: 'block' }} >
              Home
            </a>
          </div>
          <div className="landing-content-homepage">
            <a href="/home/ru" className="mr-2" style={language === 'ru' ? { width: 'fit-content', opacity: 1, paddingLeft: 6 } : { width: 1, opacity: 0, pointerEvents: 'none', display: 'block' }} >
              Главная
            </a>
          </div>
          <div className="home-toggle-design">
            <a href="#" className="mr-2" onClick={this.toggleNewDesignFunc}>
              {t('home.newDesign')}
            </a>
          </div>
        </div>

        {this.mobileDetectorWrapper()}

        <CookiesOld cookiesModalOpen={cookiesModalOpen && !config.isInAppFrame()} closeCookiesModal={this.closeCookiesModal} handleCookiesSet={this.handleCookiesSet} goToTermsPage={this.goToTermsPage} />
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

export default withTranslation('common')(withRouter(connect(mapStateToProps)(Home)));
