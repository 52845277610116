import { size, filter } from 'lodash';

export const getRoomGameType = (room) => {
    if (!room || !room.globalParams) return 'ERR';

    if (room.globalParams.gameType === 'P') {
        if (room.globalParams.smallGame) return 'PM';
        else return 'P';
    }
    if (room.globalParams.gameType === 'G') {
        if (room.globalParams.smallGame) return 'MG';
        else return 'G';
    }

    return 'ERR';
}

export const getDbRoomGameType = (dbRoom) => {
    if (dbRoom.gameType === 'P') {
      if (dbRoom.smallGame) {
        return 'PM';
      } else {
        return 'P';
      }
    } else if (dbRoom.gameType === 'G') {
      if (dbRoom.smallGame) {
        return 'MG';
      } else {
        return 'G';
      }
    }
    return 'ERR';
}

export const isRegularRoom = (room) => {
    if (!room || !room.globalParams) return false;

    return !room.globalParams.fastGame && !room.globalParams.lightningGame && !room.globalParams.unlimitedGame;
}

export const isRoomSpeedInFilter = (room, speedFilter) => {
    if (!room || !room.globalParams) return false;
    if (!speedFilter) return true
    if (room.globalParams.fastGame && speedFilter.includes('atra')) return true;
    if (room.globalParams.lightningGame && speedFilter.includes('lightning')) return true;
    if (room.globalParams.unlimitedGame && speedFilter.includes('unlimited')) return true;
    if (!room.globalParams.fastGame && !room.globalParams.lightningGame && !room.globalParams.unlimitedGame && speedFilter.includes('parasta')) return true;
    return false;
}

export const getActiveRoomsCountOld = (rooms) => {
    return (rooms && rooms.length) ? rooms.filter(room => !!room.globalParams).length : 0;
}

export const getActiveRoomsCount = (rooms) => {
    if (!rooms) return 0;
    const roomsClone = { ...rooms };
    delete roomsClone.key;

    for (let key in roomsClone) {
        if (roomsClone.hasOwnProperty(key)) {
            if (!roomsClone[key].globalParams || !roomsClone[key].playersList || roomsClone[key].removal) {
                delete roomsClone[key];
            }
        }
    }
    return Object.keys(roomsClone).length;
}
