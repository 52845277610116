import AsyncStorage from '@react-native-community/async-storage';
import { Firebase, FirebaseRef } from '../lib/firebase';

export function getRooms() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('roomsPubInf')
    .orderByChild('roomClosed')
    .equalTo(false)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ROOMS_REPLACE', data }));
    })).catch((err) => {
    console.log(err.message);
  });
}

export function toggleNewGameParamOld(param, uid) {
  console.log('toggleNewGameParam', param, uid);
  if (param && uid) {
    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'TOGGLE_NEW_GAME_PARAM', data: param, uid })));
  }
  return () => new Promise(resolve => resolve());
}

export function toggleNewGameParam(param, uid) {
  console.log('toggleNewGameParam', param);

  if (uid && param) {
    const UID = (
      FirebaseRef
      && Firebase
      && Firebase.auth()
      && Firebase.auth().currentUser
      && Firebase.auth().currentUser.uid
    ) ? Firebase.auth().currentUser.uid : null;

    let promises = [];

    console.log('changeNotificationSettings', uid, UID, param);

    return (dispatch, getState) => new Promise(async (resolve) => {
      const { userSettings } = getState();

      console.log('userSettings', userSettings);

      if (param === 'parasta') {
        if (userSettings && userSettings[uid] && userSettings[uid][param]) {
          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/${param}`).set(false).then(() => {
            console.log('changeNotificationSettings update done', param, false);
          }));

          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/G`).set(true).then(() => {
            console.log('changeNotificationSettings update done', 'G', true);
          }));
        } else {
          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/${param}`).set(true).then(() => {
            console.log('changeNotificationSettings update done', param, true);
          }));

          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/G`).set(false).then(() => {
            console.log('changeNotificationSettings update done', 'G', false);
          }));
        }
      } else if (param === 'G') {
        if (userSettings && userSettings[uid] && userSettings[uid][param]) {
          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/${param}`).set(false).then(() => {
            console.log('changeNotificationSettings update done', param, false);
          }));

          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/parasta`).set(true).then(() => {
            console.log('changeNotificationSettings update done', 'parasta', true);
          }));
        } else {
          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/${param}`).set(true).then(() => {
            console.log('changeNotificationSettings update done', param, true);
          }));

          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/parasta`).set(false).then(() => {
            console.log('changeNotificationSettings update done', 'parasta', false);
          }));
        }
      } else if (param === 'atra' || param === 'pro' || param === 'maza' || param === 'fourPRoom' || param === 'privateRoom' || param === 'studentZole') {
        console.log('changeNotificationSettings usersSettings', param, UID);
        if (userSettings && userSettings[uid] && userSettings[uid][param]) {
          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/${param}`).set(false).then(() => {
            console.log('changeNotificationSettings update done', param, false);
          }));
        } else {
          promises.push(FirebaseRef.child(`usersSettings/${UID}/newGameSettings/${param}`).set(true).then(() => {
            console.log('changeNotificationSettings update done', param, true);
          }));
        }
      }

      dispatch({ type: 'TOGGLE_NEW_GAME_PARAM', data: param, uid });

      Promise.all(promises).then(() => {
        return resolve();
      }).catch((err) => {
        return resolve();
      });

      // return resolve();
      //resolve(dispatch({ type: 'CHANGE_NOTIFICATION_SETINGS', param, uid }));
    });
  }
  return () => new Promise(resolve => resolve());
}


export function initializeFiltersFromBackup(params, uid) {
  if (params && uid) {
    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'INITIALIZE_BACKUP_PARAMS', data: params, uid })));
  }
  return () => new Promise(resolve => resolve());
}

export function toggleFilterParam(value, uid) {
  console.log('toggleFilterParam', value, uid);
  if (uid) {
    return dispatch => new Promise(resolve => resolve(dispatch({
      type: 'TOGGLE_ROOMS_FILTER_PARAM', data: value, uid,
    })));
  }
  return () => new Promise(resolve => resolve());
}

export function resetFilterRoomsExit(uid) {
  if (uid) {
    const value = {
      speed: [],
      bet: [],
      minGames: [],
      fourPRoom: [],
      gameType: [],
      maza: false,
      privateRoom: false,
      pro: false,
    };
    return dispatch => new Promise(resolve => resolve(dispatch({
      type: 'TOGGLE_ROOMS_FILTER_PARAM', data: value, uid,
    })));
  }
  return () => new Promise(resolve => resolve());
}

export function setNewGameBet(bet, uid) {
  console.log('setNewGameBet', bet, uid);
  if (bet && uid) {
    return (dispatch) => new Promise(async (resolve) => {
      FirebaseRef.child(`usersSettings/${uid}/newGameSettings/bet`).set(bet).then(() => {
        console.log('newGameSettings update done', 'bet', bet);

        return resolve(dispatch({ type: 'SET_NEW_GAME_BET', data: bet, uid }));
      });
    });
  }
  return () => new Promise(resolve => resolve());
}

export function setNewGameSpeed(speed, uid) {
  console.log('setNewGameSpeed', speed, uid);
  if (speed && uid) {
    return (dispatch) => new Promise(async (resolve) => {
      FirebaseRef.child(`usersSettings/${uid}/newGameSettings/speed`).set(speed).then(() => {
        console.log('newGameSettings update done', 'speed', speed);

        return resolve(dispatch({ type: 'SET_NEW_GAME_SPEED', data: speed, uid }));
      });
    });
  }
  return () => new Promise(resolve => resolve());
}

export function setNewMinGamesFunc(minGames, uid) {
  console.log('setNewMinGamesFunc', minGames, uid);
  if (minGames && uid) {
    return (dispatch) => new Promise(async (resolve) => {
      FirebaseRef.child(`usersSettings/${uid}/newGameSettings/minGames`).set(minGames).then(() => {
        console.log('newGameSettings update done', 'minGames', minGames);

        return resolve(dispatch({ type: 'SET_NEW_MIN_GAMES', data: minGames, uid }));
      });
    });
  }
  return () => new Promise(resolve => resolve());
}

export function toggleSound(uid) {
  if (uid) {
    return (dispatch, getState) => new Promise(async (resolve) => {
      const { userSettings } = getState();

      if (userSettings && userSettings[uid] && userSettings[uid].soundOn) {
        FirebaseRef.child(`usersSettings/${uid}/userSettings/soundOn`).set(false).then(() => {
          console.log('changeNotificationSettings update done', 'soundOn', false);

          return resolve(dispatch({ type: 'SET_SOUND', uid, data: false }));
        });
      } else {
        FirebaseRef.child(`usersSettings/${uid}/userSettings/soundOn`).set(true).then(() => {
          console.log('changeNotificationSettings update done', 'soundOn', true);

          return resolve(dispatch({ type: 'SET_SOUND', uid, data: true }));
        });
      }
    });
  } else {
    return () => new Promise(resolve => resolve());
  }
}

export function setDefaultSettings(uid) {
  console.log('setDefaultSettings');
  if (uid) {
    return dispatch => new Promise(resolve =>
    //  dispatch({ type: 'SET_DEFAULT_GAME_SETTINGS', uid });
      resolve(dispatch({ type: 'SET_DEFAULT_SETTINGS', uid })));
  }
  return () => new Promise(resolve => resolve());
}



export function changeNotificationSettings(setting, uid) {
  if (setting === 'newDesign') {
    return () => new Promise(resolve => resolve());
  }

  if (uid && setting) {
    const UID = (
      FirebaseRef
      && Firebase
      && Firebase.auth()
      && Firebase.auth().currentUser
      && Firebase.auth().currentUser.uid
    ) ? Firebase.auth().currentUser.uid : null;

    console.log('changeNotificationSettings', uid, UID, setting);

    return (dispatch, getState) => new Promise(async (resolve) => {
      const { userSettings } = getState();

      console.log('userSettings', userSettings);

      if (userSettings && userSettings[uid] && userSettings[uid].notificationSettings && userSettings[uid].notificationSettings[setting]) {
        await FirebaseRef.child(`usersSettings/${UID}/notificationSettings/${setting}`).set(false).then(() => {
          console.log('changeNotificationSettings update done', setting, false);
        });
      } else {
        await FirebaseRef.child(`usersSettings/${UID}/notificationSettings/${setting}`).set(true).then(() => {
          console.log('changeNotificationSettings update done', setting, true);
        });
      }

      resolve(dispatch({ type: 'CHANGE_NOTIFICATION_SETINGS', setting, uid }));
    });
  }
  return () => new Promise(resolve => resolve());
}

export function changeGameSettingOld(setting, uid) {
  console.log('changeGameSetting', setting, uid);

  if (uid && setting) {
    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'CHANGE_GAME_SETINGS', setting, uid })));
  }
  return () => new Promise(resolve => resolve());
}

export function changeGameSetting(setting, uid) {
  if (uid && setting) {
    const UID = (
      FirebaseRef
      && Firebase
      && Firebase.auth()
      && Firebase.auth().currentUser
      && Firebase.auth().currentUser.uid
    ) ? Firebase.auth().currentUser.uid : null;

    console.log('changeGameSetting', uid, UID, setting);

    return (dispatch, getState) => new Promise(async (resolve) => {
      const { userSettings } = getState();

      console.log('userSettings', userSettings);

      if (userSettings && userSettings[uid] && userSettings[uid].gameSettings && userSettings[uid].gameSettings[setting]) {
        await FirebaseRef.child(`usersSettings/${UID}/gameSettings/${setting}`).set(false).then(() => {
          console.log('changeGameSetting update done', setting, false);
        });
      } else {
        await FirebaseRef.child(`usersSettings/${UID}/gameSettings/${setting}`).set(true).then(() => {
          console.log('changeGameSetting update done', setting, true);
        });
      }

      resolve(dispatch({ type: 'CHANGE_GAME_SETINGS', setting, uid }));
    });
  }
  return () => new Promise(resolve => resolve());
}

export const getUsersSetting = () => {
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('getUsersSetting', UID);

  return (dispatch, getState) => new Promise(resolve => FirebaseRef.child(`usersSettings/${UID}`)
    .once('value', (snapshot) => {
      const remoteSettings = snapshot.val() || {};
      const remoteNewGameSettings = remoteSettings.newGameSettings || {};

      console.log('remoteNewGameSettings', remoteNewGameSettings);

      const { userSettings } = getState();
      let localNotificationSettings = null;
      let localGameSettings = null;
      let localSoundOn = null;

      // let localNewGameSettings = null;
      // let remoteNewGameSettings = null;

      if (userSettings && userSettings[UID] && userSettings[UID].notificationSettings) {
        localNotificationSettings = userSettings[UID].notificationSettings;
      }

      if (userSettings && userSettings[UID] && userSettings[UID].gameSettings) {
        localGameSettings = userSettings[UID].gameSettings;
      }

      if (userSettings && userSettings[UID] && userSettings[UID].soundOn) {
        localSoundOn = userSettings[UID].soundOn;
      }

      let newNewGameSeetings = {};

      console.log('userSettings[UID]', userSettings[UID]);
      console.log('userSettings.default', userSettings.default);

      if (userSettings && userSettings[UID]) {
        newNewGameSeetings = {
          G: (remoteNewGameSettings.G || remoteNewGameSettings.G === false) ? remoteNewGameSettings.G : ((userSettings[UID].G || userSettings[UID].G === false) ? userSettings[UID].G : (userSettings.default.G)),
          bet: (remoteNewGameSettings.bet || remoteNewGameSettings.bet === false) ? remoteNewGameSettings.bet : ((userSettings[UID].bet || userSettings[UID].bet === false) ? userSettings[UID].bet : (userSettings.default.bet)),
          fourPRoom: (remoteNewGameSettings.fourPRoom || remoteNewGameSettings.fourPRoom === false) ? remoteNewGameSettings.fourPRoom : ((userSettings[UID].fourPRoom || userSettings[UID].fourPRoom === false) ? userSettings[UID].fourPRoom : (userSettings.default.fourPRoom)),
          maza: (remoteNewGameSettings.maza || remoteNewGameSettings.maza === false) ? remoteNewGameSettings.maza : ((userSettings[UID].maza || userSettings[UID].maza === false) ? userSettings[UID].maza : (userSettings.default.maza)),
          minGames: (remoteNewGameSettings.minGames || remoteNewGameSettings.minGames === false) ? remoteNewGameSettings.minGames : ((userSettings[UID].minGames || userSettings[UID].minGames === false) ? userSettings[UID].minGames : (userSettings.default.minGames)),
          parasta: (remoteNewGameSettings.parasta || remoteNewGameSettings.parasta === false) ? remoteNewGameSettings.parasta : ((userSettings[UID].parasta || userSettings[UID].parasta === false) ? userSettings[UID].parasta : (userSettings.default.parasta)),
          privateRoom: (remoteNewGameSettings.privateRoom || remoteNewGameSettings.privateRoom === false) ? remoteNewGameSettings.privateRoom : ((userSettings[UID].privateRoom || userSettings[UID].privateRoom === false) ? userSettings[UID].privateRoom : (userSettings.default.privateRoom)),
          pro: (remoteNewGameSettings.pro || remoteNewGameSettings.pro === false) ? remoteNewGameSettings.pro : ((userSettings[UID].pro || userSettings[UID].pro === false) ? userSettings[UID].pro : (userSettings.default.pro)),
          speed: (remoteNewGameSettings.speed || remoteNewGameSettings.speed === false) ? remoteNewGameSettings.speed : ((userSettings[UID].speed || userSettings[UID].speed === false) ? userSettings[UID].speed : (userSettings.default.speed)),
          studentZole: (remoteNewGameSettings.studentZole || remoteNewGameSettings.studentZole === false) ? remoteNewGameSettings.studentZole : ((userSettings[UID].studentZole || userSettings[UID].studentZole === false) ? userSettings[UID].studentZole : (userSettings.default.studentZole)),
        }
      } else {
        newNewGameSeetings = {
          G: (remoteNewGameSettings.G || remoteNewGameSettings.G === false) ? remoteNewGameSettings.G : (userSettings.default.G),
          bet: (remoteNewGameSettings.bet || remoteNewGameSettings.bet === false) ? remoteNewGameSettings.bet : (userSettings.default.bet),
          fourPRoom: (remoteNewGameSettings.fourPRoom || remoteNewGameSettings.fourPRoom === false) ? remoteNewGameSettings.fourPRoom : (userSettings.default.fourPRoom),
          maza: (remoteNewGameSettings.maza || remoteNewGameSettings.maza === false) ? remoteNewGameSettings.maza : (userSettings.default.maza),
          minGames: (remoteNewGameSettings.minGames || remoteNewGameSettings.minGames === false) ? remoteNewGameSettings.minGames : (userSettings.default.minGames),
          parasta: (remoteNewGameSettings.parasta || remoteNewGameSettings.parasta === false) ? remoteNewGameSettings.parasta : (userSettings.default.parasta),
          privateRoom: (remoteNewGameSettings.privateRoom || remoteNewGameSettings.privateRoom === false) ? remoteNewGameSettings.privateRoom : (userSettings.default.privateRoom),
          pro: (remoteNewGameSettings.pro || remoteNewGameSettings.pro === false) ? remoteNewGameSettings.pro : (userSettings.default.pro),
          speed: (remoteNewGameSettings.speed || remoteNewGameSettings.speed === false) ? remoteNewGameSettings.speed : (userSettings.default.speed),
          studentZole: (remoteNewGameSettings.studentZole || remoteNewGameSettings.studentZole === false) ? remoteNewGameSettings.studentZole : (userSettings.default.studentZole),
        }
      }


      const newNotificationSeetings = remoteSettings.notificationSettings ? remoteSettings.notificationSettings : (localNotificationSettings ? localNotificationSettings : null);
      const newGameSeetings = remoteSettings.gameSettings ? remoteSettings.gameSettings : (localGameSettings ? localGameSettings : null);

      const newSoundOnSetting = (remoteSettings.userSettings && (remoteSettings.userSettings.soundOn || remoteSettings.userSettings.soundOn === false)) ? remoteSettings.userSettings.soundOn : localSoundOn;

      console.log('newNotificationSeetings', newNotificationSeetings, localNotificationSettings, remoteSettings.notificationSettings);
      console.log('newGameSeetings', newGameSeetings, localGameSettings, remoteSettings.gameSettings);
      console.log('newNewGameSeetings', newNewGameSeetings);

      console.log('newSoundOnSetting', remoteSettings.userSettings, localSoundOn, newSoundOnSetting);

      

       dispatch({
        type: 'GET_ALL_NOTIFICATION_SETINGS',
        uid: UID,
        settings: newNotificationSeetings || {
          leaveRoom: true,
          lastRound: true,
          quitRound: true,
          ignorePlayer: true,
          quitAccount: true,
          purchaseGift: true,
          removeGift: true,
          showAfterRound: true,
          showAtRoomEnd: true,
        }
      });

      dispatch({
        type: 'GET_ALL_GAME_SETINGS',
        uid: UID,
        settings: newGameSeetings || {
          doubleClickPlay: false,
        }
      });

      
      dispatch({
        type: 'GET_ALL_NEW_GAME_SETINGS',
        uid: UID,
        settings: newNewGameSeetings,
      });
      dispatch({ type: 'SET_SOUND', uid: UID, data: newSoundOnSetting });

      return resolve();
    }));
};

/*
export function setNewDesignSetting(value, uid) {
  if (uid && setting) {
    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'SET_NEW_DESIGN_SETING', value, uid })));
  }
  return () => new Promise(resolve => resolve());
} */

export function changeNotificationSettingsMobile(setting, multiple, deselect) {
  return dispatch => new Promise(resolve => resolve(dispatch({
    type: 'CHANGE_NOTIFICATION_SETINGS_MOBILE', setting, multiple, deselect,
  })));
}

export const getUserSetting = () => (dispatch) => {
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;
  return new Promise(resolve => FirebaseRef.child(`userSettings/${UID}`)
    .on('value', (snapshot) => {
      dispatch({
        type: 'CHANGE_NOTIFICATION_SETINGS_MOBILE',
        setting: snapshot.val() || {
          leaveRoom: true,
          lastRound: true,
          quitRound: true,
          ignorePlayer: true,
          quitAccount: true,
          purchaseGift: true,
          removeGift: true,
          showAfterRound: true,
          showAtRoomEnd: true,
        },
        multiple: true,
      });
      AsyncStorage.setItem('notificationsSettings', JSON.stringify(snapshot.val()));
      resolve();
    }));
};

export function toggleAllNotificationSettings(uid) {
  if (uid) {
    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'TOGGLE_ALL_NOTIFICATION_SETTINGS', uid })));
  }
  return () => new Promise(resolve => resolve());
}

export function toggleRoomChat(uid) {
  if (uid) {
    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'TOGGLE_ROOM_CHAT', uid })));
  }
  return () => new Promise(resolve => resolve());
}

export function toggleScoreTable(uid) {
  if (uid) {
    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'TOGGLE_SCORE_TABLE', uid })));
  }
  return () => new Promise(resolve => resolve());
}

export function toggleMobileScoreTable(uid, mobileScoreTableOpen) {
  if (uid) {
    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'TOGGLE_MOBILE_SCORE_TABLE', uid, mobileScoreTableOpen })));
  }
  return () => new Promise(resolve => resolve());
}
