import React, { Fragment, useState, useEffect } from 'react';
import { Button, Col, Input, Label, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import { connect } from 'react-redux';
import PaginationOld from '../Menu/PaginationOld';
import Chat from './Modals/Chat';
import LeaveReason from './Modals/LeaveReason';
import PlayerHistory from './Modals/PlayerHistory';
import TournamentPlayers from './Modals/TournamentPlayers';
import TournamentResults from './Modals/TournamentResults';
import UserTournaments from './Modals/UserTournaments';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { ENTERKEY } from '../../../constants/constants';

const TournamentsUsers = ({
    totalTournamentUserCount,
    fetchTournamentUsers,
    tournamentUsers,
    userTournaments,
    fetchTournamentResults,
    fetchBots,
    tournamentResults,
    bots,
    fetchTournamentPlayers,
    tournamentPlayersArr,
    fetchTournamentPlayerHistory,
    tournamentPlayers,
    tournamentPlayerHistory,
    showNotification,
}) => {
    const defaultStartDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const defaultEndDate = new Date();
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [filterType, setFilterType] = useState('lowerCaseName');
    const [filterValue, setFilterValue] = useState('');

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [userTournamentsModalKeys, setUserTournamentsModalKeys] = useState(null);
    const [openResultsModal, setOpenResultsModal] = useState(false);
    const [openPlayersModal, setOpenPlayersModal] = useState(false);
    const [playersModalId, setPlayersModalId] = useState('');
    const [openedPlayerTournament, setOpenedPlayerTournament] = useState('');
    const [openPlayerHistoryModal, setOpenPlayerHistoryModal] = useState(false);
    const [playerHistoryId, setPlayerHistoryId] = useState(null);
    const [leaveReasonModal, setLeaveReasonModal] = useState(null);
    const [openChatModal, setOpenChatModal] = useState(false);
    const [openChatModalName, setOpenChatModalName] = useState('');
    const [openChatModalUid, setOpenChatModalUid] = useState('');

    useEffect(() => {
        fetchBots();
    }, []);

    const handleKeyPress = (event) => {
        if (event.charCode === ENTERKEY) {
            if (event.target.name === "startDate") {
                const dateStr = event.target.value;
                const [date, time] = dateStr.split(" ");
                const [day, month, year] = date.split(".");
                const [hours, minutes] = time.split(":");
                
                const newDate = new Date(year, month - 1, day, hours, minutes);
                setStartDate(newDate);
                fetchTournamentUsers(newDate.getTime(), endDate.getTime(), filterType, filterValue);
            } else if (event.target.name === "endDate") {
                const dateStr = event.target.value;
                const [date, time] = dateStr.split(" ");
                const [day, month, year] = date.split(".");
                const [hours, minutes] = time.split(":");
                
                const newDate = new Date(year, month - 1, day, hours, minutes);                setEndDate(newDate);
                fetchTournamentUsers(startDate.getTime(), newDate.getTime(), filterType, filterValue);
            } else {
                fetchTournamentUsers(startDate.getTime(), endDate.getTime(), filterType, filterValue);
            }
            setCurrentPage(0);
        }
    }

    const handlePagination = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
      };

    const toggleUserTournamentsModal = (userUid) => {
        if (userUid) {
            const filteredTournamentUsers = Object.fromEntries(Object.entries(userTournaments).filter(([key, value]) => value.players.includes(userUid)));
            const filteredTournamentUsersKeys = Object.keys(filteredTournamentUsers);
            filteredTournamentUsersKeys.sort((a, b) => {
                const startDateA = userTournaments[a]?.startDate;
                const startDateB = userTournaments[b]?.startDate;
                return startDateB - startDateA;
            });
            setUserTournamentsModalKeys(filteredTournamentUsersKeys);
        } else {
            setUserTournamentsModalKeys(null);
        }
    }

    const openResultsModalFunc = (id) => {
        fetchTournamentResults(id);
        // fetchBots();

        setOpenResultsModal(true);
    }

    const openPlayersModalFunc = (id, tournamentState) => {    
        fetchTournamentPlayers(id, tournamentState);
        setOpenPlayersModal(true);
        setPlayersModalId(id);
        setOpenedPlayerTournament(tournamentState);
    }

    const openPlayerHistoryModalFunc = (tournamentId, playerId) => {
        fetchTournamentPlayerHistory(tournamentId, playerId);

        setOpenPlayerHistoryModal(true);
        setPlayerHistoryId(playerId);
    }

    const openChatModalFunc = (uid, name) => {
        setOpenChatModal(true);
        setOpenChatModalName(name);
        setOpenChatModalUid(uid);
    }

    const tournamentUsersKeys = Object.keys(tournamentUsers || {});

    tournamentUsersKeys.sort((a, b) => {
        const tPlayedA = tournamentUsers[a]?.tPlayed || 0;
        const tPlayedB = tournamentUsers[b]?.tPlayed || 0;
        return tPlayedB - tPlayedA;
    });

    const pagesCount = Math.ceil(tournamentUsersKeys.length / pageSize);

    return (
        <Fragment>
            <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-tournaments-users">
                <PopoverBody className="popover-body">
                    A list of all users who have participated in any tournament.
                </PopoverBody>
            </UncontrolledPopover>
            <div style={{ marginTop: 100, color: '#fff' }}>
                <Row>
                    <Col md="6">
                    <h2>
                        Users
                        <Button className="admin-help-button" id="admin-help-button-tournaments-users">
                            ?
                        </Button>
                    </h2>
                    </Col>
                </Row>

                <Row>
                    <Col sm="3">
                        <Label for="startDate">
                            From
                        </Label>
                        <Row>
                            <Col md="12">
                                <DateTimePicker
                                    name="startDate"
                                    format="DD.MM.YYYY HH:mm"
                                    culture="lv"
                                    onChange={setStartDate}
                                    value={startDate}
                                    defaultValue={defaultStartDate}
                                    // onKeyPress={handleKeyPress}
                                    onKeyPress={(e) => handleKeyPress(e)}

                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="3">
                        <Label for="endDate">
                            To
                        </Label>
                        <Row>
                            <Col md="12">
                                <DateTimePicker
                                    name="endDate"
                                    format="DD.MM.YYYY HH:mm"
                                    culture="lv"
                                    onChange={setEndDate}
                                    value={endDate}
                                    defaultValue={defaultEndDate}
                                    // onKeyPress={handleKeyPress}
                                    onKeyPress={(e) => handleKeyPress(e)}

                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="3">
                        <Button onClick={() => {fetchTournamentUsers(startDate.getTime(), endDate.getTime(), filterType, filterValue); setCurrentPage(0)}} style={{ marginTop: 20 }}>
                            Search
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col sm="3">
                    <Label for="filterType">
                        Filter By
                    </Label>
                    <Input
                        type="select"
                        className="game-bet-select"
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                    >
                        <option value="lowerCaseName" style={{ backgroundColor: '#222' }}>Name</option>
                        <option value="lowerCaseLastName" style={{ backgroundColor: '#222' }}>Last Name</option>
                        <option value="uid" style={{ backgroundColor: '#222' }}>Player Id</option>
                        <option value="email" style={{ backgroundColor: '#222' }}>Email</option>
                        <option value="lastIp" style={{ backgroundColor: '#222' }}>Last IP Address</option>
                    </Input>
                    </Col>
                    <Col sm="3">
                    <Label for="filter">
                        Value
                    </Label>
                    <Input
                        type="text"
                        name="filter"
                        id="filter"
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                    />
                    </Col>
                    <Col sm="6" className="d-flex align-items-center justify-content-center">
                        <h3>{totalTournamentUserCount ? `Filtered ${tournamentUsersKeys.length} results out of ${totalTournamentUserCount} in total` : null}</h3>
                    </Col>
                </Row>

                { tournamentUsersKeys.length > 0 && (
                    <>
                        <table className="w-100 text-center">
                            <thead>
                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Player ID
                                    </th>
                                    <th>
                                        Level
                                    </th>
                                    <th>
                                        Balance
                                    </th>
                                    <th>
                                        Points
                                    </th>
                                    <th>
                                        # of games
                                    </th>
                                    <th>
                                        # of tournaments
                                    </th>
                                    <th>
                                        Tournaments
                                    </th>
                                    <th>
                                        Total points
                                    </th>
                                    <th>
                                        Total balance
                                    </th>
                                    <th>
                                        Send message
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tournamentUsersKeys.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((key) => (
                                    <tr>
                                        <td>
                                            <b>{bots[key] ? '(B) ' : ''}</b>
                                            {tournamentUsers[key].name}
                                        </td>
                                        <td>
                                            {tournamentUsers[key].uid}
                                        </td>
                                        <td>
                                            {tournamentUsers[key].lvl}
                                        </td>
                                        <td>
                                            {tournamentUsers[key].bal}
                                        </td>
                                        <td>
                                            {tournamentUsers[key].totalPnts}
                                        </td>
                                        <td>
                                            {tournamentUsers[key].gPlayed}
                                        </td>
                                        <td>
                                            {tournamentUsers[key].tPlayed}
                                        </td>
                                        <td>
                                            <Button color="primary" onClick={() => toggleUserTournamentsModal(tournamentUsers[key].uid)}>
                                                Tournaments
                                            </Button>
                                        </td>
                                        <td>
                                            {tournamentUsers[key].tTotalPnts}
                                        </td>
                                        <td>
                                            {tournamentUsers[key].tTotalBal}
                                        </td>
                                        <td>
                                            <Button color="primary" onClick={() => openChatModalFunc(tournamentUsers[key].uid, tournamentUsers[key].name)}>
                                                Send message
                                            </Button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <PaginationOld
                            show={true}
                            currentPage={currentPage}
                            pagesCount={pagesCount}
                            handlePagination={handlePagination}
                        />
                    </>
                )}


            </div>

            <UserTournaments
                userTournamentsModalKeys={userTournamentsModalKeys}
                toggleUserTournamentsModal={toggleUserTournamentsModal}
                userTournaments={userTournaments}
                openResultsModalFunc={openResultsModalFunc}
                openPlayersModalFunc={openPlayersModalFunc}
            />

            <TournamentResults
                isOpen={openResultsModal}
                toggle={() => setOpenResultsModal(false)}
                tournamentResults={tournamentResults}
                bots={bots}
            />

            <TournamentPlayers
                isOpen={openPlayersModal}
                toggle={() => setOpenPlayersModal(false)}
                tournamentPlayersArr={tournamentPlayersArr}
                playersModalId={playersModalId}
                openPlayerHistoryModalFunc={openPlayerHistoryModalFunc}
                openChatModalFunc={openChatModalFunc}
                openedPlayerTournament={openedPlayerTournament}
            />

            <PlayerHistory
                isOpen={openPlayerHistoryModal}
                toggle={() => setOpenPlayerHistoryModal(false)}
                tournamentPlayers={tournamentPlayers}
                playersModalId={playersModalId}
                playerHistoryId={playerHistoryId}
                tournamentPlayerHistory={tournamentPlayerHistory}
                setLeaveReasonModal={setLeaveReasonModal}
            />

            <LeaveReason
                leaveReasonModal={leaveReasonModal}
                toggle={() => setLeaveReasonModal(null)}
            />

            <Chat
                openChatModal={openChatModal}
                setOpenChatModal={setOpenChatModal}
                openChatModalName={openChatModalName}
                setOpenChatModalName={setOpenChatModalName}
                openChatModalUid={openChatModalUid}
                setOpenChatModalUid={setOpenChatModalUid}
                showNotification={showNotification}
            />
        </Fragment>
    );
};


const mapStateToProps = state => ({
    // tournamentResults: state.admin.tournamentResults || [],
    tournamentPlayersArr: state.admin.tournamentPlayersArr || [],
});

  
export default connect(mapStateToProps, null)(TournamentsUsers);