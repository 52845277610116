import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import { withTranslation } from 'react-i18next';
import CustomDate from '../UI/CustomDate';
import LinkifyWithTargetBlank from '../UI/LinkifyWithTargetBlank';


// import defaultImage from '../../../images/Game/defaultImage.webp';
import { FONT_MODE } from '../../../constants/constants';

import defaultImage from '../../../images/Game/defaultImage.jpg';
import reportedMessageImg from '../../../images/redesign/chat/chat-report.png';
import reportMessageImg from '../../../images/redesign/chat/chat-report-submit.png';


import * as constants from '../../../constants/constants';
import {
  reportMessage,
} from '../../../actions/room';

// const FilteredMessage = React.memo(({ message, uid, t }) => {

class FilteredMessage extends React.Component {
    static propTypes = {
      t: PropTypes.func.isRequired,
      uid: PropTypes.string,
      message: PropTypes.shape(),
      fontMode: PropTypes.string,
      profanitiesList: PropTypes.shape(),
    };

    static defaultProps = {
      uid: null,
      message: null,
      fontMode: FONT_MODE.normal,
      profanitiesList: {},
    };

    constructor(props) {
      super(props);
      this.state = {
        popoverOpen: false,
        loading: false,
      };
    }

  onHover = (key) => {
    const { popoverOpen } = this.state;
    if (!popoverOpen) {
      this.setState({
        popoverOpen: key,
      });
    }
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  onHoverReport = (key) => {
    const { popoverOpen } = this.state;
    if (!popoverOpen) {
      this.setState({
        reportPopoverOpen: key,
      });
    }
  }

  onHoverReportLeave = () => {
    this.setState({
      reportPopoverOpen: false,
    });
  }

  handleReportMessage = () => {
    const { reportMessageFunc, uid, name, message, messageKey, roomId } = this.props;

    console.log('handleReportMessage', { uid, name, userUid: message.userUid, userName: message.userName, messageKey, message: message.message, roomId });            
    this.setState({ loading: true });
    reportMessageFunc(uid, name, message.userUid, message.userName, messageKey, message.message, roomId);
  }

  render() {
    const { t, message, uid, fontMode, profanitiesList, isReportedMessage, messageKey } = this.props;
    const { popoverOpen, reportPopoverOpen, loading } = this.state;

    console.log('filteredmessage render');

    if (message.filteredMessage && message.message) {
      let splitMessage = [];
      let splitFilteredMessage = [];

      splitMessage = message.message.split(/\n/);

      splitFilteredMessage = message.filteredMessage.split(/\n/);

      console.log('splitMessage', splitMessage, message.message);
      console.log('splitFilteredMessage', splitFilteredMessage, message.filteredMessage);

      return (
        <Fragment>
          {message.userUid && message.userUid.toString() === uid.toString() ? (
            <Fragment>
              <Row>
                {/* <Col md="1" className="chat-message-col">
                  <Media
                    className="chat-message-other-image"
                    src={message.userPhoto || defaultImage}
                    alt="photo"
                  />

                </Col> */}
                <Col sm="12" className="chat-message-col">
                  <LinkifyWithTargetBlank>
                    <div className="chat-message-user">
                      {splitFilteredMessage.map((row, index) => {
                        let words = [];
                        if (splitMessage[index]) {
                          words = splitMessage[index].split(' ');
                        }
                        const filteredWords = row.split(' ');

                        console.log('filteredWords', filteredWords);

                        return (
                          <div key={index}>
                            {filteredWords.map((word, index2) => (
                              <>
                                {(words && words[index2] !== word) ? (
                                  <>
                                    <div
                                      style={{ display: 'inline-block', marginRight: 2 }}
                                      id={`chat-popover-${message.messageId}-${index2}`}
                                      onMouseEnter={() => this.onHover(`${message.messageId}-${index2}`)}
                                      onMouseLeave={this.onHoverLeave}
                                    >
                                      {`${word.replace()} `}
                                    </div>
                                    <Popover container={'div > div'} popperClassName={`last-round-popover popover-font-${fontMode}`} placement="top" isOpen={popoverOpen === `${message.messageId}-${index2}`} target={`chat-popover-${message.messageId}-${index2}`}>
                                      <PopoverBody className="last-round-popover-body">
                                        {/*  {`${words[index2]}`} */}
                                        {`${message.message}`}
                                      </PopoverBody>
                                    </Popover>
                                  </>
                                ) : (
                                  <>
                                    {`${word} `}
                                  </>
                                )}
                              </>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  </LinkifyWithTargetBlank>
                </Col>
                <Col sm="12" className="chat-message-col">
                  <div className="chat-message-user-time">
                    { message.time && (
                    <CustomDate format="DD/ hh:mm" date={message.time} />
                    )}
                  </div>
                </Col>
              </Row>
            </Fragment>
          ) : (
            <Fragment>
              <Row>
                <Col md="1" className="chat-message-col">
                  <Media
                    className="chat-message-other-image"
                    src={message.userPhoto || defaultImage}
                    alt="photo"
                  />

                </Col>
                <Col sm="9" className="chat-message-col">
                  <Row>
                    <Col sm="12">
                      <LinkifyWithTargetBlank>
                        <div className="chat-message-other" style={{position: 'relative'}}>
                          {splitFilteredMessage.map((row, index) => {
                            /*  let words = [];
                            if (splitMessage[index]) {
                              words = splitMessage[index].split(/\n/);
                            }

                            let filteredWords = row.split(/\n/); */

                              let words = [];
                              if (splitMessage[index]) {
                                words = splitMessage[index].split(' ');
                              }
                              const filteredWords = row.split(' ');

                              return (
                                <p key={index} style={{ marginBottom: 0, lineHeight: '20px' }}>
                                  {filteredWords.map((word, index2) => (
                                    <>
                                      {(words[index2] !== word) ? (
                                        <>
                                          <div
                                            style={{ display: 'inline-block', marginRight: 2 }}
                                            id={`chat-popover-${message.messageId}-${index2}`}
                                            onMouseEnter={() => this.onHover(`${message.messageId}-${index2}`)}
                                            onMouseLeave={this.onHoverLeave}
                                          >
                                            {`${word} `}
                                          </div>
                                          <Popover container={'div > div'} popperClassName={`last-round-popover popover-font-${fontMode}`} placement="top" isOpen={popoverOpen === `${message.messageId}-${index2}`} target={`chat-popover-${message.messageId}-${index2}`}>
                                            <PopoverBody className="last-round-popover-body">
                                              {/*  {`${words[index2]}`}  */}
                                              {`${message.message}`}
                                            </PopoverBody>
                                          </Popover>
                                          
                                        </>
                                      ) : (
                                        <>
                                          {`${word} `}
                                        </>
                                      )}
                                      
                                    </>
                                  ))}
                                </p>
                              );

                              /*  filteredWords.map((word, index2) => {

                              if (words[index2] !== word) {
                                return (
                                  <p key={index} style={{ marginBottom: 0, lineHeight: '20px' }}>
                                    {`hover ${word}`}
                                  </p>
                                )
                              } else {
                                return (
                                  <p key={index} style={{ marginBottom: 0, lineHeight: '20px' }}>
                                    {word}
                                  </p>
                                )
                              }
                            }) */
                            })}
                            <div
                              id={`report-message-${messageKey}`}
                              onMouseEnter={() => this.onHoverReport(`report-message-${messageKey}`)}
                              className='report-message-other-report-image'
                              style={{position: 'absolute', right: '-20px', top: '0px'}}
                              onMouseLeave={() => this.onHoverReportLeave()}
                              onClick={(!isReportedMessage && !loading) ? this.handleReportMessage : null}
                            >
                              <Media src={!isReportedMessage ? reportMessageImg : reportedMessageImg} style={{ width: '18px', height: '18px' }} />
                            </div>
                            {messageKey && (
                              <Popover
                                container={'div > div'}
                                placement="left"
                                isOpen={reportPopoverOpen === `report-message-${messageKey}`}
                                target={`report-message-${messageKey}`}
                                popperClassName={`last-round-popover popover-font-${fontMode}`}
                              >
                                <PopoverBody
                                  onMouseLeave={this.onHoverReportLeave}
                                >
                                  {isReportedMessage ? t('reportedMessage') : t('reportMessage')}
                                </PopoverBody>
                              </Popover>
                            )}
                          </div>
                      </LinkifyWithTargetBlank>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  { message.time && (
                  <div className="chat-message-other-time chat-message-other-time-game">
                    <CustomDate format="DD/ hh:mm" date={message.time} />
                    {`${(message.userName.split(" "))[0]} ${((message.userName.split(" "))[1]) ? (((message.userName.split(" "))[1])?.charAt(0)) : ''}`}
                  </div>
                  )}
                </Col>
              </Row>
            </Fragment>
          )}
        </Fragment>
      );
    }

    return null;
  }
}

/*
FilteredMessage.propTypes = {
  message: PropTypes.shape(),
  uid: PropTypes.string,
};

FilteredMessage.defaultProps = {
  message: {},
  uid: '',
};  */

const mapStateToProps = state => ({
  //name: state.member.name || '',
});

const mapDispatchToProps = {
  reportMessageFunc: reportMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(FilteredMessage));
