import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
// import classNames from 'classnames';

// import Row from 'reactstrap/lib/Row';
// import Col from 'reactstrap/lib/Col';
// import Media from 'reactstrap/lib/Media';

// import EndResultFines from './EndResultFines';
// import EndResultPules from './EndResultPules';
import ScoreTableSum3p from './ScoreTableSum3p';
import ScoreTableSum4p from './ScoreTableSum4p';
import ScoreTableSum2p from './ScoreTableSum2p';

import { getBetValue } from '../../../../../../common/services/data-service';
// import infoImg from '../../../../../../images/redesign/player/info.svg';

const ScoreTableSum = React.memo(({
  myPos, totalPnts, isEndResultTable, gameType, bet, t, pules, globalParams, players, showInfoModal, player1ShortName, player2ShortName, player3ShortName, player4ShortName, studentRoom,
}) => {

  const {
    minGames, gameState, fourPRoom, rPlayed, closeReason
  } = globalParams;

  let betRatio;

  if (bet) {
    betRatio = parseInt(bet.replace('1:', ''), 10);
  }

  let player1Pules = 0;
  let player2Pules = 0;
  let player3Pules = 0;
  let player4Pules = 0;

  Object.keys(pules).map((key) => {
    if (pules[key] && pules[key].status === 'active') {
      if (pules[key].player === 'player1') {
        player1Pules = player1Pules + 1;
      } else if (pules[key].player === 'player2') {
        player2Pules = player2Pules + 1;
      } else if (pules[key].player === 'player3') {
        player3Pules = player3Pules + 1;
      } else if (pules[key].player === 'player4') {
        player4Pules = player4Pules + 1;
      }
    }
  })

  let betValue;
  let leavePenalty;
  let leaveReward;
  let penaltyPlayer;

  if (players && closeReason && closeReason.reason && (closeReason.reason === 'missedTurn' || closeReason.reason === 'leftRoom')) {
    betValue = getBetValue(bet);

    if (players.player1 && players.player1.uid === closeReason.playerUid) {
      penaltyPlayer = 'player1';
    } else if (players.player2 && players.player2.uid === closeReason.playerUid) {
      penaltyPlayer = 'player2';
    } else if (players.player3 && players.player3.uid === closeReason.playerUid) {
      penaltyPlayer = 'player3';
    }

    if (closeReason.isAutomated) {
      leavePenalty = 0;
      leaveReward = 0;
    } else {
      leavePenalty = fourPRoom ? Math.round(betValue * 24) : Math.round(betValue * 16);
      leaveReward = Math.round(0.25 * leavePenalty);
    }

    console.log('closeReason', { closeReason, minGames, rPlayed });

    if (closeReason.reason === 'leftRoom') {
      if (minGames && (rPlayed || rPlayed === 0) && minGames > (rPlayed) && betValue) {
        leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

        leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
      }
    } else if (minGames && (rPlayed || rPlayed === 0) && minGames > rPlayed && betValue) {
      leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

      leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
    }
  }

  if (studentRoom) {
    return (
      <ScoreTableSum2p
        showInfoModal={showInfoModal}
        totalPnts={totalPnts}
        myPos={myPos}
        isEndResultTable
        gameType={gameType}
        bet={bet}
        closeReason={closeReason}
        t={t}
        player1Pules={player1Pules}
        player2Pules={player2Pules}
        player3Pules={player3Pules}
        globalParams={globalParams}
        players={players}
        player1ShortName={player1ShortName}
        player2ShortName={player2ShortName}
        player3ShortName={player3ShortName}
        player4ShortName={player4ShortName}
      />
    )
  }

  return (
    fourPRoom ? (
      <ScoreTableSum4p
        showInfoModal={showInfoModal}
        totalPnts={totalPnts}
        myPos={myPos}
        isEndResultTable
        gameType={gameType}
        bet={bet}
        closeReason={closeReason}
        t={t}
        player1Pules={player1Pules}
        player2Pules={player2Pules}
        player3Pules={player3Pules}
        player4Pules={player4Pules}
        globalParams={globalParams}
        players={players}
        player1ShortName={player1ShortName}
        player2ShortName={player2ShortName}
        player3ShortName={player3ShortName}
        player4ShortName={player4ShortName}
      />
    ) : (
      <ScoreTableSum3p
        showInfoModal={showInfoModal}
        totalPnts={totalPnts}
        myPos={myPos}
        isEndResultTable
        gameType={gameType}
        bet={bet}
        closeReason={closeReason}
        t={t}
        player1Pules={player1Pules}
        player2Pules={player2Pules}
        player3Pules={player3Pules}
        globalParams={globalParams}
        players={players}
        player1ShortName={player1ShortName}
        player2ShortName={player2ShortName}
        player3ShortName={player3ShortName}
        player4ShortName={player4ShortName}
      />
    )
  );
});

ScoreTableSum.propTypes = {
  totalPnts: PropTypes.shape(),
  myPos: PropTypes.string,
  isEndResultTable: PropTypes.bool,
  t: PropTypes.func.isRequired,
  showInfoModal: PropTypes.func.isRequired,
};

ScoreTableSum.defaultProps = {
  totalPnts: {},
  myPos: '',
  isEndResultTable: false,
};

const mapStateToProps = (state, ownProps) => {
  if (state.game) {
    return ({
      pules: state.points.pules || {},
      myPos: state.game.myPos || null,
      globalParams: state.game.globalParams || {},
      players: state.game.players,
    });
  } else {
    return null;
  }
};

export default connect(mapStateToProps, null)(ScoreTableSum);
